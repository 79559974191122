<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4 rounded-lg">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">식사도움&nbsp;관찰기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="d-flex justify-center align-center greyE02" cols="7">
                    <div class="">
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                            </span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.name }}
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col class="tableBL py-1" cols="5">
                    <div class="">
                        <div class="d-flex justify-center tableBB pb-1">
                            <v-btn
                                color="blue004" style="padding: 0 10px 2px 6px !important"
                                :disabled="srvPkList[0] != 0" @click="getPrvsMealSrv()"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-text-box-search-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2">전일자료</span>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center pt-1">
                            <v-btn
                                color="green002" style="padding: 0 10px 2px 8px !important"
                                @click="onEmpMdl"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-account-circle-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2" style="padding-top: 1px;">{{ empInf.name }}</span>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div
            class=""
            v-for="(meal, i) in mealSrvList" :key="i">
            <v-row class="pr-2" no-gutters>
                <v-col class="d-flex justify-center align-center px-1 tableBR" cols="5">
                    <div>
                        <div class="d-flex justify-center align-center mt-2">
                            <span class="fontDotEight font-weight-medium mx-1" v-if="meal.itm1Cd == '1'">아침</span>
                            <span class="fontDotEight font-weight-medium mx-1" v-else-if="meal.itm1Cd == '3'">점심</span>
                            <span class="fontDotEight font-weight-medium mx-1" v-else-if="meal.itm1Cd == '5'">저녁</span>
                            <span class="fontDotEight font-weight-medium mx-1" v-else-if="meal.itm1Cd == '7'">오전간식</span>
                            <span class="fontDotEight font-weight-medium mx-1" v-else-if="meal.itm1Cd == '8'">오후간식</span>
                            <v-btn
                                v-if="Number(meal.itm1Cd) < 7"
                                style="margin-top: 3px;"
                                @click="meal.itm2Val = ''"
                                x-small icon>
                                <v-icon size="16">mdi-eraser</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center py-2">
                            <span class="d-inline-block" style="width: 52px;">
                                <CmmTmField v-model="meal.ofrTm" :required="true" :disabled="true"></CmmTmField>
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col class="pa-1" cols="7">
                    <div v-if="Number(meal.itm1Cd) < 7">
                        <v-row class="pa-0" no-gutters>
                            <v-col v-for="(mealTyp, i) in vCd14" :key="i">
                                <v-btn
                                    :class="[meal.itm1Val == mealTyp.valcd ? 'blue001 white--text' : '']"
                                    min-width="47" max-width="47" :ripple="false"
                                    style="padding: 0px 2px 1px 2px; margin-bottom: 2px;"
                                    @click="meal.itm1Val = mealTyp.valcd"
                                    small outlined>
                                    <span class="fontDotEight">{{ mealTyp.valcdnm }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2" style="margin-top: 6px;"></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col v-for="(mealAmt, j) in vCd15" :key="j">
                                <v-btn
                                    :class="[meal.itm2Val == mealAmt.valcd ? 'orange001 white--text' : '']"
                                    min-width="47" max-width="47" :ripple="false"
                                    style="padding: 0px 2px 1px 2px; margin-bottom: 2px;"
                                    @click="meal.itm2Val = mealAmt.valcd"
                                    small outlined>
                                    <span class="fontDotSeven">{{ mealAmt.valcdnm }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        v-else-if="Number(meal.itm1Cd) > 6"
                        class="d-inline-block d-flex justify-center align-center"
                        style="width: 100%; height: 100%;">
                        <v-btn
                            :class="['mr-1',  meal.itm1Val == '1' ? 'blue001 white--text' : '']"
                            min-width="100" max-width="100" :ripple="false"
                            style="padding: 0px 2px 0px 2px; margin-bottom: 2px;"
                            @click="
                                if(meal.itm1Val == '1') meal.itm1Val = '2' 
                                else meal.itm1Val = '1'
                            "
                            small outlined>
                            <span class="fontDotEight" v-if="meal.itm1Cd == '7'">오전</span>
                            <span class="fontDotEight" v-else-if="meal.itm1Cd == '8'">오후</span>
                            <span class="fontDotEight">간식제공</span>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div v-if="$store.state.bnftcrdCtgMdl.bccObj.intbNutrYn == '1'">
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 txtAlgnMid pa-2" cols="12">
                    <span class="fontDotEight font-weight-bold">
                        경관식&nbsp;제공기록&nbsp;&#40;필요시&#41;
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid pa-2" cols="4">
                    <span class="fontDotEight font-weight-bold">경관영양</span>
                </v-col>
                <v-col class="txtAlgnMid tablaBL pa-2" cols="8">
                    <v-btn
                        v-for="(lqd, l) in vCd30" :key="l"
                        :class="['mr-2',  lqdTyp == lqd.valcd ? 'orange001 white--text' : '']"
                        min-width="70" max-width="70" :ripple="false"
                        style="padding: 0px 2px 0px 2px; margin-bottom: 2px;"
                        @click="lqdTyp = lqd.valcd"
                        small outlined>
                        <span class="fontDotEight">{{ lqd.valcdnm }}</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center tableBR py-2" cols="2">
                    <span class="fontDotEight font-weight-bold">횟수</span>
                </v-col>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center tableBR py-2" cols="4">
                    <span class="fontDotEight font-weight-bold">식사종류</span>
                </v-col>
                <v-col class="nmlBfClr-grey001 txtAlgnMid tableBR py-2" style="line-height: 16px;" cols="3">
                    <span class="fontDotEight font-weight-bold">식사량&#40;ml&#41;</span>
                </v-col>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center py-2" cols="3">
                    <span class="fontDotEight font-weight-bold">식사시간</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 210px;">
                <v-row class="mr-2 tableBB" v-for="(lqd, k) in lqdSrvList" :key="k" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="2">
                        <span class="fontDotEight">{{ k+1 }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR px-1 py-2" cols="4">
                        <v-select
                            v-model="lqd.itm2Val"
                            class="sltFld" style="margin-top: 1px;" :items="vCd91"
                            item-text="valcdnm" item-value="valcd"
                            dense outlined hide-details>
                        </v-select>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR px-1 py-2" cols="3">
                        <v-text-field
                            class="txtFld" style="margin-top: 1px;"
                            v-model="lqd.itm3Val"
                            dense outlined hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="3">
                        <span class="d-inline-block" style="width: 52px;">
                            <CmmTmField v-model="lqd.ofrTm" :required="true"></CmmTmField>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="stkMdlBottom py-4">
            <v-row class="pr-2" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveBtn ? '' : 'blue001 white--text']"
                    @click="preMealSrvRcrdList()" :disabled="saveBtn"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <!-- <div>
            {{ lqdSrvList }}
        </div> -->
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmTmField from '../../cmm/CmmTmField.vue';

export default {
    name: 'MealSrvRcrdMdl',

    components: {
        CmmTmField,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getMealSrvRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }

            this.empInf.name = this.$store.getters.getUserNm
            this.empInf.mmbrPk = this.$store.getters.getUserPk
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
        getEmpSlctMdl: function () {
            return this.$store.getters.getSlctEmpMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getMealSrvRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }
        },
        getEmpSlctMdl: function (v) {
            if(v == false)
                this.empInf = this.$store.getters.getSlctEmpObj
        },
    },
        
    methods: {
        // 식사하기 조회
        getMealSrvRcrdInfo: function (pk, ymd){
            this.saveBtn = true
            
            http.post( 'empApp/authSelMealInfo', { bnMmbrPk: pk, inqYMD: ymd } )
                .then((response) => ( this.mealSrvRcrdInfoRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelMealInfo : ' + error))

        },
        // 식사하기 저장
        setMealSrvRcrdList: function (arr){            
            http.post( 'empApp/authInsBnftOfrRecMulti', { list: arr } )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsBnftOfrRecMulti : ' + error))
        },
        mealSrvRcrdInfoRst: function (res){
            
            if(res.statusCode == '200'){
                // 코드 저장
                this.vCd01 = res.code.vCd1
                this.vCd14 = res.code.vCd14.filter( v => v.valcdnm != '간식' )
                this.vCd15 = res.code.vCd15
                this.vCd30 = res.code.vCd30
                this.vCd91 = res.code.vCd91

                // 데이터 맵핑
                if(res.recList.length > 0)
                    this.extOrgList(res.recList, res.bnfcrDiets, 'now')
                else
                    this.nonExtOrgList(res.bnfcrDiets)
                
                // 데이터 조회 종료시 저장 버튼 활성화
                this.saveBtn = false

            }
        },
        prvsMealSrvRcrdInfoRst: function (res){
            if(res.statusCode == '200'){
                // 데이터 맵핑
                if(res.recList.length == 0){
                    let obj = {}
                    obj.clr = 'warning'

                    obj.cntn = '조회된 전일자료가 없습니다.'

                    this.$store.commit('setCmmAlertMdl', true)
                    this.$store.commit('setCmmAlertCntn', obj)
                }
                else
                    this.extOrgList(res.recList, 'prvs')
                 
                // 데이터 조회 종료시 저장 버튼 활성화
                this.saveBtn = false
            }
        },
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
                this.$parent.$parent.$parent.$parent.getMobAllBnftMealSttList(
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
                this.getMealSrvRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        preMealSrvRcrdList: function (){
            this.saveBtn = true

            // 공통 데이터 작성
            let obj = {}
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.wrtrMmbrPk = 0
            obj.wrtr = ''
            obj.userNm = ''
            obj.clsfc1Cd = '301'
            obj.clsfc2Cd = '2'
            obj.clsfc3Cd = ''
            obj.listDtl = null
       
            // 식사 정보작성
            let mealObj = Object.assign({}, obj)
            // mealObj.bnftOfrRecHisPk = this.srvPkList[0]
            mealObj.bnftOfrRecHisPk = null
            mealObj.wrtrMmbrPk = this.empInf.mmbrPk
            mealObj.wrtr = this.empInf.name
            mealObj.userNm = this.empInf.name
            mealObj.clsfc3Cd = '1'
            mealObj.listDtl = []
            
            this.mealSrvList.forEach(meal => {
                let mlObk = Object.assign({}, meal)
                mealObj.listDtl.push(mlObk)
            });

            // 경관식 정보작성
            let lqdObj = Object.assign({}, obj)
            if(this.$store.state.bnftcrdCtgMdl.bccObj.intbNutrYn == '1'){
                // lqdObj.bnftOfrRecHisPk = this.srvPkList[1]
                lqdObj.bnftOfrRecHisPk = null
                if(this.wrObj.wrtrMmbrPk == 0){
                    lqdObj.wrtrMmbrPk = this.$store.getters.getUserPk
                    lqdObj.wrtr = this.$store.getters.getUserNm
                    lqdObj.userNm = this.$store.getters.getUserNm
                }
                else{
                    lqdObj.wrtrMmbrPk = this.wrObj.wrtrMmbrPk
                    lqdObj.wrtr = this.wrObj.wrtr
                    lqdObj.userNm = this.wrObj.wrtr
                }
                lqdObj.clsfc3Cd = '2'
                lqdObj.listDtl = []

                let lqdTypRow = Object.assign({}, this.lqdSrvInf)
                lqdTypRow.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                lqdTypRow.itm2Cd = null
                lqdTypRow.itm3Cd = null
                lqdTypRow.itm1Val = this.lqdTyp
                lqdTypRow.bnftOfrRec301Pk =  this.wrObj.bnftOfrRec301Pk
           
                if(lqdTypRow.itm1Val != '')
                    lqdObj.listDtl.push(lqdTypRow)

                this.lqdSrvList.forEach(lqd => {
                    let lqObk = Object.assign({}, lqd)
                    lqObk.itm1Val = this.lqdTyp
                    if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(lqObk.ofrTm)){
                        lqObk.ofrTmHhStrt = lqObk.ofrTm?.split(':', 2)[0]
                        lqObk.ofrTmMmStrt = lqObk.ofrTm?.split(':', 2)[1]
                    }

                    if(lqObk.itm2Val != '' && lqObk.itm3Val != '' && lqObk.ofrTmHhStrt != '' && lqObk.ofrTmMmStrt != '')
                        lqdObj.listDtl.push(lqObk)
                });
            }
            else{
                lqdObj.bnftOfrRecHisPk = null
                lqdObj.listDtl = []
            }

            let rst = []
            rst.push(mealObj)
            if(lqdObj.listDtl.length > 0)
                rst.push(lqdObj)
           
            // console.log(rst)
            this.setMealSrvRcrdList(rst)

        },
        // 저장된 데이터가 존재하는 경우
        extOrgList: function (orgArr, initArr, mode) {
            if(mode == 'prvs' && this.srvPkList[1] > 0)
                this.cleanData('prvs')
            else 
                this.cleanData('now')

            // recList에서 비위관 정보를 제외한 식사정보 추출
            let mArr = orgArr.filter( v => v.clsfc3Cd == '1' )
            // recList에서 비위관 정보 추출
            let qArr = orgArr.filter( v => v.clsfc3Cd == '2' )
     
            // 식사정보 데이터 정리
            if(mArr.length == 0)
                this.aplyInitList(initArr)
            else{
                mArr.forEach(list => {
                    if(mode == 'now')
                        this.srvPkList.splice(0, 1, list.bnftOfrRecHisPk)

                    let obj = Object.assign({}, this.mealSrvInf)
                    obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')

                    if(mode == 'now')
                        obj.bnftOfrRec301Pk = list.bnftOfrRec301Pk

                    obj.wrtr = list.wrtr
                    obj.wrtrMmbrPk = list.wrtrMmbrPk
                    obj.ofrTmHhStrt = list.ofrTmHhStrt
                    obj.ofrTmMmStrt = list.ofrTmMmStrt
                    if(list.ofrTmHhStrt != '' || list.ofrTmMmStrt != '' )
                        obj.ofrTm = list.ofrTmHhStrt + ':' + list.ofrTmMmStrt
                    else
                        obj.ofrTm = ''
                    obj.itm1Cd = list.itm1Cd
                    obj.itm1Val = list.itm1Val
                    obj.itm2Cd = list.itm2Cd
                    obj.itm2Val = list.itm2Val

                    this.mealSrvList.push(obj)
                });

                // 내림차순으로 정렬
                this.mealSrvList.sort(
                    function (a, b) {
                        let x = a.itm1Cd
                        let y = b.itm1Cd

                        if (x < y)
                            return -1
                        if (x > y)
                            return 1

                        return 0
                })
            }

            // 비위관 정보 정리
            if(this.srvPkList[1] == 0){
                qArr.forEach(lqdList => {
                    if(mode == 'now')
                        this.srvPkList.splice(1, 1, lqdList.bnftOfrRecHisPk)

                    if( lqdList.itm1Cd == '1' && lqdList.itm2Cd == null && lqdList.itm3Cd == null ){
                        this.lqdTyp = lqdList.itm1Val
                        this.wrObj.wrtr = lqdList.wrtr
                        this.wrObj.wrtrMmbrPk = lqdList.wrtrMmbrPk
                        this.wrObj.bnftOfrRec301Pk = lqdList.bnftOfrRec301Pk
                    }
                    else{
                        let obj = Object.assign({}, this.lqdSrvInf)
                        obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                        if(mode == 'now')
                            obj.bnftOfrRec301Pk = lqdList.bnftOfrRec301Pk
                        obj.ofrTmHhStrt = lqdList.ofrTmHhStrt
                        obj.ofrTmMmStrt = lqdList.ofrTmMmStrt
                        if(lqdList.ofrTmHhStrt != '' || lqdList.ofrTmMmStrt != '' )
                            obj.ofrTm = lqdList.ofrTmHhStrt + ':' + lqdList.ofrTmMmStrt
                        else
                            obj.ofrTm = ''
                        obj.itm1Val = lqdList.itm1Val
                        obj.itm2Val = lqdList.itm2Val
                        obj.itm3Val = lqdList.itm3Val

                        this.lqdSrvList.push(obj)
                    }
                });

                if(this.$store.state.bnftcrdCtgMdl.bccObj.intbNutrYn == '1')
                    this.fillLqdList()
            }
            
        },
        // 저장된 데이터가 없는 경우
        nonExtOrgList: function (initArr) {
            //console.log(initArr)
            this.cleanData('now')
            this.aplyInitList(initArr)

            if(this.$store.state.bnftcrdCtgMdl.bccObj.intbNutrYn == '1')
                this.fillLqdList()
         
        },
        // diets 리스트를 이용해 초기정보 추가
        aplyInitList: function (initArr){
            //초기 데이터 맵핑
            for (let index = 0; index < 5; index++) {
                let obj = Object.assign({}, this.mealSrvInf)
                obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                obj.mlClcd = (index + 1).toString()
                
                this.mealSrvList.push(obj)
            }
            
            // 코드 데이터 분류
            this.mealSrvList.forEach(diet => {
                switch (diet.mlClcd) {
                    case '1':
                        diet.itm1Cd = '1'
                        diet.itm2Cd = '2'
                        break;
                    case '2':
                        diet.itm1Cd = '3'
                        diet.itm2Cd = '4'
                        break;
                    case '3':
                        diet.itm1Cd = '5'
                        diet.itm2Cd = '6'
                        break;
                    case '4':
                        diet.itm1Cd = '7'
                        break;
                    case '5':
                        diet.itm1Cd = '8'
                        break;
                    default:
                        break;
                }

                let idx = initArr.findIndex( v => v.mlClcd == diet.mlClcd )
                if(idx > -1){
                    if(Number(diet.itm1Cd) < 7)
                        diet.itm1Val = initArr[idx].mlTycd
                    
                    diet.ofrTmHhStrt = initArr[idx].mlTmHh
                    diet.ofrTmMmStrt = initArr[idx].mlTmMm
                    if(initArr[idx].mlTmHh != '' || initArr[idx].mlTmMm != '' )
                        diet.ofrTm = initArr[idx].mlTmHh + ':' + initArr[idx].mlTmMm
                    else
                        diet.ofrTm = ''
                }

            });
        },
        // 경관식 목록을 일정량으로 맞춤
        fillLqdList: function () {
            if(this.lqdSrvList.length < 20){
                let cnt = 20-this.lqdSrvList.length
                for (let index = 0; index < cnt; index++) {
                    let obj = Object.assign({}, this.lqdSrvInf)
                    obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')

                    this.lqdSrvList.push(obj)   
                }
            }
        },
        // 전일자료 조회
        getPrvsMealSrv: function () {
            http.post( 'empApp/authPreMealSrvRcrdInfo', {
                bnMmbrPk: this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                inqYMD: this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            } )
                .then((response) => ( this.prvsMealSrvRcrdInfoRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authPreMealSrvRcrdInfo : ' + error))
        },
        cleanData: function (mode) {
            this.srvPkList.splice(0, 1, 0)
            this.mealSrvList.splice(0)
            this.lqdTyp = ''

            if(mode == 'now'){
                this.lqdSrvList.splice(0)
                this.srvPkList.splice(1, 1, 0)
            }
        },
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },
        onEmpMdl: function () {
            this.$store.commit('setSlctEmpMdl', true)
            this.$store.commit('setSlctEmpDt', this.$store.getters.getBnftcrdCtgObj.inqYMD)
        },
    },

    data: () => ({
        vCd01: [],                  // 유 무 
        vCd14: [],                  // 식사종류코드( 1:일반식, 2:다진식, 3:죽식, 4:경관식 )
        vCd15: [],                  // 식사량코드( 1:1정량, 2:1/2이상, 3:1/2미만, 4:식사못함 )
        vCd30: [],                  // 경관영양코드( 1:비위관, 2:위루관 )
        vCd91: [],                  // 경관식목록
        saveBtn: true,              // 저장 버튼 활성여부
        lqdTyp: '',
        empInf: { name: '', mmbrPk: 0 },
        wrObj: { bnftOfrRec301Pk:0, wrtr: '', wrtrMmbrPk: 0 },
        srvPkList: [0, 0],          // 식시도움, 경관영양 PK
        mealSrvList: [],
        lqdSrvList: [],
        mealSrvInf: {
            bnftOfrRec301Pk: 0, recDt: '', wrtr: '', wrtrMmbrPk: 0, clsfc1Cd: '301', clsfc2Cd: '2', clsfc3Cd: '1',
            ofrTmHhStrt: '', ofrTmMmStrt: '', itm1Cd: '', itm1Val: '', itm2Cd: '', itm2Val: '',
        },
        lqdSrvInf: {
            bnftOfrRec301Pk: 0, recDt: '', wrtr: '', wrtrMmbrPk: 0, clsfc1Cd: '301', clsfc2Cd: '2', clsfc3Cd: '2',
            ofrTmHhStrt: '', ofrTmMmStrt: '', ofrTm: '',
            itm1Cd: '1', itm1Val: '', itm2Cd: '2', itm2Val: '', itm3Cd: '3', itm3Val: '',
        },
    }),
};
</script>