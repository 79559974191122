<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">집중배설&nbsp;관찰&nbsp;기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 pa-2" cols="12">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-bold">
                            {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                        </span>
                        <span class="fontDotEight font-weight-bold ml-1">
                            {{ $store.getters.getBnftcrdCtgObj.name }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-divider class="mr-2 mt-3"></v-divider>
            <v-row class="greyE02 mr-2" no-gutters>
                <v-col class="txtAlgnMid pa-1" cols="2">
                    <span class="fontDotEight font-weight-bold">시</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="2">
                    <span class="fontDotEight font-weight-bold">분</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="4">
                    <span class="fontDotEight font-weight-bold">작성자</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="4">
                    <span class="fontDotEight font-weight-bold">처리</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div class="pb-3">
            <v-row class="mr-2" v-for="(rec, i) in recList" :key="i" no-gutters>
                <v-col
                    v-if="recInf.ofrTmHhStrt == rec.ofrTmHhStrt && recInf.recDt == rec.recDt"
                    class="nmlBfClr-grey001 d-flex justify-center align-center tableBB py-2"
                    cols="12">
                    <v-icon class="black--text font-weight-bold" size="18">mdi-arrow-down-circle-outline</v-icon>
                    <span class="ml-2 fontDotEight font-weight-bold">집중배설관찰 시작</span>
                </v-col>
                <v-col
                    v-if="recInf.ofrTmHhEnd == rec.ofrTmHhStrt && recInf.recEndDt == rec.recDt"
                    class="nmlBfClr-grey001 d-flex justify-center align-center tableBB py-2"
                    cols="12">
                    <v-icon class="black--text font-weight-bold" size="18">mdi-arrow-up-circle-outline</v-icon>
                    <span class="ml-2 fontDotEight font-weight-bold">집중배설관찰 종료</span>
                </v-col>
                <v-col
                    :class="['txtAlgnMid tableBB py-1', rec.trgChk ? 'nmlBfClr-yellow001' : '']"
                    cols="2">
                    <span class="fontDotEight">{{ rec.ofrTmHhStrt }}</span>
                </v-col>
                <v-col 
                    :class="['txtAlgnMid tableBL tableBB py-1', rec.trgChk ? 'nmlBfClr-yellow001' : '']"
                    cols="2">
                    <span class="fontDotEight">{{ rec.ofrTmMmStrt }}</span>
                </v-col>
                <v-col
                    :class="['txtAlgnMid tableBL tableBB py-1', rec.trgChk ? 'nmlBfClr-yellow001' : '']"
                    cols="4">
                    <span class="fontDotEight">{{ rec.wrtr }}</span>
                </v-col>
                <v-col
                    :class="['txtAlgnMid tableBL tableBB py-1', rec.trgChk ? 'nmlBfClr-yellow001' : '']"
                    cols="4">
                    <v-btn
                        style="margin-top: 3px;" color="blue001" @click="mdlChecker(i)"
                        x-small icon>
                        <v-icon size="24">mdi-square-edit-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="mdlCntl" persistand>
            <CnexObsrvDetailMdl v-if="mdlCntl"></CnexObsrvDetailMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CnexObsrvDetailMdl from './CnexObsrvDetailMdl';

export default {
    name: 'CncntExcrtObsrvRcrdMdl',

    components: {
        CnexObsrvDetailMdl,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getExrtSttInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                )
            }
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getExrtSttInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                )
            }
        },
    },
        
    methods: {
        // 집중배설 관찰 기록 조회
        getExrtSttInf: function (pk, ymd){
            this.saveBtn = true
            this.cleanData()
   
            http.post( 'empApp/authSelExrtSttInfo', { bnMmbrPk: pk, inqYMD: ymd } )
                .then((response) => ( this.exrtSttRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelExrtSttInfo : ' + error))
        },

        exrtSttRst: function (res){
            if(res.statusCode == '200'){
                // 조회된 분류코드를 반영
                if(Object.keys(res.code).length > 0){
                    if(this.vCd67.length == 0)
                        this.vCd67 = res.code.vCd67
                    if(this.vCd68.length == 0)
                        this.vCd68 = res.code.vCd68
                }

                // 조회된 데이터가 있으면 반영
                if(Object.keys(res.recInfo).length > 0){
                    this.recInf.preData = res.recInfo.preData
                    this.recInf.preDataChk = res.recInfo.preDataChk
                    this.recInf.bnftOfrRecHisPk = res.recInfo.bnftOfrRecHisPk

                    this.recInf.obsSttCd = res.recInfo.rec1Items.itm1Val
                    this.recInf.ofrTmHhStrt = res.recInfo.rec1Items.ofrTmHhStrt
                    this.recInf.recDt = res.recInfo.rec1Items.recDt

                    let tmpMomt = this.recInf.recDt + ' ' + this.recInf.ofrTmHhStrt + ':00:00'
                    this.recInf.ofrTmHhEnd = this.$moment(tmpMomt, 'YYYYMMDD HH:mm:ss').add(72, 'hours').format('HH')
                    this.recInf.recEndDt = this.$moment(tmpMomt, 'YYYYMMDD HH:mm:ss').add(72, 'hours').format('YYYYMMDD')
                    
                    this.tmTblSetter(res.recInfo.rec2List)

                }
             
                this.saveBtn = false
            }
        },
      
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getAllBnftExrtSttList(
                    this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )

                this.getExrtSttInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                )

            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        tmTblSetter: function (list) {

            for (let index = 0; index < 24; index++) {
                let chc = list.findIndex( v => v.ofrTmHhStrt == ('00' + index).slice(-2) )
                if(chc > -1){
                    if(list[chc].ofrTmMmStrt == null )
                        list[chc].ofrTmMmStrt = '00'

                    this.recList.push(list[chc])
                }
                else{
                    let tmp = Object.assign({}, this.dmyRecInf)
                    tmp.ofrTmHhStrt = ('00' + index).slice(-2)
                    tmp.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')

                    this.recList.push(tmp)
                }

                let ystDt = this.$moment(this.recInf.recDt + ' ' + this.recInf.ofrTmHhStrt + ':00:00', 'YYYYMMDD HH:mm:ss').subtract(1, 'hours')
                let trgDt = this.$moment(this.recList[index].recDt + ' ' + this.recList[index].ofrTmHhStrt + ':00:00', 'YYYYMMDD HH:mm:ss')
                let tmrDt = this.$moment(this.recInf.recEndDt + ' ' + this.recInf.ofrTmHhEnd + ':00:00', 'YYYYMMDD HH:mm:ss')

                this.recList[index].trgChk = trgDt.isBetween(ystDt, tmrDt)
            }

        },

        mdlChecker: function (idx) {
            this.sltRecIdx = idx
            
            switch (this.recList[idx].wrtr) {
                case null:
                case '':
                case this.$store.getters.getUserNm:
                    this.mdlCntl = true
                    break;
                default:
                    this.$store.commit('setCmmAlertMdl', true)
                    this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '다른 유저가 작성한 데이터는 수정 할 수 없습니다.' })
                    break;
            }
        },

        cleanData: function () {
            this.recList.splice(0)
        },

        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },
    },

    data: () => ({
        prvsBtn: true,      // 전일자료 버튼 활성여부
        saveBtn: true,      // 저장 버튼 활성여부
        mdlCntl: false,
        sltRecIdx: -1,
        vCd67: [],
        vCd68: [],
        recList: [],
        recInf: {
            // 작성 불가능시 작성해야 하는 날짜안내, 작성 가능 여부, pk, 관찰상태코드, 관찰 시작 시간, 관찰 시작일
            preData: '', preDataChk: '', bnftOfrRecHisPk: 0, obsSttCd: '', ofrTmHhStrt: '', recDt: ',',
            ofrTmHhEnd: '', recEndDt: ',',
        },
        dmyRecInf: {
            bnftOfrRecHisPk: 0, bnftOfrRecPk: 0, clsfc1Cd: '301', clsfc2Cd: '3', clsfc3Cd: '2',
            ofrTmHhStrt: '', ofrTmMmStrt: '00', recDt: '', wrtr: '', wrtrMmbrPk: 0,
            itm1Val: '', itm2Val: '', itm3Val: '', itm4Val: '', itm5Val: '', itm6Val: '', itm7Val: '', itm8Val: '',
        },
    }),
};
</script>