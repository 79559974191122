<template>
    <v-sheet class="" width="100%">
        <v-row class="mt-5 pa-0" no-gutters></v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <!-- calendar -->
                <CmmClndr :clndrList="events" :vsbYn="crndrYn" @chgDtAct="getPickerDate"></CmmClndr>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="6">
                <div class="d-flex align-center" style="margin-bottom: 1px;">
                    <CmmDrawIcon type="ic_time" style="margin-top: 1px;" size="22"></CmmDrawIcon>
                    <span class="fontDotEight font-weight-bold blue105--text ml-1">출퇴근처리</span>
                </div>
                <div class="overflow-text-hidden px-1">
                    <span class="fontDotNine font-weight-bold">{{ $store.getters.getPrsntDt('yyyy.mm.dd') }} {{ rlTime }}</span>
                </div>
            </v-col>
            <v-col class="" cols="5">
                <span class="d-inline-block" style="width: 51%; height: 48px; padding-top: 6px;">
                    <v-btn
                        class="px-2" width="97%" height="36" color="blue105"
                        @click="setEmpAtnd(nowDt, nowDt, null)"
                        :disabled="btnList[0]" small>
                        <CmmDrawIcon type="ic_sm_r_modal" style="margin-top: 1px;" size="22"></CmmDrawIcon>
                        <span class="fontDotEight white--text ml-1">출근</span>
                    </v-btn>
                </span>
                <span class="d-inline-block" style="width: 49%; height: 48px; padding-top: 6px;">
                    <v-btn
                        class="px-2" width="100%" height="36" color="blue106"
                        @click="setEmpAtnd(nowDt, null, nowDt)"
                        :disabled="btnList[1]" small>
                        <CmmDrawIcon type="ic_sm_l_modal" style="margin-top: 1px;" size="22"></CmmDrawIcon>
                        <span class="fontDotEight white--text ml-1">퇴근</span>
                    </v-btn>
                </span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center" cols="7">
                <CmmDrawIcon type="ic_time_pupple" style="margin-top: 1px;" size="22"></CmmDrawIcon>
                <span class="fontDotEight font-weight-bold blue107--text ml-1 mr-2">휴가처리</span>
                <span class="fontDotEight font-weight-bold">{{ $store.getters.getPrsntDt('yyyy.mm.dd') }}</span>
            </v-col>
            <v-col class="" cols="4">
                <v-btn
                    class="pr-4" width="100%" height="36" color="blue105"
                    @click="setEmpAtnd(nowDt, nowDt, nowDt)"
                    :disabled="btnList[2]" small>
                    <CmmDrawIcon type="ic_check" style="margin-top: 1px;" size="22"></CmmDrawIcon>
                    <span class="fontDotEight white--text ml-1">휴가처리</span>
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-sheet>
</template>

<script>

import { http } from '@/assets/js/baseAxios';

import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';
import CmmClndr from '../cmm/CmmClndr.vue';

export default {
    name: 'EmpMgmt',

    components: {
        CmmClndr,
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){
        this.nowDt = this.$store.getters.getPrsntDt('yyyymmdd')
    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '내 정보 ' + ' ( ' + this.$store.getters.getUserNm + ' )'

            this.rlTime = this.$moment().format('HH:mm:ss')
            setInterval(()=>{
                this.rlTime = this.$moment().format('HH:mm:ss')
            },1000)

            if(this.$store.getters.getToken != ''){
                this.getEmpList(this.$store.getters.getPrsntDt('yyyymm'), null)
                this.getAtndYnList(this.$store.getters.getPrsntDt('yyyymmdd'))
            }
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        // 직원 정보 조회
        getEmpInfo: function (){
            http.post('empApp/selEmpInfo', {})
                .then((response) => ( this.empInfoRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/selEmpInfo : ' + error))
        },
        // 근무 정보 조회
        getEmpList: function (yymm, ymd){
            this.crndrYn = true

            http.post('empApp/authSelAtndList', { inqYYMM: yymm, inqYMD: ymd })
                .then((response) => ( this.empListRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelAtndList : ' + error))
        },
        // 직원 출퇴근 여부 조회
        getAtndYnList: function (ymd){
            http.post('empApp/authSelAtndYnList', { inqYMD: ymd })
                .then((response) => ( this.atndYnRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelAtndYnList : ' + error))
        },
        // 출퇴근 저장
        setEmpAtnd: function (ymd, atnd, leav){
            http.post('empApp/authInsAtndDt', { wrkDt: ymd, atndDt: atnd, leavDt: leav })
                .then((response) => ( this.empAtndtRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsAtndDt : ' + error))
        },
        empInfoRst: function (res){
            this.empInfo = res.result
        },
        empListRst: function (res){
            this.events.splice(0)
            
            if(res.statusCode == '200'){
                if(res.result.length > 0)
                    this.processWrkSchdl(res.result)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }

            this.crndrYn = false
        },
        atndYnRst: function (res){
            if(res.statusCode == '200'){
                if(res.result.length > 0){
                    this.btnList.splice(0, 1, true)
                    this.btnList.splice(1, 1, true)
                    this.btnList.splice(2, 1, true)

                    if(res.result.filter( v => v.wrkTyp != '2' ).length == 0){
                        if(res.result.filter( v => v.atndYn == '2' || v.leavYn == '2' ).length > 0)
                            this.btnList.splice(2, 1, false)
                        else
                            this.btnList.splice(2, 1, true)
                    }
                    else if(res.result.filter( v => v.atndYn == '2').length > 0)
                        this.btnList.splice(0, 1, false)
                    else if(res.result.filter( v => v.atndYn == '2').length == 0 && res.result.filter( v => v.leavYn == '2').length > 0)
                        this.btnList.splice(1, 1, false)
                }
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        empAtndtRst: function (res){
            let obj = {}
            if(res.statusCode == '200')
                obj.clr = 'info'
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)

            this.getEmpList(this.$store.getters.getPrsntDt('yyyymm'), null)
            this.getAtndYnList(this.$store.getters.getPrsntDt('yyyymmdd'))
        },
        preEmpAtnd: function (mode) {
            let obj = Object.assign({}, this.empInfo)
            obj.wrkTypCd = obj.esWrkTypCd
            obj.wrkDt = this.$store.getters.getPrsntDt('yyyymmdd')

            if(mode == '1'){
                obj.atndDt = this.$store.getters.getPrsntDt('yyyymmdd')
                obj.atndTmHh = obj.esWrkStrt.split(':', 2)[0]
                obj.atndTmMm = obj.esWrkStrt.split(':', 2)[1]
            }
            else if(mode == '2'){
                obj.leavDt = this.$store.getters.getPrsntDt('yyyymmdd')
                obj.leavTmHh = obj.esWrkEnd.split(':', 2)[0]
                obj.leavTmMm = obj.esWrkEnd.split(':', 2)[1]
            }

            this.setEmpAtnd(obj)
        },
        // 월간 근무 예정스케쥴을 캘린더 데이터로 가공
        processWrkSchdl: function (arr) {
            arr.forEach(itm => {
                let obj = {}
                obj.start = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')
                obj.end = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')

                if(itm.atndTmHh == null && itm.atndTmMm == null)
                    obj.atndYn = '2'
                else
                    obj.atndYn = '1'
                if(itm.leavTmHh == null && itm.leavTmMm == null)
                    obj.leavYn = '2'
                else
                    obj.leavYn = '1'

                if( obj.atndYn == '1' && obj.leavYn == '2' )
                    obj.color = '#706DFF'
                else if( obj.atndYn == '1' && obj.leavYn == '1' )
                    obj.color = '#D9D9D9'
                else
                    obj.color = '#8E8E8E'

                this.$store.getters.getCmmCode('vCd73').forEach(cd => {
                    if( itm.esWrkTypCd == cd.valcd )
                        obj.wrkTypNm = cd.valcdnm
                });
                this.$store.getters.getCmmCode('vCd107').forEach(cd => {
                    if( itm.esVctnTypCd == cd.valcd )
                        obj.vctnTypNm = cd.valcdnm
                });
                if(typeof obj.vctnTypNm == 'undefined')
                    obj.vctnTypNm = ''

                this.events.push(obj)
            });
        },
        getPickerDate: function (date) {
            this.getEmpList(this.$moment(date, 'YYYY-MM-DD').format('YYYYMM'), null)
            this.getAtndYnList(this.$store.getters.getPrsntDt('yyyymmdd'))
        },
    },

    data: () => ({
        nowDt: '',
        rlTime: '',
        crndrYn: true,
        empInfo: {
            color: '', esWrkTypCd: '', start: '', end: '', esWrkStrt: '', esWrkEnd: '', atndTyp: null, leavTyp: null,
            wrkOvnYn: '', brkDay: 0, brkNgt: 0, brkOvn: 0, actWrkHr: 0, wrkTypCd: '',
        },
        btnList: [ true, true, true ],
        events: [],
    }),
};
</script>