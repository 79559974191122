import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState(
        { paths: ['sessionInfo', 'prsntDt', 'cmmCode'], storage: window.sessionStorage },
    )],
    state: {
        //로그인 정보
        sessionInfo: { name: 0, mmbrPk: 0, jobNm: '', fcltyClnm:'', tkn: '', key: '', spr: '', celphn_1: 0, celphn_2: 0, celphn_3: '', },
        //현재 날짜 정보
        prsntDt: [
            { typ: 'yyyymmdd', dt: '' },
            { typ: 'yyyy-mm-dd', dt: '' },
            { typ: 'yyyy.mm.dd', dt: '' },
            { typ: 'yyyymm', dt: '' }, { typ: 'yyyy-mm', dt: '' }, { typ: 'yyyy.mm', dt: '' },
            { typ: 'yyyy', dt: '' }, { typ: 'mm', dt: '' }, { typ: 'dd', dt: '' },
        ],
        cmmCode: [
            { typ: 'vCd42', list: [] }, { typ: 'vCd45', list: [] },
            { typ: 'vCd65', list: [] }, { typ: 'vCd73', list: [] },
            { typ: 'vCd107', list: [] }
        ],
        crypKey: 'bd948bbd4b067e198b65a715957ee829',
        signAgrMdl: false,                      //동의 사인 팝업
        signAgrObj: { eDocClcd: '' },           //동의 사인 전송 데이터
        cmmBtmMdl: false,                       //공통 하단 팝업
        pickDtMdl: false,                       //공통 날짜 팝업
        pickDtMdlTyp: 'YMD',                    //공통 날짜 팝업 형식 선택
        pickDtMdlMin: '',                       //공통 날짜 팝업 최소일 지정
        pickDtMdlMax: '',                       //공통 날짜 팝업 최대일 지정
        pickDt: '',                             //공통 날짜 팝업에 지정된 날짜
        pickLvnRmMdl: false,                    //공통 생활실 선택 팝업
        pickLvnRmNm: '전체',                     //공통 생활실 선택 팝업에 선택된 생활실
        slctEmpMdl : false,                     //공통 직원 선택 팝업
        slctEmpDt : '',                         //공통 직원 선택 조회 날짜
        slctEmpObj : { name: '', mmbrPk: 0 },   //공통 직원 선택 적용 데이터
        cmmPrtMdl: false,                       //공통 PDF 팝업
        cmmPdfObj: {                            //공통 PDF 데이터
            pdfDstnt: '', pdfPth: '', pdfNm: '', pdfOrgNm: '', pdfTtl: ''
        },
        cmmAlertMdl: false,                     //공통 상단 알림 팝업
        cmmAlertCntn: { clr: 'info', cntn: '', },

        bnftcrdTotMdl: false,                       //요양급여제공 세부 팝업
        bnftcrdCtgMdl: { yNo: '', bccObj: {} },     //요양급여제공 세부 팝업 종류 (1. 요양급여,)

        pyrlSrvcMdl: false,                         //노인장기요양급여 세부 팝업
        pyrlSrvcCtgMdl: { yNo: '', bccObj: {} },    //노인장기요양급여 세부 팝업 종류 (1. 급여명세,)
    },
    getters: {
        getUserNm: function(state) {
            return state.sessionInfo.name
        },
        getUserPk: function(state) {
            return state.sessionInfo.mmbrPk
        },
        getJobName: function(state) {
            return state.sessionInfo.jobNm
        },
        getFcltyClnm: function(state) {
            return state.sessionInfo.fcltyClnm
        },
        getToken: function(state) {
            return state.sessionInfo.tkn
        },
        getSctKey: function(state) {
            return state.sessionInfo.key
        },
        getUserSpr: function(state) {
            return state.sessionInfo.spr
        },
        getPrsntDt: (state) => (type) => {
            return state.prsntDt.find(todo => todo.typ === type).dt
        },
        getCmmCode: (state) => (type) => {
            return state.cmmCode.find(todo => todo.typ === type).list
        },
        getCrypKey: function(state) {
            return state.crypKey
        },
        getCmmBtmMdl: function(state) {
            return state.cmmBtmMdl
        },
        getPickDtMdl: function(state) {
            return state.pickDtMdl
        },
        getPickDt: function(state) {
            return state.pickDt
        },
        getPickDtMdlTyp: function(state) {
            return state.pickDtMdlTyp
        },
        getPickDtMdlMin: function(state) {
            return state.pickDtMdlMin
        },
        getPickDtMdlMax: function(state) {
            return state.pickDtMdlMax
        },
        getPickLvnRmMdl: function(state) {
            return state.pickLvnRmMdl
        },
        getPickLvnRmNm: function(state) {
            return state.pickLvnRmNm
        },
        getSlctEmpMdl: function(state) {
            return state.slctEmpMdl
        },
        getSlctEmpDt: function(state) {
            return state.slctEmpDt
        },
        getSlctEmpObj: function(state) {
            return state.slctEmpObj
        },
        getCmmAlertMdl: function(state) {
            return state.cmmAlertMdl
        },
        getCmmAlertCntn: function(state) {
            return state.cmmAlertCntn
        },
        getCmmPrtMdl: function(state) {
            return state.cmmPrtMdl
        },
        getCmmPdfDstnt: function(state) {
            return state.cmmPdfObj.pdfDstnt
        },
        getCmmPdfPth: function(state) {
            return state.cmmPdfObj.pdfPth
        },
        getCmmPdfNm: function(state) {
            return state.cmmPdfObj.pdfNm
        },
        getCmmPdfOrgNm: function(state) {
            return state.cmmPdfObj.pdfOrgNm
        },
        getCmmPdfTtl: function(state) {
            return state.cmmPdfObj.pdfTtl
        },
        getSignAgrMdl: function(state) {
            return state.signAgrMdl
        },
        getSignAgrObj: function(state) {
            return state.signAgrObj
        },
        getBnftcrdTotMdl: function(state) {
            return state.bnftcrdTotMdl
        },
        getBnftcrdCtgMdl: function(state) {
            return state.bnftcrdCtgMdl.yNo
        },
        getBnftcrdCtgObj: function(state) {
            return state.bnftcrdCtgMdl.bccObj
        },
        getPyrlSrvcMdl: function(state) {
            return state.pyrlSrvcMdl
        },
        getPyrlSrvcCtgMdl: function(state) {
            return state.pyrlSrvcCtgMdl.yNo
        },
        getPyrlSrvcCtgObj: function(state) {
            return state.pyrlSrvcCtgMdl.bccObj
        },
    },
    mutations: {
        setUserNm: function(state, payload) {
            state.sessionInfo.name = payload
        },
        setUserPk: function(state, payload) {
            state.sessionInfo.mmbrPk = payload
        },
        setJobNm: function(state, payload) {
            state.sessionInfo.jobNm = payload
        },
        setFltyClnm: function(state, payload) {
            state.sessionInfo.fcltyClnm = payload
        },
        setToken: function(state, payload) {
            state.sessionInfo.tkn = payload
        },
        setSctKey: function(state, payload) {
            state.sessionInfo.key = payload
        },
        setUserSpr: function(state, payload) {
            state.sessionInfo.spr = payload
        },
        setPrsntDt: function(state, payload) {
            state.prsntDt = payload;
        },
        setCmmCode: function(state, payload) {
            state.cmmCode = payload;
        },
        setCrypKey: function(state, payload) {
            state.crypKey = payload
        },
        setCmmBtmMdl: function(state, payload) {
            state.cmmBtmMdl = payload;
        },
        setPickDtMdl: function(state, payload) {
            state.pickDtMdl = payload;
        },
        setPickDtMdlTyp: function(state, payload) {
            state.pickDtMdlTyp = payload;
        },
        setPickDtMdlMin: function(state, payload) {
            state.pickDtMdlMin = payload;
        },
        setPickDtMdlMax: function(state, payload) {
            state.pickDtMdlMax = payload;
        },
        setPickDt: function(state, payload) {
            state.pickDt = payload;
        },
        setPickLvnRmMdl: function(state, payload) {
            state.pickLvnRmMdl = payload;
        },
        setPickLvnRmNm: function(state, payload) {
            state.pickLvnRmNm = payload;
        },
        setSlctEmpMdl: function(state, payload) {
            state.slctEmpMdl = payload;
        },
        setSlctEmpDt: function(state, payload) {
            state.slctEmpDt = payload;
        },
        setSlctEmpObj: function(state, payload) {
            state.slctEmpObj = payload;
        },
        setCmmAlertMdl: function(state, payload) {
            state.cmmAlertMdl = payload;
        },
        setCmmAlertCntn: function(state, payload) {
            state.cmmAlertCntn = payload;
        },
        setCmmPrtMdl: function(state, payload) {
            state.cmmPrtMdl = payload;
        },
        setCmmPdfDstnt: function(state, payload) {
            state.cmmPdfObj.pdfDstnt = payload;
        },
        setCmmPdfPth: function(state, payload) {
            state.cmmPdfObj.pdfPth = payload;
        },
        setCmmPdfNm: function(state, payload) {
            state.cmmPdfObj.pdfNm = payload;
        },
        setCmmPdfOrgNm: function(state, payload) {
            state.cmmPdfObj.pdfOrgNm = payload;
        },
        setCmmPdfTtl: function(state, payload) {
            state.cmmPdfObj.pdfTtl = payload;
        },
        setSignAgrMdl: function(state, payload) {
            state.signAgrMdl = payload;
        },
        setSignAgrObj: function(state, payload) {
            state.signAgrObj = payload;
        },
        setBnftcrdTotMdl: function(state, payload) {
            state.bnftcrdTotMdl = payload;
        },
        setBnftcrdCtgMdl: function(state, payload) {
            state.bnftcrdCtgMdl.yNo = payload;
        },
        setBnftcrdCtgObj: function(state, payload) {
            state.bnftcrdCtgMdl.bccObj = payload;
        },
        setPyrlSrvcMdl: function(state, payload) {
            state.pyrlSrvcMdl = payload;
        },
        setPyrlSrvcCtgMdl: function(state, payload) {
            state.pyrlSrvcCtgMdl.yNo = payload;
        },
        setPyrlSrvcCtgObj: function(state, payload) {
            state.pyrlSrvcCtgMdl.bccObj = payload;
        },
    },
    // ...
});