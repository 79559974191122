const info = navigator.userAgent

function chkAgent() {
    let flag = false

    if( info.indexOf('iPhone') > -1
        || info.indexOf('Android') > -1
        || info.indexOf('iPad') > -1
        || info.indexOf('iPod') > -1
    ) 
        flag = true

    return flag
}

function callToast(msg) {
    if(chkAgent())
        window.Android.showToast(msg)
}

function shutDownApp() {
    if(chkAgent())
        window.Android.endWebView()
}

function fileDownApp(arr, nm, pth) {
    if(chkAgent())
        window.Android.getFileDownload(arr, nm, pth)
}

export{
    callToast, shutDownApp, fileDownApp
}