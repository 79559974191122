<template>
    <v-sheet
        class="ma-0 pa-4" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-medium fontOneToTwo">{{ $store.state.signAgrObj.msgTtl }}</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col class="d-flex justify-center" style="" cols="12">
                <v-btn
                    class="white--text pl-5 pr-6" color="blue001" @click="onPdfMdl" rounded>
                    <v-icon style="padding-top: 1px;" size="22">mdi-text-box-check-outline</v-icon>
                    <span class="ml-2 fontOne">동의서 상세보기</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-4 mb-6" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-checkbox
                    v-model="agrCnfm" label="위 항목에 모두 동의 합니다."
                    class="mt-3" style="" true-value="1" false-value="2"
                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                    hide-details>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row class="pa-0 mb-1" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="ml-3 white--text" height="26" color="blue001" rounded :disabled="agrCnfm == 2" @click="setBnftCntrcAgr"
                    style="font-size: 16px;">
                    <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                    저장
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'PrvcyAgr',

    props : {

    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getSignAgrObj: function () {
            return  this.$store.getters.getSignAgrObj
        }
    },

    watch:{

    },

    beforeDestroy: function () {

    },
        
    methods: {
        //이용 동의서 저장
        setBnftCntrcAgr: function (){
            http.post('fmly/fmlyUpdBnftCntrcAgr', {
                bzPk: this.$store.getters.getSignAgrObj.bzPk, prvcyAgrDt: this.$store.getters.getPrsntDt('yyyymmdd'),
                prvcyAgrYn: '1', eDocClcd: this.$store.state.signAgrObj.eDocClcd, bzClcd: this.$store.state.signAgrObj.bzClcd 
            })
                .then((response) => ( this.bnftCntrcAgrRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyUpdBnftCntrcAgr : ' + error))
        },
        bnftCntrcAgrRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.onClose()
            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        onPdfMdl: function (){
            let path = 'fmly/imgView?'
            path += 'prtFileNm=' + this.$store.getters.getSignAgrObj.prtFileNm
            path += '&prtFilePth=' + this.$store.getters.getSignAgrObj.prtFilePth
            path += '&t=' + Math.random()

            this.$store.commit('setCmmPdfUrl', path)
            this.$store.commit('setCmmPrtMdl', true)
        },
        handleReset: function () {
            this.$refs.esign.reset()
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res

            }).catch(err => {
                alert(err)
            })
        },
        onClose: function () {
            this.$store.commit('setSignAgrObj', {})
            this.$store.commit('setSignAgrMdl', false)
        },
    },
    
    data: () => ({
        agrCnfm: 2,
        sign: '',
        lineWidth: 8,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
    }),
};
</script>