<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%">
        <div class="d-inline-block" style="width: 100%; position: fixed; top: 0; left: 0; z-index: 6;">
            <div
                class="d-inline-block d-flex align-center blue104"
                style="width: 100%; height: 70px;">
                <v-row class="pa-0 align-center" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="5">
                        <span class="white--text font-weight-bold fontOneToSix">
                            {{ typeof bnfcrInfo.name == 'undefined' ? '' : bnfcrInfo.name }}
                        </span>
                        <span class="white--text font-weight-medium fontDotEight">
                            {{ typeof bnfcrInfo.name == 'undefined' ? '' : '어르신' }}
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex pt-1" cols="5">
                        <v-spacer></v-spacer>
                        <span
                            class="d-flex align-center rounded py-1 px-2"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="$parent.$parent.cnfLogout()">
                            <v-img src="/icons/ic_logout.svg" width="20" heigth="20"></v-img>
                            <span class="white--text font-weight-medium fontDotSeven">로그아웃</span>
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
            <div
                class="d-inline-block d-flex align-center blue104"
                style="width: 100%; height: 99px; margin-bottom: 99px; position: relative;">
                <div style="position: absolute; top: 0px; left: 0px; width: 100%;">
                    <v-row class="pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col cols="10">
                            <v-card
                                class="py-8 px-6"
                                width="100%" height="198" rounded="xl" elevation="3">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="" cols="7">
                                        <div class="d-flex align-center mb-2">
                                            <CmmDrawIcon mode="circle" type="ic_info_user" size="20"></CmmDrawIcon>
                                            <span
                                                class="fontDotNine font-weight-medium ml-2"
                                                style="padding-bottom: 2px;">
                                                {{ bnfcrInfo.gndr == 'M' ? '남' : '여' }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-center mb-2">
                                            <CmmDrawIcon mode="circle" type="ic_info_birth" size="20"></CmmDrawIcon>
                                            <span
                                                class="fontDotNine font-weight-medium ml-2"
                                                style="padding-bottom: 2px;">
                                                {{ $moment(bnfcrInfo.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') }}&nbsp;&nbsp;
                                                {{ typeof bnfcrInfo.age == 'undefined' ? '' : bnfcrInfo.age + '세' }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-center">
                                            <CmmDrawIcon mode="circle" type="ic_room" size="20"></CmmDrawIcon>
                                            <span
                                                class="fontDotNine font-weight-medium ml-3"
                                                style="padding-bottom: 2px;">
                                                {{ bnfcrInfo.lvnRm }}
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col class="d-flex justify-center align-center" cols="5">
                                        <span
                                            class="d-inline-block rounded-circle overflow-hidden"
                                            :style="{ 'width': imgWidth+'px', 'height': imgWidth+'px' }">
                                            <v-img
                                                :src="bnfcrImg" :width="imgWidth" :height="imgWidth"
                                                position="center center">
                                            </v-img>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </div>
            </div>
        </div>
        <div class="" style="padding-top: 280px; z-index: 1;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <VtlRecCard :vtlRecInf="vtlRecInfo"></VtlRecCard>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row class="mt-3 pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <PhyThrCard :list="phyThrList" :phyCode="vCd92" :wrkCode="vCd93"></PhyThrCard>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row class="my-3 pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <PrgSrvCard :prgArr="prgList"></PrgSrvCard>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';
import VtlRecCard from './smry/VtlRecCard.vue';
import PhyThrCard from './smry/PhyThrCard.vue';
import PrgSrvCard from './smry/PrgSrvCard.vue';

export default {
    name: 'BnfrcMgmt',

    components: {
        CmmDrawIcon,
        VtlRecCard,
        PhyThrCard,
        PrgSrvCard,
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$vuetify.breakpoint.width > 359)
                this.imgWidth = 120
            else
                this.imgWidth = 100

            this.getBnfcrInfo()
        })
    },

    computed: {
        getFrameWidth: function () {
            return this.$vuetify.breakpoint.width
        },
    },

    watch:{
        getFrameWidth: function (v) {
            if(v > 359)
                this.imgWidth = 120
            else
                this.imgWidth = 100
        },
    },
        
    methods: {
        //수급자 정보 조회
        getBnfcrInfo: function (){
            http.post('fmlyApp/authMain', { inqYMD: this.$moment().format('YYYYMMDD') })
                .then((response) => ( this.bnfcrInfoRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authMain : ' + error))
        },

        bnfcrInfoRst: function (res){
            if(res.statusCode == '200'){
                this.bnfcrImg = 'data:image/png;base64,' + res.photoImg
                this.bnfcrInfo = res.bnfcr
                this.vtlRecInfo = res.vtlRecInfo
                this.prgList = res.prgList
                this.phyThrList = res.phyThrList
                this.vCd92 = res.codes.vCd92
                this.vCd93 = res.codes.vCd93
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
    },

    data: () => ({
        imgWidth: 100,
        bnfcrImg: '/icons/circle_no_image.svg',
        bnfcrInfo: {},
        vtlRecInfo: {},
        phyThrList: [],
        prgList: [],
        vCd92: [],
        vCd93: [],
        bnfcrUrl: '',
    }),
};
</script>