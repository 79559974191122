<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4 pb-3">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">화장실&nbsp;이용&nbsp;기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 pa-2" cols="12">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-bold">
                            {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                        </span>
                        <span class="fontDotEight font-weight-bold ml-1">
                            {{ $store.getters.getBnftcrdCtgObj.name }}
                        </span>
                    </div>
                </v-col>
                <!-- <v-col class="d-flex justify-center align-center tableBL py-2 px-1" cols="5">
                    <v-btn
                        color="blue004" style="padding: 0 10px 2px 6px !important"
                        :disabled="prvsBtn"
                        small>
                        <v-icon class="white--text" style="padding-top: 2px;" size="20">mdi-text-box-search-outline</v-icon>
                        <span class="white--text font-weight-bold ml-2 pt-1">전일자료</span>
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <div v-if="$store.getters.getBnftcrdCtgObj.dprYn == '2' && $store.getters.getBnftcrdCtgObj.cathYn == '2'">
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">대변</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL" cols="8">
                        <CmmNmrcField v-model="tltInf.itm1Val" ></CmmNmrcField>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">소변</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL" cols="8">
                        <CmmNmrcField v-model="tltInf.itm2Val" ></CmmNmrcField>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">설사</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL" cols="8">
                        <CmmNmrcField v-model="tltInf.itm3Val" ></CmmNmrcField>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">대변</span>
                    </v-col>
                    <v-col class="txtAlgnMid pa-1 tableBL" cols="8">
                        <span class="fontDotEight">{{ tltInf.itm1Val }}</span>
                        <span class="fontDotEight ml-1">회</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">소변</span>
                    </v-col>
                    <v-col class="txtAlgnMid pa-1 tableBL" cols="8">
                        <span class="fontDotEight">{{ tltInf.itm2Val }}</span>
                        <span class="fontDotEight ml-1">회</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="pr-2" no-gutters>
                    <v-col class="greyE02 txtAlgnMid pa-1" cols="4">
                        <span class="fontDotEight font-weight-bold">설사</span>
                    </v-col>
                    <v-col class="txtAlgnMid pa-1 tableBL" cols="8">
                        <span class="fontDotEight">{{ tltInf.itm3Val }}</span>
                        <span class="fontDotEight ml-1">회</span>
                    </v-col>
                </v-row>
            </div>
            <v-divider class="mr-2"></v-divider>
        </div>
        <v-form ref="tltUse" lazy-validation>
            <div class="mb-3" v-if="$store.getters.getBnftcrdCtgObj.dprYn == '1'">
                <v-divider class="mr-2"></v-divider>
                <v-row class="greyE02 mr-2" no-gutters>
                    <v-col class="txtAlgnMid py-2" cols="8">
                        <span class="fontDotEight font-weight-bold">기저귀 교체 및 확인 기록</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL" cols="4">
                        <v-btn
                            color="green002" style="padding: 0 10px 2px 8px !important"
                            @click="onEmpMdl"
                            small>
                            <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-account-circle-outline</v-icon>
                            <span class="white--text font-weight-bold ml-2" style="padding-top: 1px;">{{ empInf.name }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="mr-2" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1" cols="3">
                        <span class="d-inline-block" style="width: 52px;">
                            <CmmTmField v-model="tltInpFrm.dprTm" :required="true"></CmmTmField>
                        </span>
                    </v-col>
                    <v-col class="tableBL" cols="7">
                        <div class="d-flex justify-center align-center tableBB" style="padding-bottom: 2px;">
                            <v-btn
                                :class="['mr-2', tltInpFrm.dprItm1Val ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="tltInpFrm.dprItm1Val = !tltInpFrm.dprItm1Val"
                                small outlined>
                                <span class="fontDotSeven">대변</span>
                            </v-btn>
                            <v-btn
                                :class="['mr-2', tltInpFrm.itm2Val ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="tltInpFrm.itm2Val = !tltInpFrm.itm2Val"
                                small outlined>
                                <span class="fontDotSeven">소변</span>
                            </v-btn>
                            <v-btn
                                :class="[tltInpFrm.itm3Val ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="tltInpFrm.itm3Val = !tltInpFrm.itm3Val"
                                small outlined>
                                <span class="fontDotSeven">설사</span>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center align-center" style="padding-bottom: 2px;">
                            <v-btn
                                :class="['mr-2', tltInpFrm.itm4Val == '1' ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="tltInpFrm.itm4Val = '1'"
                                small outlined>
                                <span class="fontDotSeven">교체</span>
                            </v-btn>
                            <v-btn
                                :class="[tltInpFrm.itm4Val == '2' ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="tltInpFrm.itm4Val = '2'"
                                small outlined>
                                <span class="fontDotSeven">확인</span>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL" cols="2">
                        <v-btn
                            class="blue001--text white--text" style="margin-top: 3px;"
                            @click="addDprRowInf()" x-small icon>
                            <v-icon size="24">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mr-2 greyE01" style="padding-bottom: 2px;"></v-divider>
                <v-row
                    :class="['mr-2']"
                    v-for="(dpr, i) in tltDprList" :key="i"
                    no-gutters>
                    <v-col class="d-flex justify-center align-center tableBB py-1" cols="3">
                        <span class="fontDotEight">{{ dpr.ofrTmHhStrt }}:{{ dpr.ofrTmMmStrt }}</span>
                    </v-col>
                    <v-col class="tableBL tableBB pa-1" cols="7">
                        <span class="fontDotEight">{{ typeof dpr.itm4Nm != 'undefined' ? dpr.itm4Nm : '' }}</span>
                        <v-icon class="black--text" size="16">mdi-slash-forward</v-icon>
                        <span class="fontDotEight">{{ dpr.itm1Val == '1' ? '대변' : '' }}</span>
                        <span
                            v-if="dpr.itm1Val == '1' && (dpr.itm2Val == '1' || dpr.itm3Val == '1')"
                            class="fontDotEight">
                            ,
                        </span>
                        <span class="fontDotEight">{{ dpr.itm2Val == '1' ? '소변' : '' }}</span>
                        <span
                            v-if="dpr.itm2Val == '1' && dpr.itm3Val == '1'"
                            class="fontDotEight">
                            ,
                        </span>
                        <span class="fontDotEight">{{ dpr.itm3Val == '1' ? '설사' : '' }}</span>
                        <v-icon class="black--text" size="16">mdi-slash-forward</v-icon>
                        <span class="fontDotEight">{{ dpr.wrtr }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL tableBB py-1" cols="2">
                        <span v-if="dpr.bnftOfrRecHisPk != 0 && dpr.bnftOfrRec301Pk != 0">
                            <v-btn
                                style="margin-top: 3px;" @click="rmRecDtlInf(dpr.bnftOfrRecHisPk, dpr.bnftOfrRec301Pk)"
                                x-small icon>
                                <v-icon size="24">mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                        <span v-else>
                            <v-btn
                                style="margin-top: 3px;" @click="tltDprList.splice(i, 1)"
                                x-small icon>
                                <v-icon size="24">mdi-minus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </div>
            <div v-if="$store.getters.getBnftcrdCtgObj.cathYn == '1'">
                <v-divider class="mr-2"></v-divider>
                <v-row class="greyE02 mr-2" no-gutters>
                    <v-col class="txtAlgnMid py-2" cols="12">
                        <span class="fontDotEight font-weight-bold">소변량 기록 (유치도뇨관) - 필요시</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="mr-2" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1" cols="3">
                        <span class="d-inline-block" style="width: 52px;">
                            <CmmTmField v-model="tltInpFrm.cathTm" :required="true"></CmmTmField>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL" cols="7">
                        <span class="d-inline-block" style="width: 54px;">
                            <v-text-field
                                class="cmmTmFld" type="number" height="24"
                                v-model="tltInpFrm.cathItm1Val"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="fontDotEight ml-1">㎖</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL" cols="2">
                        <v-btn
                            class="blue001--text white--text" style="margin-top: 3px;"
                            @click="addCathRowInf()" x-small icon>
                            <v-icon size="24">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mr-2 greyE01" style="padding-bottom: 2px;"></v-divider>
                <v-row
                    :class="['mr-2']"
                    v-for="(cath, j) in tltCathList" :key="j"
                    no-gutters>
                    <v-col class="d-flex justify-center align-center tableBB py-1" cols="3">
                        <span class="fontDotEight">{{ cath.ofrTmHhStrt }}:{{ cath.ofrTmMmStrt }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL tableBB py-1" cols="7">
                        <span class="fontDotEight">{{ cath.itm1Val }}</span>
                        <span class="fontDotEight ml-1">㎖</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL tableBB py-1" cols="2">
                        <span v-if="cath.bnftOfrRecHisPk != 0 && cath.bnftOfrRec301Pk != 0">
                            <v-btn
                                style="margin-top: 3px;" @click="rmRecDtlInf(cath.bnftOfrRecHisPk, cath.bnftOfrRec301Pk)"
                                x-small icon>
                                <v-icon size="24">mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                        <span v-else>
                            <v-btn
                                style="margin-top: 3px;" @click="tltCathList.splice(j, 1)"
                                x-small icon>
                                <v-icon size="24">mdi-minus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        <div class="stkMdlBottom py-4">
            <v-row class="pr-2" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveBtn ? '' : 'blue001 white--text']"
                    @click="preTltUseInf()" :disabled="saveBtn"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmNmrcField from '../../cmm/CmmNmrcField';
import CmmTmField from '../../cmm/CmmTmField.vue';

export default {
    name: 'TltUseRcrdMdl',

    components: {
        CmmNmrcField,
        CmmTmField,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getTltUseInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }
            
            this.cleanInpFrm('both')

            this.empInf.name = this.$store.getters.getUserNm
            this.empInf.mmbrPk = this.$store.getters.getUserPk
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
        getEmpSlctMdl: function () {
            return this.$store.getters.getSlctEmpMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getTltUseInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }
        },
        getEmpSlctMdl: function (v) {
            if(v == false)
                this.empInf = this.$store.getters.getSlctEmpObj
        },
    },
        
    methods: {
        // 화장실 이용 기록 조회
        getTltUseInf: function (pk, ymd, mode){
            this.saveBtn = true
            this.prvsBtn = true
            
            if(mode == 'now'){
                this.cleanData()

                http.post( 'empApp/authSelTltUseInfo', { bnMmbrPk: pk, inqYMD: ymd } )
                    .then((response) => ( this.tltUseRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelTltUseInfo : ' + error))
            }
            else if(mode == 'prvs'){
                let prvsDay = this.$moment(ymd, 'YYYYMMDD', true).subtract(1, 'days').format('YYYYMMDD')

                http.post( 'empApp/authSelTltUseInfo', { bnMmbrPk: pk, inqYMD: prvsDay } )
                    .then((response) => ( this.prvsTltUseRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelTltUseInfo : ' + error))
            }

        },
        // 화장실 이용 기록 저장
        setTltUseInf: function (obj){
            http.post( 'empApp/authInsTltUse', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsTltUse : ' + error))
        },
        // 화장실 이용 기록 삭제
        rmTltUseInf: function (obj){
            http.post( 'mobemp/empDelTltUse', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /mobemp/empDelTltUse : ' + error))
        },
        tltUseRst: function (res){
            if(res.statusCode == '200'){
                // 조회된 분류코드를 반영
                if(Object.keys(res.code).length > 0){
                    if(this.vCd86.length == 0)
                        this.vCd86 = res.code.vCd86
                }

                // 조회된 데이터가 있으면 반영
                if(res.recList.length > 0){
                    let chc = res.recList.findIndex( v => v.clsfc3Cd == '3' )
                    if(chc > -1)
                        this.rflTtlInf(res.recList[chc])

                    this.tltDprList = res.recList.filter( v => v.clsfc3Cd == '5' )
                    this.tltDprList.forEach(itm => {
                        if(this.dprHisPk == -1) this.dprHisPk = itm.bnftOfrRecHisPk
                        this.vCd86.forEach(cd => {
                            if(itm.itm4Val == cd.valcd) itm.itm4Nm = cd.valcdnm
                        });
                    });

                    this.tltCathList = res.recList.filter( v => v.clsfc3Cd == '4' )
                    if(this.tltCathList.length > 0)
                        this.cathHisPk = this.tltCathList[0].bnftOfrRecHisPk
                }
             
                this.saveBtn = false
            }
        },
      
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getAllBnftToilSttList(
                    this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )

                this.getTltUseInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )

            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        preTltUseInf: function () {
            this.saveBtn = true

            let wrtrList = []
            let wrtrPkList = []
            this.tltDprList.forEach(itm => {
                wrtrList.push(itm.wrtr)
                wrtrPkList.push(itm.wrtrMmbrPk)
            });

            // 저장전 미반영 데이터 적용 함수
            this.addInsCfmRow()

            // 기본 정보 작성
            let obj = {}
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.userNm = this.$store.getters.getUserNm
            obj.list = []

            // 공통 정보 작성
            let objDtl = {}
            objDtl.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            objDtl.wrtrMmbrPk = this.$store.getters.getUserPk
            objDtl.wrtr = this.$store.getters.getUserNm
            objDtl.clsfc1Cd = '301'
            objDtl.clsfc2Cd = '3'
            objDtl.clsfc3Cd = ''
            objDtl.wrtrGr = null
            objDtl.wrtrGrMmbrPk = null
            objDtl.listDtl = null

            // 배설 관찰 기록 작성
            let cmmObj = Object.assign({}, objDtl)
            // cmmObj.bnftOfrRecHisPk = this.tltInf.bnftOfrRecHisPk
            cmmObj.bnftOfrRecHisPk = null
            cmmObj.clsfc3Cd = '3'
            cmmObj.listDtl = []
            cmmObj.listDtl.push(Object.assign({}, this.tltInf))

            // 유치 도뇨관 기록 작성
            let cathObj = Object.assign({}, objDtl)
            // cathObj.bnftOfrRecHisPk = this.cathHisPk
            cathObj.bnftOfrRecHisPk = null
            cathObj.clsfc3Cd = '4'
            cathObj.listDtl = [...this.tltCathList]
            // cathObj.listDtl = this.tltCathList.filter( v => v.wrtr == this.$store.getters.getUserNm )

            // 기저귀 기록 작성
            let dprObj = Object.assign({}, objDtl)
            // dprObj.bnftOfrRecHisPk = this.dprHisPk
            dprObj.bnftOfrRecHisPk = null
            dprObj.clsfc3Cd = '5'
            dprObj.wrtrGr = [...new Set(wrtrList)]
            dprObj.wrtrGrMmbrPk = [...new Set(wrtrPkList)]
            dprObj.listDtl = [...this.tltDprList]
            // dprObj.listDtl = this.tltDprList.filter( v => v.wrtr == this.$store.getters.getUserNm )

            obj.list.push(cmmObj)
            if(this.$store.getters.getBnftcrdCtgObj.cathYn == '1')
                obj.list.push(cathObj)
            if(this.$store.getters.getBnftcrdCtgObj.dprYn == '1')
                obj.list.push(dprObj)

            //console.log(obj)
    
            // if(this.$store.getters.getBnftcrdCtgObj.cathYn == '1' && cathObj.listDtl.length == 0){
            //     this.$store.commit('setCmmAlertMdl', true)
            //     this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '필수항목을 입력해주세요.' })
            //     this.saveBtn = false
            //     return
            // }
            // else if(this.$store.getters.getBnftcrdCtgObj.dprYn == '1' && dprObj.listDtl.length == 0){
            //     this.$store.commit('setCmmAlertMdl', true)
            //     this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '필수항목을 입력해주세요.' })
            //     this.saveBtn = false
            //     return
            // }
            // else
                this.setTltUseInf(obj)
        },

        rmRecDtlInf: function (recHisPk, recPk) {
            this.saveBtn = true

            // 기본 정보 작성
            let obj = {}
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.userNm = this.$store.getters.getUserNm
            obj.bnftOfrRecHisPk = recHisPk
            obj.bnftOfrRec301Pk = recPk
            obj.inqYMD = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')

            //console.log(obj)
            this.rmTltUseInf(obj)
        },

        cleanData: function () {
            this.tltDprList.splice(0)
            this.tltInf.bnftOfrRecHisPk = 0
            this.tltInf.bnftOfrRec301Pk = 0
            this.dprHisPk = -1
            this.cathHisPk = -1
            this.tltInf.recDt = ''
            this.tltInf.itm1Val = 0
            this.tltInf.itm2Val = 0
            this.tltInf.itm3Val = 0
        },

        // 저장전 미반영 데이터 적용 함수
        addInsCfmRow: function () {

            if(this.$store.getters.getBnftcrdCtgObj.cathYn == '1'){
                let obj = Object.assign( {}, this.dmyTltCathInf )
                let chc = this.tltCathList.findIndex( v => v.ofrTmHhStrt == this.tltInpFrm.cathTm.split(':', 2)[0] && v.ofrTmMmStrt == this.tltInpFrm.cathTm.split(':', 2)[1] )

                if(chc == -1 && this.tltInpFrm.cathTm != ''){
                    obj.ofrTmHhStrt = this.tltInpFrm.cathTm.split(':', 2)[0]
                    obj.ofrTmMmStrt = this.tltInpFrm.cathTm.split(':', 2)[1]
                    obj.wrtr = this.$store.getters.getUserNm
                    obj.wrtrMmbrPk = this.$store.getters.getUserPk
                    obj.itm1Val = this.tltInpFrm.cathItm1Val

                    if(obj.itm1Val != 0 && obj.itm1Val != null && obj.itm1Val != '')
                        this.tltCathList.push(obj)
                }
            }
            
            if(this.$store.getters.getBnftcrdCtgObj.dprYn == '1'){
                let obj = Object.assign( {}, this.dmyTltDprInf )
                let chc = this.tltDprList.findIndex( v => v.ofrTmHhStrt == this.tltInpFrm.dprTm.split(':', 2)[0] && v.ofrTmMmStrt == this.tltInpFrm.dprTm.split(':', 2)[1] )

                if(chc == -1 && this.tltInpFrm.dprTm != ''){
                    let chc = this.tltInpFrm.dprItm1Val == false && this.tltInpFrm.itm2Val == false && this.tltInpFrm.itm3Val == false && this.tltInpFrm.itm4Val == ''
                    if(chc == false){
                        obj.ofrTmHhStrt = this.tltInpFrm.dprTm.split(':', 2)[0]
                        obj.ofrTmMmStrt = this.tltInpFrm.dprTm.split(':', 2)[1]
                        obj.wrtr = this.empInf.name
                        obj.wrtrMmbrPk = this.empInf.mmbrPk
                        obj.itm4Val = this.tltInpFrm.itm4Val

                        if(this.tltInpFrm.dprItm1Val == true)
                            obj.itm1Val = '1'
                        else
                            obj.itm1Val = '2'
                        if(this.tltInpFrm.itm2Val == true)
                            obj.itm2Val = '1'
                        else
                            obj.itm2Val = '2'
                        if(this.tltInpFrm.itm3Val == true)
                            obj.itm3Val = '1'
                        else
                            obj.itm3Val = '2'

                        this.vCd86.forEach(cd => {
                            if(obj.itm4Val == cd.valcd) obj.itm4Nm = cd.valcdnm
                        });

                        this.tltDprList.push(obj)
                    }
                }
            }
            
            this.calcTlt()
            this.cleanInpFrm('both')
        },

        addDprRowInf: function () {
            let obj = Object.assign( {}, this.dmyTltDprInf )
            let chc = this.tltDprList.findIndex( v => v.ofrTmHhStrt == this.tltInpFrm.dprTm.split(':', 2)[0] && v.ofrTmMmStrt == this.tltInpFrm.dprTm.split(':', 2)[1] )

            if(chc > -1){
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '동일 시간에 작성된 데이터가 존재합니다.' })
                return
            }

            if(this.tltInpFrm.dprTm == ''){
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '필수항목을 입력해주세요.' })
                return
            }

            obj.ofrTmHhStrt = this.tltInpFrm.dprTm.split(':', 2)[0]
            obj.ofrTmMmStrt = this.tltInpFrm.dprTm.split(':', 2)[1]
            obj.wrtr = this.empInf.name
            obj.wrtrMmbrPk = this.empInf.mmbrPk
            obj.itm4Val = this.tltInpFrm.itm4Val

            if(this.tltInpFrm.dprItm1Val == true)
                obj.itm1Val = '1'
            else
                obj.itm1Val = '2'
            if(this.tltInpFrm.itm2Val == true)
                obj.itm2Val = '1'
            else
                obj.itm2Val = '2'
            if(this.tltInpFrm.itm3Val == true)
                obj.itm3Val = '1'
            else
                obj.itm3Val = '2'

            this.vCd86.forEach(cd => {
                if(obj.itm4Val == cd.valcd) obj.itm4Nm = cd.valcdnm
            });

            this.tltDprList.push(obj)
            this.calcTlt()
            this.cleanInpFrm('dpr')
        },
        
        addCathRowInf: function () {
            let obj = Object.assign( {}, this.dmyTltCathInf )
            let chc = this.tltCathList.findIndex( v => v.ofrTmHhStrt == this.tltInpFrm.cathTm.split(':', 2)[0] && v.ofrTmMmStrt == this.tltInpFrm.cathTm.split(':', 2)[1] )

            if(chc > -1){
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '동일 시간에 작성된 데이터가 존재합니다.' })
                return
            }

            if(this.tltInpFrm.cathTm == ''){
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '필수항목을 입력해주세요.' })
                return
            }

            obj.ofrTmHhStrt = this.tltInpFrm.cathTm.split(':', 2)[0]
            obj.ofrTmMmStrt = this.tltInpFrm.cathTm.split(':', 2)[1]
            obj.wrtr = this.$store.getters.getUserNm
            obj.wrtrMmbrPk = this.$store.getters.getUserPk
            obj.itm1Val = this.tltInpFrm.cathItm1Val

            this.tltCathList.push(obj)
            this.calcTlt()
            this.cleanInpFrm('cath')
        },

        calcTlt: function () {
            // this.tltDprList
            let cnt1 = 0
            let cnt2 = 0
            let cnt3 = 0

            if(this.$store.getters.getBnftcrdCtgObj.dprYn == '1'){
                this.tltDprList.forEach(itm => {
                    if(itm.itm1Val == '1') cnt1 += 1
                    if(itm.itm2Val == '1') cnt2 += 1
                    if(itm.itm3Val == '1') cnt3 += 1
                });
            }

            if(this.$store.getters.getBnftcrdCtgObj.cathYn == '1')
                cnt2 += this.tltCathList.length

            if(this.$store.getters.getBnftcrdCtgObj.dprYn != '1' && this.$store.getters.getBnftcrdCtgObj.cathYn != '1')
                return

            this.tltInf.itm1Val = cnt1
            this.tltInf.itm2Val = cnt2
            this.tltInf.itm3Val = cnt3

        },
        
        rflTtlInf: function (obj) {
            this.tltInf.bnftOfrRecHisPk = obj.bnftOfrRecHisPk
            this.tltInf.bnftOfrRec301Pk = obj.bnftOfrRec301Pk
            this.tltInf.recDt = obj.recDt
            this.tltInf.itm1Val = Number(obj.itm1Val)
            this.tltInf.itm2Val = Number(obj.itm2Val)
            this.tltInf.itm3Val = Number(obj.itm3Val)
        },

        cleanInpFrm: function (mode) {
            if(mode == 'dpr')
                this.setDrpInpTm()
            else if(mode == 'cath')
                this.setCathInpTm()
            else if(mode == 'both'){
                this.setDrpInpTm()
                this.setCathInpTm()
            }
            
            this.tltInpFrm.itm4Val = ''
            this.tltInpFrm.dprItm1Val = false
            this.tltInpFrm.cathItm1Val = 0
            this.tltInpFrm.itm2Val = false
            this.tltInpFrm.itm3Val = false
        },

        setDrpInpTm: function () {
            let chc = this.tltDprList.findIndex( v => v.ofrTmHhStrt == this.$moment().format('HH') && v.ofrTmMmStrt == this.$moment().format('mm') )
            if(chc > -1)
                this.tltInpFrm.dprTm = this.$moment().add(1, 'minutes').format('HH:mm')
            else
                this.tltInpFrm.dprTm = this.$moment().format('HH:mm')
        },

        setCathInpTm: function () {
            let chc = this.tltCathList.findIndex( v => v.ofrTmHhStrt == this.$moment().format('HH') && v.ofrTmMmStrt == this.$moment().format('mm') )
            if(chc > -1)
                this.tltInpFrm.cathTm = this.$moment().add(1, 'minutes').format('HH:mm')
            else
                this.tltInpFrm.cathTm = this.$moment().format('HH:mm')
        },
      
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },

        onEmpMdl: function () {
            this.$store.commit('setSlctEmpMdl', true)
            this.$store.commit('setSlctEmpDt', this.$store.getters.getBnftcrdCtgObj.inqYMD)
        },
    },

    data: () => ({
        prvsBtn: true,      // 전일자료 버튼 활성여부
        saveBtn: true,      // 저장 버튼 활성여부
        vCd86: [],
        dprHisPk: -1,
        cathHisPk: -1,
        empInf: { name: '', mmbrPk: 0 },
        tltInf: {
            bnftOfrRecHisPk: 0, bnftOfrRec301Pk: 0, clsfc1Cd: '301', clsfc2Cd: '3', clsfc3Cd: '3', recDt: '',
            itm1Cd: '1', itm2Cd: '2', itm3Cd: '3', itm1Val: 0, itm2Val: 0, itm3Val: 0,
        },
        tltDprList: [],
        tltCathList: [],
        tltInpFrm: {
            dprTm: '', cathTm: '', dprItm1Val: false, cathItm1Val: 0, itm2Val: false, itm3Val: false, itm4Val: '',
        },
        dmyTltDprInf: {
            bnftOfrRecHisPk: 0, bnftOfrRec301Pk: 0, clsfc1Cd: '301', clsfc2Cd: '3', clsfc3Cd: '5',
            recDt: '', ofrTmHhStrt: '', ofrTmMmStrt: '',
            itm1Cd: '1', itm2Cd: '1', itm3Cd: '1', itm4Cd: '86',
            itm1Val: '', itm2Val: '', itm3Val: '', itm4Val: '',
        },
        dmyTltCathInf: {
            bnftOfrRecHisPk: 0, bnftOfrRec301Pk: 0, clsfc1Cd: '301', clsfc2Cd: '3', clsfc3Cd: '4',
            recDt: '', ofrTmHhStrt: '', ofrTmMmStrt: '',
            itm1Cd: '2', itm1Val: '',
        },
    }),
};
</script>