<template>
    <v-row class="pa-0" no-gutters>
        <v-col cols="12">
            <PayExpStt
                ref="payExpStt"
                :aplyYyMm="$moment($parent.$parent.sltDt, 'YYYYMM', true).add(1, 'months').format('YYYYMM')"
                :fcltyNum="$parent.$parent.sltFcltyNum"
                :bnMmbrPk="$parent.$parent.sltBnMmbrPk"
                :bnfcrPk="$parent.$parent.sltBnfcrPk">
            </PayExpStt>
        </v-col>
    </v-row>
</template>

<script>
import PayExpStt from '../bnfcr/pyrlsrvc/PayExpStt.vue';

export default {
    name: 'OutPayExpStt',

    components: {
        PayExpStt,
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '본인부담금 청구 명세서'
            // let obj = {}
            // obj.bnMmbrPk = 16
            // obj.fcltyNum = 14111000790
            // obj.inqYYMM = 202307

            // let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            // console.log(encrypted.toString())
            // let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            // console.log(bs64)

            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)

                    this.onPayExpStt(JSON.parse(bytes.toString(this.$crypto.enc.Utf8)))
                }
            }
        },
        onPayExpStt: function (obj) {
            let tmp = Object.assign({}, obj)
            this.$parent.$parent.sltDt = tmp.inqYYMM.toString()
            this.$parent.$parent.sltFcltyNum = tmp.fcltyNum
            this.$parent.$parent.sltBnMmbrPk = tmp.bnMmbrPk
            this.$parent.$parent.sltBnfcrPk = tmp.bnfcrPk
        },
    },

    data: () => ({

    }),
};
</script>