<template>
    <v-sheet class="" width="100%">
        <v-app-bar
            color="blue104" elevation="0" height="70" app>
            <v-row class="pa-0 align-center" no-gutters>
                <v-col class="overflow-text-hidden white--text" cols="9">
                    <span class="fontOneToTwo font-weigth-bold" style="padding-left: 5px;">{{ appBarTtl }}</span>
                </v-col>
                <v-col class="d-flex" cols="3">
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="25"></CmmDrawIcon>
                </v-col>
            </v-row>
        </v-app-bar>

        <v-row class="pa-0 mb-4" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <router-view></router-view>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-dialog v-model="$store.state.pyrlSrvcMdl" persistent>
            <MdclExpnsMdl
                v-if="$store.state.pyrlSrvcCtgMdl.yNo == '1'"
                :aplyYyMm="sltDt" :fcltyNum="sltFcltyNum" :bnMmbrPk="sltBnMmbrPk" :bnfcrPk="sltBnfcrPk">
            </MdclExpnsMdl>
            <MsclnExpsMdl
                v-else-if="$store.state.pyrlSrvcCtgMdl.yNo == '2'"
                :aplyYyMm="sltDt" :fcltyNum="sltFcltyNum" :bnMmbrPk="sltBnMmbrPk" :bnfcrPk="sltBnfcrPk">
            </MsclnExpsMdl>
            <OutStndCndtMdl
                v-else-if="$store.state.pyrlSrvcCtgMdl.yNo == '3'"
                :aplyYyMm="sltDt" :fcltyNum="sltFcltyNum" :bnMmbrPk="sltBnMmbrPk">
            </OutStndCndtMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import MdclExpnsMdl from '../bnfcr/pyrlsrvc/MdclExpnsMdl.vue';     // 약제비, 진찰비 팝업
import MsclnExpsMdl from '../bnfcr/pyrlsrvc/MsclnExpsMdl.vue';     // 기타 비용 팝업
import OutStndCndtMdl from '../extr/OutStndCndtMdl.vue';           // 계약서 확인사항 팝업

import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';

export default {
    name: 'ExtrTpl',

    components: {
        MdclExpnsMdl,
        MsclnExpsMdl,
        OutStndCndtMdl,
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },

    methods: {

    },

    data: () => ({
        appBarTtl: '',
        sltFcltyNum: '',
        sltBnMmbrPk: 0,
        sltBnfcrPk: 0,
        sltEmpPk: 0,
        sltDt: '',
        sltprtCdAr: [],
    }),
};
</script>