<template>
    <v-sheet
        width="100%" :height="$vuetify.breakpoint.height"
        class="d-flex justify-center align-center">
        <!-- <input type="file" @change="handleChange" /> -->
        테스트
    </v-sheet>
</template>

<script>
import { snglHdrXlsxPrdc, getXlsxSidoCode } from '@/assets/js/cmmExcelJS.js';
import { dataApis } from '@/assets/js/datagokrAxios';

export default {
    name: 'UnitTest',

    components: {
       
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            //this.getLtcInstt('11', '110')
            //this.getMsgSndRst('b9593a9b-06ef-4693-aef7-f70417125a14')
        })
    },

    computed: {

    },

    watch:{
        'itmCnt': function () {
            console.log(this.itmCnt + ' / ' + this.items.length + ',     ' + this.xlsCnt + ' / ' + this.totCnt)
            if(this.itmCnt != 0 && this.itmCnt == this.items.length && this.xlsCnt != 0 && this.xlsCnt == this.totCnt){
                this.xlsxProd(this.items)
                this.totCnt = 0
                this.xlsCnt = 0
                this.itmCnt = 0
            }
        },
    },
        
    methods: {
        // 공공데이터 요양기관 입소인원 조회
        getNmprInfo: async function (code) {
            await dataApis.get('/getLtcInsttDetailInfoService02/getAceptncNmprDetailInfoItem02', { params: { longTermAdminSym: code, adminPttnCd: 'A03' } })
                .then((response) => ( this.getNmprInfoRst(response.data) ))
                .catch((error) => console.log('connect error /getAceptncNmprDetailInfoItem02 : ' + error))
        },
        // 공공데이터 요양기관 조회
        getLtcInstt: async function (sido, gun) {
            await dataApis.get('/searchLtcInsttService01/getLtcInsttSeachList01', { params: { siDoCd: sido, siGunGuCd: gun, adminPttnCd: 'A03' } })
                .then((response) => ( this.getLtcInsttRst(response.data) ))
                .catch((error) => console.log('connect error /getLtcInsttSeachList01 : ' + error))
        },
        getLtcInsttRst: async function (res) {
            this.xlsCnt += 1
            if(typeof res != 'undefined'){
                //console.log(res.response.body.items.item)
                if(typeof res.response.body.items.item != 'undefined'){
                    if(Array.isArray(res.response.body.items.item)){
                        for (const item of res.response.body.items.item) {
                            this.items.push(item)
                            await this.getNmprInfo(item.longTermAdminSym)
                            setTimeout(function(){}, 100);
                        }
                        // res.response.body.items.item.forEach(itm => {
                        //     this.items.push(itm)
                        // });
                    }
                    else{
                        this.items.push(res.response.body.items.item)
                        this.getNmprInfo(res.response.body.items.item.longTermAdminSym)
                    }
                }
            }
            //this.xlsxProd(res.response.body.items.item)
        },
        getNmprInfoRst: function (res) {
            this.itmCnt += 1
            if(typeof res.response.body !=  'undefined'){
                let chc = this.items.findIndex( v => v.longTermAdminSym == res.response.body.item.longTermAdminSym )
                if(chc > -1){
                    this.items[chc].longTermAdminSym = res.response.body.item.longTermAdminSym
                    this.items[chc].totPer = res.response.body.item.totPer
                    this.items[chc].maNowPer = res.response.body.item.maNowPer
                    this.items[chc].fmNowPer = res.response.body.item.fmNowPer
                }
            }
            else
                console.log(res)
        },
        getMsgSndRst: function (mId) {
            let timestamp = new Date().getTime()
            let accessKey = '8urxnLDAUj6whc8FSaG3'
            let serviceId = 'ncp:kkobizmsg:kr:3047637:hgndalimtalk'
            let secretKey = '1DjYnS5mN3JwLrrwKHpgbhFCBG3lEJXjI9HSfMIv'
            let host = 'https://sens.apigw.ntruss.com'
            let uri = '/alimtalk/v2/services/' + serviceId + '/messages/' + mId + ''
            let signature = this.makeSignature(uri, accessKey, secretKey, timestamp)
 
            this.$axios({
                method: 'get',
                url: host+uri,
                dataType: 'json',
                headers: {
                    'Content-Type':'application/json',
                    'x-ncp-apigw-timestamp':timestamp,
                    'x-ncp-iam-access-key':accessKey,
                    'x-ncp-apigw-signature-v2':signature
                },
                proxy: {
                    host: host,
                    port: 443,
                }
            })
                .then((response) => ( this.msgSndRst(response.data) ))
                .catch((error) => console.log('axios error : ' + error))
        },
        xlsxProd: function (arr){
            let shetNm = '요양시설'
            let title = '요양시설_간략정보'

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, arr, shetNm, title)
            this.exlBtn = true
        },
        msgSndRst: function (res) {
            console.log(res)
        },
        makeSignature: function (url, aKey, sKey, tm) {
            var space = ' ';			                // one space
            var newLine = '\n'				            // new line
            var method = 'GET'				            // method
            var timestamp = '{' + tm + '}'	            // current timestamp (epoch)
            var accessKey = '{' + aKey + '}'		    // access key id (from portal or Sub Account)
            var secretKey = '{' + sKey + '}'			// secret key (from portal or Sub Account)
  
            var hmac = this.$crypto.algo.HMAC.create(this.$crypto.algo.SHA256, secretKey)
            hmac.update(method)
            hmac.update(space)
            hmac.update(url)
            hmac.update(newLine)
            hmac.update(timestamp)
            hmac.update(newLine)
            hmac.update(accessKey)

            var hash = hmac.finalize()

            return hash.toString(this.$crypto.enc.Base64)
        },
        handleChange: async function(e) {
            this.totCnt = 0
            this.xlsCnt = 0
            this.itmCnt = 0
            this.items.splice(0)
            this.file = e.target.files[0]

            let list = await getXlsxSidoCode(this.file)

            this.dataCollect(list)
        },
        dataCollect: async function(list) {
            this.totCnt = list.length

            for (const item of list) {
                await this.getLtcInstt(item.sidoCd, item.gunguCd)
                setTimeout(function(){}, 200);
            }
            // list.forEach(itm => {
            //     this.getLtcInstt(itm.sidoCd, itm.gunguCd)
            // });
        },
    },

    data: () => ({
        totCnt: 0,
        xlsCnt: 0,
        itmCnt: 0,
        file: null,
        items: [],
        exlHeader: [
            { header: '기관명', key: 'adminNm', width: 50, }, { header: '기관번호', key: 'longTermAdminSym', width: 20, },
            { header: '기관유형코드', key: 'adminPttnCd', width: 16, }, { header: '시도코드', key: 'siDoCd', width: 10, },
            { header: '시군구코드', key: 'siGunGuCd', width: 10, }, { header: '지정일', key: 'longTermPeribRgtDt', width: 10, },
            { header: '신고일자', key: 'stpRptDt', width: 10, }, { header: '정원', key: 'totPer', width: 10, },
            { header: '현원_남', key: 'maNowPer', width: 10, }, { header: '현원_여', key: 'fmNowPer', width: 10, },
        ],
    }),
};
</script>