<template>
    <v-sheet width="100%" class="px-7 pt-6 pb-7">
        <div class="d-flex justify-center align-center mb-10">
            <span 
                class="d-inline-block rounded-xl grey005"
                style="width: 30%; height: 7px;"
                @click="$store.commit('setCmmBtmMdl', false)">
            </span>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="12">
                <span class="grey006--text fontOneToFour font-weight-bold">보호자 로그인</span>
            </v-col>
        </v-row>
        <v-form ref="fmlyLgn" lazy-validation>
            <div v-if="step == 0">
                <v-row class="mt-12 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.name" :rules="[rules.required]"
                            @keyup.enter="preFmlyLgn"
                            label="어르신 성함"
                            outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.rsdntBirth" :rules="[rules.required, rules.birthCheck]"
                            label="어르신 생년월일" hint="1950-01-01 형식으로 입력해 주세요."
                            @input="chnDtFrm"
                            @click:append="$store.commit('setPickDtMdl', true)"
                            append-icon="mdi-calendar-blank" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div class="" v-else-if="step == 1">
                <v-row class="mt-12 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-select
                            v-model="grdnInfo.sltGrdn" :items="grdnList"
                            @change="rflGrndList"
                            item-text="vTxt" item-value="mmbrPk" label="보호자 목록"
                            hide-details dense outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-6 pa-0" v-if="onLgnBtn" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.passwd" :rules="[rules.required]"
                            :append-icon="pswdShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="pswdShow ? 'text' : 'password'"
                            @click:append="pswdShow = !pswdShow"
                            label="비밀번호" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div class="" v-else-if="step == 2">
                <v-row class="mt-12 pa-0" no-gutters>
                    <v-col cols="7">
                        <v-select
                            v-model="grdnInfo.sltGrdn" :items="grdnList"
                            @change="rflGrndList" :disabled="true"
                            item-text="vTxt" item-value="mmbrPk" label="보호자 목록"
                            hide-details dense outlined>
                        </v-select>
                    </v-col>
                    <v-col class="pl-1" cols="5">
                        <v-btn class="py-5" width="100%" color="blue102" :disabled="smsCfmBtn" @click="preSmsSnd">
                            <span class="white--text fontOne">인증번호 발송</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-6 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.cnfmSms" :rules="[rules.required]"
                            label="인증번호" hint="전송받은 문자 메세지 인증번호를 입력해주세요."
                            @click:append="$store.commit('setPickDtMdl', true)"
                            outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div class="" v-else-if="step == 3">
                <v-row class="mt-12 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.passwd" :rules="[rules.required]"
                            :append-icon="pswdShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="pswdShow ? 'text' : 'password'"
                            @click:append="pswdShow = !pswdShow"
                            label="비밀번호" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-6 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-text-field
                            v-model="grdnInfo.passwdCfm" :rules="[rules.required]"
                            :append-icon="pswdCfmShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="pswdCfmShow ? 'text' : 'password'"
                            @click:append="pswdCfmShow = !pswdCfmShow"
                            label="비밀번호 확인" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
            </div>
            <!-- <v-row class="mt-2 pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center" cols="12">
                    <span class="d-inline-block" style="width: 30%;">
                        <v-text-field
                            v-model="grdnInfo.celphn_1" @keyup.enter="preFmlyLgn"
                            :rules="[rules.numberCheck, rules.required]"
                            type="number"
                            label="휴대전화" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 29px;">
                        <v-icon size="20">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 30%;">
                        <v-text-field
                            v-model="grdnInfo.celphn_2" @keyup.enter="preFmlyLgn"
                            :rules="[rules.numberCheck, rules.required]"
                            type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 29px;">
                        <v-icon size="20">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 30%;">
                        <v-text-field
                            v-model="grdnInfo.celphn_3" @keyup.enter="preFmlyLgn"
                            :rules="[rules.numberCheck, rules.required]"
                            type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row> -->
        </v-form>
        <div v-if="step == 0">
            <v-row class="mt-10 mb-2 pa-0" no-gutters>
                <v-col class="" cols="12">
                    <v-btn class="py-5" width="100%" color="blue002" @click="onFmlyList">
                        <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                        <span class="white--text ml-4" style="font-size: 1.3rem;">다음</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="step == 1">
            <v-row class="mt-10 mb-2 pa-0" no-gutters>
                <v-col class="" cols="4">
                    <v-btn class="py-5" width="100%" color="grey006" @click="cnfmClear">
                        <span class="white--text" style="font-size: 1.3rem;">이전</span>
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="" cols="7">
                    <v-btn class="py-5" width="100%" color="blue002" @click="onLgnBtn ? preFmlyLgn() : step = 2">
                        <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                        <span v-if="onLgnBtn" class="white--text ml-4" style="font-size: 1.3rem;">로그인</span>
                        <span v-else class="white--text ml-4" style="font-size: 1.3rem;">다음</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="step == 2">
            <v-row class="mt-10 mb-2 pa-0" no-gutters>
                <v-col class="" cols="4">
                    <v-btn class="py-5" width="100%" color="grey006" @click="cnfmClear">
                        <span class="white--text" style="font-size: 1.3rem;">이전</span>
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="" cols="7">
                    <v-btn
                        class="py-5" width="100%" color="blue002" @click="preSmsChk"
                        :disabled="grdnInfo.cnfmSms.length > 5 ? false : true">
                        <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                        <span class="white--text ml-4" style="font-size: 1.3rem;">다음</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="step == 3">
            <v-row class="mt-10 mb-2 pa-0" no-gutters>
                <v-col class="" cols="12">
                    <v-btn
                        class="py-5" width="100%" color="blue002" @click="preFmlyInsAcnt"
                        :disabled="grdnInfo.cnfmSms.length > 5 ? false : true">
                        <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                        <span class="white--text ml-4" style="font-size: 1.3rem;">다음</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'GrdnLgn',

    components: {
       
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
        })
    },

    computed: {
        getPickDt: function () {
            return this.$store.getters.getPickDt
        }
    },

    watch:{
        getPickDt: function (value) {
            this.grdnInfo.birth = value
        },
    },
        
    methods: {
        //보호자 로그인
        fmlyLgn: function (obj){
            http.post('fmlyApp/login', obj)
                .then((response) => ( this.fmlyLgnRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyLogin : ' + error))
        },

        //보호자 목록 조회
        getAllFmlyList: function (obj){
            http.post('fmlyApp/selAllFmlyList', obj)
                .then((response) => ( this.getAllFmlyListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selAllFmlyList : ' + error))
        },

        //문자 인증번호 전송
        getAuthSmsSend: function (obj){
            http.post('fmlyApp/smsSend', obj)
                .then((response) => ( this.getAuthSmsSendRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/authSmsSend : ' + error))
        },

        //문자 인증번호 확인
        getAuthNumChk: function (obj){
            http.post('fmlyApp/certNumChk', obj)
                .then((response) => ( this.getAuthNumChkRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/authNumChk : ' + error))
        },

        //보호자 계정 저장
        setFmlyInsAcnt: function (obj){
            http.post('fmlyApp/createAcnt', obj)
                .then((response) => ( this.fmlyLgnRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyInsAcnt : ' + error))
        },

        getAuthSmsSendRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
                this.smsCfmBtn = true
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        getAuthNumChkRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                if(res.result == '1'){
                    this.step = 3
                    this.$refs.fmlyLgn.resetValidation()
                }
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        
        getAllFmlyListRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                this.$refs.fmlyLgn.reset()
                this.step = 1

                res.list.forEach(ll => {
                    ll.vTxt = ll.fname + ' (' + ll.celphn_1 + '-' + ll.celphn_2 + '-****)'
                });
                this.grdnList = res.list
            }
            else{
                obj.clr = 'error'
                obj.cntn = res.message

                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', obj)
            }
        },

        onFmlyList: function (){
            let obj = Object.assign({}, this.grdnInfo)
            obj.rsdntBirth = this.$moment(obj.rsdntBirth, 'YYYY-MM-DD', true).format('YYYYMMDD')
            obj.name = obj.name.trim()

            if(this.$refs.fmlyLgn.validate())
                this.getAllFmlyList(obj)
            else{
                let objAl= {}
                objAl.clr = 'info'
                objAl.cntn = '필수 항목을 입력해주세요.'
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', objAl)
            }
        },

        preSmsSnd: function (){
            if(this.grdnInfo.sltGrdn != 0){
                let chc = this.grdnList.findIndex( v => v.mmbrPk == this.grdnInfo.sltGrdn )
                if(chc > -1){
                    this.getAuthSmsSend(this.grdnList[chc])
                }
            }
            
        },

        preSmsChk: function (){
            if(this.grdnInfo.sltGrdn != 0){
                let chc = this.grdnList.findIndex( v => v.mmbrPk == this.grdnInfo.sltGrdn )
                if(chc > -1){
                    let obj = Object.assign({}, this.grdnList[chc])
                    obj.cntnt = this.grdnInfo.cnfmSms

                    this.getAuthNumChk(obj)
                }
            }
        },

        preFmlyInsAcnt: function (){
            if(this.grdnInfo.sltGrdn != 0){
                let chc = this.grdnList.findIndex( v => v.mmbrPk == this.grdnInfo.sltGrdn )
                if(chc > -1){
                    let obj = Object.assign({}, this.grdnList[chc])
                    obj.passwd = this.grdnInfo.passwd

                    if(this.grdnInfo.passwd == this.grdnInfo.passwdCfm)
                        this.setFmlyInsAcnt(obj)
                    else{
                        let obb
                        obb.clr = 'warning'
                        obb.cntn = '비밀번호가 일치하지 않습니다.'

                        this.$store.commit('setCmmAlertMdl', true)
                        this.$store.commit('setCmmAlertCntn', obb)
                    }
                }
            }
        },

        fmlyLgnRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                this.$refs.fmlyLgn.reset()

                this.$store.commit('setCmmBtmMdl', false)
                this.$store.commit('setToken', res.token)
                this.$store.commit('setSctKey', res.key)

                this.$router.push({ name: 'BnfrcMgmt' })
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.step = 0
            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        preFmlyLgn: function (){
            let obj = {}

            let chc = this.grdnList.findIndex( v => v.mmbrPk == this.grdnInfo.sltGrdn)
            if(chc > -1){
                obj = this.grdnList[chc]
                // obj.fcltyNum = this.grdnList[chc].fcltyNum
                // obj.mmbrPk = this.grdnList[chc].mmbrPk
                obj.passwd = this.grdnInfo.passwd
            }

            if(this.$refs.fmlyLgn.validate())
                this.fmlyLgn(obj)
            else{
                let objAl= {}
                objAl.clr = 'info'
                objAl.cntn = '필수 항목을 입력해주세요.'
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', objAl)
            }

        },
        rflGrndList: function () {
            let chc = this.grdnList.findIndex( v => v.mmbrPk == this.grdnInfo.sltGrdn )
            if(chc > -1){
                if(this.grdnList[chc].actyn == '1')
                    this.onLgnBtn = true
                else
                    this.onLgnBtn = false
            }
        },

        chnDtFrm: function (v) {
            let rst = ''
            
            if(v != null)
                rst = v.replace(/\s/gi, "")

            if(rst.length == 8)
                rst = rst.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
          
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(rst)){
                this.grdnInfo.rsdntBirth = rst
            }
            else{
                this.grdnInfo.rsdntBirth = rst.replaceAll('-', '')
            }
        },
        cnfmClear: function () {
            this.step = 0
            this.grdnList.splice(0)
            this.$refs.fmlyLgn.reset()
        },
    },

    data: () => ({
        step: 0,
        pswdShow: false,
        pswdCfmShow: false,
        smsCfmBtn: false,
        onLgnBtn: false,
        grdnInfo: {
            name: '', rsdntBirth: '', celphn_1: '', celphn_2: '', celphn_3: '', passwd: '', passwdCfm: '', sltGrdn: 0,
            cnfmSms: '',
        },
        grdnList: [],
        rules: {
            birthCheck: value => /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value) || '올바른 날짜 형식이 아닙니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            required: value => !!value || '필수 입력 항목입니다.',
        },
    }),
};
</script>