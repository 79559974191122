<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">수급자</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div class="my-5">
                    <v-row class="pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col cols="10">
                            <v-row class="pa-0 align-center" no-gutters>
                                <span
                                    class="d-inline-block rounded-circle blue104 mr-3"
                                    style="width: 12px; height: 12px;">
                                </span>
                                <span class="blue104--text fontOneToOne font-weight-bold">수급자 정보</span>
                            </v-row>
                            <v-divider class="mt-5"></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">어르신 성함</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ bnfcrInf.name }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">입소일</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ $moment(bnfcrInf.entDt).format('YYYY.MM.DD') }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">수급분류</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ bnfcrInf.copayNm }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">생활실</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ bnfcrInf.lvnRm }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">인정만료일</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ $moment(bnfcrInf.acknwPrdEnd).format('YYYY.MM.DD') }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-10"></v-divider>
                            <v-row class="pa-0 align-center" no-gutters>
                                <span
                                    class="d-inline-block rounded-circle blue104 mr-3"
                                    style="width: 12px; height: 12px;">
                                </span>
                                <span class="blue104--text fontOneToOne font-weight-bold">주 보호자 정보</span>
                            </v-row>
                            <v-divider class="mt-5"></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">보호자명</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.name }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">관계</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.rltns }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">휴대폰</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.celphn_1 }}</span>
                                    <span v-if="gndrInf.celphn_1 != ''" style="padding: 0 3px;">-</span>
                                    <span class="fontDotNine">{{ gndrInf.celphn_2 }}</span>
                                    <span v-if="gndrInf.celphn_1 != ''" style="padding: 0 3px;">-</span>
                                    <span class="fontDotNine">{{ gndrInf.celphn_3 }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">전화번호</span>
                                </v-col>
                                <v-col class="pl-4 py-1" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.tel_1 }}</span>
                                    <span v-if="gndrInf.tel_1 != ''" style="padding: 0 3px;">-</span>
                                    <span class="fontDotNine">{{ gndrInf.tel_2 }}</span>
                                    <span v-if="gndrInf.tel_1 != ''" style="padding: 0 3px;">-</span>
                                    <span class="fontDotNine">{{ gndrInf.tel_3 }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 d-flex justify-center align-center py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">이메일</span>
                                </v-col>
                                <v-col class="px-4 py-1" style="line-height: 1.2;" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.emailId }}</span>
                                    <span v-if="gndrInf.emailId != ''" style="padding: 0 3px;">@</span>
                                    <span class="fontDotNine">{{ gndrInf.emailDmn }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col class="greyE03 d-flex justify-center align-center py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">주소</span>
                                </v-col>
                                <v-col class="px-4 py-1" style="line-height: 1.2;" cols="7">
                                    <span class="fontDotNine">{{ gndrInf.addr }}&nbsp;</span>
                                    <span class="fontDotNine">{{ gndrInf.addrDtl }}</span>
                                </v-col>
                            </v-row>
                            <v-divider class=""></v-divider>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'BnfcrInfoApp',

    components: {
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            this.getBnfcrInfo()
            this.viewHeight = this.$vuetify.breakpoint.height - 154
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        //수급자 정보 조회
        getBnfcrInfo: function (){
            http.post('fmlyApp/authBnfcrInfo', {})
                .then((response) => ( this.bnfcrInfoRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authBnfcrInfo : ' + error))
        },
        bnfcrInfoRst: function (res){
            if(res.statusCode == '200'){
                res.codes.vCd63.forEach(itm => {
                    if(itm.valcd == res.bnfcr.copayRate)
                    res.bnfcr.copayNm = itm.valcdnm
                });

                this.vCd63 = res.codes.vCd63
                this.bnfcrInf = res.bnfcr
                this.gndrInf = res.selGarnInfo
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
    },

    data: () => ({
        viewHeight: 0,
        vCd63: [],
        bnfcrInf: {},
        gndrInf: {},
    }),
};
</script>