<template>
    <v-sheet class="py-7" width="100%">
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="grey006"
                @click="getRptInf"
                outlined rounded>
                <v-icon class="" size="18">mdi-printer-outline</v-icon>
                <span class="fontDotNine ml-2" style="padding-bottom: 2px;">
                    {{ $parent.$parent.sltDt }}년&nbsp;급여&nbsp;수가&nbsp;확인
                </span>
            </v-btn>
        </v-row>
        <v-row class="pt-5 pb-3 mt-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center" cols="11">
                <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">
                    {{ $parent.$parent.sltDt }}년&nbsp;장기요양보험&nbsp;급여수가&nbsp;안내
                </span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <v-divider class="black002"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">수급자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="7">
                <span class="fontDotEight" style="line-height: 2.1;">{{ bnfcrInf.name }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">생년월일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="7">
                <span class="fontDotEight" style="line-height: 2.1;">{{ bnfcrInf.rsdntBirth }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">등급</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="7">
                <span class="fontDotEight" style="line-height: 2.1;">{{ bnfcrInf.acknwRtngNm }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">본인부담률</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="7">
                <span class="fontDotEight" style="line-height: 2.1;">{{ bnfcrInf.copayRateNm }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">조회일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="7">
                <span class="fontDotEight" style="line-height: 2.1;">{{ bnfcrInf.chcDt }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <v-divider class="black002"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="3">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">등급</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ lstYr }}&nbsp;수가</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ nxtYr }}&nbsp;수가</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row
            v-for="(list, i) in blnftInf" :key="i"
            class="'pa-0'" no-gutters>
            <v-spacer></v-spacer>
            <v-col
                :class="['txtAlgnMid tableBR tableBB py-1', bnfcrInf.acknwRtngNm == (i+1) + '등급' ? 'nmlBfClr-greyE09' : 'nmlBfClr-greyE07']"
                cols="3">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ i+1 }}등급</span>
            </v-col>
            <v-col
                :class="['txtAlgnMid tableBR tableBB py-1', bnfcrInf.acknwRtngNm == (i+1) + '등급' ? 'nmlBfClr-greyE09' : '']"
                cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.lstYr }}</span>
            </v-col>
            <v-col
                :class="['txtAlgnMid tableBB py-1', bnfcrInf.acknwRtngNm == (i+1) + '등급' ? 'nmlBfClr-greyE09' : '']"
                cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.nxtYr }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="blnftInf.length == 0" no-gutters>
            <v-col class="txtAlgnMid tableBB py-1" cols="12">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">※ 조회된 내용이 없습니다.</span>
            </v-col>
        </v-row>
        <v-row class="pt-5 pb-3 mt-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center" cols="11">
                <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">비급여 비용 안내 ( 회당 비용 )</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <v-divider class="black002"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="3">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">&nbsp;</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ lstYr }}</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ nxtYr }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row
            v-for="(list, j) in blnftNonPay" :key="blnftInf.length+j"
            class="'pa-0'" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="txtAlgnMid tableBR tableBB py-1" cols="3">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.nm }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.lstYr }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.nxtYr }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pt-5 pb-3 mt-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center" cols="11">
                <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">급여비용 안내 ( 30일 기준 )</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <v-divider class="black002"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="3">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">&nbsp;</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ lstYr }}</span>
            </v-col>
            <v-col class="nmlBfClr-greyE07 txtAlgnMid tableBB py-1" cols="4">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">{{ nxtYr }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row
            v-for="(list, k) in blnftPay" :key="blnftInf.length+blnftNonPay.length+k"
            class="'pa-0'" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="txtAlgnMid tableBR tableBB py-1" cols="3">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.nm }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR tableBB py-1" cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.lstYr }}</span>
            </v-col>
            <v-col class="txtAlgnMid tableBB py-1" cols="4">
                <span class="fontDotEight" style="line-height: 2.1;">{{ list.nxtYr }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="blnftPay.length == 0" no-gutters>
            <v-col class="txtAlgnMid tableBB py-1" cols="12">
                <span class="fontDotEight font-weight-medium" style="line-height: 2.1;">※ 조회된 내용이 없습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';

export default {
    name: 'OutBlnftAmntAnnc',

    components: {
        CmmDrawIcon,
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '장기요양보험 급여 수가 안내'
            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        // 노인장기요양 급여수가 안내 조회
        getBnftFee: function (){
            let obj = {}
            obj.fcltyNum = this.$parent.$parent.sltFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.sltBnMmbrPk
            obj.inqYYYY = this.$parent.$parent.sltDt
            obj.prtCd = '57'

            this.clearData()

            http.post('fmly/selDirtBnftFeeNtc', obj)
                .then((response) => ( this.bnftFeeRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selDirtBnftFeeNtc : ' + error))
        },
        // 출력물 조회 Params
        getRptMgmtInf: function (obj){
            http.post('fmly/rptBasicInfo', obj)
                .then((response) => ( this.rptMgmtRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/rptBasicInfo : ' + error))
        },
        // PDF 조회
        getRptInf: function (){
            let obj = {}
            obj.fcltyNum = this.$parent.$parent.sltFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.sltBnMmbrPk
            obj.inqYYYY = this.$parent.$parent.sltDt
            obj.prtClcd = '1'
            obj.inqClcd = '2'
            obj.prtCd = '57'
            obj.list = []
            obj.list.push( { bzClcd: [], bzPk: [] } )

            http.post('fmly/reportInfo', obj)
                .then((response) => ( this.rptInfRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/reportInfo : ' + error))
        },
        rptMgmtRst: function (res){
            if(res.statusCode == '200'){
                this.bnMmbrPk = res.result.bnMmbrPk
                this.frmList = res.result.list
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rptInfRst: function (res){
            if(res.statusCode == '200'){
                this.$store.commit('setCmmPdfDstnt', 'imgPrview')
                this.$store.commit('setCmmPdfPth', res.filePth)
                this.$store.commit('setCmmPdfNm', res.fileNm)
                this.$store.commit('setCmmPdfOrgNm', res.orgFileNm)
                this.$store.commit('setCmmPdfTtl', this.$parent.$parent.sltDt + '년 급여 수가 안내')
                this.$store.commit('setCmmPrtMdl', true)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        bnftFeeRst: function (res){
            console.log(res)
            if(res.statusCode == '200'){
                if(Object.keys(res.result).length > 0){
                    // 수급자 기본정보 맵핑
                    this.bnfcrInf.name = res.result.data1
                    this.bnfcrInf.rsdntBirth = res.result.data2.slice(0, 10)
                    this.bnfcrInf.gndr = res.result.data2.slice(-3).slice(1, 2)
                    this.bnfcrInf.acknwRtngNm = res.result.data5
                    this.bnfcrInf.copayRate = res.result.data6
                    this.bnfcrInf.copayRateNm = res.result.data3
                    this.bnfcrInf.chcDt = res.result.data29

                    // 전년도, 당해년도 맵핑
                    this.lstYr = res.result.data8
                    this.nxtYr = res.result.data14

                    // 등급별 수가정보 맵핑
                    this.blnftInf.push({ lstYr: res.result.data9, nxtYr: res.result.data15 })
                    this.blnftInf.push({ lstYr: res.result.data10, nxtYr: res.result.data16 })
                    this.blnftInf.push({ lstYr: res.result.data11, nxtYr: res.result.data17 })
                    this.blnftInf.push({ lstYr: res.result.data12, nxtYr: res.result.data18 })
                    this.blnftInf.push({ lstYr: res.result.data13, nxtYr: res.result.data19 })

                    // 비급여 비용정보 맵핑
                    this.blnftNonPay.push({ nm: '식대', lstYr: res.result.data32, nxtYr: res.result.data35 })
                    this.blnftNonPay.push({ nm: '간식비', lstYr: res.result.data33, nxtYr: res.result.data36 })
                    this.blnftNonPay.push({ nm: '경관유동식', lstYr: res.result.data34, nxtYr: res.result.data37 })

                    // 급여비용 정보 맵핑
                    this.blnftPay.push({ nm: '1일 수가', lstYr: res.result.data20, nxtYr: res.result.data23 })
                    this.blnftPay.push({ nm: '급여비용',lstYr: res.result.data21, nxtYr: res.result.data24 })
                    this.blnftPay.push({ nm: '본인부담금',lstYr: res.result.data22, nxtYr: res.result.data25 })
                    this.blnftPay.push({ nm: '식대',lstYr: res.result.data38, nxtYr: res.result.data40 })
                    this.blnftPay.push({ nm: '합계',lstYr: res.result.data39, nxtYr: res.result.data41 })
                }
            }
        },
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)
                    
                    let tmp = Object.assign({}, JSON.parse(bytes.toString(this.$crypto.enc.Utf8)))
                    this.$parent.$parent.sltFcltyNum = tmp.fcltyNum
                    this.$parent.$parent.sltBnMmbrPk = tmp.bnMmbrPk
                    this.$parent.$parent.sltDt = tmp.year

                    this.getBnftFee()
                }
            }
        },
        clearData: function () {
            this.bnfcrInf.name = ''
            this.bnfcrInf.rsdntBirth = ''
            this.bnfcrInf.gndr = ''
            this.bnfcrInf.acknwRtngNm = ''
            this.bnfcrInf.copayRate = ''
            this.bnfcrInf.copayRateNm = ''
            this.bnfcrInf.chcDt = ''

            this.blnftInf.splice(0)
            this.blnftPay.splice(0)
        },
    },

    data: () => ({
        lstYr: '',
        nxtYr: '',
        bnfcrInf: {
            name: '', rsdntBirth: '', gndr: '', acknwRtngNm: '', copayRate: '', copayRateNm: '', chcDt: ''
        },
        blnftInf: [],
        blnftPay: [],
        blnftNonPay: [],
    }),
};
</script>