<template>
    <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-bold fontOneToTwo" v-if="mode == '1'">계약의사 진료비 상세내역</span>
                <span class="font-weight-bold fontOneToTwo" v-else-if="mode == '2'">진료 약제비 상세내역</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <div v-if="mode == '1'">
            <v-row class="pa-0 greyE01" no-gutters>
                <v-col class="py-1 txtAlgnMid tableBR" cols="4">
                    <span class="fontDotNine font-weight-medium">일자</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBR" cols="4">
                    <span class="fontDotNine font-weight-medium">계약의사명</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid" cols="4">
                    <span class="fontDotNine font-weight-medium">진찰비</span>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="mode == '2'">
            <v-row class="pa-0 greyE01" no-gutters>
                <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                    <span class="fontDotNine font-weight-medium">일자</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                    <span class="fontDotNine font-weight-medium">병의원</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                    <span class="fontDotNine font-weight-medium">진료비</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid" cols="3">
                    <span class="fontDotNine font-weight-medium">약제비</span>
                </v-col>
            </v-row>
        </div>
        <v-divider class=""></v-divider>
        <div
            v-if="mode == '1'"
            class="d-inline-block overflow-y-auto"
            style="width: 100%; height: 300px;">
            <v-row class="pa-0 tableBB" v-for="(recList, i) in clncRecList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center overflow-text-hidden tableBR py-1" cols="4">
                    <span class="fontDotEight">
                        {{ $moment(recList.clncDt, 'YYYYMMDD', true).format('YYYY.MM.DD') }}
                    </span>
                </v-col>
                <v-col class="txtAlgnMid txtLnHgt-4 tableBR pa-1" cols="4">
                    <span class="fontDotEight">{{ recList.hospNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1" cols="4">
                    <span class="fontDotEight">{{ Number(recList.dctrFee)?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 tableBB" v-if="clncRecList.length == 0" no-gutters>
                <v-col class="py-3 txtAlgnMid" cols="12">
                    <span class="fontDotNine">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 greyE01" no-gutters>
                <v-col class="py-1 txtAlgnMid tableBR" cols="8">
                    <span class="fontDotNine font-weight-medium">합계</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid" cols="4">
                    <span class="fontDotEight">{{ Number(clncRecSum[0])?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <div
            v-else-if="mode == '2'"
            class="d-inline-block overflow-y-auto"
            style="width: 100%; height: 300px;">
            <v-row class="pa-0 tableBB" v-for="(recList, i) in clncRecList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center overflow-text-hidden tableBR py-1" cols="3">
                    <span class="fontDotEight">
                        {{ $moment(recList.clncDt, 'YYYYMMDD', true).format('YYYY.MM.DD') }}
                    </span>
                </v-col>
                <v-col class="txtAlgnMid txtLnHgt-4 tableBR pa-1" cols="3">
                    <span class="fontDotEight">{{ recList.hospNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR pa-1" cols="3">
                    <span class="fontDotEight">{{ Number(recList.clncFee)?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1" cols="3">
                    <span class="fontDotEight">{{ Number(recList.drugFee)?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 tableBB" v-if="clncRecList.length == 0" no-gutters>
                <v-col class="py-3 txtAlgnMid" cols="12">
                    <span class="fontDotNine">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 greyE01" no-gutters>
                <v-col class="py-1 txtAlgnMid tableBR" cols="6">
                    <span class="fontDotNine font-weight-medium">합계</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                    <span class="fontDotEight">{{ Number(clncRecSum[1])?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid" cols="3">
                    <span class="fontDotEight">{{ Number(clncRecSum[2])?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'MdclExpnsMdl',

    components: {

    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
        bnfcrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.mode = this.$store.getters.getPyrlSrvcCtgObj.clncClcd

            if(this.aplyYyMm != '' && this.bnMmbrPk == 0)
                this.getClncRecList(this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM'))
            else if(this.bnMmbrPk != 0)
                this.getOutClncRecList(this.fcltyNum, this.bnMmbrPk, this.bnfcrPk, this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYYMM'))

        })
    },

    computed: {

    },

    watch:{
        'bnMmbrPk': function (){
            if(this.bnMmbrPk != 0)
                this.getOutCopayClmHisInfo(this.fcltyNum, this.bnMmbrPk, this.aplyYyMm)
        },
    },
        
    methods: {
        //진료 약제비, 계약의사 진찰비 조회 TOKEN
        getClncRecList: function (yymm){
            http.post('fmly/selClncRecList', { inqYYMM: yymm, })
                .then((response) => ( this.clncRecListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selClncRecList : ' + error))
        },
        //진료 약제비, 계약의사 진찰비 조회 Params
        getOutClncRecList: function (code, bnmm, bnfcr, yymm){
            http.post('fmly/selClncRecList', { fcltyNum: code, bnMmbrPk: bnmm, bnfcrPk: bnfcr, clmYymm: yymm, })
                .then((response) => ( this.clncRecListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selClncRecList : ' + error))
        },
        clncRecListRst: function (res){
            this.clncRecList.splice(0)
   
            for (let index = 0; index < this.clncRecSum.length; index++) {
                this.clncRecSum.splice(index, 1, 0)
            }

            if(res.statusCode == '200'){
                if(this.mode == '1')
                    this.clncRecList = res.result.filter( v => v.drugFeeClmYn == '1' )
                else
                    this.clncRecList = res.result.filter( v => v.clncFeeClmYn == '1' || v.drugFeeClmYn == '1' )

                this.clncRecList.forEach(crList => {
                    this.clncRecSum[0] += crList.dctrFee
                    this.clncRecSum[1] += crList.clncFee
                    this.clncRecSum[2] += crList.drugFee
                });
            }

            //console.log(res)
        },
        onClose: function () {
            this.$store.commit('setPyrlSrvcMdl', false)
            this.$store.commit('setPyrlSrvcCtgMdl', '')
            this.$store.commit('setPyrlSrvcCtgObj', {})
        },
    },

    data: () => ({
        mode: '',
        clncRecSum: [0, 0, 0],      //진찰비, 진료비, 약제비
        clncRecList: [],
    }),
};
</script>