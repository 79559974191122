<template>
    <v-sheet class="mt-19" width="100%">
        <div class="fxdAppTop py-3">
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <span class="overflow-x-auto">
                        <v-tabs
                            class="cstTabs" slider-size="1" height="40"
                            v-model="tab" show-arrows>
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab
                                active-class="greyE02 blue102--text"
                                v-for="(tb, i) in tabMenu" :key="i">
                                <span class="fontOne">{{ tb.tbNm }}</span>
                            </v-tab>
                        </v-tabs>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row class="pa-0 mt-6" no-gutters>
            <v-col cols="12">
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item
                        v-for="(tb, j) in tabMenu" :key="j"
                        transition="false">
                        <FmlyLeter
                            v-if="tb.prtCd == '1' && tab == tb.idx"
                            :aplyYyMm="sltDt">
                        </FmlyLeter>
                        <BnftSrvRcrd
                            v-else-if="tb.prtCd == '14' && tab == tb.idx"
                            :aplyYyMm="sltDt">
                        </BnftSrvRcrd>
                        <PrgSrvRcrd
                            v-else-if="tb.prtCd == '27' && tab == tb.idx"
                            :aplyYyMm="sltDt">
                        </PrgSrvRcrd>
                        <PayExpStt
                            v-else-if="tb.prtCd == '8' && tab == tb.idx"
                            :aplyYyMm="sltDt">
                        </PayExpStt>
                        <DietTbl
                            v-else-if="tb.prtCd == '2' && tab == tb.idx"
                            :aplyYyMm="sltDt">
                        </DietTbl>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog v-model="$store.state.pyrlSrvcMdl" persistent>
            <MdclExpnsMdl v-if="$store.state.pyrlSrvcCtgMdl.yNo == '1'" :aplyYyMm="sltDt"></MdclExpnsMdl>
            <MsclnExpsMdl v-else-if="$store.state.pyrlSrvcCtgMdl.yNo == '2'" :aplyYyMm="sltDt"></MsclnExpsMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import FmlyLeter from './pyrlsrvc/FmlyLeter.vue';           //가정통신문
import BnftSrvRcrd from './pyrlsrvc/BnftSrvRcrd.vue';       //급여제공기록
import PayExpStt from './pyrlsrvc/PayExpStt.vue';           //비용 명세서
import PrgSrvRcrd from './pyrlsrvc/PrgSrvRcrd.vue';         //프로그램
import DietTbl from './pyrlsrvc/DietTbl.vue';               //주간식단표
import MdclExpnsMdl from './pyrlsrvc/MdclExpnsMdl.vue';     //약제비, 진찰비 팝업
import MsclnExpsMdl from './pyrlsrvc/MsclnExpsMdl.vue';     //기타 비용 팝업

export default {
    name: 'PyrlSrvcMgmt',

    components: {
        FmlyLeter,
        BnftSrvRcrd,
        PayExpStt,
        PrgSrvRcrd,
        DietTbl,
        MdclExpnsMdl,
        MsclnExpsMdl,
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '노인장기요양급여 조회'

            if( typeof this.$route.params.yymm == 'undefined')
                this.sltDt = this.$moment().format('YYYYMM')
            else
                this.sltDt = this.$route.params.yymm

            if( typeof this.$route.params.prtCdAr != 'undefined')
                this.arrCtr(this.$route.params.prtCdAr)
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        arrCtr: function (arr) {
            let chc = arr.findIndex( v => v == '0' )

            if(chc > -1)
                arr.splice(chc, 1, '1')

            this.sltprtCdAr = [...new Set(arr)]

            if(this.sltprtCdAr.length > 0)
                this.rflTabList()
        },
        rflTabList: function () {
            this.tabMenu.splice(0)

            this.sltprtCdAr.forEach((cdS, i) => {
                let chc = this.tabList.findIndex( v => v.prtCd == cdS )
                if(chc > -1){
                    let obj = Object.assign({}, this.tabList[chc])
                    obj.idx = i
                    this.tabMenu.push(obj)
                }
            });
        },
    },

    data: () => ({
        tab: 0,
        sltDt: '',
        sltprtCdAr: [],
        tabMenu: [],
        tabList: [
            { tbNm: '가정통신문', prtCd: '1' }, { tbNm: '급여', prtCd: '14' },
            { tbNm: '프로그램참여', prtCd: '27' }, { tbNm: '명세서', prtCd: '8' },
            { tbNm: '식단표', prtCd: '2' }, { tbNm: '프로그램일정표', prtCd: '13' },
        ],
    }),
};
</script>