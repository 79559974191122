<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">급여비용 명세서</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYYMM :aplyMinDt="'2020-01'" :aplyMaxDt="$moment().format('YYYY-MM')" @sltHypDt="getHypDt"></AppYYMM>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <div class="mt-15" style="min-height: 138.8px;">
                    <!-- bnfcrPks -->
                    <v-row class="pt-5 pb-4" v-if="bnfcrPks.length > 1" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="" cols="10">
                            <v-row class="pa-0 align-center" no-gutters>
                                <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                                <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">청구서 목록</span>
                            </v-row>
                            <v-divider class="black002 mt-3"></v-divider>
                            <v-row
                                class="pa-0 tableBB" v-for="(pks, i) in bnfcrPks" :key="i"
                                @click="sltBnfcrPk = pks.bnfcrPk"
                                no-gutters>
                                <v-col class="pa-1" cols="7">
                                    <span class="grey006--text fontDotSeven">{{ $moment(pks.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    <span class="mx-1 pb-1">
                                        <v-icon size="10">mdi-tilde</v-icon>
                                    </span>
                                    <span class="grey006--text fontDotSeven" v-if="pks.lvDt != ''">{{ $moment(pks.lvDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="d-flex justify-end align-center pa-1" cols="3">
                                    <v-icon v-if="pks.bnfcrPk == sltBnfcrPk" color="blue104" size="16">mdi-check</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </div>
                <v-row class="pt-5 pb-4" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">급여비용 명세서</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="pt-1" cols="10">
                        <div
                            v-if="clmInfo.cnfrmYn == '1'"
                            class="d-inline-block rounded-xl txtAlgnMid blue104 py-6 px-12"
                            style="width: 100%;">
                            <div class="fontDotEight font-weight-medium white--text">총 청구액</div>
                            <v-divider class="greyE08 mt-3 mb-1"></v-divider>
                            <div class="mb-2">
                                <span class="fontOneToFive font-weight-black white--text">
                                    {{ clmAmt?.toLocaleString('ko-KR') }}
                                </span>
                                <span class="ml-1 fontDotEight white--text">원</span>
                            </div>
                            <div class="">
                                <div class="fontDotEight font-weight-medium white--text">
                                    &#40;&nbsp;납부한 금액&nbsp;&#58;&nbsp;{{ dpsAmt?.toLocaleString('ko-KR') }}&nbsp;원&nbsp;&#41;
                                </div>
                                <div v-if="copayInfo.unpdAmt == 0" class="fontDotEight font-weight-medium white--text">
                                    &#40;&nbsp;납부 완료 되었습니다.&nbsp;&#41;
                                </div>
                                <div v-else class="fontDotEight font-weight-medium white--text">
                                    &#40;&nbsp;납부기한&nbsp;&#58;&nbsp;{{ $moment(copayInfo.pymDuDt, 'YYYYMMDD').format('YYYY년 MM월 DD일') }}.&nbsp;&#41;
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="d-inline-block rounded-xl txtAlgnMid blue104 py-6 px-12"
                            style="width: 100%;">
                            <div class="fontOne font-weight-medium white--text">청구 내역이 없습니다.</div>
                        </div>
                        <div class="mt-7">
                            <v-row class="pa-0" no-gutters>
                                <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                    <span class="blue104--text fontDotEight font-weight-medium">납부내역</span>
                                </span>
                            </v-row>
                            <v-divider class="black002 mt-3"></v-divider>
                            <v-row class="pa-0 tableBB" v-for="(list, i) in clmDpsList" :key="i" no-gutters>
                                <v-spacer></v-spacer>
                                <v-col class="py-2" cols="11">
                                    <span class="fontDotNine">{{ $moment(list.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                    <span class="mx-2">&#47;</span>
                                    <span class="fontDotNine">{{ list.dpsAmt?.toLocaleString('ko-KR') }}원</span>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="pa-0 tableBB" v-if="clmDpsList.length == 0" no-gutters>
                                <v-col class="txtAlgnMid py-2" cols="12">
                                    <span class="grey006--text fontDotSeven">납부내역이 없습니다.</span>
                                </v-col>
                            </v-row>
                            <v-row class="mt-7" no-gutters>
                                <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                    <span class="blue104--text fontDotEight font-weight-medium">수급자 정보</span>
                                </span>
                            </v-row>
                            <v-divider class="black002 mt-3"></v-divider>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">수급자명</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotNine">{{ clmInfo.name }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">등급</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotNine">{{ clmInfo.copayRate }}등급</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">본인부담률</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotNine">{{ clmInfo.copayRateNm }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="mt-7" no-gutters>
                                <span class="rounded-xl px-2 pb-1" style="border: 1px solid #1B81DF;">
                                    <span class="blue104--text fontDotEight font-weight-medium">청구 기관정보</span>
                                </span>
                            </v-row>
                            <v-divider class="black002 mt-3"></v-divider>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">기관명</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotEight">{{ fcltyInfo.fcltyClnm }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 d-flex align-center justify-center py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">주소</span>
                                </v-col>
                                <v-col class="txtAlgnEnd px-2 py-1" style="line-height: 18px;" cols="7">
                                    <span class="fontDotEight">{{ fcltyInfo.addr }} {{ fcltyInfo.addrDtl }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">전화번호</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotEight">
                                        {{ fcltyInfo.fcltyTel1 }} - {{ fcltyInfo.fcltyTel2 }} - {{ fcltyInfo.fcltyTel3 }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 d-flex align-center justify-center py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">이메일</span>
                                </v-col>
                                <v-col class="px-2 py-1" style="line-height: 18px;" cols="7">
                                    <div class="d-flex justify-end">
                                        <span class="fontDotEight">{{ fcltyInfo.emailId }}</span>
                                    </div>
                                    <div class="d-flex align-center justify-end">
                                        <span class="fontDotEight">@{{ fcltyInfo.emailDmn }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">대표자</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotEight">
                                        {{ fcltyInfo.ceo }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 tableBB" no-gutters>
                                <v-col class="greyE03 txtAlgnMid py-1" cols="5">
                                    <span class="fontDotNine font-weight-medium">사업자 번호</span>
                                </v-col>
                                <v-col class="d-flex align-center justify-end px-2 py-1" cols="7">
                                    <span class="fontDotEight">{{ fcltyInfo.bsnsRgst }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="mt-7" no-gutters>
                                <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                    <span class="blue104--text fontDotEight font-weight-medium">청구 상세내역</span>
                                </span>
                                <v-spacer></v-spacer>
                                <span
                                    class="d-flex align-center rounded-xl blue104 pl-3 pr-2 py-1"
                                    v-if="clmInfo.cnfrmYn == '1'"
                                    v-ripple="{ center: true, class: 'grey--text' }"
                                    @click="getRptInf">
                                    <CmmDrawIcon type="ic_print_white" size="18"></CmmDrawIcon>
                                    <span class="white--text fontDotEight font-weight-medium ml-2">출력</span>
                                    <v-icon class="white--text ml-1" size="18">mdi-chevron-right</v-icon>
                                </span>
                            </v-row>
                            <v-divider class="black002 mt-3"></v-divider>
                            <div v-if="clmInfo.cnfrmYn == '1'">
                                <v-row class="pa-0 tableBB" no-gutters>
                                    <v-col class="greyE03 txtAlgnMid py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">청구일자</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="fontDotEight">
                                            {{ $moment(clmInfo.clmDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-0 tableBB" no-gutters>
                                    <v-col class="greyE03 txtAlgnMid py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">급여제공 기간</span>
                                    </v-col>
                                    <v-col class="d-flex justify-end align-center px-2 py-1" cols="6">
                                        <span class="fontDotSeven">
                                            {{ $moment(clmInfo.ofrStrtDt, 'YYYYMMDD').format('MM월 DD일') }}
                                        </span>
                                        <span style="margin: 0 2px;">~</span>
                                        <span class="fontDotSeven">
                                            {{ $moment(clmInfo.ofrEndDt, 'YYYYMMDD').format('MM월 DD일') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="greyE03 txtAlgnMid py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">납부기한</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="fontDotEight">
                                            {{ $moment(copayInfo.pymDuDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="black002"></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClr-greyE03 txtAlgnMid tableBR" cols="6">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="py-1 tableBR" cols="5">
                                                <span class="fontDotNine font-weight-medium">급여</span>
                                            </v-col>
                                            <v-col class="py-1" cols="7">
                                                <span class="fontDotNine font-weight-medium">본인부담금</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="fontDotEight font-weight-bold">
                                            {{ clmInfo.copay?.toLocaleString('ko-KR') }}&nbsp;원
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="black002"></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClr-greyE03 txtAlgnMid tableBR" cols="6">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="d-flex justify-center align-center tableBR" cols="5">
                                                <span class="fontDotNine font-weight-medium">비급여</span>
                                            </v-col>
                                            <v-col class="py-1" cols="7">
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">식사재료비</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">간식비</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">경관유동식</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">상급침실비</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">이,미용비</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">등급외</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">진료약제비</div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1" style="line-height: 1.2;">
                                                    계약의사<br/>진찰비
                                                </div>
                                                <div class="fontDotNine font-weight-medium tableBB pb-1">기타비용</div>
                                                <div class="fontDotNine font-weight-medium">비급여 계</div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end" cols="6">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="" cols="12">
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 4px;">
                                                    <span class="fontDotEight">{{ clmInfo.mealCost?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 3px;">
                                                    <span class="fontDotEight">{{ clmInfo.snackFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 3px;">
                                                    <span class="fontDotEight">{{ clmInfo.lqdFood?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 3px;">
                                                    <span class="fontDotEight">{{ clmInfo.upBedFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 3px;">
                                                    <span class="fontDotEight">{{ clmInfo.btyFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 2px;">
                                                    <span class="fontDotEight">{{ clmInfo.otrAmt?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 3px;">
                                                    <span class="fontDotEight">{{ clmInfo.clncPhrmcFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-top: 6px; padding-bottom: 6px;">
                                                    <!-- <v-btn small icon>
                                                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                                                    </v-btn> -->
                                                    <span class="fontDotEight">{{ clmInfo.cntrcDctrFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2 tableBB" style="padding-bottom: 2.4px;">
                                                    <span class="fontDotEight">{{ clmInfo.msclnFee?.toLocaleString('ko-KR') }} 원</span>
                                                </div>
                                                <div class="txtAlgnEnd px-2">
                                                    <span class="fontDotEight font-weight-bold">
                                                        {{
                                                            ( Number(clmInfo.msclnFee)
                                                                + Number(clmInfo.mealCost)
                                                                + Number(clmInfo.upBedFee)
                                                                + Number(clmInfo.btyFee)
                                                                + Number(clmInfo.lqdFood)
                                                                + Number(clmInfo.snackFee)
                                                                + Number(clmInfo.clncPhrmcFee)
                                                                + Number(clmInfo.cntrcDctrFee)
                                                                + Number(clmInfo.otrAmt)
                                                            )?.toLocaleString('ko-KR')
                                                        }} 원
                                                    </span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider class="black002"></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClr-greyE03 txtAlgnMid tableBR py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">당월 본인부담금합계</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="fontDotEight font-weight-bold">
                                            {{
                                                (
                                                    Number(clmInfo.copay)
                                                    + Number(clmInfo.msclnFee)
                                                    + Number(clmInfo.mealCost)
                                                    + Number(clmInfo.upBedFee)
                                                    + Number(clmInfo.btyFee)
                                                    + Number(clmInfo.lqdFood)
                                                    + Number(clmInfo.snackFee)
                                                    + Number(clmInfo.clncPhrmcFee)
                                                    + Number(clmInfo.cntrcDctrFee)
                                                    + Number(clmInfo.otrAmt)
                                                )?.toLocaleString('ko-KR')
                                            }} 원
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="black002"></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClr-greyE03 txtAlgnMid tableBR py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">이전 미납금</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="fontDotEight font-weight-bold">
                                            {{
                                                clmInfo.unpdAmt?.toLocaleString('ko-KR')
                                            }} 원
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="black002"></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="nmlBfClr-greyE03 d-flex align-center justify-center tableBR py-1" cols="6">
                                        <span class="fontDotNine font-weight-medium">총 청구액</span>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end px-2 py-1" cols="6">
                                        <span class="blue104--text fontOneToTwo font-weight-bold">
                                            {{ clmInfo.clmTot?.toLocaleString('ko-KR') }}
                                        </span>
                                        <span class="blue104--text fontOne font-weight-bold ml-1">원</span>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="txtAlgnMid py-2" cols="12">
                                        <span class="grey006--text fontDotSeven">청구 내역이 없습니다.</span>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-divider class=""></v-divider>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYYMM from '../../cmm/AppYYMM.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'BnftSrvSttmApp',

    components: {
        CmmDrawIcon,
        AppYYMM,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getBnfcrPkList()
            //this.getCopayClmInfo(this.$moment().format('YYYYMM'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
        'sltBnfcrPk': function () {
            if(this.sltBnfcrPk == -1){
                this.clmAmt = 0
                this.dpsAmt = 0
                this.clmDpsList.splice(0)
                this.clmInfo = Object.assign({})
                this.copayInfo = Object.assign({})
                this.fcltyInfo = Object.assign({})
            }
            else
                this.getCopayClmInfo(this.sltDt)
        },
    },
        
    methods: {
        // 수급자 목록 조회
        getBnfcrPkList: function (){
            this.sltBnfcrPk = -1
            this.bnfcrPks.splice(0)

            http.post('fmlyApp/authBnfcrPkList', { })
                .then((response) => ( this.bnfcrPkRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authBnfcrPkList : ' + error))
        },
        // 본인부담금 청구내역 조회 (월간)
        getCopayClmInfo: function (yymm){
            this.clmAmt = 0
            this.dpsAmt = 0
            this.clmDpsList.splice(0)
            this.clmInfo = Object.assign({})
            this.copayInfo = Object.assign({})
            this.fcltyInfo = Object.assign({})

            http.post('fmlyApp/authCopayClmInfo', { inqYYMM: yymm, bnfcrPk: this.sltBnfcrPk })
                .then((response) => ( this.copayClmRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authCopayClmInfo : ' + error))
        },
        bnfcrPkRst: function (res){
            if(res.statusCode == '200'){
                if(res.result?.bnfcrPkList.length > 0){
                    this.bnfcrPks = res.result.bnfcrPkList

                    let chc = []
                    this.bnfcrPks.forEach(idx => {
                        let trg = this.sltDt
                        if(trg == '')
                            trg = this.$moment().format('YYYYMM')
                        let strt = this.$moment(idx.entDt, 'YYYYMMDD').format('YYYY-MM')
                        let end = ''
                        if(idx.lvDt == '')
                            end = this.$moment().format('YYYY-MM')
                        else
                            end = this.$moment(idx.lvDt, 'YYYYMMDD').format('YYYY-MM')

                        if(this.$moment(trg, 'YYYYMM').isSameOrAfter(strt) && this.$moment(trg, 'YYYYMM').isSameOrBefore(end))
                            chc.push(idx.bnfcrPk)
                    });

                    if(chc.length > 0)
                        this.sltBnfcrPk = chc[0]
                    else
                        this.sltBnfcrPk = -1
                }
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        // PDF 조회
        getRptInf: function (){
            let obj = {}
            obj.fcltyNum = this.fcltyInfo.fcltyNum
            obj.bnMmbrPk = this.clmInfo.bnMmbrPK
            obj.bnfcrPk = this.sltBnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = '1'
            obj.prtCd = '8'
            obj.inqYYMM = this.sltDt
            obj.list = []
            obj.list.push( { bzClcd: [], bzPk: [] } )

            http.post('fmly/reportInfo', obj)
                .then((response) => ( this.rptInfRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/reportInfo : ' + error))
        },
        copayClmRst: function (res){
            if(res.statusCode == '200'){
                if(Object.keys(res.codes).length > 0)
                    this.vCd63 = res.codes.vCd63
                if(res.clmDpsList.length > 0){
                    this.clmDpsList = res.clmDpsList
                    this.clmDpsList.forEach(itm => {
                        this.clmAmt = itm.clmamt
                        this.dpsAmt += itm.dpsAmt
                    });
                }
                if(Object.keys(res.clmInfo).length > 0)
                    this.clmInfo = res.clmInfo
                if(Object.keys(res.copayInfo).length > 0)
                    this.copayInfo = res.copayInfo
                if(Object.keys(res.fcltyInfo).length > 0)
                    this.fcltyInfo = res.fcltyInfo

                this.rflCode()
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rptInfRst: function (res){
            if(res.statusCode == '200'){
                this.$store.commit('setCmmPdfDstnt', 'imgPrview')
                this.$store.commit('setCmmPdfPth', res.filePth)
                this.$store.commit('setCmmPdfNm', res.fileNm)
                this.$store.commit('setCmmPdfOrgNm', res.orgFileNm)
                this.$store.commit('setCmmPdfTtl', '급여비용 명세서')
                this.$store.commit('setCmmPrtMdl', true)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rflCode: function (){
            if(this.clmInfo.copayRate != null && this.vCd63.length > 0){
                this.vCd63.forEach(itm => {
                    if(itm.valcd == this.clmInfo.copayRate)
                        this.clmInfo.copayRateNm = itm.valcdnm
                });
            }
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != ''){
                this.sltDt = this.$moment(v, 'YYYY-MM').format('YYYYMM')
                this.getBnfcrPkList()
                //this.getCopayClmInfo(this.sltDt)
            }
        },
    },

    data: () => ({
        viewHeight: 0,
        sltDt: '',
        sltBnfcrPk: -1,
        bnfcrPks: [],
        clmAmt: 0,          // 총 청구액
        dpsAmt: 0,          // 납부액
        vCd63: [],          // 본인부담율 코드
        clmDpsList: [],     // 본인부담금 청구월 입금정보
        clmInfo: {},        // 본인부담금 청구 상세내역
        copayInfo: {},      // 본인부담금 미납정보
        fcltyInfo: {},      // 기관정보
        isMdl: false,
        mdlTyp: 0,
    }),
};
</script>