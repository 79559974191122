<template>
    <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-bold fontOneToTwo">요양급여제공기록</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-2 mt-4"></v-divider>
        <v-row class="pr-2" no-gutters>
            <v-col class="greyE02 pa-2" cols="7">
                <div class="txtAlgnMid">
                    <span class="fontDotEight font-weight-bold">
                        {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                    </span>
                </div>
                <div class="txtAlgnMid">
                    <span class="fontDotEight font-weight-bold">
                        {{ $store.getters.getBnftcrdCtgObj.name }}
                    </span>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL py-2 px-1" cols="5">
                <v-btn color="blue004" style="padding: 0 10px 2px 6px !important" :disabled="prvsBtn" @click="prvsDataLoad" small>
                    <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-text-box-search-outline</v-icon>
                    <span class="white--text font-weight-bold ml-2" style="padding-top: 2px;">전일자료</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-2"></v-divider>
        <div
            class="d-inline-block overflow-y-auto pr-2"
            :style="{ 'width': '100%', 'height': (($vuetify.breakpoint.height * 0.89) - 196) + 'px' }">
            <v-row class="tableBB" v-for="(list, i) in locRecDayList" :key="i" no-gutters>
                <v-col class="" v-if="list.clsfc3Cd != '99'" cols="7">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center py-2" cols="6">
                            <div>
                                <div class="d-flex justify-center align-center">
                                    <span class="fontDotSeven">{{ list.clsfc3Cdnm }}</span>
                                </div>
                                <div class="d-flex justify-center align-center" v-if="list.txtSub != ''">
                                    <span :class="[ list.clsfc3Cd == '10' ? 'fontDotFive' : 'fontDotSeven']">{{ list.txtSub }}</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-3 tableBL" cols="6">
                            <div class="txtAlgnMid fontDotSeven" v-html="list.plnNm"></div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" v-if="list.clsfc3Cd != '99'" cols="5">
                    <div v-if="list.clsfc2Cd == '1' && list.clsfc3Cd == '11'">
                        <v-btn
                            :class="['mr-1', list.itmAr1 ? 'blue001 white--text' : '']"
                            min-width="46" style="padding: 0px 2px !important;"
                            @click="list.itmAr1 = !list.itmAr1" :ripple="false"
                            small outlined>
                            <v-icon size="15">mdi-walk</v-icon>
                            <span>산책</span>
                        </v-btn>
                        <v-btn
                            :class="['mr-1', list.itmAr2 ? 'blue001 white--text' : '']"
                            min-width="46" style="padding: 0px 2px !important;"
                            @click="list.itmAr2 = !list.itmAr2" :ripple="false"
                            small outlined>
                            <v-icon size="15">mdi-exit-run</v-icon>
                            <span>외출</span>
                        </v-btn>
                    </div>
                    <CmmNmrcField v-model="list.itmVal" v-else></CmmNmrcField>
                </v-col>
                <v-col 
                    class="d-flex justify-center align-center greyE02 py-2"
                    v-if="list.clsfc3Cd == '99'" cols="7">
                    <div>
                        <div class="d-flex justify-center align-center">
                            <span class="fontDotEight">{{ list.clsfc3Cdnm }}</span>
                        </div>
                        <div class="d-flex justify-center align-center" v-if="list.txtSub != ''">
                            <span class="fontDotSeven">{{ list.txtSub }}</span>
                        </div>
                    </div>
                </v-col>
                <v-col
                    class="pa-1 greyE02" style="border-left: 1px solid #dbdbdb !important;"
                    v-if="list.clsfc3Cd == '99'" cols="5">
                    <v-textarea
                        v-model="list.itmVal"
                        class="rmrksArea" rows="3"
                        no-resize outlined hide-details dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <div v-if="typeof orgRecList[0]?.bnftOfrRecHisPk != 'undefined'">
                <v-row class="tableBB" v-if="orgRecList[0]?.wrtrMmbrPk != $store.getters.getUserPk" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <v-checkbox
                            v-model="actAgr" :ripple="false"
                            class="cmmCkBoxSize-20 ma-0" style="" true-value="1" false-value="2"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="fontDotEight font-weight-medium" style="color: rgba(0, 0, 0, 0.54);">
                            신체활동 작성자를 {{ this.$store.getters.getUserNm }}(으)로 변경.
                        </span>
                    </v-col>
                </v-row>
            </div>
            <div v-if="typeof orgRecList[1]?.bnftOfrRecHisPk != 'undefined'">
                <v-row class="tableBB" v-if="orgRecList[1]?.wrtrMmbrPk != $store.getters.getUserPk" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <v-checkbox
                            v-model="cgnAgr" :ripple="false"
                            class="cmmCkBoxSize-20 ma-0" style="" true-value="1" false-value="2"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                        <span class="fontDotEight font-weight-medium" style="color: rgba(0, 0, 0, 0.54);">
                            인지관리 작성자를 {{ this.$store.getters.getUserNm }}(으)로 변경.
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row class="pr-2 mt-4" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
                :class="[saveBtn ? '' : 'blue001 white--text']"
                @click="preBnftOfrRecDayList" :disabled="saveBtn"
                outlined rounded small>
                <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                <span class="mx-1" style="padding-bottom: 1px;">저장</span>
            </v-btn>
            <v-spacer></v-spacer>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmNmrcField from '../../cmm/CmmNmrcField';

export default {
    name: 'CareBlnftSrvRcrdMdl',

    components: {
        CmmNmrcField,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getBnftOfrRecDayInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getBnftOfrRecDayInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }
        },
    },
        
    methods: {
        //일일 급여제공기록 조회
        getBnftOfrRecDayInfo: function (pk, ymd){
            http.post(
                    'empApp/authOfrRecDayInfo', 
                    { bnMmbrPk: pk, clsfc1Cd: '301', clsfc2Cd: '1', clsfc3CdS: ['1','2','3'], inqYMD: ymd }
                )
                .then((response) => ( this.bnftOfrRecDayInfoRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authOfrRecDayInfo : ' + error))
        },
        //일일 급여제공기록 저장
        setBnftOfrRecDayList: function (arr){
            http.post( 'empApp/authInsBnftOfrRec', { bnMmbrPk: this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk, list: arr } )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsBnftOfrRec : ' + error))
        },
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
                this.getBnftOfrRecDayInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getMobAllBnftOfrSttList(this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD'))
                this.actAgr = '2'
                this.cgnAgr = '2'
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        bnftOfrRecDayInfoRst: function (res){
            this.orgRecList.splice(0)
    
            if(res.statusCode == '200'){
                //좌측 메뉴 텍스트 작성
                if(res.result.plnList.length > 0){
                    res.result.plnList.forEach(pln => {
                        let chc = this.locRecDayList.findIndex(
                            v => v.clsfc1Cd == pln.clsfc1Cd && v.clsfc2Cd == pln.clsfc2Cd && v.clsfc3Cd == pln.clsfc3Cd
                        )
                        if(chc > -1){
                            this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                                if(pln.itmVal1 == cd.valcd && pln.itmVal2 != null)
                                    this.locRecDayList[chc].plnNm = '<div>' + cd.valcdnm + pln.itmVal2 + '회</div><div>/ ' + pln.itmVal3 + '분</div>'
                                else if(pln.itmVal1 == cd.valcd && pln.itmVal2 == null)
                                    this.locRecDayList[chc].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + pln.itmVal3 + '분</div>'
                            });
                        }
                    });
                }

                //이전자료 버튼 활성화
                if(res.result.recLen > 0)
                    this.prvsBtn = true
                else
                    this.prvsBtn = false

                //value 값 적용 (0: 신체활동, 1: 인지정서, 2: 기능회복 )
                //bnftOfrRecHisPk, bnftOfrRec301Pk
                this.orgRecList.push(res.result.recInfo1)
                this.orgRecList.push(res.result.recInfo2)
                this.orgRecList.push(res.result.recInfo3)
             
                //전일자료 로딩시에는 PK값을 초기화
                if(this.saveMode == 'prvs'){
                    this.orgRecList.forEach(arr => {
                        arr.bnftOfrRecHisPk = null
                        arr.bnftOfrRec301Pk = 0
                    });
                }
                
                this.locRecDayList.forEach(ll => {
                    //console.log(typeof this.orgRecList[0].itm11ValAr)
                    switch (ll.clsfc3Cdnm) {
                        case '옷갈아입기':
                            if(typeof this.orgRecList[0].itm1Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm1Val)
                            break;
                        case '세면하기':
                            if(typeof this.orgRecList[0].itm2Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm2Val)
                            break;
                        case '양치하기':
                            if(typeof this.orgRecList[0].itm3Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm3Val)
                            break;
                        case '머리감기':
                            if(typeof this.orgRecList[0].itm5Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm5Val)
                            break;
                        case '몸단장':
                            if(typeof this.orgRecList[0].itm6Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm6Val)
                            break;
                        case '이동도움':
                            if(typeof this.orgRecList[0].itm10Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[0].itm10Val)
                            break;
                        case '산책(외출)':
                            if(typeof this.orgRecList[0].itm11ValAr != 'undefined'){
                                if(this.orgRecList[0].itm11ValAr?.findIndex( v => v == '1' ) > -1)
                                    ll.itmAr1 = true
                                if(this.orgRecList[0].itm11ValAr?.findIndex( v => v == '2' ) > -1)
                                    ll.itmAr2 = true
                            }
                            break;
                        case '신체활동 특이사항':
                            if(typeof this.orgRecList[0].spclNote == 'undefined')
                                ll.itmVal = ''
                            else
                                ll.itmVal = this.orgRecList[0].spclNote
                            break;
                        case '인지관리':
                            if(typeof this.orgRecList[1].itm1Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[1].itm1Val)
                            break;
                        case '의사소통,':
                            if(typeof this.orgRecList[1].itm2Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[1].itm2Val)
                            break;
                        case '인지 정서 특이사항':
                            if(typeof this.orgRecList[1].spclNote == 'undefined')
                                ll.itmVal = ''
                            else
                                ll.itmVal = this.orgRecList[1].spclNote
                            break;
                        case '신체기능의':
                            if(typeof this.orgRecList[2].itm1Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[2].itm1Val)
                            break;
                        case '기본동작':
                            if(typeof this.orgRecList[2].itm2Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[2].itm2Val)
                            break;
                        case '일상생활동작':
                            if(typeof this.orgRecList[2].itm3Val == 'undefined')
                                ll.itmVal = 0
                            else
                                ll.itmVal = Number(this.orgRecList[2].itm3Val)
                            break;
                        default:
                            break;
                    }
                });
            }

            this.saveBtn = false

            //전일자료 세팅이 종료됬으면 초기화
            if(this.saveMode == 'prvs')
                this.saveMode = ''
        },
        preBnftOfrRecDayList: function () {
            this.saveBtn = true
            let obj = Object.assign([], this.orgRecList)

            //데이터 검증
            if(obj.length == 3){
                //신체활동 최초 저장시 기본정보 등록
                obj[0].clsfc1Cd = '301'
                obj[0].clsfc2Cd = '1'
                obj[0].clsfc3Cd = '1'
                obj[0].bnftOfrRecHisPk = null

                //신체활동 변경값 적용
                if(typeof obj[0].bnftOfrRec301Pk == 'undefined'){
                    obj[0].wrtr = this.$store.getters.getUserNm 
                    obj[0].wrtrMmbrPk = this.$store.getters.getUserPk 
                }
                else{
                    if(this.actAgr == '1'){
                        obj[0].wrtr = this.$store.getters.getUserNm 
                        obj[0].wrtrMmbrPk = this.$store.getters.getUserPk 
                    }
                }
                obj[0].recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                obj[0].itm1Val = this.locRecDayList[0].itmVal
                obj[0].itm2Val = this.locRecDayList[1].itmVal
                obj[0].itm3Val = this.locRecDayList[2].itmVal
                obj[0].itm5Val = this.locRecDayList[3].itmVal
                obj[0].itm6Val = this.locRecDayList[4].itmVal
                obj[0].itm10Val = this.locRecDayList[5].itmVal
                obj[0].itm11ValAr = []
                if(this.locRecDayList[6].itmAr1 == true)
                    obj[0].itm11ValAr.push('1')
                if(this.locRecDayList[6].itmAr2 == true)
                    obj[0].itm11ValAr.push('2')
                obj[0].spclNote = this.locRecDayList[7].itmVal

                //인지정서 최초 저장시 기본정보 등록
                obj[1].clsfc1Cd = '301'
                obj[1].clsfc2Cd = '1'
                obj[1].clsfc3Cd = '2'
                obj[1].bnftOfrRecHisPk = null

                //인지정서 변경값 적용
                if(typeof obj[1].bnftOfrRec301Pk == 'undefined'){
                    obj[1].wrtr = this.$store.getters.getUserNm 
                    obj[1].wrtrMmbrPk = this.$store.getters.getUserPk 
                }
                else{
                    if(this.cgnAgr == '1'){
                        obj[1].wrtr = this.$store.getters.getUserNm 
                        obj[1].wrtrMmbrPk = this.$store.getters.getUserPk 
                    }
                }
                obj[1].recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                obj[1].itm1Val = this.locRecDayList[8].itmVal
                obj[1].itm2Val = this.locRecDayList[9].itmVal
                obj[1].spclNote = this.locRecDayList[10].itmVal

                //기능회복 최초 저장시 기본정보 등록
                obj[2].clsfc1Cd = '301'
                obj[2].clsfc2Cd = '1'
                obj[2].clsfc3Cd = '3'
                obj[2].bnftOfrRecHisPk = null

                //기능회복 변경값 적용
                if(typeof obj[2].bnftOfrRec301Pk == 'undefined'){
                    obj[2].wrtr = this.$store.getters.getUserNm 
                    obj[2].wrtrMmbrPk = this.$store.getters.getUserPk 
                }
                else{
                    // actAgr: '2',
                    if(this.cgnAgr == '1'){
                        obj[2].wrtr = this.$store.getters.getUserNm 
                        obj[2].wrtrMmbrPk = this.$store.getters.getUserPk 
                    }
                }
                obj[2].recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                obj[2].itm1Val = this.locRecDayList[11].itmVal
                obj[2].itm2Val = this.locRecDayList[12].itmVal
                obj[2].itm3Val = this.locRecDayList[13].itmVal

            }

            // console.log(obj)
            this.setBnftOfrRecDayList(obj)
        },
        prvsDataLoad: function () {
            this.getBnftOfrRecDayInfo(
                this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).subtract(1, 'days').format('YYYYMMDD')
            )
            this.saveMode = 'prvs'
        },
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },
    },

    data: () => ({
        orgRecList: [],
        prvsBtn: true,      //전일자료 버튼 활성여부
        saveBtn: true,      //저장 버튼 활성여부
        saveMode: '',
        actAgr: '2',
        cgnAgr: '2',
        //bnftOfrRecHisPk bnftOfrRec301Pk clsfc1Cd clsfc2Cd clsfc3Cd itmCd spclNote recDt wrtr wrtrMmbrPk
        locRecDayList: [
            //신체활동지원 ( 301, 1, 1 ) ( val1, val2, val3, val4, val5, val10, val11 )
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '1', clsfc3Cdnm: '옷갈아입기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '2', clsfc3Cdnm: '세면하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '3', clsfc3Cdnm: '양치하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '5', clsfc3Cdnm: '머리감기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '6', clsfc3Cdnm: '몸단장', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '10', clsfc3Cdnm: '이동도움', txtSub:'신체 기능유지, 증진', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '11', clsfc3Cdnm: '산책(외출)', txtSub:'동행', plnNm: '', itmAr1: false, itmAr2: false, },
            { clsfc1Cd: '201', clsfc2Cd: '1', clsfc3Cd: '99', clsfc3Cdnm: '신체활동 특이사항', txtSub:'(50자초과시 별지첨부)', plnNm: '', itmVal: '' },
            //인지정서지원 ( 301, 1, 2 ) ( val1, val2 )
            { clsfc1Cd: '201', clsfc2Cd: '3', clsfc3Cd: '1', clsfc3Cdnm: '인지관리', txtSub:'지원', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '4', clsfc3Cd: '1', clsfc3Cdnm: '의사소통,', txtSub:'정서', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '5', clsfc3Cd: '99', clsfc3Cdnm: '인지 정서 특이사항', txtSub:'(50자초과시 별지첨부)', plnNm: '', itmVal: '' },
            //기능회복훈련 ( 301, 1, 3 ) ( val1, val2, val3 )
            { clsfc1Cd: '201', clsfc2Cd: '7', clsfc3Cd: '2', clsfc3Cdnm: '신체기능의', txtSub:'훈련', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '7', clsfc3Cd: '3', clsfc3Cdnm: '기본동작', txtSub:'훈련', plnNm: '', itmVal: 0, },
            { clsfc1Cd: '201', clsfc2Cd: '7', clsfc3Cd: '4', clsfc3Cdnm: '일상생활동작', txtSub:'훈련', plnNm: '', itmVal: 0, },
        ],
    }),
};
</script>