<template>
    <v-card
        class="py-4 px-5"
        width="100%" rounded="xl" elevation="3">
        <v-row class="pb-3 align-center" no-gutters>
            <CmmDrawIcon type="ic_time" size="20"></CmmDrawIcon>
            <span class="fontDotEight font-weight-medium blue105--text ml-1" style="line-height: 0.1">
                Today
            </span>
            <span class="fontDotEight font-weight-medium ml-2" style="line-height: 0.1">건강정보</span>
            <v-spacer></v-spacer>
            <v-btn @click="rtrAct('HealthVtlApp')" x-small text>
                <span>더보기</span>
            </v-btn>
        </v-row>
        <v-row class="pa-0" v-if="Object.keys(this.vtlRecInf).length > 0" no-gutters>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotEight font-weight-medium">혈압</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotEight font-weight-medium">맥박</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotEight font-weight-medium">체온</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotEight font-weight-medium">혈당</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-0" v-if="Object.keys(this.vtlRecInf).length > 0" no-gutters>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 tblInitBT"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotSeven font-weight-medium">{{ vtlRecInf.bldPrsH }}/</span>
                    <span class="fontDotSeven font-weight-medium">{{ vtlRecInf.bldPrsL }}</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 tblInitBT"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotSeven font-weight-medium">{{ vtlRecInf.pulse }}</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 tblInitBT"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotSeven font-weight-medium">{{ vtlRecInf.tmprt }}</span>
                </div>
            </v-col>
            <v-col style="padding: 0 1px;" cols="3">
                <div
                    class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 tblInitBT"
                    style="line-height: 1.6; width: 100%; heigth: 100%">
                    <span class="fontDotSeven font-weight-medium">{{ vtlRecInf.bldSgr }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-0" v-else no-gutters>
            <v-col class="txtAlgnMid" cols="12">
                <span class="greyE06--text fontDotEight">측정된 건강정보가 없습니다.</span>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';

export default {
    name: 'VtlRecCard',

    components: {
        CmmDrawIcon,
    },

    props: {
        vtlRecInf: { type: Object, default: new Object() },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
          
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        rtrAct: function (nm){
            if(this.$route.name != nm)
                this.$router.push({ name: nm })
        },
    },

    data: () => ({

    }),
};
</script>