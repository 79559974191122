<template>
    <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-bold fontOneToTwo">기타비용&nbsp;상세내역</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-row class="pa-0 greyE01" no-gutters>
            <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                <span class="fontDotNine font-weight-medium">제공일</span>
            </v-col>
            <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                <span class="fontDotNine font-weight-medium">비용항목</span>
            </v-col>
            <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                <span class="fontDotNine font-weight-medium">금액</span>
            </v-col>
            <v-col class="py-1 txtAlgnMid" cols="3">
                <span class="fontDotNine font-weight-medium">내용</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 250px;">
            <v-row
                class="pa-0 tableBB"
                v-for="(recList, i) in msclnFeeDtl" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center overflow-text-hidden tableBR py-1" cols="3">
                    <span class="fontDotSeven">{{ recList.bnftOfrDt }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="3">
                    <span class="fontDotSeven">{{ recList.costItm }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid tableBR pa-1" cols="3">
                    <span class="fontDotSeven">{{ Number(recList.amt)?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="overflow-text-hidden txtAlgnMid pa-1" cols="3">
                    <span class="fontDotSeven">{{ recList.dtlCntnt }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0" v-if="msclnFeeDtl.length == 0" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="12">
                    <span class="fontDotEight">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <v-divider></v-divider>
        <v-row class="pa-0 greyE01" no-gutters>
            <v-col class="py-1 txtAlgnMid tableBR" cols="6">
                <span class="fontDotNine font-weight-medium">합계</span>
            </v-col>
            <v-col class="py-1 txtAlgnMid tableBR" cols="3">
                <span class="fontDotEight">{{ Number(msclnFeeSum)?.toLocaleString('ko-KR') }}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'MsclnExpsMdl',

    components: {

    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
        bnfcrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {


            if(this.aplyYyMm != '' && this.bnMmbrPk == 0)
                this.getMsclnFeeDtl(this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM'))
            else if(this.bnMmbrPk != 0)
                this.getOutMsclnFeeDtl(this.fcltyNum, this.bnMmbrPk, this.bnfcrPk, this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYYMM'))

        })
    },

    computed: {

    },

    watch:{
        'bnMmbrPk': function (){
            if(this.bnMmbrPk != 0)
                this.getMsclnFeeDtl(this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM'))
        },
    },
        
    methods: {
        //기타급여 조회 TOKEN
        getMsclnFeeDtl: function (yymm){
            http.post('fmly/selMsclnFeeDtlList', { inqYYMM: yymm, })
                .then((response) => ( this.msclnFeeDtlRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selMsclnFeeDtlList : ' + error))
        },
        //기타급여 조회 Params
        getOutMsclnFeeDtl: function (code, bnmm, bnfcr, yymm){
            http.post('fmly/selMsclnFeeDtlList', { fcltyNum: code, bnMmbrPk: bnmm, bnfcrPk: bnfcr, clmYymm: yymm, })
                .then((response) => ( this.msclnFeeDtlRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selMsclnFeeDtlList : ' + error))
        },
        msclnFeeDtlRst: function (res){
            this.msclnFeeSum = 0

            if(res.statusCode == '200'){
                this.msclnFeeDtl = res.result

                this.msclnFeeDtl.forEach(msList => {
                    msList.bnftOfrDt = this.$moment(msList.bnftOfrDt, 'YYYYMMDD', true).format('YYYY.MM.DD')
                    this.msclnFeeSum += msList.amt
                });
            }

        },
        onClose: function () {
            this.$store.commit('setPyrlSrvcMdl', false)
            this.$store.commit('setPyrlSrvcCtgMdl', '')
            this.$store.commit('setPyrlSrvcCtgObj', {})
        },
    },

    data: () => ({
        msclnFeeSum: 0,
        msclnFeeDtl: [],
    }),
};
</script>