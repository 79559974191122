<template>
    <v-sheet
        class="ma-0 py-4 pl-4 pr-3" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-medium fontOneToTwo">알림 내역</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-6 pr-1" no-gutters>
            <v-col cols="12">
                <v-row class="pa-0" v-for="(list, i) in mobESndList" :key="i" no-gutters>
                    <v-col cols="12">
                        <v-alert
                            class="pl-3 pr-1 py-2 mb-2" color="gray"
                            text outlined>
                            <div class="d-flex align-center">
                                <span class="d-inline-block overflow-text-hidden fontOne" style="width: 68%;">{{ list.msgTtl }}</span>
                                <v-spacer></v-spacer>
                                <span class="mr-1" v-for="(ar, j) in list.prcClcdAr" :key="j">
                                    <v-btn v-if="ar == '1'" height="24" @click="onNtcMdl(list)" icon>
                                        <v-icon>mdi-text-box-check-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="ar == '2'" height="24" icon>
                                        <v-icon>mdi-printer-outline</v-icon>
                                    </v-btn>
                                </span>
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div v-if="mobESndList.length == 0">
            <v-divider></v-divider>
            <v-row class="pr-1 py-5" no-gutters>
                <v-col style="text-align: center;" cols="12">
                    <span class="grey006--text">※ 조회된 알림 목록이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'EmpNtcMdl',

    props : {

    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            //this.getMobESndList()
        })
    },

    computed: {

    },

    watch:{

    },

    beforeDestroy: function () {

    },
        
    methods: { 
        //직원 알림내역 조회
        getMobESndList: function (){
            http.post('mobemp/selMobESndList', {})
                .then((response) => ( this.mobESndListRst(response.data) ))
                .catch((error) => console.log('axios error /mobemp/selMobESndList : ' + error))
        },
        mobESndListRst: function (res){
            this.mobESndList = res.result
        },
        onNtcMdl: function (obj){
            this.$store.commit('setSignAgrObj', obj)
            this.$store.commit('setSignAgrMdl', true)
        },
        onClose: function () {
            this.$store.commit('setSignAgrObj', {})
            this.$store.commit('setSignAgrMdl', false)
        },
    },
    
    data: () => ({
        mobESndList: [],
    }),
};
</script>