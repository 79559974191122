import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { en, ko } from 'vuetify/es5/locale';

Vue.use(Vuetify);

export default new Vuetify({
    // 언어 설정
    lang: {
        locales: { ko, en },
        current: 'ko',
    },
    //사용자 아이콘 설정
    icons: {
        iconfont: 'mdi',
    },
    //테마 설정
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                brown001: '#bcb582',
                black001: '#1A1A1A',
                black002: '#121212',
                blue001: '#2a9bed',
                blue002: '#3688c1',
                blue003: '#82b1ff',
                blue004: '#3C9099',
                blue005: '#3c78bc',
                blue006: '#e2f3fa',
                blue007: '#c5d9f1',
                blue008: '2196f3',
                blue009: 'a6daff',
                blue101: '#005593',
                blue102: '#004584',
                blue103: '#1f497d',
                blue104: '#1B81DF',
                blue105: '#4845ED',
                blue106: '#706DFF',
                blue107: '#393896',
                grey005: '#eaedf2',
                grey006: '#666',
                grey007: '#424242',
                grey008: '#939393',
                greyE00: '#dbdbdb',
                greyE01: '#ededed',
                greyE02: '#eee',
                greyE03: '#EFEFEF',
                greyE04: '#F5F5F5',
                greyE05: '#CCC',
                greyE06: '#8E8E8E',
                greyE07: '#F2F2F2',
                greyE08: '#d9d9d9',
                greyE09: '#E6E6E6',
                green001: '#dbffca',
                green002: '#00ACC1',
                orange001: '#f7ae22',
                orange002: '#ef7c00',
                white001: 'F7F2E1',
                yellow001: '#fffee0',
                yellow002: '#fffc38',
                red001: '#ee0000',
            },
            dark: {
                primaryText: '#9e9e9e',
                secondaryText: '#e75546',
                thirdText: '#fff',
                fourthText: '#4b82f2',
                subText: '#606e79',
                mainText: '#9c9996',
                menuDiv: '#4f4f4f',
                backgroundColor: '#121212',
            },
        },
    },
});
