<template>
    <v-sheet class="ma-0" style="position: relative;" rounded="lg">
        <div
            class="d-inline-block white px-5 py-3"
            style="width: 100%; height: 64px; position: sticky; top: 0px; left: 0px; z-index: 6;">
            <v-row class="pa-0 mb-1" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <CmmDrawIcon style="margin-top: 1px;" type="ic_pdf" size="26"></CmmDrawIcon>
                    <span class="font-weight-bold fontOneToOne ml-2">{{ $store.getters.getCmmPdfTtl }}</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose()"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="28">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>
        </div>
        <div
            id="svg-print"
            class="d-flex justify-center overflow-x-hidden overflow-y-auto mb-5"
            style="height: 430px;"
            v-touch="{ left: () => isNext(), right: () => isPrev() }">
            <span id="the-svg" ref="parentSvg"></span>
        </div>
        <div
            class="d-inline-block white px-5"
            style="width: 100%; height: 46px; position: sticky; bottom: 0px; left: 0px; z-index: 6;">
            <v-divider class="mb-1"></v-divider>
            <div class="d-flex align-center">
                <v-btn @click="isPrev()" small icon>
                    <CmmDrawIcon style="margin-top: 1px;" type="ic_pdf_arrow_prev" size="20"></CmmDrawIcon>
                </v-btn>
                <span class="fontDotEight ml-2">{{ page }}</span>
                <span class="fontDotSeven mx-2">&#47;</span>
                <span class="fontDotEight mr-2">{{ totalCnt }}</span>
                <v-btn @click="isNext()" small icon>
                    <CmmDrawIcon style="margin-top: 1px;" type="ic_pdf_arrow_nxt" size="20"></CmmDrawIcon> 
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="pdfTmpDwld" small icon>
                    <CmmDrawIcon style="margin-top: 1px;" type="ic_download" size="18"></CmmDrawIcon>
                </v-btn>
            </div>
        </div>
        <span
            class="d-inline-block px-3"
            style="width: 100%; height: 430px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
            v-if="swipeBtn">
            <v-row
                class="rounded justify-center align-center"
                style="height: 100%; background-color: rgba(239, 239, 239, 0.8); z-index: 2;"
                no-gutters>
                <CmmDrawIcon type="gstr_swipe_prev" size="50"></CmmDrawIcon>
                <span class="d-inline-block" style="width: 10px;"></span>
                <CmmDrawIcon type="gstr_swipe_nxt" size="50"></CmmDrawIcon>
            </v-row>
        </span>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from './CmmDrawIcon.vue';

import { http, remoteURL } from '@/assets/js/baseAxios';
import { fileDownApp } from '@/assets/js/ntvBridge';

import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "pdfjs-dist/web/pdf_viewer.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default {
    name: 'PdfCore',

    props : {
     
    },
            
    components: {
        CmmDrawIcon,
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.reflPdfScale()
        })
    },

    computed: {
        getPrtMdl: function () {
            return this.$store.getters.getCmmPrtMdl
        }
    },

    watch:{
        'url': function () {
            if(this.url != ''){
                this.clear()
                this.loadingTask = pdfjsLib.getDocument( remoteURL + this.url, this.pdfOptions)
                setTimeout(() => {
                    this.swipeBtn = false
                }, 1200)
                this.getPdfForSvg()
            }
        },
        getPrtMdl: function (v) {
            if(v){
                let path = ''

                if(this.$store.getters.getCmmPdfDstnt == 'imgView'){
                    path = 'fmly/imgView?'
                    path += 'prtFileNm=' + this.$store.getters.getCmmPdfNm
                    path += '&prtFilePth=' + this.$store.getters.getCmmPdfPth
                    path += '&t=' + Math.random()
                }
                else if(this.$store.getters.getCmmPdfDstnt == 'imgPrview'){
                    path = 'fmly/imgPrview?'
                    path += 'fileNm=' + this.$store.getters.getCmmPdfNm
                    path += '&filePth=' + this.$store.getters.getCmmPdfPth
                    path += '&fileKndCd=2'
                    path += '&t=' + Math.random()
                }

                this.url = path
            }
        },
    },

    beforeDestroy: function () {
        this.loadingTask?.destroy()
    },
        
    methods: { 
        // pdf 다운로드
        pdfTmpDwld: function (){
            let obj = {}
            obj.fileNm = this.$store.getters.getCmmPdfNm
            obj.filePth = this.$store.getters.getCmmPdfPth
            obj.orgFileNm = this.$store.getters.getCmmPdfOrgNm?.replaceAll(' ', '_')
            if(this.$store.getters.getCmmPdfDstnt == 'imgView')
                obj.atchdFilePk = '1'

            http({
                method: 'post',
                url: 'cmm/fileDownload',
                responseType: 'arraybuffer',
                data: obj
            })
                .then((response) => ( this.thrwFile(response) ))
                .catch((error) => console.log('axios error /cmm/fileDownload : ' + error))
        },
        thrwFile: function (res){
            let contentDisposition = decodeURI(res.headers['content-disposition'])
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()

            fileDownApp(this.$store.getters.getCmmPdfDstnt, this.$store.getters.getCmmPdfNm, this.$store.getters.getCmmPdfPth)
        },
        getPdfForCanvas: async function () {
            let res = ''
                
            this.loadingTask.promise.then(function(pdf) {
                console.log('PDF loaded');
                
                // Fetch the first page
                let pageNumber = 1;
                
                pdf.getPage(pageNumber).then(function(page) {
                    console.log('Page loaded');
                    
                    var scale = 3
                    //var outputScale = window.devicePixelRatio
                    var viewport = page.getViewport({scale: scale})

                    // Prepare canvas using PDF page dimensions
                    var canvas = document.createElement("canvas")
                    var context = canvas.getContext('2d')
             
                    canvas.height = viewport.height
                    canvas.width = viewport.width
       
                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                      
                        viewport: viewport
                    };

                    var renderTask = page.render(renderContext)

                    renderTask.promise.then(function () {
                        console.log('Page rendered')
                    });

                    canvas.toBlob((blob) => {
                        res = URL.createObjectURL(blob)
                        console.log(res)
                    })

                });
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });

            return res
        },
        getPdfForSvg: async function() {
            let me = this
                
            this.loadingTask.promise.then(function(pdf) {
                //console.log('PDF loaded')
                
                // Fetch the first page
                me.totalCnt = pdf.numPages
               
                pdf.getPage(Number(me.page)).then(function(page) {
                    // Set scale (zoom) level
                    // Get viewport (dimensions)
                    var viewport = page.getViewport({scale: me.pdfScale})
                    // Get div#the-svg
                    var container = document.getElementById('the-svg')
           
                    // Set dimensions
                    container.style.width = viewport.width + 'px'
                    container.style.height = viewport.height + 'px'

                    // SVG rendering by PDF.js
                    page.getOperatorList()
                        .then(function (opList) {
                            var svgGfx = new pdfjsLib.SVGGraphics(page.commonObjs, page.objs);
                           
                            return svgGfx.getSVG(opList, viewport);
                        })
                        .then(function (svg) {
                            container.appendChild(svg);
                        });
                });
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });
        },

        getPdfAllPagesForSvg: async function() {
            let me = this
                
            await this.loadingTask.promise.then(function(pdf) {
                console.log('PDF loaded')
                
                // Fetch the first page
                me.totalCnt = pdf.numPages
                for (let index = 1; index <= pdf.numPages; index++) {
                    pdf.getPage(index).then(function(page) {
                        // Set scale (zoom) level
                        // Get viewport (dimensions)
                        var viewport = page.getViewport({scale: me.pdfScale})
                        // Get div#the-svg
                        var container = document.getElementById('the-svg')
            
                        // Set dimensions
                        container.style.width = viewport.width + 'px'
                        container.style.height = viewport.height + 'px'

                        // SVG rendering by PDF.js
                        page.getOperatorList()
                            .then(function (opList) {
                                var svgGfx = new pdfjsLib.SVGGraphics(page.commonObjs, page.objs);
                            
                                return svgGfx.getSVG(opList, viewport);
                            })
                            .then(function (svg) {
                                container.appendChild(svg);
                                me.chkSum += 1
                            });
                    });
                }
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });
        },

        isPrev: function () {
            if(this.page > 1){
                this.page -= 1
                this.$refs.parentSvg.firstChild?.remove()
                this.$refs.parentSvg.style = ''
                this.getPdfForSvg()
            }
        },

        isNext: function () {
            if(this.page < this.totalCnt){
                this.page += 1
                this.$refs.parentSvg.firstChild?.remove()
                this.$refs.parentSvg.style = ''
                this.getPdfForSvg()
            }
        },

        reRenderSvg: function () {
            this.$refs.parentSvg.firstChild.remove()
            this.$refs.parentSvg.style = ''
            this.getPdfForSvg()
        },

        reflPdfScale: function () {
            if(this.$vuetify.breakpoint.width < 350)
                this.pdfScale = 0.4
            else if(this.$vuetify.breakpoint.width < 381 && this.$vuetify.breakpoint.width > 349)
                this.pdfScale = 0.5
            else if(this.$vuetify.breakpoint.width < 431 && this.$vuetify.breakpoint.width > 379)
                this.pdfScale = 0.6
            else if(this.$vuetify.breakpoint.width < 491 && this.$vuetify.breakpoint.width > 429)
                this.pdfScale = 0.7
            else if(this.$vuetify.breakpoint.width < 541 && this.$vuetify.breakpoint.width > 489)
                this.pdfScale = 0.8
            else if(this.$vuetify.breakpoint.width < 601 && this.$vuetify.breakpoint.width > 539)
                this.pdfScale = 0.9
            else
                this.pdfScale = 1
        },

        clear: function () {
            var list = document.getElementById("the-svg");

            while (list.hasChildNodes()) {
                list.removeChild(list.firstChild)
            }

            this.$refs.parentSvg.style = ''
            this.chkSum = 0
            this.page = 1

            this.reflPdfScale()

            this.totalCnt = 0
        },

        onClose: function () {
            this.$store.commit('setCmmPrtMdl', false)
            this.$store.commit('setCmmPdfPth', '')
            this.$store.commit('setCmmPdfNm', '')
            this.$store.commit('setCmmPdfDstnt', '')
            this.$store.commit('setCmmPdfTtl', '')
        },

        sleep: function(sec) {
            return new Promise(resolve => setTimeout(resolve, sec * 100))    
        },

    },
    
    data: () => ({
        page: 1,
        url: '',
        swipeBtn: true,
        chkSum: 0,
        pdfScale: 0.4,
        totalCnt: 0,
        loadingTask: null,
        zoomItems: [
            { valcdnm: '75%', valcd: 0.75 }, { valcdnm: '100%', valcd: 1 }, { valcdnm: '125%', valcd: 1.25 },
            { valcdnm: '150%', valcd: 1.5 }
        ],
        pdfOptions: { cMapPacked: true, cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.0.279/cmaps/', disableFontFace: true, showPreviousViewOnLoad: false, },
    }),
};
</script>