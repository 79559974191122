<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">식단표</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYMD :aplyMinDt="'2020-01-01'" :aplyMaxDt="$moment().format('YYYY-MM-DD')" @sltHypDt="getHypDt"></AppYMD>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 pb-4 mt-15" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="ic_food" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 0px;">식단표</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="10">
                        <v-divider class="black002"></v-divider>
                        <v-row class="pa-0" v-for="(list, i) in mealMenuList" :key="i" no-gutters>
                            <v-col class="txtAlgnMid" cols="12">
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center justify-center nmlBfClrImp-greyE07 tableBR" cols="2">
                                        <span class="fontDotEight font-weight-medium">조식</span>
                                    </v-col>
                                    <v-col class="" cols="10">
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 d-flex align-center justify-center tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">일반식</span>
                                            </v-col>
                                            <v-col class="py-1" style="line-height: 1;" cols="9">
                                                <span class="fontDotEight" v-html="list.gnrlBrkfs"></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">죽식</span>
                                            </v-col>
                                            <v-col class="py-1" cols="9">
                                                <span class="fontDotEight" v-html="list.papBrkfs"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center justify-center nmlBfClr-grey001 tableBR" cols="2">
                                        <span class="fontDotEight font-weight-medium">오전</span>
                                    </v-col>
                                    <v-col class="" cols="10">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClr-grey001 d-flex align-center justify-center tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">간식</span>
                                            </v-col>
                                            <v-col class="py-1" style="line-height: 1;" cols="9">
                                                <span class="fontDotEight" v-html="list.snckAm"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center justify-center nmlBfClrImp-greyE07 tableBR" cols="2">
                                        <span class="fontDotEight font-weight-medium">중식</span>
                                    </v-col>
                                    <v-col class="" cols="10">
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 d-flex align-center justify-center tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">일반식</span>
                                            </v-col>
                                            <v-col class="py-1" style="line-height: 1;" cols="9">
                                                <span class="fontDotEight" v-html="list.gnrlLunch"></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">죽식</span>
                                            </v-col>
                                            <v-col class="py-1" cols="9">
                                                <span class="fontDotEight" v-html="list.papLunch"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center justify-center nmlBfClr-grey001 tableBR" cols="2">
                                        <span class="fontDotEight font-weight-medium">오후</span>
                                    </v-col>
                                    <v-col class="" cols="10">
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClr-grey001 d-flex align-center justify-center tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">간식</span>
                                            </v-col>
                                            <v-col class="py-1" style="line-height: 1;" cols="9">
                                                <span class="fontDotEight" v-html="list.snckPm"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pa-0" no-gutters>
                                    <v-col class="d-flex align-center justify-center nmlBfClrImp-greyE07 tableBR" cols="2">
                                        <span class="fontDotEight font-weight-medium">석식</span>
                                    </v-col>
                                    <v-col class="" cols="10">
                                        <v-row class="pa-0 tableBB" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 d-flex align-center justify-center tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">일반식</span>
                                            </v-col>
                                            <v-col class="py-1" style="line-height: 1;" cols="9">
                                                <span class="fontDotEight" v-html="list.gnrlDiner"></span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0" no-gutters>
                                            <v-col class="nmlBfClrImp-greyE07 tableBR py-1" cols="3">
                                                <span class="fontDotEight font-weight-medium">죽식</span>
                                            </v-col>
                                            <v-col class="py-1" cols="9">
                                                <span class="fontDotEight" v-html="list.papDiner"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0" v-if="mealMenuList.length == 0" no-gutters>
                            <v-col class="txtAlgnMid py-2" cols="12">
                                <span class="fontDotEight">식단표가 존재하지 않습니다.</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pt-5 pb-4" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="10">
                        <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                            <span class="blue104--text fontDotEight font-weight-medium">원산지</span>
                        </span>
                        <v-divider class="black002 mt-4"></v-divider>
                        <div class="py-1 px-2" v-if="mealOrigin != ''" style="line-height: 1;">
                            <span class="grey006--text fontDotSeven">{{ mealOrigin }}</span>
                        </div>
                        <div v-else class="txtAlgnMid py-1 px-2">
                            <span class="grey006--text fontDotSeven">원산지 정보가 없습니다.</span>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYMD from '../../cmm/AppYMD.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'MealMenuApp',

    components: {
        CmmDrawIcon,
        AppYMD,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getMealMenuList(this.$moment().format('YYYYMMDD'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 식단표 조회 (일간)
        getMealMenuList: function (ymd){
            this.mealOrigin = ''
            this.mealMenuList.splice(0)

            http.post('fmlyApp/authMenuList', { inqYMD: ymd })
                .then((response) => ( this.mealMenuRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authMenuList : ' + error))
        },
        mealMenuRst: function (res){
            if(res.statusCode == '200'){
                if(res.menuList?.length > 0){
                    res.menuList.forEach(itm => {
                        if(this.mealOrigin == '')
                            this.mealOrigin = itm.orgn

                        itm.gnrlBrkfs = itm.gnrlBrkfs.replaceAll('\n', '<br>')
                        itm.gnrlLunch = itm.gnrlLunch.replaceAll('\n', '<br>')
                        itm.gnrlDiner = itm.gnrlDiner.replaceAll('\n', '<br>')
                    });
                    this.mealMenuList = res.menuList
                }
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != '')
                this.getMealMenuList(this.$moment(v, 'YYYY-MM-DD').format('YYYYMMDD'))
        },
    },

    data: () => ({
        viewHeight: 0,
        mealMenuList: [],
        mealOrigin: '',
    }),
};
</script>