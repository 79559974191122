<template>
    <v-sheet width="100%" class="px-7 pt-6 pb-7">
        <div class="d-flex justify-center align-center mb-10">
            <span 
                class="d-inline-block rounded-xl grey005"
                style="width: 30%; height: 7px;"
                @click="$store.commit('setCmmBtmMdl', false)">
            </span>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="12">
                <span class="grey006--text fontOneToFour font-weight-bold">직원 로그인</span>
            </v-col>
        </v-row>
        <v-form ref="empLgn" lazy-validation>
            <v-row class="mt-12 pa-0" no-gutters>
                <v-col cols="12">
                    <v-text-field
                        class="authTxtField" v-model="empInfo.fcltyNum"
                        hint="'-' 를 제외한 11자리 숫자만 입력해주세요." label="기관번호"
                        :rules="[rules.required, rules.fcltyConfirm, rules.numberCheck]"
                        counter outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-2 pa-0" no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="empInfo.id"
                        class="authTxtField" label="아이디" :rules="[rules.required]" outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-2 pa-0" no-gutters>
                <v-col cols="12">
                    <v-text-field
                        :class="['authTxtField', pswdShow==true ? 'authTxtFieldPwdIcon' : '' ]"
                        v-model="empInfo.passwd" :rules="[rules.required]"
                        :append-icon="pswdShow ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="pswdShow ? 'text' : 'password'"
                        pattern="\d*"
                        @click:append="pswdShow = !pswdShow"
                        @keyup.enter="preEmpLgn"
                        label="비밀번호" outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-12 mb-2 pa-0" no-gutters>
                <v-col class="" cols="12">
                    <v-btn class="py-5" width="100%" color="blue002" @click="preEmpLgn">
                        <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                        <span class="white--text ml-4" style="font-size: 1.3rem;">로그인</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'EmpLgn',

    components: {
       
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            if(typeof this.$route.query.fcltyCd != 'undefined'){
                this.empInfo.fcltyNum = this.$route.query.fcltyCd
                this.$cookies.set("fcltyCd", this.$route.query.fcltyCd)
            }
            else if(this.$cookies.get("fcltyCd") != null && this.$cookies.get("fcltyCd") != '')
                this.empInfo.fcltyNum = this.$cookies.get("fcltyCd")
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        //직원 로그인
        empLgn: function (obj){
            http.post('/empApp/login', obj)
                .then((response) => ( this.empLgnRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/login : ' + error))
        },

        empLgnRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                this.$cookies.set("fcltyCd", this.empInfo.fcltyNum)

                obj.clr = 'info'

                this.empInfo.id = ''
                this.empInfo.passwd = ''
                this.$refs.empLgn.resetValidation()
                this.$store.commit('setCmmBtmMdl', false)
                this.$store.commit('setToken', res.token)
                this.$store.commit('setSctKey', res.key)
                this.$store.commit('setUserNm', res.name)
                this.$store.commit('setUserPk', res.mmbrPk)
                this.$store.commit('setJobNm', res.jobNm)
                this.$store.commit('setFltyClnm', res.fcltyClnm)

                this.$router.push({ name: 'EmpMgmt' })
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        preEmpLgn: function (){
            let obj = {}
            obj.fcltyNum = this.empInfo.fcltyNum
            obj.id = this.empInfo.id
            obj.passwd = this.empInfo.passwd

            if(this.$refs.empLgn.validate()){
                this.empLgn(obj)
            }
            else{
                let objAl= {}
                objAl.clr = 'info'
                objAl.cntn = '필수 항목을 입력해주세요.'
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', objAl)
            }
        },
    },

    data: () => ({
        pswdShow: false,
        empInfo: { fcltyNum: '', id: '', passwd: '' },
        rules: {
            birthCheck: value => /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value) || '올바른 날짜 형식이 아닙니다.',
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            required: value => !!value || '필수 입력 항목입니다.',
        },
    }),
};
</script>