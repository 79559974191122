<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">새소식</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <v-row class="py-5 listBB" v-for="n in 10" :key="n" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="10">
                        <span class="fontDotNine font-weight-medium">새로운 소식은 이곳에서 확인하세요.</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';
// import { http } from '@/assets/js/baseAxios';

export default {
    name: 'NewNewsApp',

    components: {
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        prvRtr: function (){
            this.$router.go(-1)
        },
    },

    data: () => ({
        viewHeight: 0,
    }),
};
</script>