<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">목욕도움&nbsp;관찰기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="d-flex justify-center align-center greyE02 pa-2" cols="7">
                    <div class="">
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                            </span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.name }}
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col class="tableBL py-1" cols="5">
                    <div>
                        <div class="d-flex justify-center tableBB pb-1">
                            <v-btn
                                color="blue004" style="padding: 0 10px 2px 6px !important"
                                :disabled="prvsBtn" @click="getPrvsBathSrv()"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-text-box-search-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2">전일자료</span>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center pt-1">
                            <v-btn
                                color="green002" style="padding: 0 10px 2px 8px !important"
                                @click="onEmpMdl"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-account-circle-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2" style="padding-top: 1px;">{{ empInf.name }}</span>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 txtAlgnMid px-2 py-1" cols="4">
                    <span class="fontDotEight font-weight-bold">목욕시간</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="8">
                    <span class="d-inline-block" style="width: 52px;">
                        <CmmTmField v-model="bathInfo.actBathStrt" :required="true"></CmmTmField>
                    </span>
                    <span class="mx-2 pb-1">
                        <v-icon size="14">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 52px;">
                        <CmmTmField v-model="bathInfo.actBathEnd" :required="true"></CmmTmField>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 d-flex justify-center align-center px-2 py-1" cols="4">
                    <span class="fontDotEight font-weight-bold">특이사항</span>
                </v-col>
                <v-col class="px-2 py-1 tableBL" cols="8">
                    <v-textarea
                        v-model="bathSpclNote"
                        class="rmrksArea" rows="3"
                        no-resize outlined hide-details dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-divider class="mt-3 mr-2"></v-divider>
            <v-row class="greyE02 mr-2" no-gutters>
                <v-col class="d-flex justify-center align-center pa-1" cols="3">
                    <span class="fontDotEight font-weight-bold">항목</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL" cols="9">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center py-2" cols="3">
                            <span class="fontDotEight font-weight-bold">구분</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBL" cols="9">
                            <span class="fontDotEight font-weight-bold">내용</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <v-row class="mr-2" v-for="(frm, i) in cmmFrameList" :key="i" no-gutters>
            <v-col class="d-flex justify-center align-center tableBB pa-1" cols="3">
                <span class="fontDotEight">{{ frm.itmCdnm }}</span>
            </v-col>
            <v-col class="tableBB tableBL" cols="9">
                <v-row class="tableBB pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="fontDotEight">전</span>
                    </v-col>
                    <v-col class="px-1 tableBL" style="padding-bottom: 2px;" cols="9">
                        <div v-if="frm.valClcd == '1'">
                            <v-btn
                                v-for="(btn, j) in vCd1" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '16'">
                            <v-btn
                                v-for="(btn, j) in vCd16" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '17'">
                            <v-btn
                                v-for="(btn, j) in vCd17" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '18'">
                            <v-btn
                                v-for="(btn, j) in vCd18" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '19'">
                            <v-btn
                                v-for="(btn, j) in vCd19" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '20'">
                            <v-btn
                                v-for="(btn, j) in vCd20" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '21'">
                            <v-btn
                                v-for="(btn, j) in vCd21" :key="j"
                                :class="['mr-1', frm.itm1Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm1Val = (j+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center" cols="3">
                        <span class="fontDotEight">후</span>
                    </v-col>
                    <v-col class="px-1 tableBL" style="padding-bottom: 2px;" cols="9">
                        <div v-if="frm.valClcd == '1'">
                            <v-btn
                                v-for="(btn, k) in vCd1" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '16'">
                            <v-btn
                                v-for="(btn, k) in vCd16" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '17'">
                            <v-btn
                                v-for="(btn, k) in vCd17" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '18'">
                            <v-btn
                                v-for="(btn, k) in vCd18" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '19'">
                            <v-btn
                                v-for="(btn, k) in vCd19" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '20'">
                            <v-btn
                                v-for="(btn, k) in vCd20" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                        <div v-else-if="frm.valClcd == '21'">
                            <v-btn
                                v-for="(btn, k) in vCd21" :key="k"
                                :class="['mr-1', frm.itm2Val == btn.valcd ? 'orange001 white--text' : '']"
                                min-width="40" max-width="55" :ripple="false"
                                style="padding: 0px 2px 1px 2px; margin-bottom: 1px; margin-top: 3px;"
                                @click="frm.itm2Val = (k+1).toString()"
                                small outlined>
                                <span class="fontDotSeven">{{ btn.valcdnm }}</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div v-if="cmmFrameList.length == 0">
            <v-row class="pa-0 mx-2 tableBB" no-gutters>
                <v-col class="txtAlgnMid px-2 py-2" cols="12">
                    <span class="fontDotEight">조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <div class="stkMdlBottom py-4">
            <v-row class="pr-2" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveBtn ? '' : 'blue001 white--text']"
                    @click="preBnftOfrRecDay" :disabled="saveBtn"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmTmField from '../../cmm/CmmTmField.vue';

export default {
    name: 'BathSrvRcrdMdl',

    components: {
        CmmTmField,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getBathRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }

            this.empInf.name = this.$store.getters.getUserNm
            this.empInf.mmbrPk = this.$store.getters.getUserPk
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
        getEmpSlctMdl: function () {
            return this.$store.getters.getSlctEmpMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getBathRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }
        },
        getEmpSlctMdl: function (v) {
            if(v == false)
                this.empInf = this.$store.getters.getSlctEmpObj
        },
    },
        
    methods: {
        // 목욕관찰 조회
        getBathRcrdInfo: function (pk, ymd, mode){
            this.saveBtn = true
            this.prvsBtn = true
            
            if(mode == 'now'){
                this.cleanData()

                http.post( 'empApp/authSelBathRcrdInfo', { bnMmbrPk: pk, inqYMD: ymd } )
                    .then((response) => ( this.bathRcrdRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelBathRcrdInfo : ' + error))
            }
            else if(mode == 'prvs'){
                let prvsDay = this.$moment(ymd, 'YYYYMMDD', true).subtract(1, 'days').format('YYYYMMDD')

                http.post( 'empApp/authSelBathRcrdInfo', { bnMmbrPk: pk, inqYMD: prvsDay } )
                    .then((response) => ( this.prvsBathRcrdRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelBathRcrdInfo : ' + error))
            }

        },
        // 목욕관찰 저장
        setBathRcrdInfo: function (obj){
            http.post( 'empApp/authInsBathBnftOfrRec', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsBathBnftOfrRec : ' + error))
        },
        bathRcrdRst: function (res){
 
            if(res.statusCode == '200'){
                // 조회된 분류코드를 반영
                if(Object.keys(res.code).length > 0){
                    this.vCd1 = res.code.vCd1
                    this.vCd16 = res.code.vCd16
                    this.vCd17 = res.code.vCd17
                    this.vCd18 = res.code.vCd18
                    this.vCd19 = res.code.vCd19
                    this.vCd20 = res.code.vCd20
                    this.vCd21 = res.code.vCd21
                }
                
                // 목욕 기본 정보를 반영
                if(Object.keys(res.bathInfo).length > 0){
                    res.bathInfo.actBathStrt = res.bathInfo.actBathTmHhStrt + ':' + res.bathInfo.actBathTmMmStrt
                    res.bathInfo.actBathEnd = res.bathInfo.actBathTmHhEnd + ':' + res.bathInfo.actBathTmMmEnd

                    this.bathSchdlPk = res.bathInfo.bathSchdlPk
                    this.bathInfo = res.bathInfo
                }

                // 테이블 작성을 위한 코드 데이터를 정리
                if(res.cmmList?.length > 0){
                    let list = res.cmmList.filter( v => v.clsfc3Cd == '1' )
                    list.forEach(row => {
                        let obj = {}
                        obj.itmCd = row.itmCd
                        obj.itm1Val = ''
                        obj.itm2Val = ''
                        obj.itmCdnm = row.itmCdnm
                        obj.valClcd = row.valClcd
                        this.cmmFrameList.push(obj)
                    });
         
                }

                // 기존 저장된 데이터가 있으면 값을 반영
                if(res.recList?.length > 0){
                    let list1 = res.recList.filter( v => v.clsfc3Cd == '1' )[0]
                    let list2 = res.recList.filter( v => v.clsfc3Cd == '2' )[0]

                    this.cmmFrameList.forEach(itm => {
                        itm.itm1Val = list1['itm'+itm.itmCd+'Val']
                        itm.itm2Val = list2['itm'+itm.itmCd+'Val']
                       
                        if(this.bathSpclNote == '')
                            this.bathSpclNote = list1.spclNote
                        if(this.bnftOfrRecHisPk[0] == 0)
                            this.bnftOfrRecHisPk.splice(0, 1, list1.bnftOfrRecHisPk)
                        if(this.bnftOfrRecHisPk[1] == 0)
                            this.bnftOfrRecHisPk.splice(1, 1, list2.bnftOfrRecHisPk)
                        if(this.bnftOfrRec301Pk[0] == 0)
                            this.bnftOfrRec301Pk.splice(0, 1, list1.bnftOfrRec301Pk)
                        if(this.bnftOfrRec301Pk[1] == 0)
                            this.bnftOfrRec301Pk.splice(1, 1, list2.bnftOfrRec301Pk)

                    });

                }
                else if(res.recList?.length == 0){
                    this.prvsBtn = false

                    // 기본값 세팅
                    this.cmmFrameList.forEach(itm => {
                        switch (itm.valClcd) {
                            case '1':
                            case '19':
                                itm.itm1Val = '2'
                                itm.itm2Val = '2'
                                break;
                            case '17':
                                itm.itm1Val = '4'
                                itm.itm2Val = '4'
                                break;
                            case '21':
                                itm.itm1Val = '3'
                                itm.itm2Val = '3'
                                break;
                            default:
                                itm.itm1Val = '1'
                                itm.itm2Val = '1'
                                break;
                        }
                    });

                }

                this.saveBtn = false
            }
        },
        prvsBathRcrdRst: function (res){
            if(res.statusCode == '200'){
                if(res.recList?.length == 0){
                    this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '조회된 전일자료가 없습니다.' })
                    this.$store.commit('setCmmAlertMdl', true)
                }
                else{
                    let list1 = res.recList.filter( v => v.clsfc3Cd == '1' )[0]
                    let list2 = res.recList.filter( v => v.clsfc3Cd == '2' )[0]

                    this.cmmFrameList.forEach(itm => {
                        itm.itm1Val = list1['itm'+itm.itmCd+'Val']
                        itm.itm2Val = list2['itm'+itm.itmCd+'Val']
                       
                        if(this.bathSpclNote == '')
                            this.bathSpclNote = list1.spclNote

                    });
                }

                this.saveBtn = false
                
            }
        },
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getMobAllBnftBthSttList(
                    this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )

                this.getBathRcrdInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )

            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        preBnftOfrRecDay: function () {
            this.saveBtn = true
            this.prvsBtn = true
   
            // 공통 데이터 작성
            let obj = {}
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.userNm = this.empInf.name
            obj.bathTmInfo = Object.assign({}, this.bathInfo)
            obj.list = []

            // info 데이터 작성
            obj.bathTmInfo.bathSchdlPk = this.bathSchdlPk
            obj.bathTmInfo.actBathDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.bathTmInfo.bathMthdcd = this.cmmFrameList?.filter( v => v.itmCd == '9' )[0].itm1Val
            obj.bathTmInfo.wrtrMmbrPk = this.empInf.mmbrPk
            obj.bathTmInfo.wrtr = this.empInf.name

            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(obj.bathTmInfo.actBathStrt)){
                obj.bathTmInfo.actBathTmHhStrt = obj.bathTmInfo.actBathStrt.split(':', 2)[0]
                obj.bathTmInfo.actBathTmMmStrt = obj.bathTmInfo.actBathStrt.split(':', 2)[1]
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '목욕 시간을 정확히 입력해주세요.' })
                this.saveBtn = false
                return
            }

            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(obj.bathTmInfo.actBathEnd)){
                obj.bathTmInfo.actBathTmHhEnd = obj.bathTmInfo.actBathEnd.split(':', 2)[0]
                obj.bathTmInfo.actBathTmMmEnd = obj.bathTmInfo.actBathEnd.split(':', 2)[1]
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '목욕 시간을 정확히 입력해주세요.' })
                this.saveBtn = false
                return
            }
   
            // rec 공통 데이터 작성
            let cmmRecInf = {}
            // cmmRecInf.bnftOfrRecHisPk = 0
            cmmRecInf.bnftOfrRecHisPk = null
            cmmRecInf.bnftOfrRec301Pk = 0
            cmmRecInf.recDt = obj.bathTmInfo.actBathDt
            cmmRecInf.ofrTmHhStrt = obj.bathTmInfo.actBathTmHhStrt
            cmmRecInf.ofrTmMmStrt = obj.bathTmInfo.actBathTmMmStrt
            cmmRecInf.ofrTmHhEnd = obj.bathTmInfo.actBathTmHhEnd
            cmmRecInf.ofrTmMmEnd = obj.bathTmInfo.actBathTmMmEnd
            cmmRecInf.clsfc1Cd = '301'
            cmmRecInf.clsfc2Cd = '5'
            cmmRecInf.clsfc3Cd = ''
            cmmRecInf.wrtr = this.empInf.name
            cmmRecInf.wrtrMmbrPk = this.empInf.mmbrPk
            cmmRecInf.spclNote = this.bathSpclNote

            // 목욕 전 데이터 작성
            let ystObj = Object.assign({}, cmmRecInf)
            ystObj.clsfc3Cd = '1'
            // ystObj.bnftOfrRecHisPk = this.bnftOfrRecHisPk[0]
            ystObj.bnftOfrRecHisPk = null
            ystObj.bnftOfrRec301Pk = this.bnftOfrRec301Pk[0]

            this.cmmFrameList.forEach(itm => {
                ystObj['itm'+itm.itmCd+'Cd'] = itm.itmCd
                ystObj['itm'+itm.itmCd+'Val'] = itm.itm1Val
            });

            // 목욕 후 데이터 작성
            let tomObj = Object.assign({}, cmmRecInf)
            tomObj.clsfc3Cd = '2'
            // tomObj.bnftOfrRecHisPk = this.bnftOfrRecHisPk[1]
            tomObj.bnftOfrRecHisPk = null
            tomObj.bnftOfrRec301Pk = this.bnftOfrRec301Pk[1]

            this.cmmFrameList.forEach(itm => {
                tomObj['itm'+itm.itmCd+'Cd'] = itm.itmCd
                tomObj['itm'+itm.itmCd+'Val'] = itm.itm2Val
            });

            obj.list.push(ystObj)
            obj.list.push(tomObj)

            // console.log(obj)
            this.setBathRcrdInfo(obj)

        },

        // 전일자료 조회
        getPrvsBathSrv: function () {
            this.getBathRcrdInfo(
                this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                'prvs'
            )
        },

        cleanData: function () {
            this.cmmFrameList.splice(0)
            this.bathSchdlPk = 0
            this.bnftOfrRecHisPk.splice(0, 1, 0)
            this.bnftOfrRecHisPk.splice(1, 1, 0)
            this.bnftOfrRec301Pk.splice(0, 1, 0)
            this.bnftOfrRec301Pk.splice(1, 1, 0)
            this.bathSpclNote = ''
            this.bathInfo = Object.assign({}, this.dmyBathInfo)
        },
      
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },

        onEmpMdl: function () {
            this.$store.commit('setSlctEmpMdl', true)
            this.$store.commit('setSlctEmpDt', this.$store.getters.getBnftcrdCtgObj.inqYMD)
        },
    },

    data: () => ({
        bathSpclNote: '',
        bathSchdlPk: 0,
        bnftOfrRecHisPk: [0, 0],
        bnftOfrRec301Pk: [0, 0],
        empInf: { name: '', mmbrPk: 0 },
        bathInfo: {
            bathSchdlPk: 0, actBathDt: '', actBathTmHhStrt: '', actBathTmMmStrt: '', actBathTmHhEnd: '', actBathTmMmEnd: '',
            actBathStrt: '', actBathEnd: '',
        },
        dmyBathInfo: {
            bathSchdlPk: 0, actBathDt: '', actBathTmHhStrt: '', actBathTmMmStrt: '', actBathTmHhEnd: '', actBathTmMmEnd: '',
            actBathStrt: '', actBathEnd: '',
        },
        cmmFrameList: [],
        prvsBtn: true,      // 전일자료 버튼 활성여부
        saveBtn: true,      // 저장 버튼 활성여부
        vCd1: [],
        vCd16: [],          // 건강상태 ( 1.정상, 2.비정상, 3.기타 )
        vCd17: [],          // 피부손상 ( 1.욕창, 2.습진, 3.상처, 4.정상 )
        vCd18: [],          // 정신상태 ( 1.명료, 2.혼돈, 3.기타 )
        vCd19: [],          // 식사유무 ( 1.공복, 2.식사전, 3.식사후 )
        vCd20: [],          // 목욕부위 ( 1.전신, 2.부분, 3.기타 )
        vCd21: [],          // 목욕방법 ( 1.통목욕, 2.침상목욕, 3.목욕의자 )
    }),
};
</script>