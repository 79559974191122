<template>
    <v-row class="pa-0 align-center" no-gutters>
        <v-col class="d-flex justify-center" cols="4">
            <v-btn height="32" color="" @click="onMinsVal" icon>
                <v-icon size="26">mdi-minus-circle-outline</v-icon>
            </v-btn>
        </v-col>
        <v-col class="" cols="4">
            <v-text-field
                class="nmrcInp" type="number" height="24"
                v-model="val" @input="vmlRflt"
                hide-details outlined dense>
            </v-text-field>
        </v-col>
        <v-col class="d-flex justify-center" cols="4">
            <v-btn height="32" @click="val++" icon>
                <v-icon size="26">mdi-plus-circle-outline</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>


export default {
    name: 'CmmNmrcField',

    components: {
        
    },

    props: {
        value: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.value == null)
                this.val = 0
            else
                this.val = this.value
        })
    },

    computed: {

    },

    watch:{
        'val': function () {
            this.$emit('input', this.val)
        },
        'value': function () {
            if(this.value == null)
                this.val = 0
            else
                this.val = this.value
        },
    },
        
    methods: {
        vmlRflt: function (v) {
            this.val = v
        },
        onMinsVal: function () {
            if(this.val > 0)
                this.val--
        },
    },

    data: () => ({
        val: 0,
    }),
};
</script>