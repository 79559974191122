<template>
    <v-dialog v-model="$store.getters.getSlctEmpMdl">
        <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">직원&nbsp;선택</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 mr-2 mt-3" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="4">
                    <v-spacer></v-spacer>
                    <v-text-field
                        class="cmmTmFld" height="24" label="이름 검색"
                        v-model="tblSrch"
                        hide-details dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mr-2"></v-divider>
            <v-data-table
                class="cmmTblComp elevation-0 pr-2" height="234" mobile-breakpoint="280"
                :headers="headers" :items="empList" :search="tblSrch"
                fixed-header hide-default-footer disable-sort>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="txtAlgnMid tableBB px-1">
                            <span class="fontDotSeven">{{ item.name }}</span>
                        </td>
                        <td class="txtAlgnMid tableBL tableBB px-1">
                            <span class="fontDotSeven">{{ item.jobCdNm }}</span>
                        </td>
                        <td class="txtAlgnMid tableBL tableBB px-1">
                            <span class="fontDotSeven">{{ item.wrkSchdl }}</span>
                        </td>
                        <td class="d-flex justify-center align-center tableBL tableBB px-1">
                            <span v-if="item.wrkTypCd == '1'">
                                <v-btn
                                    class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 0px !important"
                                    color="blue009" min-width="30" height="25"
                                    @click="actEmpChc(item.name, item.mmbrPk)"
                                    small>
                                        <v-icon size="17">mdi-checkbox-marked-outline</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-dialog>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'CmmEmpSlctMdl',

    components: {
      
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.getters.getSlctEmpDt != '')
                this.getEmpChcList(this.$store.getters.getSlctEmpDt)
        })
    },

    computed: {
        getEmpSlctDt: function () {
            return this.$store.getters.getSlctEmpDt
        },
    },

    watch:{
        getEmpSlctDt: function (v) {
            if(v != '')
                this.getEmpChcList(v)
        },
    },
        
    methods: {
        //직원 정보 조회
        getEmpChcList: function (dt){
            let obj = {}
            obj.wrtDt = this.$moment(dt, 'YYYY.MM.DD').format('YYYYMMDD')
            obj.wrkStcd = '1'

            http.post('empApp/authSelChcList', obj)
                .then((response) => ( this.empChcRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelChcList : ' + error))
        },

        empChcRst: function (res){
            if(res.statusCode == '200'){
                res.list.forEach(itm => {
                    let chc = this.$store.getters.getCmmCode('vCd42').findIndex( v => v.valcd == itm.jobCd )
                    if(chc > -1)
                        itm.jobCdNm = this.$store.getters.getCmmCode('vCd42')[chc].valcdnm
                });
                this.empList = res.list
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }

        },

        actEmpChc: function (name, mmbr){
            let oObj = {}
            oObj.name = name
            oObj.mmbrPk = mmbr

            this.$store.commit('setSlctEmpObj', oObj)
            this.onClose()
        },

        clearFrm: function (){
            this.empList.splice(0)
        },
     
        onClose: function () {
            this.$store.commit('setSlctEmpMdl', false)
            this.$store.commit('setSlctEmpDt', '')
        },
    },

    data: () => ({
        tblSrch: '',
        empList: [],
        headers: [
            { text: '직원명', value: 'name', align: 'center', class: 'black--text nmlBfClrImp-grey001 px-1'},
            { text: '담당직종', value: 'jobCdNm', align: 'center', class: 'black--text nmlBfClrImp-grey001 tableBL px-1'},
            { text: '근무일정', value: 'wrkSchdl', align: 'center', class: 'black--text nmlBfClrImp-grey001 tableBL px-1'},
            { text: '', value: '', align: 'center', class: 'nmlBfClrImp-grey001 tableBL px-1'},
        ],
    }),
};
</script>