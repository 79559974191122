<template>
    <v-card
        class="py-4 px-5"
        width="100%" rounded="xl" elevation="3">
        <v-row class="pa-0 align-center" style="line-height: 1;" no-gutters>
            <CmmDrawIcon type="ic_time" size="20"></CmmDrawIcon>
            <span class="fontDotEight font-weight-medium blue105--text ml-1" style="line-height: 0.1">
                Today
            </span>
            <span class="fontDotEight font-weight-medium ml-2" style="line-height: 0.1">
                물리&#40;작업&#41;치료내역
            </span>
            <v-spacer></v-spacer>
            <v-btn @click="rtrAct('PhyThrRecApp')" x-small text>
                <span>더보기</span>
            </v-btn>
        </v-row>
        <div v-if="list.length > 0">
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" v-if="Object.keys(phyInf).length > 0" no-gutters>
                <v-col class="nmlBfClrImp-greyE02 d-flex justify-center align-center pa-1 tableBR" cols="2">
                    <span class="fontDotEight font-weight-bold">물리</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR pa-1" style="line-height: 1;" cols="5">
                    <span class="fontDotEight font-weight-medium">
                        {{ phyInf.ofrTmHhStrt }}:{{ phyInf.ofrTmMmStrt }} ~ {{ phyInf.ofrTmHhEnd }}:{{ phyInf.ofrTmMmEnd }}
                    </span>
                </v-col>
                <v-col class="txtAlgnMid pa-1" style="line-height: 0.9;" cols="5">
                    <span
                        v-for="(itm, i) in phyInf.itm1ValAr" :key="i"
                        class="fontDotSeven font-weight-medium">
                        {{ itm }}<span v-if="(phyInf.itm1ValAr.length-1) != i">,&nbsp;</span>
                    </span>
                </v-col>
            </v-row>
            <v-divider v-if="list.length > 1" class=""></v-divider>
            <v-row class="pa-0" v-if="Object.keys(wrkInf).length > 0" no-gutters>
                <v-col class="nmlBfClrImp-greyE02 d-flex justify-center align-center pa-1 tableBR" cols="2">
                    <span class="fontDotEight font-weight-bold">작업</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR pa-1" style="line-height: 1;" cols="5">
                    <span class="fontDotEight font-weight-medium">
                        {{ wrkInf.ofrTmHhStrt }}:{{ wrkInf.ofrTmMmStrt }} ~ {{ wrkInf.ofrTmHhEnd }}:{{ wrkInf.ofrTmMmEnd }}
                    </span>
                </v-col>
                <v-col class="txtAlgnMid pa-1" style="line-height: 0.9;" cols="5">
                    <span
                        v-for="(itm, i) in wrkInf.itm1ValAr" :key="i"
                        class="fontDotSeven font-weight-medium">
                        {{ itm }}<span v-if="(wrkInf.itm1ValAr.length-1) != i">,&nbsp;</span>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
        <div class="pt-2" v-else>
            <v-row class="pa-0" no-gutters>
            <v-col class="txtAlgnMid" cols="12">
                <span class="greyE06--text fontDotEight">치료내역이 없습니다.</span>
            </v-col>
        </v-row>
        </div>
    </v-card>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';

export default {
    name: 'PhyThrCard',

    components: {
        CmmDrawIcon,
    },

    props: {
        list: { type: Array, default: () => [] },
        phyCode: { type: Array, default: () => [] },
        wrkCode: { type: Array, default: () => [] },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.sprtPhyWrkTyp(this.list)
        })
    },

    computed: {

    },

    watch:{
        list: {
            deep: true,
            handler: function (value){
                this.sprtPhyWrkTyp(value)
            }
        },
    },
        
    methods: {
        sprtPhyWrkTyp: function (list) {
            let chc = list.findIndex( v => v.clsfc3Cd == '1' )
            if(chc > -1)
                this.phyInf = list[chc]

            let idx = list.findIndex( v => v.clsfc3Cd == '2' )
            if(idx > -1)
                this.wrkInf = list[idx]

                
            if(Object.keys(this.phyInf).length > 0){
                this.phyInf.itm1ValAr.forEach((idx, i) => {
                    this.phyCode.forEach(itm => {
                        if(itm.valcd == idx)
                            this.phyInf.itm1ValAr.splice(i, 1, itm.valcdnm)
                    });
                });
            }
            if(Object.keys(this.wrkInf).length > 0){
                this.wrkInf.itm1ValAr.forEach((idx, i) => {
                    this.wrkCode.forEach(itm => {
                        if(itm.valcd == idx)
                            this.wrkInf.itm1ValAr.splice(i, 1, itm.valcdnm)
                    });
                });
            }
        },
        rtrAct: function (nm){
            if(this.$route.name != nm)
                this.$router.push({ name: nm })
        },
    },

    data: () => ({
        phyInf: {},
        wrkInf: {},
    }),
};
</script>