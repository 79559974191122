<template>
    <v-sheet class="py-7" width="100%">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon color="blue102">mdi-chevron-down</v-icon>
                <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                    미납상세내역&nbsp;&#40;수급자:&nbsp;{{ bnfcrInf.name }}&#41;
                </span>
            </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-row class="pa-0 greyE01" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="fontDotEight font-weight-bold">급여제공월</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="fontDotEight font-weight-bold">청구금액</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="fontDotEight font-weight-bold">입금액</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="3">
                <span class="fontDotEight font-weight-bold">미납액</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <div
            :style="{ 'width': '100%', 'height': ($vuetify.breakpoint.height - 206)+'px', 'position': 'relative' }">
            <div
                class="d-inline-block overflow-y-auto"
                :style="{ 'width': '100%', 'height': ($vuetify.breakpoint.height - 238)+'px' }">
                <v-row class="pa-0 tableBB" v-for="(list, i) in dirtUnpdList" :key="i" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontDotEight font-weight-medium">{{ list.clmYymm }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontDotEight font-weight-medium">{{ list.clmTot?.toLocaleString('ko-KR') }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="fontDotEight font-weight-medium">{{ list.dpsAmt?.toLocaleString('ko-KR') }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <span class="fontDotEight font-weight-medium red--text">{{ list.unpdAmt?.toLocaleString('ko-KR') }}</span>
                    </v-col>
                </v-row>
            </div>

            <div style="position: absolute; bottom: 0px; left: 0px; width: 100%;">
                <v-row class="pa-0 greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="9">
                        <span class="fontDotEight font-weight-medium">합계</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <span class="fontDotEight font-weight-medium">{{ totAmt?.toLocaleString('ko-KR') }}</span>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'OutUnpdClms',

    components: {
        
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '노인장기요양 미납청구안내'
            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        // 미납내역 조회
        getDirtUnpdList: function (code, pk){
            http.post('fmly/selDirtUnpdList', { fcltyNum: code, bnMmbrPk: pk })
                .then((response) => ( this.dirtUnpdRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selDirtUnpdList : ' + error))
        },

        dirtUnpdRst: function (res){
            if(res.statusCode == '200'){
                res.result.forEach(itm => {
                    this.dirtUnpdList.push(itm)
                    this.totAmt += Number(itm.unpdAmt)
                });
                this.bnfcrInf = res.mmbrInfo
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: '서버 연결 오류' })
            }
        },

        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)
                    let rst = JSON.parse(bytes.toString(this.$crypto.enc.Utf8))

                    this.$parent.$parent.sltFcltyNum = rst.fcltyNum
                    this.$parent.$parent.sltBnMmbrPk = rst.bnMmbrPk
                    this.getDirtUnpdList(rst.fcltyNum, rst.bnMmbrPk)
                }
            }
        },

    },

    data: () => ({
        totAmt: 0,
        bnfcrInf: {},
        dirtUnpdList: [],
    }),
};
</script>