<template>
    <v-sheet class="py-7" width="100%">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon color="blue102">mdi-chevron-down</v-icon>
                <span class="ml-2 font-weight-bold fontOneToOne blue102--text">{{ sndConnInf?.eSigNm }}</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1"></span>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mt-4"></v-divider>
        <v-row class="mx-2 tableBB" v-for="(list, i) in frmList" :key="i" no-gutters>
            <v-col class="nmlBfClr-grey001 txtAlgnMid py-1" cols="4">
                <span class="fontDotEight font-weight-bold">{{ list.label }}</span>
            </v-col>
            <v-col class="d-flex align-center pl-4 tableBL" cols="8">
                <span class="fontDotEight">{{ list.val }}</span>
            </v-col>
        </v-row>
        <v-row class="justify-center pt-4 pb-2" no-gutters>
            <v-btn
                class="" color="grey006"
                @click="getRptInf"
                outlined rounded>
                <v-icon class="" size="18">mdi-printer-outline</v-icon>
                <span class="fontDotNine ml-2" style="padding-bottom: 2px;">{{ sndConnInf?.eSigNm }}&nbsp;상세내용&nbsp;확인</span>
            </v-btn>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'OutRptMgmt',

    components: {
        
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            // let obj = {}
            // obj.bnMmbrPk = 16
            // obj.fcltyNum = 14111000790
            // obj.inqYYMM = 202307

            // let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            // console.log(encrypted.toString())
            // let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            // console.log(bs64)

            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        // 출력물 조회 Params
        getRptMgmtInf: function (obj){
            http.post('fmly/rptBasicInfo', obj)
                .then((response) => ( this.rptMgmtRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/rptBasicInfo : ' + error))
        },
        // PDF 조회
        getRptInf: function (){
            let obj = {}
            obj.fcltyNum = this.$parent.$parent.sltFcltyNum
            obj.bnMmbrPk = this.bnMmbrPk
            obj.prtClcd = '1'
            obj.inqClcd = '2'
            obj.prtCd = this.sndConnInf.prtCd
            obj.list = []
            obj.list.push( { bzClcd: [], bzPk: [this.sndConnInf.bzClcdPk] } )

            http.post('fmly/reportInfo', obj)
                .then((response) => ( this.rptInfRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/reportInfo : ' + error))
        },
        rptMgmtRst: function (res){
            if(res.statusCode == '200'){
                this.bnMmbrPk = res.result.bnMmbrPk
                this.frmList = res.result.list
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rptInfRst: function (res){
            if(res.statusCode == '200'){
                this.$store.commit('setCmmPdfDstnt', 'imgPrview')
                this.$store.commit('setCmmPdfPth', res.filePth)
                this.$store.commit('setCmmPdfNm', res.fileNm)
                this.$store.commit('setCmmPdfOrgNm', res.orgFileNm)
                this.$store.commit('setCmmPdfTtl', this.sndConnInf.ttl)
                this.$store.commit('setCmmPrtMdl', true)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)

                    this.onPayExpStt(JSON.parse(bytes.toString(this.$crypto.enc.Utf8)))
                }
            }
        },
        onPayExpStt: function (obj) {
            let tmp = Object.assign({}, obj)

            this.$parent.$parent.sltFcltyNum = tmp.fcltyNum
            this.sndConnInf.ttl = tmp.title
            this.sndConnInf.bzClcdPk = tmp.bzPk
            this.sndConnInf.eSigClcd = tmp.clcd
            this.sndConnInf.mmbrPk = tmp.mmbrPk
            this.sndConnInf.name = tmp.name
            this.sndConnInf.prtCd = tmp.prtCd
            this.sndConnInf.bzClcd = tmp.bzClcd

            let chc = this.sgnCd.findIndex( v => v.valcd == tmp.clcd )
            if(chc > -1){
                this.sndConnInf.eSigNm = this.sgnCd[chc].valcdnm
                this.$parent.$parent.appBarTtl = this.sgnCd[chc].valcdnm + ' 조회'
            }

            let sgnObj = Object.assign({}, this.sndConnInf)
            sgnObj.fcltyNum = this.$parent.$parent.sltFcltyNum
            this.getRptMgmtInf(sgnObj)
        },
    },

    data: () => ({
        bnMmbrPk: 0,
        frmList: [],
        sndConnInf: { bzClcdPk: 0, eSigClcd: 0, eSigNm: '', mmbrPk: 0, name: '', prtCd: '', bzClcd: '', ttl:'' },
        sgnCd: [
            { valcd: '13', valcdnm: '연계 기록지' },
        ],
    }),
};
</script>