<template>
    <v-dialog v-model="$store.state.pickDtMdl" max-width="320">
        <v-date-picker
            class="cmmDtPckr" locale="en-US"
            v-model="$store.state.pickDt" :min="$store.state.pickDtMdlMin" :max="$store.state.pickDtMdlMax"
            :type="$store.state.pickDtMdlTyp == 'YYMM' ? 'month' : 'date'"
            :weekday-format="getDay" :month-format="getMonth" :header-date-format="getTitle"
            @click:date="dtAply" @click:month="dtAply"
            no-title scrollable>

            <!-- <v-btn
                class="font-weight-bold" color="blueBtnColor"
                @click="$store.commit('setPickDtMdl', false)" text>
                취소
            </v-btn> -->

        </v-date-picker>
    </v-dialog>
</template>

<script>

export default {
    name: 'CmmDtPickMdl',

    components: {
      
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
           
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        getDay: function (date) {
            const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        dtAply: function () {
            this.$store.commit('setPickDt', this.$store.state.pickDt)
            this.$store.commit('setPickDtMdl', false)
        },
    },

    data: () => ({

    }),
};
</script>