<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">프로그램 일정</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYMD :aplyMinDt="'2020-01-01'" :aplyMaxDt="$moment().format('YYYY-MM-DD')" @sltHypDt="getHypDt"></AppYMD>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 pb-4 mt-15" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="ic_calendar" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 0px;">프로그램 일정</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" v-for="(list, i) in prgOpSchd" :key="i" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="10">
                        <v-divider class="black002"></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="d-flex align-center py-1 px-2 tableBR" cols="5">
                                <span class="fontDotEight font-weight-medium">프로그램 유형</span>
                            </v-col>
                            <v-col class="txtAlgnEnd px-2 py-1" style="line-height: 1;" cols="7">
                                <span class="fontDotSeven">{{ list.prgNm }}</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="d-flex align-center py-1 px-2 tableBR" cols="5">
                                <span class="fontDotEight font-weight-medium">운영시간</span>
                            </v-col>
                            <v-col class="txtAlgnEnd px-2 py-1" cols="7">
                                <span class="fontDotSeven">{{ list.opTm }}</span>
                            </v-col>
                        </v-row>
                        <v-divider class="black002"></v-divider>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <div v-if="prgOpSchd.length == 0">
                    <v-row class="pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col cols="10">
                            <v-divider class="black002"></v-divider>
                            <div class="txtAlgnMid my-1">
                                <span class="grey006--text fontDotSeven">프로그램 일정이 없습니다.</span>
                            </div>
                            <v-divider class="mt-2"></v-divider>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYMD from '../../cmm/AppYMD.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'PrgmSchdlApp',

    components: {
        CmmDrawIcon,
        AppYMD,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getPrgOpSchdList(this.$moment().format('YYYYMMDD'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 프로그램 일정 조회(일간)
        getPrgOpSchdList: function (ymd){
            this.prgOpSchd.splice(0)

            http.post('fmlyApp/authPrgOpSchdList', { inqYMD: ymd })
                .then((response) => ( this.prgOpSchdRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authPrgOpSchdList : ' + error))
        },

        prgOpSchdRst: function (res){
            if(res.statusCode == '200'){
                if(res.result.length > 0)
                    this.prgOpSchd = res.result
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },

        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != '')
                this.getPrgOpSchdList(this.$moment(v, 'YYYY-MM-DD').format('YYYYMMDD'))
        },
    },

    data: () => ({
        viewHeight: 0,
        prgOpSchd: [],
    }),
};
</script>