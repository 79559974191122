<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="11">
            <v-row class="pa-0" no-gutters>
                <v-col v-if="rngCldTyp == 'mnt'" class="" cols="12">
                    <CmmYMD
                        class="my-4"
                        :aplyYMD="$moment(aplyYyMm+'01', 'YYYYMMDD', true).format('YYYYMMDD') "
                        :aplyMinDt="$moment(aplyYyMm+'01', 'YYYYMMDD', true).format('YYYY-MM-DD')"
                        :aplyMaxDt="$moment(aplyYyMm, 'YYYYMM', true).endOf('month').format('YYYY-MM-DD')"
                        @sltHypDt="getCompDt">
                    </CmmYMD>
                </v-col>
                <v-col v-else-if="rngCldTyp == 'none'" class="" cols="12">
                    <CmmYMD
                        class="my-4"
                        :aplyYMD="$moment().format('YYYYMMDD')"
                        @sltHypDt="getCompDt">
                    </CmmYMD>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        프로그램&nbsp;일정
                    </span>
                </v-col>
            </v-row>
            <v-divider class="black mt-4" style="padding: 1px 0;"></v-divider>
            <v-row class="nmlBfClr-grey001 py-2 px-6" no-gutters>
                <span class="fontOne font-weight-medium">{{ $moment(sltDt, 'YYYYMMDD', true).format('MM월 DD일') }}</span>
                <v-spacer></v-spacer>
                <span class="fontOne font-weight-medium">&#40;{{ $moment(sltDt, 'YYYYMMDD', true).format('dddd') }}&#41;</span>
            </v-row>
            <v-divider class=""></v-divider>
            <div v-for="(prgList, i) in prgOpSchdList" :key="i">
                <v-row class="px-4" no-gutters>
                    <v-col class="py-2 tableBR" cols="6">
                        <span class="fontDotNine grey006--text">프로그램</span>
                    </v-col>
                    <v-col class="d-flex py-2" cols="6">
                        <v-spacer></v-spacer>
                        <span class="fontDotNine grey006--text">{{ prgList.prgNm }}</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="px-4" no-gutters>
                    <v-col class="py-2 tableBR" cols="6">
                        <span class="fontDotNine grey006--text">운영시간</span>
                    </v-col>
                    <v-col class="d-flex py-2" cols="6">
                        <v-spacer></v-spacer>
                        <span class="fontDotNine grey006--text">{{ prgList.opTm }}</span>
                    </v-col>
                </v-row>
                <v-divider class="black" style="padding: 1px 0;"></v-divider>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmYMD from '../../cmm/CmmYMD_kr.vue';

export default {
    name: 'PrgSrvSchdl',

    components: {
        CmmYMD,
    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
        rngCldTyp: { type: String, default: 'mnt' },
    },

    created: function(){
      
    },

    mounted: function(){
        this.$nextTick(function () {
            this.sltDt = this.$moment().format('YYYYMM') + '01'

        })
    },

    computed: {
       
    },

    watch:{
        
    },
        
    methods: {
        //프로그램 일정표 조회 TOKEN
        getPrgOpSchdList: function (ymd){
            http.post('fmly/selPrgOpSchdList', { inqYmdStart: ymd, inqYmdEnd: ymd })
                .then((response) => ( this.prgOpSchdListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selPrgOpSchdList : ' + error))
        },
        //프로그램 일정표 조회 Params
        getOutPrgPrtcpList: function (code, pk, ymd){
            http.post('fmly/selPrgOpSchdList', { fcltyNum: code, bnMmbrPk: pk, inqYmdStart: ymd, inqYmdEnd: ymd })
                .then((response) => ( this.prgOpSchdListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selPrgOpSchdList : ' + error))
        },
        prgOpSchdListRst: function (res){
            this.prgOpSchdList.splice(0)

            if(res.statusCode == '200'){
                if(res.result.length > 0)
                    this.prgOpSchdList = res.result
                else
                    this.prgOpSchdList.push(Object.assign({}, this.dmyPrgPrtcpObj))
            }
        },
        getCompDt: function (dt) {
            this.sltDt = this.$moment(dt, 'YYYY-MM-DD', true).format('YYYYMMDD')

            if(this.bnMmbrPk == 0)
                this.getPrgOpSchdList(this.sltDt)
            else
                this.getOutPrgPrtcpList(this.fcltyNum, this.bnMmbrPk, this.sltDt)
        },
    },

    data: () => ({
        sltDt: '',
        prgOpSchdList: [],
        dmyPrgOpSchdObj: { opDt: '', opTm: '', perfNm: '', prgNm: '', prgTypNm: '', prtcpNm: '', stsfcNm: '', },
    }),
};
</script>