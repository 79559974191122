<template>
    <v-sheet
        class="ma-0 py-4 pl-4 pr-3" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-medium fontOneToTwo">{{ $store.state.signAgrObj.msgTtl }}</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="$store.state.signAgrObj.msgTtl != '직원 사인'">
            <v-row class="pa-0 mt-5" v-if="eSIgnLoad" no-gutters>
                <v-col class="d-flex justify-center" ref="divEsign" cols="12">
                    <vue-esign
                        class="rounded" style="border: 3px solid #4bc5e8;"
                        ref="esign" format="image/png" :width="signWidth" :height="signHeight"
                        :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                    </vue-esign>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" v-else no-gutters>
                <v-col cols="12">
                    <v-img
                        class="rounded" style="border: 3px solid #4bc5e8;"
                        :src="eSIgnInfo.photoImg"
                        width="280" height="210" aspect-ratio="4/3">
                    </v-img>
                </v-col>
            </v-row>
            <v-row 
                class="pa-0 mt-3 mb-1"
                v-if="eSIgnInfo.isFile == 'Y' && eSIgnLoad == true"
                no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="ml-3 white--text" height="26" color="blue002" @click="eSIgnApl()" rounded
                        style="font-size: 16px;">
                        내 서명 불러오기
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-3 mb-1" v-if="eSIgnLoad == true" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        style="font-size: 16px;" height="26" color="grey006" @click="handleReset" outlined rounded>
                        <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                        지우기
                    </v-btn>
                    <v-btn
                        class="ml-3 white--text" height="26" color="blue001" rounded @click="handleGenerate"
                        style="font-size: 16px;">
                        <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                        저장
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-3 mb-1" v-if="!eSIgnLoad" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        style="font-size: 16px;" height="26" color="grey006" @click="eSIgnLoad = true" outlined rounded>
                        <v-icon class="mr-1" size="18">mdi-arrow-u-right-bottom</v-icon>
                        되돌리기
                    </v-btn>
                    <v-btn
                        class="ml-3 white--text" height="26" color="blue001" @click="preESndInsSign" rounded>
                        <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                        저장
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0 mt-5" v-if="eSIgnInfo.isFile == 'N'" no-gutters>
                <v-col class="d-flex justify-center" ref="divEsign" cols="12">
                    <vue-esign
                        class="rounded" style="border: 3px solid #4bc5e8;"
                        ref="esign" format="image/png" :width="signWidth" :height="signHeight"
                        :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                    </vue-esign>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" v-else no-gutters>
                <v-col cols="12">
                    <v-img
                        class="rounded" style="border: 3px solid #4bc5e8;"
                        :src="eSIgnInfo.photoImg"
                        width="280" height="210" aspect-ratio="4/3">
                    </v-img>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-3 mb-1" v-if="eSIgnInfo.isFile == 'N'" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        style="font-size: 16px;" height="26" color="grey006" @click="handleReset" outlined rounded>
                        <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                        지우기
                    </v-btn>
                    <v-btn
                        class="ml-3 white--text" height="26" color="blue001" rounded @click="handleGenerate"
                        style="font-size: 16px;">
                        <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                        저장
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-3 mb-1" v-else no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="ml-3 white--text" height="26" color="blue002" rounded
                        @click="eSIgnInfo.isFile='N'"
                        style="font-size: 16px;">
                        새로 작성
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'EmpSignMdl',

    props : {

    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getEmpESig()
        })
    },

    computed: {

    },

    watch:{

    },

    beforeDestroy: function () {

    },
        
    methods: {
        //직원 사인 조회
        getEmpESig: function (){
            http.post('empApp/authSelEmpESig', {})
                .then((response) => ( this.empESigRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelEmpESig : ' + error))
        },
        //직원 사인 저장
        setEmpInsSign: function (obj){
            http.post('empApp/authEmpInsSign', obj)
                .then((response) => ( this.eSndInsSignRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authEmpInsSign : ' + error))
        },
        //알림내역 사인 저장
        setESndInsSign: function (obj){
            http.post('mobemp/eSndInsSign', obj)
                .then((response) => ( this.eSndInsSignRst(response.data) ))
                .catch((error) => console.log('axios error /mobemp/eSndInsSign : ' + error))
        },
        empESigRst: function (res){
            this.eSIgnInfo.isFile = res.isFile
            this.eSIgnInfo.photoImg = 'data:image/png;base64,' + res.photoImg
            this.eSIgnInfo.fileNm = res.fileNm
            this.eSIgnInfo.filePth = res.filePth
        },
        eSndInsSignRst: function (res){
            let obj = {}
            if(res.statusCode == '200')
                obj.clr = 'info'
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)

            this.onClose()
        },
        preESndInsSign: function () {
            let obj = {}
            obj.bzPk = this.$store.getters.getSignAgrObj.bzPk
            obj.eDocClcd = this.$store.state.signAgrObj.eDocClcd 
            obj.bzClcd = this.$store.state.signAgrObj.bzClcd
            if(this.eSIgnLoad == true)
                obj.sign = this.sign
            else if(this.eSIgnLoad == false)
                obj.sign = this.eSIgnInfo.photoImg

            this.setESndInsSign(obj)
        },
        preEmpInsSign: function () {
            let obj = {}
            obj.sign = this.sign

            this.setEmpInsSign(obj)
        },
        eSIgnApl: function () {
            this.eSIgnLoad = false
        },
        handleReset: function () {
            this.$refs.esign.reset()
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res

                //mobemp/empInsSign
                if(this.$store.state.signAgrObj.msgTtl == '직원 사인')
                    this.preEmpInsSign()
                else
                    this.preESndInsSign()
            }).catch(err => {
                console.log(err)
            })
        },
        onClose: function () {
            this.$store.commit('setSignAgrObj', {})
            this.$store.commit('setSignAgrMdl', false)
        },
    },
    
    data: () => ({
        eSIgnInfo: { isFile: 'N', photoImg: null, fileNm: '', filePth: '', },
        eSIgnLoad: true,
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        signWidth: 280,
        signHeight: 210,
    }),
};
</script>