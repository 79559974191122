<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">입금내역</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYYYY :aplyMinYear="'2020'" :aplyMaxYear="$moment().format('YYYY')" @sltYear="getDt"></AppYYYY>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 pb-4 mt-15 " no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                            <span class="blue104--text fontDotEight font-weight-medium">입금내역</span>
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="pt-1" cols="10">
                        <!-- <v-divider class="black002"></v-divider> -->
                        <!-- <v-row class="tableBB" no-gutters>
                            <v-col class="tableBR pa-1" cols="6">
                                <span class="fontDotEight font-weight-medium">입금상태</span>
                            </v-col>
                            <v-col class="txtAlgnEnd pa-1" cols="6">
                                <span class="fontDotEight">{{ Object.keys(dpsInf).length > 0 ? dpsInf.dpsSts : '' }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBB" no-gutters>
                            <v-col class="tableBR pa-1" cols="6">
                                <span class="fontDotEight font-weight-medium">잔여선납금</span>
                            </v-col>
                            <v-col class="txtAlgnEnd pa-1" cols="6">
                                <span class="fontDotEight">
                                    {{ Object.keys(dpsInf).length > 0 ? dpsInf.rmnPrpym?.toLocaleString('ko-KR') + ' 원' : '' }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBB" no-gutters>
                            <v-col class="tableBR pa-1" cols="6">
                                <span class="fontDotEight font-weight-medium">미납금</span>
                            </v-col>
                            <v-col class="txtAlgnEnd pa-1" cols="6">
                                <span class="fontDotEight">
                                    {{ Object.keys(dpsInf).length > 0 ? dpsInf.unpdAmt?.toLocaleString('ko-KR') + ' 원' : '' }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="pt-8 pb-4" no-gutters>
                            <v-col class="d-flex align-center" cols="12">
                                <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                    <span class="blue104--text fontDotEight font-weight-medium">입금내역</span>
                                </span>
                            </v-col>
                        </v-row> -->
                        <div class="pb-8" v-for="(list, i) in prcList" :key="i">
                            <v-divider class="black002"></v-divider>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">입금일자</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ $moment(list.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">입금액</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.dpsTot?.toLocaleString('ko-KR') + '원' }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">입금방법</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.dpsMthdNm }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" v-if="list.child.length > 0" no-gutters>
                                <v-col class="pa-1" cols="12">
                                    <span class="fontDotEight font-weight-medium">내역</span>
                                    <div
                                        class="d-inline-block rounded px-2 my-3"
                                        style="width: 100%; margin: 0 2px; padding-bottom: 1px; background-color: #F5F5F5; border: 2px solid #1B81DF; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);">
                                        <div v-for="(sub, j) in list.child" :key="j">
                                            <v-row :class="['pa-0', (list.child.length - 1) != j ? 'tableBB' : '']" no-gutters>
                                                <v-col class="txtAlgnMid pt-1 pb-2" cols="3">
                                                    <span :class="['rounded-xl px-3 pb-1', sub.dpsClcd != '99' ? 'blue104' : 'greyE06']">
                                                        <span class="white--text fontDotEight font-weight-medium">
                                                            {{ sub.dpsClnm }}
                                                        </span>
                                                    </span>
                                                </v-col>
                                                <v-col class="txtAlgnMid py-1" cols="5">
                                                    <span class="fontDotEight">{{ sub.dpsAmt?.toLocaleString('ko-KR') + '원' }}</span>
                                                </v-col>
                                                <v-col class="txtAlgnMid py-1" cols="4">
                                                    <span class="fontDotEight">
                                                        {{ sub.dpsClcd != '99' ? $moment(sub.clmYymm, 'YYYYMM').format('YYYY.MM') : '' }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pb-8" v-if="prcList.length == 0">
                            <v-divider class="black002"></v-divider>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="txtAlgnMid pt-1 pb-2" cols="12">
                                    <span class="grey006--text fontDotEight font-weight-medium">입금내역이 존재하지 않습니다.</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYYYY from '../../cmm/AppYYYY.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'DpstHstryApp',

    components: {
        CmmDrawIcon,
        AppYYYY,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 본인부담금 입금내역 조회 (연간)
        getCopayDpstInfo: function (yyyy){
            this.dpsInf = Object.assign({})
            this.prcList.splice(0)

            http.post('fmlyApp/authCopayDpstInfo', { inqYYYY: yyyy })
                .then((response) => ( this.copayDpstRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authCopayDpstInfo : ' + error))
        },
        copayDpstRst: function (res){
            if(res.statusCode == '200'){
                if(res.codes.vCd85.length > 0)
                    this.vCd85 = res.codes.vCd85
                if(Object.keys(res.dpstInfo).length > 0)
                    this.dpsInf = res.dpstInfo
                if(res.prcHisList.length > 0){
                    res.prcHisList.forEach(itm => {
                        let arr = res.prcList.filter( v => v.dpsPrcHisPk == itm.dpsPrcHisPk )
                        itm.child = arr
                    });
                    this.prcList = res.prcHisList
                }

                // if(this.vCd85.length > 0 && this.prcList.length)
                //     this.rflCode()

            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        rflCode: function (){
            this.prcList.forEach(itm => {
                let chc = this.vCd85.findIndex( v => v.valcd == itm.dpsClcd )
                if(chc > -1)
                    itm.dpsClcdNm = this.vCd85[chc].valcdnm
            });
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getDt: function (v){
            if(v != '')
                this.getCopayDpstInfo(v)
        },
    },

    data: () => ({
        viewHeight: 0,
        dpsInf: {},
        prcList: [],
        vCd85: [],
    }),
};
</script>