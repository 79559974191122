<template>
    <v-sheet class="py-7" width="100%">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon color="blue102">mdi-chevron-down</v-icon>
                <span class="ml-2 font-weight-bold fontOneToOne blue102--text">{{ sndConnInf?.eSigNm }}</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1"></span>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mt-4"></v-divider>
        <v-row v-for="(list, i) in tblList" :key="i" class="mx-2 tableBB" no-gutters>
            <v-col class="nmlBfClr-grey001 txtAlgnMid py-1" cols="4">
                <span class="fontDotEight font-weight-bold">{{ list.label }}</span>
            </v-col>
            <v-col class="d-flex align-center pl-4 tableBL" cols="8">
                <span class="fontDotEight">{{ list.val }}</span>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mt-4"></v-divider>
        <v-row class="justify-center pt-4 pb-2" no-gutters>
            <v-btn
                class="" color="grey006"
                :disabled="sgnInf.bnMmbrPk == 0"
                @click="getRptInf"
                outlined rounded>
                <v-icon class="" size="18">mdi-printer-outline</v-icon>
                <span class="fontDotNine ml-2" style="padding-bottom: 2px;">{{ sndConnInf?.eSigNm }}&nbsp;상세내용확인</span>
            </v-btn>
        </v-row>
        <v-divider class="mt-2 mb-4 mx-2"></v-divider>
        <v-row v-if="sgnInf.isFile == 'N'" class="" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <vue-esign
                    class="rounded" style="border: 3px solid #4bc5e8;"
                    ref="esign" format="image/png" :width="signWidth" :height="signHeight"
                    :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                </vue-esign>
            </v-col>
        </v-row>
        <v-row v-else-if="sgnInf.isFile == 'Y'" class="" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <span class="d-iline-block" style="width: 280px; height: 210px;">
                    <v-img
                        class="rounded" style="border: 3px solid #4bc5e8;"
                        :src="sgnInf.photoImg"
                        width="280" height="210" aspect-ratio="4/3">
                    </v-img>
                </span>
            </v-col>
        </v-row>
        <v-row v-if="sgnInf.isFile == 'N'" class="justify-end pt-3 px-6" no-gutters>
            <v-btn
                style="font-size: 16px;" height="26" color="grey006"
                @click="handleReset" outlined rounded>
                <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                <span>서명&nbsp;지우기</span>
            </v-btn>
        </v-row>
        <v-row v-else-if="sgnInf.isFile == 'Y'" class="justify-end pt-3 px-6" no-gutters>
            <v-btn
                style="font-size: 16px;" height="26" color="grey006"
                @click="sgnInf.isFile = 'N', sgnInf.photoImg = ''" outlined rounded>
                <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                <span>서명&nbsp;다시하기</span>
            </v-btn>
        </v-row>
        <v-divider class="mx-2 mt-4"></v-divider>
        <v-row class="greyE01 mx-2" no-gutters>
            <v-col class="txtAlgnMid py-1 px-3" cols="12">
                <span class="font-weight-medium fontDotEight">※ 본인의 이름을 정자로 입력하시기 바랍니다.</span>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-divider class="mx-2 mt-1"></v-divider>
        <v-row class="greyE01 mx-2" no-gutters>
            <v-col class="d-flex justify-center align-center pl-2" cols="2">
                <v-checkbox
                    v-model="sgnInf.cfmYn" :ripple="false"
                    class="cmmSqrBox mt-0" style="" true-value="1" false-value="2"
                    hide-details>
                </v-checkbox>
            </v-col>
            <v-col class="d-flex align-center py-1 pr-3" style="line-height: 14px;" cols="10">
                <div>
                    <span class="fontDotSeven">위의&nbsp;{{ sndConnInf?.eSigNm }}에 대해 상세히 설명 들었음을 확인합니다. </span>
                    <span class="fontDotSeven">본인이 맞으며 전자서명 등록과 사용에 동의합니다.</span>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mx-2 mb-4"></v-divider>
        <v-row class="justify-center" no-gutters>
            <span v-if="sgnInf.isFile == 'N'">
                <v-btn
                    :class="['pl-9 pr-10', saveBtn || sgnInf.cfmYn == '2' ? '' : 'blue001 white--text']"
                    @click="handleGenerate" :disabled="saveBtn || sgnInf.cfmYn == '2'"
                    outlined rounded>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="fontDotNine ml-2" style="padding-bottom: 2px;">저장</span>
                </v-btn>
            </span>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'OutSignMgmt',

    components: {
        
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            // let obj = {}
            // obj.bnMmbrPk = 16
            // obj.fcltyNum = 14111000790
            // obj.inqYYMM = 202307

            // let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            // console.log(encrypted.toString())
            // let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            // console.log(bs64)

            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        // 전자서명 조회 Params
        getSgnMgmtInf: function (obj){
            this.saveBtn = true

            http.post('fmly/selPrivacyAgrInfo', obj)
                .then((response) => ( this.sgnMgmtRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selPrivacyAgrInfo : ' + error))
        },
        sgnMgmtRst: function (res){
            this.sgnInf.isFile = res.result.isFile

            if(res.result.bnMmbrPk == '')
                this.sgnInf.bnMmbrPk = 0
            else
                this.sgnInf.bnMmbrPk = Number(res.result.bnMmbrPk)
            if(res.result.inqYYYY != '')
                this.sgnInf.inqYYYY = res.result.inqYYYY
            if(res.result.list.length > 0)
                this.tblList = res.result.list
            if(res.result.photoImg != null)
                this.sgnInf.photoImg = 'data:image/png;base64,' + res.result.photoImg

            this.saveBtn = false
        },

        // PDF 조회
        getRptInf: function (){
            let obj = {}
            obj.fcltyNum = this.$parent.$parent.sltFcltyNum
            obj.bnMmbrPk = this.sgnInf.bnMmbrPk
            obj.prtClcd = '1'
            obj.prtCd = this.sndConnInf.prtCd
            obj.inqYYYY = this.sgnInf.inqYYYY
            obj.list = []
            obj.list.push( { bzClcd: [this.sndConnInf.bzClcd], bzPk: [this.sndConnInf.bzClcdPk] } )
   
            http.post('fmly/reportInfo', obj)
                .then((response) => ( this.rptInfRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/reportInfo : ' + error))
        },
        rptInfRst: function (res){
            if(res.statusCode == '200'){
                if(res.prtClcd == '1')
                    this.$store.commit('setCmmPdfDstnt', 'imgPrview')
                else
                    this.$store.commit('setCmmPdfDstnt', 'imgView')
                this.$store.commit('setCmmPdfPth', res.filePth)
                this.$store.commit('setCmmPdfNm', res.fileNm)
                this.$store.commit('setCmmPdfOrgNm', res.orgFileNm)
                this.$store.commit('setCmmPdfTtl', this.sndConnInf.ttl)
                this.$store.commit('setCmmPrtMdl', true)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },

        // 전자서명 저장
        setDirtSgn: function (obj){
            this.saveBtn = true
  
            http.post('fmly/fmlyInsDirtSign', obj)
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyInsDirtSign : ' + error))
        },

        asyncRst: function (res){
            let sgnObj = Object.assign({}, this.sndConnInf)
            sgnObj.fcltyNum = this.$parent.$parent.sltFcltyNum
            this.getSgnMgmtInf(sgnObj)

            let obj = {}
            if(res.statusCode == '200')
                obj.clr = 'info'
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
            this.saveBtn = false
            this.clearInf()
        },

        preIndSgn: function () {
            this.saveBtn = true

            let oObj = Object.assign({}, this.sndConnInf)
            oObj.fcltyNum = this.$parent.$parent.sltFcltyNum
            oObj.sign = this.sign

            this.setDirtSgn(oObj)
        },

        // canvas Clear
        handleReset: function () {
            this.$refs.esign.reset()
        },

        // convert canvas to base64
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.preIndSgn()
            }).catch(err => {
                console.log(err)
            })
        },

        // get param 복호화
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)

                    this.onPayExpStt(JSON.parse(bytes.toString(this.$crypto.enc.Utf8)))
                }
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: '잘못된 접근 입니다.' })
            }
        },
        onPayExpStt: function (obj) {
            let tmp = Object.assign({}, obj)
            
            this.$parent.$parent.sltFcltyNum = tmp.fcltyNum
            this.sndConnInf.ttl = tmp.title
            this.sndConnInf.bzClcdPk = tmp.bzPk
            this.sndConnInf.eSigClcd = tmp.clcd
            this.sndConnInf.mmbrPk = tmp.mmbrPk
            this.sndConnInf.name = tmp.name
            this.sndConnInf.prtCd = tmp.prtCd
            this.sndConnInf.eSigNm = tmp.title
            this.sndConnInf.bzClcd = tmp.bzClcd
            this.$parent.$parent.appBarTtl = tmp.title

            let sgnObj = Object.assign({}, this.sndConnInf)
            sgnObj.fcltyNum = this.$parent.$parent.sltFcltyNum
        
            this.getSgnMgmtInf(sgnObj)
        },

        clearInf: function () {
            this.sgnInf.bnMmbrPk = 0
            this.sgnInf.bnfcrNm = ''
            this.sgnInf.celphn = ''
            this.sgnInf.fmName = ''
            this.sgnInf.rsdntBirth = ''
            this.sgnInf.isFile = ''
            this.sgnInf.photoImg = ''
            this.sgnInf.cfmYn = '2'
        },
    },

    data: () => ({
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        signWidth: 280,
        signHeight: 210,
        saveBtn: true,
        tblList: [],
        sndConnInf: { bzClcdPk: 0, eSigClcd: 0, eSigNm: '', mmbrPk: 0, name: '', prtCd: '', bzClcd: '', ttl:'' },
        sgnInf: { bnMmbrPk: 0, bnfcrNm: '', celphn: '', fmName: '', rsdntBirth: '', inqYYYY: '', isFile: '', photoImg: '', cfmYn: '2' },
    }),
};
</script>