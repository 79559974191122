<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">신체제재&nbsp;기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 pa-2" cols="12">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-bold">
                            {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                        </span>
                        <span class="fontDotEight font-weight-bold ml-1">
                            {{ $store.getters.getBnftcrdCtgObj.name }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div class="">
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center pa-1" cols="5">
                    <span class="fontDotEight font-weight-bold">제재기간</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="7">
                    <span class="fontDotEight">{{ $moment(recInfo.snctDtStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    <span class="mx-1 pb-1">
                        <v-icon size="14">mdi-tilde</v-icon>
                    </span>
                    <span class="fontDotEight">{{ $moment(recInfo.snctDtEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 pa-1" style="line-height: 18px;" cols="5">
                    <div class="txtAlgnMid">
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">수급자&nbsp;상태</span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">&#40;제재사유&#41;</span>
                        </div>
                    </div>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" style="line-height: 16px;" cols="7">
                    <span class="fontDotEight">{{ recInfo.snctRsn }}</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid pa-1" cols="5">
                    <span class="fontDotEight font-weight-bold">신체제재&nbsp;일</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="7">
                    <span class="fontDotEight">{{ $store.state.bnftcrdCtgMdl.bccObj.inqYMD }}</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-form ref="physcSnctnInp" lazy-validation>
                <v-row class="mr-2" no-gutters>
                    <v-col class="d-flex justify-center align-center nmlBfClr-grey001 pa-1" cols="5">
                        <span class="fontDotEight font-weight-bold">신체제재&nbsp;시간</span>
                    </v-col>
                    <v-col class="txtAlgnMid pa-1 tableBL" cols="7">
                        <span class="d-inline-block" style="width: 52px;">
                            <CmmTmField v-model="recInpInf.snctTmStrt" :maxTm="recInpInf.snctTmEnd" :required="true"></CmmTmField>
                        </span>
                        <span class="mx-1 pb-1">
                            <v-icon size="14">mdi-tilde</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 52px;">
                            <CmmTmField v-model="recInpInf.snctTmEnd" :minTm="recInpInf.snctTmStrt" :required="true"></CmmTmField>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mr-2"></v-divider>
                <v-row class="mr-2" no-gutters>
                    <v-col class="d-flex justify-center align-center nmlBfClr-grey001 pa-1" cols="5">
                        <span class="fontDotEight font-weight-bold">제재&nbsp;방법</span>
                    </v-col>
                    <v-col class="pa-1 tableBL" cols="7">
                        <v-text-field
                            class="cmmTmFld" height="24"
                            v-model="recInpInf.snctMthd "
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid pa-1" style="line-height: 18px;" cols="5">
                    <span class="fontDotEight font-weight-bold">제재자</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="7">
                    <span class="fontDotEight">{{ $store.state.bnftcrdCtgMdl.bccObj.name }}</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2 mt-3" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveInfBtn == false && saveLstBtn == false ? 'blue001 white--text' : '']"
                    :disabled="saveInfBtn == false && saveLstBtn == false ? false : true"
                    @click="prePhysSnct()"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
            <v-divider class="mr-2 mt-3"></v-divider>
            <v-row class="greyE02 mr-2" no-gutters>
                <v-col class="txtAlgnMid pa-1" cols="6">
                    <span class="fontDotEight font-weight-bold">제재일시</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="4">
                    <span class="fontDotEight font-weight-bold">제재자</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1 tableBL" cols="2">
                    <span class="fontDotEight font-weight-bold">처리</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <div v-if="recList.length > 0" style="min-height: 40px;">
                <v-row class="mr-2" v-for="(rec, i) in recList" :key="i" no-gutters>
                    <v-col class="tableBB py-1" style="line-height: 18px;" cols="6">
                        <div class="txtAlgnMid">
                            <span class="fontDotEight">{{ $moment(rec.snctDtStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="fontDotSeven">{{ rec.snctTmHhStrt }}</span>
                            <span class="fontDotEight" style="margin-right: 1px;">:</span>
                            <span class="fontDotSeven">{{ rec.snctTmMmStrt }}</span>
                            <span class="" style="margin: 0 2px;">
                                <v-icon size="12">mdi-tilde</v-icon>
                            </span>
                            <span class="fontDotSeven">{{ rec.snctTmHhEnd }}</span>
                            <span class="fontDotEight" style="margin-right: 1px;">:</span>
                            <span class="fontDotSeven">{{ rec.snctTmMmEnd }}</span>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL tableBB py-1" cols="4">
                        <span class="fontDotEight">{{ rec.wrtr }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL tableBB py-1" cols="2">
                        <span v-if="rec.wrtr == $store.getters.getUserNm">
                            <v-btn @click="rmPhysSnctDtl(rec.physSnctDtlPk)" x-small icon>
                                <v-icon size="20">mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row class="mr-2" no-gutters>
                    <v-col class="txtAlgnMid py-1" cols="12">
                        <span class="fontDotNine">※ 작성된 기록이 없습니다.</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </div>
        </div>
        <v-pagination
            class="cmmPgntComp mt-2 " :total-visible="5"
            v-model="page" :length="pageLnt"
            circle>
        </v-pagination>
        <div class="pb-3"></div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmTmField from '../../cmm/CmmTmField.vue';

export default {
    name: 'PhyscSnctnRcrdMdl',

    components: {
        CmmTmField,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getPhysSnctInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk,
                )

                this.getPhysSnctLst(this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk, 0, this.pageLmt)
            }
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getPhysSnctInf(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk,
                )
            }
        },
        'page': function () {
            let nnn = ( this.page - 1 ) * this.pageLmt
            this.getPhysSnctLst(this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk, nnn, this.pageLmt)
        },
    },
        
    methods: {
        // 신체제재 기록 정보 조회
        getPhysSnctInf: function (pk, ymd, physPk){
            this.saveInfBtn = true
            this.cleanData()

            http.post( 'empApp/authSelPhysSnctInfo', { bnMmbrPk: pk, inqYMD: ymd, physSnctPk: physPk } )
                .then((response) => ( this.physSnctRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelPhysSnctInfo : ' + error))
        },

        // 신체제재 기록 리스트 조회
        getPhysSnctLst: function (physPk, strt, lmt){
            this.saveLstBtn = true
            this.cleanData()

            http.post( 'empApp/authSelPhysSnctDtlList', { physSnctPk: physPk, rnStrt: strt, rnLmt: lmt } )
                .then((response) => ( this.physSnctLst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelPhysSnctDtlList : ' + error))
        },

        // 신체제재 기록 저장
        setPhysSnctDtl: function (obj){
            this.saveInfBtn = true
            this.saveLstBtn = true
            this.cleanData()

            http.post( 'empApp/authInsPhysSnctDtl', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsPhysSnctDtl : ' + error))
        },

        // 신체제재 기록 삭제
        rmPhysSnctDtl: function (physPk){
            this.saveInfBtn = true
            this.saveLstBtn = true
            this.cleanData()

            http.post( 'empApp/authDelPhysSnctDtl', { physSnctDtlPk: physPk, userNm: this.$store.getters.getUserNm } )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authDelPhysSnctDtl : ' + error))
        },

        physSnctRst: function (res){
            if(res.statusCode == '200'){
                // 조회된 데이터가 있으면 반영
                if(Object.keys(res.recInfo).length > 0){
                    this.recInfo = res.recInfo
                    this.recInpInf.snctMthd = this.recInfo.snctMthd
                }
             
                this.saveInfBtn = false
            }
        },

        physSnctLst: function (res){
            // 조회된 데이터가 있으면 반영
            if(res.statusCode == '200'){
                if(Object.keys(res.recInfo).length > 0){
                    if(res.recInfo.list?.length > 0)
                        this.recList = res.recInfo.list
                }

                this.recTotCnt = res.recInfo.rnTot
                this.pageLnt = Math.ceil(Number(this.recTotCnt) / this.pageLmt)

                this.saveInfBtn = false
                this.saveLstBtn = false
            }
        },
      
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getAllBnftSnctSttList(
                    this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )

                let nnn = ( this.page - 1 ) * this.pageLmt
                this.getPhysSnctLst(this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk, nnn, this.pageLmt)

            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        prePhysSnct: function () {
            this.saveInfBtn = true
            this.saveLstBtn = true

            let obj = {}
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.physSnctPk = this.$store.state.bnftcrdCtgMdl.bccObj.physSnctPk
            obj.physSnctDtlPk = 0
            obj.snctDtStrt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.snctDtEnd = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.snctTmHhStrt = this.recInpInf.snctTmStrt.split(':', 2)[0]
            obj.snctTmMmStrt = this.recInpInf.snctTmStrt.split(':', 2)[1]
            obj.snctTmHhEnd = this.recInpInf.snctTmEnd.split(':', 2)[0]
            obj.snctTmMmEnd = this.recInpInf.snctTmEnd.split(':', 2)[1]
            obj.snctMthd = this.recInpInf.snctMthd
            obj.wrtr = this.$store.getters.getUserNm		
            obj.userNm = this.$store.getters.getUserNm

            //console.log(obj)
            if(this.$refs.physcSnctnInp.validate())
                this.setPhysSnctDtl(obj)
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '필수항목을 입력해주세요.' })
                this.saveInfBtn = false
                this.saveLstBtn = false
            }


        },

        cleanData: function () {
            this.recList.splice(0)
            this.recInpInf.snctTmStrt = ''
            this.recInpInf.snctTmEnd = ''
            this.recInpInf.snctMthd = ''
            this.$refs.physcSnctnInp.resetValidation()
        },

        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },
    },

    data: () => ({
        saveInfBtn: true,   // 저장 버튼 활성여부
        saveLstBtn: true,   // 저장 버튼 활성여부
        page: 1,
        pageLnt: 1,
        pageLmt: 5,         // 출력될 리스트 갯수
        recTotCnt: 0,
        recList: [],
        recInfo: {},
        recInpInf: {
            snctTmStrt: '', snctTmHhStrt: '', snctTmMmStrt: '', snctTmEnd: '', snctTmHhEnd: '', snctTmMmEnd: '', snctMthd: '',
        },
    }),
};
</script>