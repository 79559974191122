<template>
    <span
        v-if="mode == 'circle'"
        class="d-inline-block rounded-circle greyE03"
        :style="{'padding': cirPd }">
        <v-img
            :src="imgSrc" :key="forceRender"
            position="center center" :width="icSize" :height="icSize">
        </v-img>
    </span>
    <span
        v-else-if="mode == 'logo'"
        class="d-inline-block d-flex align-center">
        <v-img
            :src="imgSrc" :key="forceRender"
            position="center center" :width="Number(icSize)*(2.5)" :height="icSize">
        </v-img>
    </span>
    <span
        v-else
        class="d-inline-block">
        <v-img
            :src="imgSrc" :key="forceRender"
            position="center center" :width="icSize" :height="icSize">
        </v-img>
    </span>
</template>

<script>

export default {
    name: 'CmmDrawIcon',

    components: {
       
    },

    props: {
        mode: { type: String, default: 'simple' },
        type: { type: String, default: '' },
        size: { type: String, default: '' },
    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            this.rflSrc()
            this.rflSize()
        })
    },

    computed: {

    },

    watch:{
        'type': function () {
            this.rflSrc()
        },
        'size': function () {
            this.rflSize()
        },
    },
        
    methods: {
        rflSrc: function () {
            this.imgSrc = '/icons/' + this.type + '.svg'
            this.forceRender += 1
        },
        rflSize: function () {
            let tmp = (this.size / 3)
            this.icSize = this.size
            this.cirPd = tmp + 'px ' + tmp + 'px'
        },
    },

    data: () => ({
        cirPd: '8px 8px',
        icSize: '30',
        imgSrc : '',
        forceRender: 0,
    }),
};
</script>