<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">체위변경&nbsp;기록</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="d-flex justify-center align-center greyE02 pa-2" cols="7">
                    <div class="">
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                            </span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="fontDotEight font-weight-bold">
                                {{ $store.getters.getBnftcrdCtgObj.name }}
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col class="tableBL py-1" cols="5">
                    <div>
                        <div class="d-flex justify-center tableBB pb-1">
                            <v-btn
                                color="blue004" style="padding: 0 10px 2px 6px !important"
                                :disabled="prvsBtn" @click="getPrvsChgPosObsrv()"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-text-box-search-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2">전일자료</span>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center pt-1">
                            <v-btn
                                color="green002" style="padding: 0 10px 2px 8px !important"
                                @click="onEmpMdl"
                                small>
                                <v-icon class="white--text" style="padding-top: 2px;" size="18">mdi-account-circle-outline</v-icon>
                                <span class="white--text font-weight-bold ml-2" style="padding-top: 1px;">{{ empInf.name }}</span>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="txtAlgnMid pa-1" cols="12">
                    <span class="fontDotNine">체위변경:&nbsp;&nbsp;</span>
                    <span class="fontDotNine">{{ posList.filter( v => v.itm1Val != '' && v.ofrTmMmStrt != '' ).length }}</span>
                    <span class="fontDotNine">&nbsp;회</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-divider class="mt-3 mr-2"></v-divider>
            <v-row class="greyE02 mr-2" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="4">
                    <span class="fontDotEight font-weight-bold">시간대</span>
                </v-col>
                <v-col class="txtAlgnMid py-2 tableBL" cols="5">
                    <span class="fontDotEight font-weight-bold">자세</span>
                </v-col>
                <v-col class="txtAlgnMid py-2 tableBL" cols="3">
                    <span class="fontDotEight font-weight-bold">작성자</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <v-form ref="posObsrv" lazy-validation>
            <v-row
                :class="['mr-2', pos.bnftOfrRec301Pk == 0 ? '' : 'green001']"
                v-for="(pos, i) in posList" :key="i"
                no-gutters>
                <v-col class="d-flex justify-center align-center tableBB py-1" cols="4">
                    <span class="fontDotEight">{{ pos.ofrTmHhStrt }}</span>
                    <span class="fontDotEight mx-1">:</span>
                    <span class="d-inline-block" style="width: 34px;">
                        <v-text-field
                            class="cmmTmFld" type="number" height="24"
                            v-model="pos.ofrTmMmStrt"
                            :rules="[minTypCfm(pos.ofrTmMmStrt)]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 tableBB tableBL" cols="5">
                    <v-select
                        v-model="pos.itm1Val"
                        class="sltFld" style="margin-top: 1px;" :items="vCd29"
                        item-text="valcdnm" item-value="valcd"
                        dense outlined hide-details>
                    </v-select>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBB tableBL pa-1" cols="3">
                    <span
                        v-if="pos.wrtrMmbrPk == empInf.mmbrPk"
                        :class="['rounded-xl px-2 py-1', pos.bnftOfrRec301Pk != 0 ? 'orange002 white--text' : '']"
                        style="height: 18px; line-height: 7px;">
                        <span class="fontDotSeven">{{ pos.wrtr }}</span>
                    </span>
                    <v-btn
                        v-else
                        color="orange002" style="padding: 0 10px 2px 8px !important"
                        @click="chgRowWrtr(i)"
                        x-small>
                        <span class="white--text font-weight-bold" style="padding-top: 1px;">{{ pos.wrtr }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <div class="stkMdlBottom py-4">
            <v-row class="pr-2" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveBtn ? '' : 'blue001 white--text']"
                    @click="preChgPosObsrv" :disabled="saveBtn"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'ChgPosObsrvRcrdMdl',

    components: {
       
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getChgPosObsrvInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }

            this.empInf.name = this.$store.getters.getUserNm
            this.empInf.mmbrPk = this.$store.getters.getUserPk
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
        getEmpSlctMdl: function () {
            return this.$store.getters.getSlctEmpMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getChgPosObsrvInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )
            }
        },
        getEmpSlctMdl: function (v) {
            if(v == false){
                this.empInf = this.$store.getters.getSlctEmpObj
                // if(this.posList.length > 0){
                //     this.posList.forEach(itm => {
                //         if(itm.bnftOfrRec301Pk == 0){
                //             itm.wrtr = this.empInf.name
                //             itm.wrtrMmbrPk = this.empInf.mmbrPk
                //         }
                //     });
                // }
            }
        },
    },
        
    methods: {
        // 체위변경 조회
        getChgPosObsrvInfo: function (pk, ymd, mode){
            this.saveBtn = true
            this.prvsBtn = true
            
            if(mode == 'now'){
                this.cleanData()
             
                http.post( 'empApp/authSelChgPosInfo', { bnMmbrPk: pk, inqYMD: ymd } )
                    .then((response) => ( this.chgPosObsrvRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelChgPosInfo : ' + error))
            }
            else if(mode == 'prvs'){
                let prvsDay = this.$moment(ymd, 'YYYYMMDD', true).subtract(1, 'days').format('YYYYMMDD')

                http.post( 'empApp/authSelChgPosInfo', { bnMmbrPk: pk, inqYMD: prvsDay } )
                    .then((response) => ( this.prvsChgPosObsrvRst(response.data) ))
                    .catch((error) => console.log('axios error /empApp/authSelChgPosInfo : ' + error))
            }

        },
        // 체위변경 저장
        setChgPosObsrvInfo: function (obj){
            http.post( 'empApp/authInsChgPosObsrv', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsChgPosObsrv : ' + error))
        },
        chgPosObsrvRst: function (res){
 
            if(res.statusCode == '200'){

                // 조회된 분류코드를 반영
                if(Object.keys(res.code).length > 0){
                    if(this.vCd29.length == 0)
                        this.vCd29 = res.code.vCd29
                }

                // 시간별로 기초 로우 데이터 작성
                for (let index = 0; index < 24; index++) {
                    let obj = Object.assign({}, this.dmyPosInf)
                    obj.ofrTmHhStrt = ('00' + index).slice(-2)
                    obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                    obj.wrtr = this.$store.getters.getUserNm
                    obj.wrtrMmbrPk = this.$store.getters.getUserPk
                    
                    this.posList.push(obj)
                }

                // 기존 저장된 데이터가 있으면 값을 반영
                if(res.recList?.length > 0){
                    this.posList.forEach(itm => {
                        let chc = res.recList.findIndex( v => v.ofrTmHhStrt == itm.ofrTmHhStrt )
                        if(chc > -1){
                            if(this.bnftOfrRecHisPk == 0) 
                                this.bnftOfrRecHisPk = res.recList[chc].bnftOfrRecHisPk

                            itm.bnftOfrRec301Pk = res.recList[chc].bnftOfrRec301Pk
                            itm.itm1Val = res.recList[chc].itm1Val
                            itm.ofrTmMmStrt = res.recList[chc].ofrTmMmStrt
                            itm.wrtr = res.recList[chc].wrtr
                            itm.wrtrMmbrPk = res.recList[chc].wrtrMmbrPk
                        }
                    });
                }
                else if(res.recList?.length == 0)
                    this.prvsBtn = false

                //console.log(this.posList)
                this.saveBtn = false
            }
        },

        prvsChgPosObsrvRst: function (res){
            if(res.statusCode == '200'){
                this.cleanData()

                // 시간별로 기초 로우 데이터 작성
                for (let index = 0; index < 24; index++) {
                    let obj = Object.assign({}, this.dmyPosInf)
                    obj.ofrTmHhStrt = ('00' + index).slice(-2)
                    obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                    obj.wrtr = this.$store.getters.getUserNm
                    obj.wrtrMmbrPk = this.$store.getters.getUserPk
                    
                    this.posList.push(obj)
                }

                // 기존 저장된 데이터가 있으면 값을 반영
                if(res.recList?.length > 0){
                    this.posList.forEach(itm => {
                        let chc = res.recList.findIndex( v => v.ofrTmHhStrt == itm.ofrTmHhStrt )
                        if(chc > -1){
                            itm.itm1Val = res.recList[chc].itm1Val
                            itm.ofrTmMmStrt = res.recList[chc].ofrTmMmStrt
                        }
                    });
                }

                this.saveBtn = false

            }
        },
      
        asyncRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'

                let hypDt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltHypDt
                this.$parent.$parent.$parent.$parent.getMobAllBnftPosSttList(
                    this.$moment(hypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )

                this.getChgPosObsrvInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                    'now'
                )

            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },

        preChgPosObsrv: function () {
            this.saveBtn = true
            this.prvsBtn = true

            // 공통 데이터 작성
            let obj = {}
            // obj.bnftOfrRecHisPk = this.bnftOfrRecHisPk
            obj.bnftOfrRecHisPk = null
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.userNm = this.$store.getters.getUserNm
            obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.wrtrMmbrPk = this.$store.getters.getUserPk
            obj.wrtr = this.$store.getters.getUserNm
            // obj.wrtrGr = []
            // obj.wrtrGrMmbrPk = []
            obj.clsfc1Cd = '301'
            obj.clsfc2Cd = '4'
            obj.clsfc3Cd = '1'
            obj.list = []

            // this.posList.filter( v => v.wrtr != this.$store.getters.getUserNm ).forEach(itm => {
            //     obj.list.push(itm)
            // });
            // this.posList.forEach(itm => {
            //     let gr = obj.wrtrGr.findIndex( v => v == itm.wrtr )
            //     if(gr == -1){
            //         obj.wrtrGr.push(itm.wrtr)
            //         obj.wrtrGrMmbrPk.push(itm.wrtrMmbrPk)
            //     }
            // });
            
            // 시간대 정보 작성
            let chc = this.posList.filter( v => v.itm1Val != '' && v.ofrTmMmStrt != '' )
            chc.forEach(itm => {
                // itm.bnftOfrRecHisPk = this.bnftOfrRecHisPk
                itm.bnftOfrRecHisPk = null

                if(this.minTypCfm(itm.ofrTmMmStrt) && itm.itm1Val != '')
                    obj.list.push(itm)
            });

            console.log(obj)
            this.setChgPosObsrvInfo(obj)

        },

        // 분(0~59) 형식 검사
        minTypCfm: function (num) {
            if(/^([0-5][0-9])$/.test(num))
                return true
            else
                return false
        },

        // 전일자료 조회
        getPrvsChgPosObsrv: function () {
            this.getChgPosObsrvInfo(
                this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD'),
                'prvs'
            )
        },

        cleanData: function () {
            this.posList.splice(0)
            this.bnftOfrRecHisPk = 0
        },
      
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },

        chgRowWrtr: function (idx) {
            if(this.posList.length > idx){
                this.posList[idx].wrtr = this.empInf.name
                this.posList[idx].wrtrMmbrPk = this.empInf.mmbrPk
            }
        },

        onEmpMdl: function () {
            this.$store.commit('setSlctEmpMdl', true)
            this.$store.commit('setSlctEmpDt', this.$store.getters.getBnftcrdCtgObj.inqYMD)
        },
    },

    data: () => ({
        prvsBtn: true,      // 전일자료 버튼 활성여부
        saveBtn: true,      // 저장 버튼 활성여부
        bnftOfrRecHisPk: 0,
        empInf: { name: '', mmbrPk: 0 },
        vCd29: [],
        posList: [],
        dmyPosInf: {
            bnftOfrRecHisPk: 0, bnftOfrRec301Pk: 0, clsfc1Cd: '301', clsfc2Cd: '4', clsfc3Cd: '1',
            itm1Cd: '29', itm1Val: '', ofrTmHhStrt: '', ofrTmMmStrt: '', recDt: '', wrtr: '', wrtrMmbrPk: 0,
        },
    }),
};
</script>