<template>
    <v-sheet class="ma-0 pl-4 pr-2" rounded="">
        <div class="stkMdlTop pt-4">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">집중배설&nbsp;관찰&nbsp;기록&nbsp;작성</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2 mt-4"></v-divider>
            <v-row class="pr-2" no-gutters>
                <v-col class="greyE02 pa-2" cols="12">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-bold">
                            {{ $store.getters.getBnftcrdCtgObj.inqYMD }}
                        </span>
                        <span class="fontDotEight font-weight-bold ml-1">
                            {{ $store.getters.getBnftcrdCtgObj.name }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div class="pb-3">
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid py-1" cols="4">
                    <span class="font-weight-bold fontDotEight">시간</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL px-1" cols="8">
                    <span class="fontDotEight">{{ recMdfInf.ofrTmHhStrt }}</span>
                    <span class="fontDotEight mx-1">:</span>
                    <span class="d-inline-block" style="width: 33px;">
                        <v-text-field
                            class="cmmTmFld" height="24"
                            v-model="recMdfInf.ofrTmMmStrt" @focus="recMdfInf.ofrTmMmStrt = ''"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid py-1" cols="4">
                    <span class="font-weight-bold fontDotEight">섭취량</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL px-1" cols="8">
                    <v-text-field
                        class="cmmTmFld" height="24"
                        v-model="recMdfInf.itm6Val"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center py-1" cols="4">
                    <span class="font-weight-bold fontDotEight">기저귀&nbsp;착용</span>
                </v-col>
                <v-col class="tableBL" cols="8">
                    <div class="tableBB">
                        <span class="nmlBfClr-grey001 txtAlgnMid d-inline-block tableBR py-1" style="width: 70px;">
                            <span class="font-weight-bold fontDotEight">대변</span>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 68px;">
                            <v-select
                                v-model="recMdfInf.itm1Val"
                                class="sltFld" style="margin-top: 1px;" :items="$parent.$parent.$parent.$parent.vCd68"
                                item-text="valcdnm" item-value="valcd"
                                dense outlined hide-details>
                            </v-select>
                        </span>
                    </div>
                    <div class="">
                        <span class="nmlBfClr-grey001 txtAlgnMid d-inline-block tableBR py-1" style="width: 70px;">
                            <span class="font-weight-bold fontDotEight">소변</span>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 68px;">
                            <v-select
                                v-model="recMdfInf.itm2Val"
                                class="sltFld" style="margin-top: 1px;" :items="$parent.$parent.$parent.$parent.vCd68"
                                item-text="valcdnm" item-value="valcd"
                                dense outlined hide-details>
                            </v-select>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center py-1" cols="4">
                    <span class="font-weight-bold fontDotEight">스스로&nbsp;이동</span>
                </v-col>
                <v-col class="tableBL" cols="8">
                    <div class="tableBB">
                        <span class="nmlBfClr-grey001 txtAlgnMid d-inline-block tableBR py-1" style="width: 70px;">
                            <span class="font-weight-bold fontDotEight">대변</span>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 68px;">
                            <v-select
                                v-model="recMdfInf.itm3Val"
                                class="sltFld" style="margin-top: 1px;" :items="$parent.$parent.$parent.$parent.vCd68"
                                item-text="valcdnm" item-value="valcd"
                                dense outlined hide-details>
                            </v-select>
                        </span>
                    </div>
                    <div class="">
                        <span class="nmlBfClr-grey001 txtAlgnMid d-inline-block tableBR py-1" style="width: 70px;">
                            <span class="font-weight-bold fontDotEight">소변</span>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 68px;">
                            <v-select
                                v-model="recMdfInf.itm4Val"
                                class="sltFld" style="margin-top: 1px;" :items="$parent.$parent.$parent.$parent.vCd68"
                                item-text="valcdnm" item-value="valcd"
                                dense outlined hide-details>
                            </v-select>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 txtAlgnMid py-1" cols="7">
                    <span class="font-weight-bold fontDotEight">장루&#40;요루&#41;&nbsp;도뇨관&#40;㎖&#41;</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL px-1" cols="5">
                    <v-text-field
                        class="cmmTmFld" height="24"
                        v-model="recMdfInf.itm5Val"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center py-1" cols="5">
                    <span class="font-weight-bold fontDotEight">기저귀&nbsp;교환</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL pa-1" cols="7">
                    <v-btn
                        :class="['mr-1', recMdfInf.itm7Val == '1' ? 'orange001 white--text' : '']"
                        min-width="72" max-width="92" :ripple="false"
                        style="padding: 0px 6px 1px 6px; margin-bottom: 1px; margin-top: 3px;"
                        @click="switchItm('7')"
                        small outlined>
                        <span class="fontDotEight">기저귀&nbsp;교환</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
            <v-row class="mr-2" no-gutters>
                <v-col class="nmlBfClr-grey001 d-flex justify-center align-center py-1" cols="5">
                    <span class="font-weight-bold fontDotEight">옷&nbsp;교환</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL pa-1" cols="7">
                    <v-btn
                        :class="['mr-1', recMdfInf.itm8Val == '1' ? 'orange001 white--text' : '']"
                        min-width="72" max-width="92" :ripple="false"
                        style="padding: 0px 6px 1px 6px; margin-bottom: 1px; margin-top: 3px;"
                        @click="switchItm('8')"
                        small outlined>
                        <span class="fontDotEight">옷&nbsp;교환</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mr-2"></v-divider>
        </div>
        <div class="stkMdlBottom py-4">
            <v-row class="pr-2" no-gutters>
                <v-spacer></v-spacer>
                <v-btn
                    :class="[saveBtn ? '' : 'blue001 white--text']"
                    @click="preBnftExrtStt" :disabled="saveBtn"
                    outlined rounded small>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="mx-1" style="padding-bottom: 1px;">저장</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'CnexObsrvDetailMdl',

    components: {

    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.recMdfInf = this.$parent.$parent.$parent.$parent.recList[this.$parent.$parent.$parent.$parent.sltRecIdx]

            if(Object.keys(this.recMdfInf).length > 0)
                this.saveBtn = false
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        // 집중배설 관찰 기록 저장
        setBnftExrtStt: function (obj){
            http.post( 'empApp/authInsBnftExrtStt', obj )
                .then((response) => ( this.asyncRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authInsBnftExrtStt : ' + error))
        },
        
        asyncRst: function (res){
            this.$parent.$parent.$parent.$parent.asyncRst(res)
            this.onClose()
        },

        preBnftExrtStt: function () {
            let obj = {}                   
            obj.bnMmbrPk = this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk
            obj.userNm = this.$store.getters.getUserNm
            obj.bnftOfrRecHisPk = null
            //obj.bnftOfrRecHisPk = this.$parent.$parent.$parent.$parent.recInf.bnftOfrRecHisPk
            obj.recDt = this.$moment(this.$store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
            obj.clsfc1Cd = this.recMdfInf.clsfc1Cd
            obj.clsfc2Cd = this.recMdfInf.clsfc2Cd
            obj.clsfc3Cd = this.recMdfInf.clsfc3Cd
            obj.list = []

            let recObj = Object.assign({}, this.recMdfInf)
            recObj.wrtr = this.$store.getters.getUserNm
            recObj.wrtrMmbrPk = this.$store.getters.getUserPk
            obj.list.push(recObj)

            //console.log(obj)
            this.setBnftExrtStt(obj)
        },

       switchItm: function (val) {
            switch (val) {
                case '7':
                    if(this.recMdfInf.itm7Val == '1')
                        this.recMdfInf.itm7Val = '2'
                    else
                        this.recMdfInf.itm7Val = '1'
                    break;
                case '8':
                    if(this.recMdfInf.itm8Val == '1')
                        this.recMdfInf.itm8Val = '2'
                    else
                        this.recMdfInf.itm8Val = '1'
                    break;
                default:
                    break;
            }
       },

        cleanData: function () {
          
        },

        onClose: function () {
            this.$parent.$parent.$parent.$parent.mdlCntl = false
        },
    },

    data: () => ({
        saveBtn: true,      // 저장 버튼 활성여부
        recMdfInf: {

        },
    }),
};
</script>