import { render, staticRenderFns } from "./VtlRecCard.vue?vue&type=template&id=7c94a01c&"
import script from "./VtlRecCard.vue?vue&type=script&lang=js&"
export * from "./VtlRecCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports