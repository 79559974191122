import axios from 'axios';

let prtcl = 'https'
let prt = 443
let dmn = 'apis.data.go.kr/'

const dataApis = axios.create({
    baseURL: prtcl + '://' + dmn + 'B550928',
    timeout: 0,
    withCredentials: false,

    proxy: {
        protocol: prtcl,
        host: prtcl + '://' + dmn,
        port: prt,
        changeOrigin: true,
    },
})

dataApis.interceptors.request.use(
    (config) => {

        config.params.serviceKey = 'c4l7LqGoCdMJWf68u5+TOVX3em1/3kPzCsdj+FQU/s3oChSwOUkhXanHe/f244s/w+8JrLyIXXAvzA3q7BYzgQ=='

        return config
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error)
    }
);

export{
    dataApis
}