import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store.js';

import NotFound from '../components/layout/NotFound.vue' 

import SprtdUser from '../components/lgn/SprtdUser.vue'
import NtvLgn from '../components/lgn/NtvLgn.vue'
import UnitTest from '../components/lgn/UnitTest.vue'

import BnfcrTpl from '../components/layout/BnfcrTpl.vue'
import BnfrcMgmt from '../components/bnfcr/BnfrcMgmt.vue'
import PyrlSrvcMgmt from '../components/bnfcr/PyrlSrvcMgmt.vue'
import NewNewsApp from '../components/bnfcr/NewNewsApp.vue'
import BnfcrInfoApp from '../components/bnfcr/BnfcrInfoApp.vue'
import SiteMapApp from '../components/bnfcr/SiteMapApp.vue'
import FmltLtrApp from '../components/bnfcr/svcapp/FmltLtrApp.vue'
import PrgmSchdlApp from '../components/bnfcr/svcapp/PrgmSchdlApp.vue'
import MealMenuApp from '../components/bnfcr/svcapp/MealMenuApp.vue'
import HealthVtlApp from '../components/bnfcr/svcapp/HealthVtlApp.vue'
import PhyThrRecApp from '../components/bnfcr/svcapp/PhyThrRecApp.vue'
import BnftSrvSttmApp from '../components/bnfcr/svcapp/BnftSrvSttmApp.vue'
import BnftSrvRcrdApp from '../components/bnfcr/svcapp/BnftSrvRcrdApp.vue'
import DpstHstryApp from '../components/bnfcr/svcapp/DpstHstryApp.vue'

import EmpTpl from '../components/layout/EmpTpl.vue'
import EmpMgmt from '../components/emp/EmpMgmt.vue'
import BnftrcrdMgmt from '../components/emp/BnftrcrdMgmt.vue'

import ExtrTpl from '../components/layout/ExtrTpl.vue'
import OutPayExpStt from '../components/extr/OutPayExpStt.vue'
import OutPyrlSrvcMgmt from '../components/extr/OutPyrlSrvcMgmt.vue'
import OutEmpWage from '../components/extr/OutEmpWage.vue'
import OutRptMgmt from '../components/extr/OutRptMgmt.vue'
import OutSignMgmt from '../components/extr/OutSignMgmt.vue'
import OutDcmntBepr from '../components/extr/OutDcmntBepr.vue'
import OutCnsnFlmn from '../components/extr/OutCnsnFlmn.vue'
import OutPrsnlStts from '../components/extr/OutPrsnlStts.vue'
import OutUnpdClms from '../components/extr/OutUnpdClms.vue'
import OutBlnftAmntAnnc from '../components/extr/OutBlnftAmntAnnc.vue'
import OutPrivacyAgr from '../components/extr/OutPrivacyAgr.vue'

Vue.use(VueRouter);

//페이지 이동 권한 검사
const prmsnChcks = () => (to, from, next) =>  {
    let tkn = store.getters.getToken
 
    if(tkn != '')
        next()
    else
        next({ name: 'SprtdUser' })
}

// Vue Router를 관리하는 객체
export const router = new VueRouter({
    mode: 'history',

    scrollBehavior() {
        return { x: 0, y: 0 }
    },

    routes: [
        {
            path: "*",
            component: NotFound,
        },
        {
            // path: url 경로, component: 표시될 컴포넌트
            path: '/',
            name: 'SprtdUser',
            component: SprtdUser,
            props: true,
        },
        {
            // 네이티브 앱 접근 통로
            path: '/appLgn',
            name: 'NtvLgn',
            component: NtvLgn,
            props: true,
        },
        {
            // 단위테스트 빈 페이지
            path: '/ncloud',
            name: 'UnitTest',
            component: UnitTest,
            props: true,
        },
        {
            path: "/bnfcrTpl",
            name: 'BnfcrTpl',
            beforeEnter: prmsnChcks(),
            props: true,
            component: BnfcrTpl,
            children: [
                {
                    // 수급자 메인
                    path: '/bnfcrTpl/bnfrcMgmt',
                    name: 'BnfrcMgmt',
                    component: BnfrcMgmt,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 수급자 새소식
                    path: '/bnfcrTpl/news',
                    name: 'NewNewsApp',
                    component: NewNewsApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 수급자 정보
                    path: '/bnfcrTpl/bnfcrInf',
                    name: 'BnfcrInfoApp',
                    component: BnfcrInfoApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 수급자 더보기
                    path: '/bnfcrTpl/siteMap',
                    name: 'SiteMapApp',
                    component: SiteMapApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 가정통신문
                    path: '/bnfcrTpl/fmltLtr',
                    name: 'FmltLtrApp',
                    component: FmltLtrApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 프로그램 일정
                    path: '/bnfcrTpl/prgmSchdl',
                    name: 'PrgmSchdlApp',
                    component: PrgmSchdlApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 기관 식단표
                    path: '/bnfcrTpl/mealMenu',
                    name: 'MealMenuApp',
                    component: MealMenuApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 수급자 건강정보
                    path: '/bnfcrTpl/healthVtl',
                    name: 'HealthVtlApp',
                    component: HealthVtlApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 물리(작업) 치료내역
                    path: '/bnfcrTpl/phyThrRec',
                    name: 'PhyThrRecApp',
                    component: PhyThrRecApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 급여비용 명세서
                    path: '/bnfcrTpl/bnftSrvSttm',
                    name: 'BnftSrvSttmApp',
                    component: BnftSrvSttmApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 급여제공 기록지
                    path: '/bnfcrTpl/bnftSrvRcrd',
                    name: 'BnftSrvRcrdApp',
                    component: BnftSrvRcrdApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 입금내역
                    path: '/bnfcrTpl/dpstHstry',
                    name: 'DpstHstryApp',
                    component: DpstHstryApp,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 노인장기요양급여 제공 서비스
                    path: '/bnfcrTpl/pyrlSrvcMgmt',
                    name: 'PyrlSrvcMgmt',
                    beforeEnter: prmsnChcks(),
                    component: PyrlSrvcMgmt,
                    props: true,
                },
            ]
        },
        {
            path: "/empTpl",
            name: 'EmpTpl',
            beforeEnter: prmsnChcks(),
            props: true,
            component: EmpTpl,
            children: [
                {
                    // 직원 메인
                    path: '/empTpl/empMgmt',
                    name: 'EmpMgmt',
                    component: EmpMgmt,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
                {
                    // 요양 급여 제공기록
                    path: '/empTpl/bnftrcrdMgmt',
                    name: 'BnftrcrdMgmt',
                    component: BnftrcrdMgmt,
                    beforeEnter: prmsnChcks(),
                    props: true,
                },
            ]
        },
        {
            path: "/out",
            name: 'ExtrTpl',
            props: true,
            component: ExtrTpl,
            children: [
                {
                    // 외부 급여비용 명세서
                    path: '/out/payExpStt',
                    name: 'OutPayExpStt',
                    component: OutPayExpStt,
                    props: true,
                },
                {
                    // 외부 가정통신문
                    path: '/out/pyrlSrvcMgmt',
                    name: 'OutPyrlSrvcMgmt',
                    component: OutPyrlSrvcMgmt,
                    props: true,
                },
                {
                    // 외부 직원 급여 명세서
                    path: '/out/empWage',
                    name: 'OutEmpWage',
                    component: OutEmpWage,
                    props: true,
                },
                {
                    // pdf 조회
                    path: '/out/rptMgmt',
                    name: 'OutRptMgmt',
                    component: OutRptMgmt,
                    props: true,
                },
                {
                    // 외부 사인 등록
                    path: '/out/signMgmt',
                    name: 'OutSignMgmt',
                    component: OutSignMgmt,
                    props: true,
                },
                {
                    // 외부 표준약관 서명
                    path: '/out/dcmntAgr',
                    name: 'OutDcmntBepr',
                    component: OutDcmntBepr,
                    props: true,
                },
                {
                    // 외부 촬영동의 서명
                    path: '/out/cnsnFlmn',
                    name: 'OutCnsnFlmn',
                    component: OutCnsnFlmn,
                    props: true,
                },
                {
                    // 외부 수급자 개인현황
                    path: '/out/prsnlStts',
                    name: 'OutPrsnlStts',
                    component: OutPrsnlStts,
                    props: true,
                },
                {
                    // 외부 미납청구안내
                    path: '/out/unpdClms',
                    name: 'OutUnpdClms',
                    component: OutUnpdClms,
                    props: true,
                },
                {
                    // 외부 장기요양보험 수가 안내
                    path: '/out/blnftAmntAnnc',
                    name: 'OutBlnftAmntAnnc',
                    component: OutBlnftAmntAnnc,
                    props: true,
                },
                {
                    // 외부 개인정보 이용동의
                    path: '/out/privacyAgr',
                    name: 'OutPrivacyAgr',
                    component: OutPrivacyAgr,
                    props: true,
                },
            ]
        }
    ]
})