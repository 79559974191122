<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="11">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        {{ $moment(aplyYyMm, 'YYYYMM', true).format('YYYY년 MM월') }}&nbsp;&nbsp;가정통신문
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="p1-2" cols="12">
                    <div class="d-inline-block rounded decoBox py-1 px-2">
                        <span class="tiptapEditor" v-html="fmlyInfo.cntnt"></span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-7" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">생활상황&nbsp;및&nbsp;건강상태</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="p1-2" cols="12">
                    <div class="d-inline-block rounded decoBox py-1 px-2">
                        <span class="tiptapEditor" v-html="fmlyInfo.cntnt2"></span>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'FmlyLeter',

    components: {

    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.aplyYyMm != '' && this.bnMmbrPk == 0)
                this.getFamlLtr(this.aplyYyMm)
            else if(this.bnMmbrPk != 0)
                this.getOutFamlLtr(this.fcltyNum, this.bnMmbrPk, this.aplyYyMm)
        })
    },

    computed: {
       
    },

    watch:{
        'aplyYyMm': function () {
            if(this.aplyYyMm != '' && this.bnMmbrPk == 0)
                this.getFamlLtr(this.aplyYyMm)
            else if(this.bnMmbrPk != 0)
                this.getOutFamlLtr(this.fcltyNum, this.bnMmbrPk, this.aplyYyMm)
        },
        'bnMmbrPk': function (){
            if(this.bnMmbrPk != 0)
                this.getOutFamlLtr(this.fcltyNum, this.bnMmbrPk, this.aplyYyMm)
        },
    },
        
    methods: {
        //가정통신문 조회 TOKEN
        getFamlLtr: function (yymm){
            http.post('fmly/selFamlLtr', { inqYYMM: yymm })
                .then((response) => ( this.famlLtrRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selFamlLtr : ' + error))
        },
        //가정통신문 조회 Params
        getOutFamlLtr: function (code, pk, yymm){
            http.post('fmly/selFamlLtr', { fcltyNum: code, bnMmbrPk: pk, inqYYMM: yymm, })
                .then((response) => ( this.famlLtrRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selFamlLtr : ' + error))
        },
        famlLtrRst: function (res){
            this.fmlyInfo.cntnt = ''
            this.fmlyInfo.cntnt2 = ''

            if(res.statusCode == '200'){
                res.resList.forEach(ll => {
                    this.fmlyInfo.cntnt += ll.cntnt
                });
                if(res.resObj != null)
                    this.fmlyInfo.cntnt2 = res.resObj.cntnt
            }
        },
    },

    data: () => ({
        fmlyInfo: { cntnt: '', cntnt2: '' }
    }),
};
</script>