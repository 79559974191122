<template>
    <v-sheet class="ma-0" rounded="md" style="position: relative;">
        <div 
            v-if="vsbYn"
            class="d-inline-block d-flex justify-center align-center"
            style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 4;
                opacity: 0.7; background-color: #666;">
                <div>
                    <div class="mb-2" style="text-align: center; ">
                        <v-icon class="white--text" size="120">mdi-calendar-alert-outline</v-icon>
                    </div>
                    <div>
                        <span class="white--text font-weight-bold" style="font-size: 2.4rem;">로딩중...</span>
                    </div>
                </div>
        </div>
        <CmmYYMM @sltHypDt="getCompDt"></CmmYYMM>
        <div
            class="d-inline-block overflow-y-auto elevation-1 mt-3"
            :style="{ width: '100%', 'max-height': clndrHght+'px' }">
            <v-calendar
                :class="['cmmClndr',]"
                color="" locale="ko" event-text-color="black"
                v-model="sltDt" :now="nowDt" :type="calendarObj.type"
                :events="clndrList" :event-height="60" :event-more="false" :event-ripple="false"
                @click:event="dayClck">
                <template #day-label="{ date, day }" >
                    <div
                        :class="[
                            'd-flex justify-center align-center tableBB',
                            $moment(date, 'YYYY-MM-DD').format('YYYYMMDD') == $moment(nowDt, 'YYYY-MM-DD').format('YYYYMMDD') ? 'blue105 white--text' : ''
                            ]"
                        style="padding-top: 3px; min-height: 24px;">
                        <!-- 달력 날짜 시작 -->
                        <span
                            v-if="$moment(sltDt, 'YYYY-MM-DD').format('YYYYMM') == $moment(date, 'YYYY-MM-DD').format('YYYYMM')"
                            class="fontDotSeven">
                            {{ day }}
                        </span>
                        <!-- 달력 날짜 끝 -->
                    </div>
                </template>
                <template #event="{ event }">
                    <v-row class="pa-0 justify-center align-center" style="line-height: 1.5;" no-gutters>
                        <span class="overflow-text-hidden" :style="{ color: event.color }">{{ event.wrkTypNm }}</span>
                    </v-row>
                </template>
            </v-calendar>
        </div>
    </v-sheet>
</template>

<script>
import CmmYYMM from './CmmYYMM_kr.vue';

export default {
    name: 'CmmClndr',
    
    components: {
        CmmYYMM,
    },

    props : {
        vsbYn: { type: Boolean, default: false },                   // 달력 활성화 여부
        clndrHght: { type: String, default: '700' },                // 달력 최대 높이
        clndrList: { type: Array, default: () => { return [] } },   // 달력에 표시될 내용(events)
    },

    created: function(){
        this.nowDt = this.$moment().format('YYYY-MM-DD')
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.sltDt = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
 
    },  

    watch: {

    },

    methods: {
        // 달력 클릭 이벤트
        dayClck: function (v) {
            this.$emit('dayClck', v)
        },
        getCompDt: function (dt) {
            this.sltDt = this.$moment(dt, 'YYYY-MM').format('YYYY-MM-DD')
            this.$emit('chgDtAct', this.sltDt)
        },
    },

    data: () => ({
        sltDt: '',
        nowDt: '',
        sltList: [],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
    }),
};
</script>

<style>
    .cmmClndr .v-calendar-weekly__head div {
        font-weight: 500; padding: 6px 0px;
        margin-right: initial !important; border-bottom: #e0e0e0 1px solid;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label { cursor: initial; }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label > div {
        text-align: initial !important; padding: 0px 8px 2px 8px; font-size: 0.75rem;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day { min-height: 49px; margin-right: 0; }
    .cmmClndr .v-calendar-weekly__head div:first-child { color: red !important; }
    .cmmClndr .v-calendar-weekly__head div:last-child { color: #003b9e !important; }
    .cmmClndr .v-calendar-weekly__head { 
        position: sticky; top: 0px; left: 0; width: 100%; background-color: white; z-index: 3;
        border-top: #e0e0e0 1px solid; margin-top: -1px; padding-bottom: 1px;
        border-collapse: separate !important; border-spacing: 0px !important;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label {
        margin: initial; margin-bottom: 2px;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-event{
        height: 20px !important; padding: 0 2px; width: 88% !important; margin-left: 3px;
        border: 1px solid; background-color: white !important;
    }
</style>