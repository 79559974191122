<template>
     <v-sheet class="" width="100%">
        <v-app-bar color="" app dense>
            <v-app-bar-nav-icon @click="drw = !drw"></v-app-bar-nav-icon>
            <v-toolbar-title class="pl-3">{{ appBarTtl }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="onNtc" icon>
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn>
                </template>
            </v-menu>
            <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="onNtcMdl" icon>
                        <v-icon>mdi-account-circle-outline</v-icon>
                    </v-btn>
                </template>
            </v-menu>
        </v-app-bar>

        <v-navigation-drawer
            class="rounded-lg rounded-l-0 elevation-0"
            v-model="drw"
            disable-resize-watcher left app>
            <v-row class="py-5 px-3 orange001" no-gutters>

                <v-col class="pl-2" cols="9">
                    <v-row class="pa-0" no-gutters>
                        <v-col cols="12">
                            <span class="white--text font-weight-medium fontOneToOne">
                                {{ $store.state.sessionInfo.fcltyClnm }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col cols="12">
                            <span class="white--text font-weight-bold fontOneToThree mr-1">
                                {{ $store.state.sessionInfo.name }}
                            </span>
                            <span class="white--text font-weight-medium fontOneToOne">
                                &#40;{{ $store.state.sessionInfo.jobNm }}&#41;
                            </span>
                        </v-col>
                    </v-row>
                    <!-- <span class="font-weight-bold white--text fontOneToFive">해리지앤디</span> -->
                </v-col>
                <v-col class="d-flex align-center" cols="3">
                    <v-spacer></v-spacer>
                    <span class="d-inline-block" style="width: 36px;">
                        <v-btn class="pt-1" color="white" @click="drw = !drw" icon>
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-col>

            </v-row>
            <v-divider></v-divider>
            <v-list class="pa-0">
                <v-list-item-group
                    v-model="vLst" color="blue101">
                    <v-list-item
                        v-for="(item, i) in drwList" :key="i" @click="drwAct(item.action)" link>
                        <v-list-item-icon>
                            <v-icon large>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                class="font-weight-medium"
                                style="font-size: 1.3rem !important;">
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <template v-slot:append>
                <v-row class="py-4 orange001" no-gutters>
                    <v-col cols="3">
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="7">
                        <v-btn color="white" @click="cnfLogout" outlined>
                            <div class="d-flex align-center">
                                <v-icon>mdi-logout</v-icon>
                                <span class="fontOneToOne font-weight-bold ml-2">로그아웃</span>
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>

        <router-view></router-view>

        <CmmLvnRmPickMdl></CmmLvnRmPickMdl>
        <CmmEmpSlctMdl></CmmEmpSlctMdl>
     </v-sheet>
</template>

<script>
import { shutDownApp } from '@/assets/js/ntvBridge';

import CmmLvnRmPickMdl from '../cmm/CmmLvnRmPickMdl';
import CmmEmpSlctMdl from '../cmm/CmmEmpSlctMdl';

export default {
    name: 'EmpTpl',

    components: {
        CmmLvnRmPickMdl,
        CmmEmpSlctMdl,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },

    methods: {
        onNtc: function (){
            let obj = {}
            obj.eDocClcd = '201'
            this.$store.commit('setSignAgrObj', obj)
            this.$store.commit('setSignAgrMdl', true)
        },
        onNtcMdl: function (){
            let obj = {}
            obj.eDocClcd = '8'
            obj.msgTtl = '직원 사인'
            obj.bzPk = ''
            obj.bzClcd = []

            this.$store.commit('setSignAgrObj', obj)
            this.$store.commit('setSignAgrMdl', true)
        },
        cnfLogout: function (){
            this.$store.commit('setToken', '')
            this.$store.commit('setSctKey', '')
            this.$store.commit('setUserNm', '')
            this.$store.commit('setUserPk', '')
            this.$store.commit('setJobNm', '')
            this.$store.commit('setFltyClnm', '')

            this.$router.push({ name: 'SprtdUser' })
            shutDownApp()
        },
        drwAct: function (nm){
            if(nm != '' && nm != this.$route.name)
                this.$router.push({ name: nm })
        },
    },

    data: () => ({
        appBarTtl: '',
        drw: false,
        vLst: 0,
        drwList: [
            {
                icon: 'mdi-account-outline',
                title: '내 정보',
                action: 'EmpMgmt',
            },
            {
                icon: 'mdi-text-box-check-outline',
                title: '요양급여제공',
                //action: '',
                action: 'BnftrcrdMgmt',
            },
        ],
    }),
};
</script>