<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-col class="" cols="12">
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-spacer></v-spacer>
                    <span class="fontDotEight">지급일:&nbsp;</span>
                    <span class="fontDotEight">
                        {{ $moment(wgeInfo.pymDt, 'YYYYMMDD', true).format('YYYY.MM.DD') }}
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-2 wgeBox" no-gutters>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBB tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">성명</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tblWgeBB tblWgeBR" cols="4">
                    <span class="fontDotEight">{{ wgeInfo.name }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBB tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">입사일</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid tblWgeBB" cols="4">
                    <span class="fontDotEight">{{ $moment(wgeInfo.entDt, 'YYYYMMDD', true).format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBB tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">담당직종</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBB tblWgeBR" cols="4">
                    <span class="fontDotEight">{{ wgeInfo.jobNm }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBB tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">생년월일</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBB" cols="4">
                    <span class="fontDotEight">{{ $moment(wgeInfo.rsdntBirth, 'YYYYMMDD', true).format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">근무기간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center" cols="10">
                    <span class="fontDotEight">{{ $moment(wgeInfo.wrkDtStrt, 'YYYYMMDD', true).format('YYYY.MM.DD') }}</span>
                    <span class="mx-2" style="padding-bottom: 2px;">
                        <v-icon size="12">mdi-tilde</v-icon>
                    </span>
                    <span class="fontDotEight">{{ $moment(wgeInfo.wrkDtEnd, 'YYYYMMDD', true).format('YYYY.MM.DD') }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">급여계좌</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-2 wgeBox" no-gutters>
                <v-col class="py-1 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">은행</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBR" cols="4">
                    <span class="fontDotEight">{{ wgeInfo.bnkNm }}</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR" cols="2">
                    <span class="fontDotEight font-weight-medium">계좌번호</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center" cols="4">
                    <span class="fontDotEight">{{ wgeInfo.bnkAcct }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">급여내역</span>
                </v-col>
            </v-row>
            <div class="wgeBox mt-2">
                <v-row class="pa-0" no-gutters>
                    <v-col class="py-1 txtAlgnMid nmlBfClr-blue007 tblWgeBB tblWgeBR" cols="6">
                        <span class="fontDotEight font-weight-medium">소득내역&nbsp;&#40;</span>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-a-circle-outline</v-icon>
                        <span class="fontDotEight font-weight-medium">&#41;</span>
                    </v-col>
                    <v-col class="py-1 txtAlgnMid nmlBfClr-blue007 tblWgeBB" cols="6">
                        <span class="fontDotEight font-weight-medium">공제내역&nbsp;&#40;</span>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-b-circle-outline</v-icon>
                        <span class="fontDotEight font-weight-medium">&#41;</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" v-for="(i) in maxCnt" :key="i" no-gutters>
                    <v-col class="py-2 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                        <span class="fontDotSeven">{{ inWgePymlist.length >= (i) ? inWgePymlist[i-1].itmNm : '' }}</span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center tblWgeBB tblWgeBR" cols="3">
                        <span class="fontDotSeven">
                            {{ inWgePymlist.length >= (i) ? inWgePymlist[i-1].amt?.toLocaleString('ko-KR')+' 원' : '' }}
                        </span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                        <span class="fontDotSeven">{{ outWgePymlist.length >= (i) ? outWgePymlist[i-1].itmNm : '' }}</span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center tblWgeBB" cols="3">
                        <span class="fontDotSeven">
                            {{ outWgePymlist.length >= (i) ? outWgePymlist[i-1].amt?.toLocaleString('ko-KR')+' 원' : '' }}
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-col class="py-2 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                        <span class="fontDotSeven font-weight-medium">소득액&nbsp;합계</span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center tblWgeBR tblWgeBB" cols="3">
                        <span class="fontDotSeven font-weight-medium">{{ inWgeSum?.toLocaleString('ko-KR') }}&nbsp;원</span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                        <span class="fontDotSeven font-weight-medium">공제액&nbsp;합계</span>
                    </v-col>
                    <v-col class="py-2 d-flex justify-center align-center tblWgeBB" cols="3">
                        <span class="fontDotSeven font-weight-medium">{{ outWgeSum?.toLocaleString('ko-KR') }}&nbsp;원</span>
                    </v-col>
                </v-row>
            </div>
            <v-row class="pa-0 wgeBox" style="border-top: initial;" no-gutters>
                <v-col class="nmlBfClr-blue103 py-1" cols="6">
                    <div class="d-flex justify-center align-center">
                        <span class="fontDotNine font-weight-medium white--text">실수령액</span>
                    </div>
                    <div class="d-flex justify-center align-center">
                        <span class="fontDotNine font-weight-medium white--text">&#40;</span>
                        <v-icon class="white--text" size="18" style="padding-top: 3px; margin-left: 1px;">mdi-alpha-a-circle-outline</v-icon>
                        <span class="fontDotNine font-weight-medium white--text px-1">-</span>
                        <v-icon class="white--text" size="18" style="padding-top: 3px; margin-left: 1px;">mdi-alpha-b-circle-outline</v-icon>
                        <span class="fontDotNine font-weight-medium white--text">&#41;</span>
                    </div>
                </v-col>
                <v-col class="d-flex justify-end align-center px-3" cols="6">
                    <span class="fontDotNine font-weight-medium">
                        {{ (inWgeSum - outWgeSum)?.toLocaleString('ko-KR') }}&nbsp;원
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">근무시간</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-2 wgeBox" no-gutters>
                <v-col class="py-1 d-flex justify-center align-center nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven font-weight-medium">근로일수</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.wrkDays }}&nbsp;일</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven font-weight-medium">총&nbsp;근로시간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBB" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.actWrkHr }}&nbsp;시간</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven font-weight-medium">추가&nbsp;근로시간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.adWrkHr }}&nbsp;시간</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR tblWgeBB" cols="3">
                    <span class="fontDotSeven font-weight-medium">연장&nbsp;근로시간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBB" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.adExtnHr }}&nbsp;시간</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR" cols="3">
                    <span class="fontDotSeven font-weight-medium">야간&nbsp;근로시간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center tblWgeBR" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.adNgtHr }}&nbsp;시간</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue006 tblWgeBR" cols="3">
                    <span class="fontDotSeven font-weight-medium">휴일&nbsp;근로시간</span>
                </v-col>
                <v-col class="py-1 d-flex justify-center align-center" cols="3">
                    <span class="fontDotSeven">{{ wrkHrInfo.adVcnkHr }}&nbsp;시간</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">계산방법</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-2 wgeBox" no-gutters>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue007 tblWgeBR" cols="3">
                    <span class="fontDotEight font-weight-medium">구분</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue007 tblWgeBR" cols="6">
                    <span class="fontDotEight font-weight-medium">산출식</span>
                </v-col>
                <v-col class="py-1 txtAlgnMid nmlBfClr-blue007" cols="3">
                    <span class="fontDotEight font-weight-medium">지급액</span>
                </v-col>
                <v-col v-for="(cml, j) in calcMthdList" :key="j" cols="12">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="py-1 txtAlgnMid tblWgeBT tblWgeBR" cols="3">
                            <span class="fontDotEight">{{ cml.itmNm }}</span>
                        </v-col>
                        <v-col class="py-1 txtAlgnMid tblWgeBT tblWgeBR" cols="6">
                            <span class="fontDotEight">{{ cml.calcCntnt }}</span>
                        </v-col>
                        <v-col class="py-1 txtAlgnMid tblWgeBT" cols="3">
                            <span class="fontDotEight">{{ cml.amt?.toLocaleString('ko-KR') }}&nbsp;원</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">비고</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-2 wgeBox" no-gutters>
                <v-col class="overflow-text-hidden txtAlgnMid pa-2" cols="12">
                    <span class="fontDotEight">{{ wgeInfo.rmrks }}</span>
                </v-col>
            </v-row>
            <v-divider class="blue005 mt-7"></v-divider>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="txtAlgnMid py-3" cols="12">
                    <span class="font-weight-bold fontOneToOne blue102--text">
                        귀하의 노고에 감사드립니다.
                    </span>
                </v-col>
            </v-row>
            <v-divider class="blue005 mt-4"></v-divider>
        </v-col>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'EmpWage',

    components: {
      
    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        empPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.aplyYyMm != '' && this.empPk == 0)
                this.getEmpWge(this.aplyYyMm)
            else if(this.empPk != 0)
                this.getOutEmpWge(this.fcltyNum, this.empPk, this.aplyYyMm)
        })
    },

    computed: {
       
    },

    watch:{
        'empPk': function (){
            if(this.empPk != 0)
                this.getOutEmpWge(this.fcltyNum, this.empPk, this.aplyYyMm)
        },
    },
        
    methods: {
        //비용 명세서 조회 TOKEN
        getEmpWge: function (yymm){
            console.log(yymm)
        },
        //비용 명세서 조회 Params
        getOutEmpWge: function (code, pk, yymm){
            http.post('mobemp/selEmpWge', { fcltyNum: code, empPk: pk, inqYYMM: yymm,})
                .then((response) => ( this.empWgeRst(response.data) ))
                .catch((error) => console.log('axios error /mobemp/selEmpWge : ' + error))
        },
        empWgeRst: function (res){
            //변수 초기화
            this.inWgePymlist.splice(0)
            this.outWgePymlist.splice(0)
            this.calcMthdList.splice(0)
            this.inWgeSum = 0
            this.outWgeSum = 0
            
            //서버와 정상통신 확인
            if(res.statusCode == '200'){
                //데이터 매핑
                if(res.wgeInfo != null)
                    this.wgeInfo = res.wgeInfo
                if(res.wrkHrInfo != null)
                    this.wrkHrInfo = res.wrkHrInfo
                if(res.wgePymlist != null){
                    //급여내역을 소득과 공제로 나눔
                    this.inWgePymlist = res.wgePymlist.filter( v => v.calcClcd == '1' )
                    this.outWgePymlist = res.wgePymlist.filter( v => v.calcClcd == '2' )

                    //계산방법 리스트를 산정
                    this.calcMthdList = res.wgePymlist.filter( v => v.calcCntnt != '' && v.calcCntnt != null )
                }

                //최대 row 수를 구함
                this.maxCnt = Math.max(this.inWgePymlist.length, this.outWgePymlist.length)

                //급여액 합산
                this.inWgePymlist.forEach(el => {
                    this.inWgeSum += el.amt
                });
                this.outWgePymlist.forEach(el => {
                    this.outWgeSum += el.amt
                });

            }
        },
    },

    data: () => ({
        maxCnt: 0,
        inWgeSum: 0,
        outWgeSum: 0,
        wgeInfo: {
            name: '', entDt: '', jobNm: '', rsdntBirth: '', wrkDtStrt: '', wrkDtEnd: '',
            bnkNm: '', bnkAcct: '', wgpyDt: '',
        },
        wrkHrInfo: {
            wrkDays: 0, actWrkHr: 0, adWrkHr: 0, adExtnHr: 0, adNgtHr: 0, adVcnkHr: 0,
        },
        inWgePymlist: [],       //소득
        outWgePymlist: [],      //공제
        calcMthdList: [],       //계산방법
    }),
};
</script>