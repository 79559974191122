<template>
    <v-app class="">
        <v-main class="d-flex align-center">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-card
                    class="py-8 px-10 d-flex justify-center align-center" elevation="0">
                    <div>
                        <div class="d-flex justify-center">
                            <span class="d-inline-block d-flex justify-center align-center" style="height: 140px;">
                                <span class="black--text font-weight-bold pb-4 pr-1" style="font-size: 6rem;">404</span>
                            </span>
                        </div>
                        <div class="blue001--text fontOneToEight font-weight-bold mt-2 mb-4">
                            페이지를 찾을 수 없습니다.
                        </div>
                        <div class="fontOne mb-3">
                            원하는 결과를 찾을 수 없습니다.
                        </div>
                        <div class="fontOne">
                            올바른 URL을 입력하였는지 확인하세요. 자세한 내용은 관리자에게 문의하시기 바랍니다.
                        </div>
                        <div class="mt-6 d-flex justify-center">
                            <v-btn class="mr-4" color="blue001" @click="$router.go(-1)" outlined icon>
                                <v-icon>mdi-redo-variant</v-icon>
                            </v-btn>
                            <v-btn class="" color="blue001" @click="cnfLogout()" outlined icon>
                                <v-icon>mdi-home-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card>
                <v-spacer></v-spacer>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>


export default {
    name: 'NotFound',

    components: {
       
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
         
        })
    },

    computed: {
        theme: function() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
    },

    watch:{
  
    },
        
    methods: {
        cnfLogout: function (){
            this.$store.commit('setToken', '')
            this.$router.push({ name: 'SprtdUser' })
        },
    },

    data: () => ({
        
    }),
};
</script>