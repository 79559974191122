<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="11">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">건강 및 간호 제공 기록내역</span>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0 nmlBfClr-grey001" no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="3">
                    <span class="fontDotEight font-weight-medium">기록일자</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="3">
                    <span class="fontDotEight font-weight-medium">혈압</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontDotEight font-weight-medium">맥박</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="fontDotEight font-weight-medium">체온</span>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="2">
                    <span class="fontDotEight font-weight-medium">혈당</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <div v-if="hlthNrsList.length > 0">
                <v-row class="pa-0 tableBB" v-for="(list, k) in hlthNrsList" :key="k" no-gutters>
                    <v-col class="txtAlgnMid tableBR" style="padding: 2px 0 5px 0;" cols="3">
                        <span class="fontDotEight">{{ $moment(list.recDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" cols="3">
                        <span class="fontDotEight">{{ list.bldPrsH }} / {{ list.bldPrsL }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" cols="2">
                        <span class="fontDotEight">{{ list.pulse }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR" cols="2">
                        <span class="fontDotEight">{{ list.tmprt }}</span>
                    </v-col>
                    <v-col class="txtAlgnMid" cols="2">
                        <span class="fontDotEight">{{ list.bldSgr }}</span>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row class="pa-0 tableBB" no-gutters>
                    <v-col class="txtAlgnMid" style="padding: 4px 0 7px 0;" cols="12">
                        <span class="fontDotEight grey006--text">※ 조회된 목록이 없습니다.</span>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'PrsnlHlthInf',

    components: {

    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.getHlthNrsList(this.fcltyNum, this.bnMmbrPk)
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        // 건강정보 조회
        getHlthNrsList: function (code, pk){
            this.hlthNrsList.splice(0)

            http.post('fmly/selHlthNrsList', { fcltyNum: code, bnMmbrPk: pk })
                .then((response) => ( this.hlthNrsRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selHlthNrsList : ' + error))
        },
        
        hlthNrsRst: function (res){
            if(res.result.list != null){
                this.hlthNrsList = res.result.list
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: '잘못된 데이터 형식입니다.' })
            }
        },
    },

    data: () => ({
        hlthNrsList: [],
    }),
};
</script>