<template>
    <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-bold fontOneToTwo">급여제공 서비스 내역</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-4 mb-5 pr-2" no-gutters>
            <v-col class="txtAlgnMid" cols="12">
                <span class="blue102--text fontOneToThree font-weight-bold">
                    {{ $moment($store.getters.getBnftcrdCtgObj.inqYMD, 'YYYY.MM.DD').format('YYYY.MM.DD (dddd)') }}
                </span>
            </v-col>
        </v-row>
        <div
            class="d-inline-block overflow-y-auto pr-2"
            :style="{ 'width': '100%', 'height': (($vuetify.breakpoint.height * 0.89) - 170) + 'px' }">
            <v-row class="align-center mb-4" no-gutters>
                <v-icon class="iconDeco font-weight-medium grey006--text mr-3" size="26">mdi-chevron-right</v-icon>
                <span class="grey006--text font-weight-medium">신체활동지원</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1">( 작성자: {{ hisWrtrs[0] }} )</span>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" v-for="(list, i) in recList1" :key="i" no-gutters>
                <v-col
                    :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-center align-center' : '' ]"
                    cols="5">
                    <div class="d-flex justify-center align-center fontDotEight">{{ list.clsfc3Cdnm }}</div>
                    <div
                        :class="['txtAlgnMid', list.clsfc3Cd == '10' ? 'fontDotSeven' : 'fontDotEight']">
                        {{ list.txtSub }}
                    </div>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBB tableBR" cols="3">
                    <span class="fontDotEight" v-html="list.plnNm"></span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                    <div v-if="list.clsfc3Cd == '11'" style="padding: 0 1px;">
                        <span
                            :class="['d-inline-block rounded onOffSpan', list.itmAr1 ? 'blue001 white--text' : 'black--text']"
                            style="width: 40px; margin-right: 1px;">
                            <v-icon :class="[list.itmAr1 ? 'white--text' : 'black--text']" size="12">mdi-walk</v-icon>
                            <span style="font-size: 0.69rem;">산책</span>
                        </span>

                        <span
                            :class="['d-inline-block rounded onOffSpan', list.itmAr2 ? 'blue001 white--text' : 'black--text']"
                            style="width: 40px;">
                            <v-icon :class="[list.itmAr2 ? 'white--text' : 'black--text']" size="12">mdi-exit-run</v-icon>
                            <span style="font-size: 0.69rem;">외출</span>
                        </span>
                    </div>
                    <div
                        class="overflow-x-hidden overflow-y-auto d-inline-block px-1"
                        style="width: 100%; height: 42px; word-break: break-all;"
                        v-else-if="list.clsfc3Cd == '99'">
                        <span class="fontDotEight">{{ list.itmVal }}</span>
                    </div>
                    <div
                        class="fontDotEight"
                        v-else-if="list.clsfc3Cd == '98'">
                        {{ list.itmVal }}
                    </div>
                    <span
                        v-else-if="list.clsfc3Cd == '4'"
                        :class="['fontDotEight', list.clr == 'red' ? 'red--text' : 'blue--text']">
                        {{ list.itmVal }}
                    </span>
                    <span style="padding: 0 1px;" v-else-if="list.clsfc3Cd == '96'">
                        <div class="d-flex justify-center">
                            <span
                                :class="['d-inline-block rounded onOffSpan', list.morn ? 'blue001 white--text' : 'black--text']"
                                style="width: 30px; margin-right: 1px;">
                                <span class="fontDotSeven">아침</span>
                            </span>
                            <span
                                :class="['d-inline-block rounded onOffSpan', list.lnch ? 'blue001 white--text' : 'black--text']"
                                style="width: 30px; margin-right: 1px;">
                                <span class="fontDotSeven">점심</span>
                            </span>
                            <span
                                :class="['d-inline-block rounded onOffSpan', list.dinr ? 'blue001 white--text' : 'black--text']"
                                style="width: 30px;">
                                <span class="fontDotSeven">저녁</span>
                            </span>
                        </div>
                        <div class="d-flex justify-center mt-1">
                            <span
                                :class="['d-inline-block rounded onOffSpan', list.am ? 'blue001 white--text' : 'black--text']"
                                style="width: 30px; margin-right: 1px; border: 1px solid black;">
                                <span class="fontDotSeven">오전</span>
                            </span>
                            <span
                                :class="['d-inline-block rounded onOffSpan', list.pm ? 'blue001 white--text' : 'black--text']"
                                style="width: 30px; border: 1px solid black;">
                                <span class="fontDotSeven">오후</span>
                            </span>
                        </div>
                    </span>
                    <span v-else class="fontDotEight">{{ list.itmVal }} 회</span>
                </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4" no-gutters>
                <v-icon class="iconDeco font-weight-medium grey006--text mr-3" size="26">mdi-chevron-right</v-icon>
                <span class="grey006--text font-weight-medium">인지·정서 지원</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1">( 작성자: {{ hisWrtrs[1] }} )</span>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" v-for="(list, j) in recList2" :key="recList1.length + j" no-gutters>
                <v-col
                    :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-center align-center' : '' ]"
                    cols="5">
                    <div class="d-flex justify-center align-center fontDotEight">{{ list.clsfc3Cdnm }}</div>
                    <div class="txtAlgnMid fontDotEight">
                        {{ list.txtSub }}
                    </div>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBB tableBR" cols="3">
                    <span class="fontDotEight" v-html="list.plnNm"></span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                    <div
                        class="overflow-x-hidden overflow-y-auto d-inline-block px-1"
                        style="width: 100%; height: 42px; word-break: break-all;"
                        v-if="list.clsfc3Cd == '99'">
                        <span class="fontDotEight">{{ list.itmVal }}</span>
                    </div>
                    <span v-else class="fontDotEight">{{ list.itmVal }} 회</span>
                </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4" no-gutters>
                <v-icon class="iconDeco font-weight-medium grey006--text mr-3" size="26">mdi-chevron-right</v-icon>
                <span class="grey006--text font-weight-medium">기능회복</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1">( 작성자: {{ hisWrtrs[2] }} )</span>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" v-for="(list, k) in recList3" :key="recList1.length + recList2.length + k" no-gutters>
                <v-col
                    :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-center align-center' : '' ]"
                    cols="5">
                    <div class="d-flex justify-center align-center fontDotEight">{{ list.clsfc3Cdnm }}</div>
                    <div class="txtAlgnMid fontDotEight">
                        {{ list.txtSub }}
                    </div>
                </v-col>
                <v-col class="py-1 txtAlgnMid tableBB tableBR" cols="3">
                    <span class="fontDotEight" v-html="list.plnNm"></span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                    <span class="fontDotEight">{{ list.itmVal }} 회</span>
                </v-col>
            </v-row>
            <v-row class="align-center mt-5 mb-4" no-gutters>
                <v-icon class="iconDeco font-weight-medium grey006--text mr-3" size="26">mdi-chevron-right</v-icon>
                <span class="grey006--text font-weight-medium">건강및간호관리</span>
                <v-spacer></v-spacer>
                <span class="grey006--text fontDotNine mr-1">( 작성자: {{ hisWrtrs[3] == '' ? '없음' : hisWrtrs[3] }} )</span>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBR pt-1 pb-2" cols="8">
                    <span class="fontDotEight">혈압 ( 고/저 )</span>
                </v-col>
                <v-col class="txtAlgnMid pt-1 pb-2" cols="4">
                    <span class="fontDotEight">{{ hlthNrs.bldPrsH }}/{{ hlthNrs.bldPrsL }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBR pt-1 pb-2" cols="8">
                    <span class="fontDotEight">맥박 / 체온</span>
                </v-col>
                <v-col class="txtAlgnMid pt-1 pb-2" cols="4">
                    <span class="fontDotEight">{{ hlthNrs.pulse }} / {{ hlthNrs.tmprt }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBR pt-1 pb-2" cols="8">
                    <span class="fontDotEight">호흡 / 혈당</span>
                </v-col>
                <v-col class="txtAlgnMid pt-1 pb-2" cols="4">
                    <span class="fontDotEight">{{ hlthNrs.rsprt }} / {{ hlthNrs.bldSgr }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBR pt-1 pb-2" cols="8">
                    <span class="fontDotEight">체중</span>
                </v-col>
                <v-col class="txtAlgnMid pt-1 pb-2" cols="4">
                    <span class="fontDotEight">{{ hlthNrs.wght }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR pt-1 pb-2" cols="5">
                    <span class="fontDotEight">간호일지</span>
                </v-col>
                <v-col class="pt-1 pb-2" cols="7">
                    <div
                        class="overflow-x-hidden overflow-y-auto d-inline-block px-1"
                        style="width: 100%; height: 42px; word-break: break-all;">
                        <span class="fontDotEight">{{ hlthNrs.cntnt }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'CareBlnftHstrMdl',

    components: {

    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.state.bnftcrdTotMdl == true){
                this.getBnftOfrRecDayInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }
        })
    },

    computed: {
        getBnftcrdCtgObj: function () {
            return this.$store.state.bnftcrdTotMdl
        },
    },

    watch:{
        getBnftcrdCtgObj: function (bool) {
            if(bool == true){
                this.getBnftOfrRecDayInfo(
                    this.$store.state.bnftcrdCtgMdl.bccObj.bnMmbrPk,
                    this.$moment(this.$store.state.bnftcrdCtgMdl.bccObj.inqYMD, 'YYYY.MM.DD', true).format('YYYYMMDD')
                )
            }
        },
    },
        
    methods: {
        //일일 급여제공기록 조회
        getBnftOfrRecDayInfo: function (pk, ymd){
            http.post(
                    'empApp/authOfrRecDayInfo', 
                    { bnMmbrPk: pk, clsfc1Cd: '301', clsfc2Cd: '1', clsfc3CdS: ['1','2','3'], inqYMD: ymd }
                )
                .then((response) => ( this.bnftOfrRecDayInfoRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authOfrRecDayInfo : ' + error))
        },
        bnftOfrRecDayInfoRst: function (res){

            if(res.statusCode == '200'){
                //좌측 메뉴 텍스트 작성
                if(res.result.plnList.length > 0){
                    let trgArr = res.result.plnList.filter( v => v.clsfc2Cd == '1' )
                    
                    trgArr.forEach(list => {
                        let chc = this.recList1.findIndex( v => v.clsfc2Cd == list.clsfc2Cd && v.clsfc3Cd == list.clsfc3Cd )

                        if(chc > -1){
                            this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                                if(list.itmVal1 == cd.valcd && list.itmVal2 != null)
                                    this.recList1[chc].plnNm = '<div>' + cd.valcdnm + list.itmVal2 + '회</div><div>/ ' + list.itmVal3 + '분</div>'
                                else if(list.itmVal1 == cd.valcd && list.itmVal2 == null)
                                    this.recList1[chc].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + list.itmVal3 + '분</div>'
                            });
                        }
                    });

                    let trgArr2 = res.result.plnList.filter( v => v.clsfc2Cd == '7' )
                    
                    trgArr2.forEach(list => {
                        let chc = this.recList3.findIndex( v => v.clsfc2Cd == list.clsfc2Cd && v.clsfc3Cd == list.clsfc3Cd )

                        if(chc > -1){
                            this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                                if(list.itmVal1 == cd.valcd && list.itmVal2 != null)
                                    this.recList3[chc].plnNm = '<div>' + cd.valcdnm + list.itmVal2 + '회</div><div>/ ' + list.itmVal3 + '분</div>'
                                else if(list.itmVal1 == cd.valcd && list.itmVal2 == null)
                                    this.recList3[chc].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + list.itmVal3 + '분</div>'
                            });
                        }
                    });

                    let chc1 = res.result.plnList.findIndex( v => v.clsfc2Cd == '3' )
                    if(chc1 > -1){
                        this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                            if(res.result.plnList[chc1].itmVal1 == cd.valcd && res.result.plnList[chc1].itmVal2 != null)
                                this.recList2[0].plnNm = '<div>' + cd.valcdnm + res.result.plnList[chc1].itmVal2 + '회</div><div>/ ' + res.result.plnList[chc1].itmVal3 + '분</div>'
                            else if(res.result.plnList[chc1].itmVal1 == cd.valcd && res.result.plnList[chc1].itmVal2 == null)
                                this.recList2[0].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + res.result.plnList[chc1].itmVal3 + '분</div>'
                        });
                    }
                    let chc2 = res.result.plnList.findIndex( v => v.clsfc2Cd == '4' )
                    if(chc2 > -1){
                        this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                            if(res.result.plnList[chc2].itmVal1 == cd.valcd && res.result.plnList[chc2].itmVal2 != null)
                                this.recList2[1].plnNm = '<div>' + cd.valcdnm + res.result.plnList[chc2].itmVal2 + '회</div><div>/ ' + res.result.plnList[chc2].itmVal3 + '분</div>'
                            else if(res.result.plnList[chc2].itmVal1 == cd.valcd && res.result.plnList[chc2].itmVal2 == null)
                                this.recList2[1].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + res.result.plnList[chc2].itmVal3 + '분</div>'
                        });
                    }

                    //신체활동지원
                    if(Object.keys(res.result.recInfo1).length > 0){
                        this.hisWrtrs.splice(0, 1, res.result.recInfo1.hisWrtr)
                        this.recList1.forEach(rec1 => {
                            switch (rec1.clsfc3Cdnm) {
                                case '옷갈아입기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm1Val)
                                    break;
                                case '세면하기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm2Val)
                                    break;
                                case '양치하기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm3Val)
                                    break;
                                case '머리감기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm5Val)
                                    break;
                                case '몸단장':
                                    rec1.itmVal = Number(res.result.recInfo1.itm6Val)
                                    break;
                                case '이동도움':
                                    rec1.itmVal = Number(res.result.recInfo1.itm10Val)
                                    break;
                                case '산책(외출)':
                                    if(res.result.recInfo1.itm11ValAr?.findIndex( v => v == '1' ) > -1)
                                        rec1.itmAr1 = true
                                    if(res.result.recInfo1.itm11ValAr?.findIndex( v => v == '2' ) > -1)
                                        rec1.itmAr2 = true
                                    break;
                                case '신체활동':
                                    rec1.itmVal = res.result.recInfo1.spclNote
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //인지정서지원
                    if(Object.keys(res.result.recInfo2).length > 0){
                        this.hisWrtrs.splice(1, 1, res.result.recInfo2.hisWrtr)
                        this.recList2.forEach(rec2 => {
                            switch (rec2.clsfc3Cdnm) {
                                case '인지관리':
                                    rec2.itmVal = Number(res.result.recInfo2.itm1Val)
                                    break;
                                case '의사소통,':
                                    rec2.itmVal = Number(res.result.recInfo2.itm2Val)
                                    break;
                                case '인지 정서':
                                    rec2.itmVal = res.result.recInfo2.spclNote
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //기능회복
                    if(Object.keys(res.result.recInfo3).length > 0){
                        this.hisWrtrs.splice(2, 1, res.result.recInfo3.hisWrtr)
                        this.recList3.forEach(rec3 => {
                            switch (rec3.clsfc3Cdnm) {
                                case '신체기능의':
                                    rec3.itmVal = Number(res.result.recInfo3.itm1Val)
                                    break;
                                case '기본동작':
                                    rec3.itmVal = Number(res.result.recInfo3.itm2Val)
                                    break;
                                case '일상생활동작':
                                    rec3.itmVal = Number(res.result.recInfo3.itm2Val)
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //건강및간호관리
                    if(res.result.hlthNrs == null)
                        res.result.hlthNrs = {}
                        
                    if(Object.keys(res.result.hlthNrs).length > 0){
                        this.hlthNrs = res.result.hlthNrs

                        if(res.result.hlthNrs.wrtr != null)
                            this.hisWrtrs.splice(3, 1, res.result.hlthNrs.wrtr)
                    }

                    res.result.rec301List.forEach(list => {
                        //몸씻기
                        if(list.bathSchdlPk > 0 && list.bathSchdlPk != null) { 
                            if(list.actBth != '' && list.actBth != null){
                                this.recList1[5].clr = 'red'
                                this.recList1[5].itmVal = list.actBth
                            }
                            else{
                                this.recList1[5].clr = 'blue'
                                this.recList1[5].itmVal = list.bthTm
                            }
                        }
                        //식사하기
                        let mealArr = list.itmMeal
                        if(mealArr != null){
                            mealArr.forEach(itm => {
                                if(itm == '아침') this.recList1[6].morn = true
                                else if(itm == '점심') this.recList1[6].lnch = true
                                else if(itm == '저녁') this.recList1[6].dinr = true
                                else if(itm == '오전') this.recList1[6].am = true
                                else if(itm == '오후') this.recList1[6].pm = true
                            });
                        }
                        //체위변경
                        if(list.itmPos != null)
                            this.recList1[7].itmVal = list.itmPos
                        //화장실 이용하기
                        if(list.itmToil != null)
                            this.recList1[8].itmVal = list.itmToil
                    });
                }
            }

        },
        onClose: function () {
            this.$store.commit('setBnftcrdTotMdl', false)
            this.$store.commit('setBnftcrdCtgMdl', '')
            this.$store.commit('setBnftcrdCtgObj', {})
        },
    },

    data: () => ({
        hisWrtrs: [ '', '', '', '', ],
        recList1: [
            //신체활동지원 ( 301, 1, 1 ) ( val1, val2, val3, val4, val5, val10, val11 )
            { clsfc2Cd: '1', clsfc3Cd: '1', clsfc3Cdnm: '옷갈아입기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '2', clsfc3Cdnm: '세면하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '3', clsfc3Cdnm: '양치하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '5', clsfc3Cdnm: '머리감기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '6', clsfc3Cdnm: '몸단장', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '4', clsfc3Cdnm: '몸씻기', txtSub:'', plnNm: '', clr: '', itmVal: '', },
            { clsfc2Cd: '1', clsfc3Cd: '96', clsfc3Cdnm: '식사하기', txtSub:'', plnNm: '', morn: false, lnch: false, dinr: false, am: false, pm: false },
            { clsfc2Cd: '1', clsfc3Cd: '97', clsfc3Cdnm: '체위변경', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '98', clsfc3Cdnm: '화장실', txtSub:'이용하기', plnNm: '', itmVal: '', },
            { clsfc2Cd: '1', clsfc3Cd: '10', clsfc3Cdnm: '이동도움', txtSub:'신체 기능유지, 증진', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '11', clsfc3Cdnm: '산책(외출)', txtSub:'동행', plnNm: '', itmAr1: false, itmAr2: false, },
            { clsfc2Cd: '1', clsfc3Cd: '99', clsfc3Cdnm: '신체활동', txtSub:'특이사항', plnNm: '', itmVal: '' },
        ],
        recList2: [
            //인지정서지원 ( 301, 1, 2 ) ( val1, val2 )
            { clsfc2Cd: '3', clsfc3Cd: '1', clsfc3Cdnm: '인지관리', txtSub:'지원', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '4', clsfc3Cd: '1', clsfc3Cdnm: '의사소통,', txtSub:'정서', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '5', clsfc3Cd: '99', clsfc3Cdnm: '인지 정서', txtSub:'특이사항', plnNm: '', itmVal: '' },
        ],
        recList3: [
            //기능회복훈련 ( 301, 1, 3 ) ( val1, val2, val3 )
            { clsfc2Cd: '7', clsfc3Cd: '2', clsfc3Cdnm: '신체기능의', txtSub:'훈련', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '7', clsfc3Cd: '3', clsfc3Cdnm: '기본동작', txtSub:'훈련', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '7', clsfc3Cd: '4', clsfc3Cdnm: '일상생활동작', txtSub:'훈련', plnNm: '', itmVal: 0, },
        ],
        hlthNrs: {
            bldPrsH: '', bldPrsL: '', bldSgr: '', cntnt: '', hltTmSum: 0, nlstmsum: 0, pulse: '', recDt: '',
            rn: 0, rnTot: 0, rsprt: '', tmprt: '', wght: '', wrtr: ''
        },
    }),
};
</script>