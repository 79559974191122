<template>
    <v-sheet class="mt-19" width="100%">
        <div class="fxdAppTop py-3" style="width: 92% !important;">
            <v-tabs
                class="cstTabs mt-4" height="40"
                v-model="tab" show-arrows centered>
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                <v-tab
                    active-class="greyE02 blue102--text"
                    v-for="(tb, i) in tabMenu" :key="i">
                    <span class="fontOne">{{ tb.tbNm }}</span>
                </v-tab>
            </v-tabs>
        </div>
        <v-row class="pa-0 mt-6" no-gutters>
            <v-col cols="12">
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item
                        v-for="(tb, j) in tabMenu" :key="j"
                        transition="false">
                        <PrsnlHlthInf
                            v-if="tb.prtCd == '101' && tab == tb.idx"
                            :aplyYyMm="$parent.$parent.sltDt"
                            :fcltyNum="$parent.$parent.sltFcltyNum"
                            :bnMmbrPk="$parent.$parent.sltBnMmbrPk">
                        </PrsnlHlthInf>
                        <BnftSrvRcrd
                            v-else-if="tb.prtCd == '14' && tab == tb.idx"
                            :aplyYyMm="$parent.$parent.sltDt"
                            :fcltyNum="$parent.$parent.sltFcltyNum"
                            :bnMmbrPk="$parent.$parent.sltBnMmbrPk"
                            :rngCldTyp="'none'">
                        </BnftSrvRcrd>
                        <PrgSrvRcrd
                            v-else-if="tb.prtCd == '27' && tab == tb.idx"
                            :aplyYyMm="$parent.$parent.sltDt"
                            :fcltyNum="$parent.$parent.sltFcltyNum"
                            :bnMmbrPk="$parent.$parent.sltBnMmbrPk"
                            :rngCldTyp="'none'">
                        </PrgSrvRcrd>
                        <PrgSrvSchdl
                            v-else-if="tb.prtCd == '13' && tab == tb.idx"
                            :aplyYyMm="$parent.$parent.sltDt"
                            :fcltyNum="$parent.$parent.sltFcltyNum"
                            :bnMmbrPk="$parent.$parent.sltBnMmbrPk"
                            :rngCldTyp="'none'">
                        </PrgSrvSchdl>
                        <DietTbl
                            v-else-if="tb.prtCd == '2' && tab == tb.idx"
                            :aplyYyMm="$parent.$parent.sltDt"
                            :fcltyNum="$parent.$parent.sltFcltyNum"
                            :bnMmbrPk="$parent.$parent.sltBnMmbrPk"
                            :rngCldTyp="'none'">
                        </DietTbl>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import PrsnlHlthInf from '../bnfcr/pyrlsrvc/PrsnlHlthInf.vue';      // 건강정보
import BnftSrvRcrd from '../bnfcr/pyrlsrvc/BnftSrvRcrd.vue';        // 급여제공기록
import PrgSrvRcrd from '../bnfcr/pyrlsrvc/PrgSrvRcrd.vue';          // 프로그램 참여
import PrgSrvSchdl from '../bnfcr/pyrlsrvc/PrgSrvSchdl.vue';        // 프로그램 일정
import DietTbl from '../bnfcr/pyrlsrvc/DietTbl.vue';                // 주간 식단

export default {
    name: 'OutPrsnlStts',

    components: {
        PrsnlHlthInf,
        BnftSrvRcrd,
        PrgSrvRcrd,
        PrgSrvSchdl,
        DietTbl,
    },

    props: {
        aplyYyMm: { type: String, default: '' }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '어르신 개인 현황 안내'
            this.rflQuery()
        })
    },

    computed: {
       
    },

    watch:{

    },
        
    methods: {
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)
                    let rst = JSON.parse(bytes.toString(this.$crypto.enc.Utf8))

                    this.$parent.$parent.sltDt = this.$moment().format('YYYYMM')
                    this.$parent.$parent.sltFcltyNum = rst.fcltyNum
                    this.$parent.$parent.sltBnMmbrPk = rst.bzPk
                    this.$parent.$parent.appBarTtl = rst.title
                    this.arrCtr(['101', '14', '27', '2', '13'])
                }
            }
        },
        arrCtr: function (arr) {
            let chc = arr?.findIndex( v => v == '0' )
 
            if(chc > -1)
                arr.splice(chc, 1, '1')

            this.$parent.$parent.sltprtCdAr = [...new Set(arr)]

            if(this.$parent.$parent.sltprtCdAr.length > 0)
                this.rflTabList()
        },
        rflTabList: function () {
            this.tabMenu.splice(0)

            let cch = this.$parent.$parent.sltprtCdAr.findIndex( v => v == '101' )
            if(cch > -1){
                this.$parent.$parent.sltprtCdAr.splice(cch, 1)
                this.$parent.$parent.sltprtCdAr.unshift('101')
            }

            this.$parent.$parent.sltprtCdAr.forEach((cdS, i) => {
                let chc = this.tabList.findIndex( v => v.prtCd == cdS )
                if(chc > -1){
                    let obj = Object.assign({}, this.tabList[chc])
                    obj.idx = i
                    this.tabMenu.push(obj)
                }
            });
        
        },
    },

    data: () => ({
        tab: 0,
        tabMenu: [],
        tabList: [
            { tbNm: '건강정보', prtCd: '101', onTab: false }, { tbNm: '급여', prtCd: '14', onTab: false },
            { tbNm: '프로그램 참여', prtCd: '27', onTab: false },
            { tbNm: '식단표', prtCd: '2', onTab: false }, { tbNm: '프로그램 일정', prtCd: '13', onTab: false },
        ],
    }),
};
</script>