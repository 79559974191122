import excInst from 'exceljs'

const styleObj = { 
    alignment: { horizontal: 'center', vertical: 'middle' },
}

const fillObj = {}
      fillObj.type = 'pattern'
      fillObj.pattern = 'solid'
      fillObj.fgColor = { argb: 'ffededed' }
      fillObj.bgColor = { argb: 'ffededed' }

const borderObj = {}
      borderObj.top = { style:'thin', color: { argb:'ff000000' } }
      borderObj.left = { style:'thin', color: { argb:'ff000000' } }
      borderObj.bottom = { style:'thin', color: { argb:'ff000000' } }
      borderObj.right = { style:'thin' , color: { argb:'ff000000' } }

/**
 * 
 * @param { Number } num
 * @returns 변환된 알파벳
 */
function numericToStr(num) {
    let rst = num

    if( 64 < num && num < 91)
        rst = String.fromCharCode(num)
    else if( 90 < num && num < 116)
        rst = 'A' + String.fromCharCode((num - 90) + 64)
    else if( 115 < num && num < 141)
        rst = 'B' + String.fromCharCode((num - 90) + 64)
    // .....추가 작성 요망

    return rst
}

/**
 * 
 * @param { Workbook } wrkbk
 * @param { String } wrkbkTitle
 * @returns 엑셀 워크북 객체를 파일로 다운로드
 */
function xlsxDwlr(wrkbk, wrkbkTitle){
    wrkbk.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        let url = window.URL.createObjectURL(blob)
        let anchor = document.createElement('a')

        anchor.href = url
        anchor.download = wrkbkTitle.replaceAll(' ', '_') + '.xlsx'
        anchor.click()

        window.URL.revokeObjectURL(url)
    })
}

/**
 * 
 * @param { Array } header
 * @param { Array } data
 * @param { String } shtNm
 * @param { String } ttl
 * @returns 1행 헤더 생성 후 다운로드
 */
function snglHdrXlsxPrdc(header, data, shtNm, ttl){
    console.log('>> excel create Start : ')

    let start  = performance.now()

    header.forEach(itm => {
        itm.style = styleObj
    });

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = ttl
    workbook.description = ''
    workbook.creator = ''
    workbook.lastModifiedBy = ''
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성
    let sheet = workbook.addWorksheet(shtNm)
    sheet.columns = header
    sheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1, }
    ]

    data.forEach((itm, i) => {
        sheet.addRow(itm)
        sheet._rows[i].height = 22

        sheet._rows[i]._cells.forEach(itm => {
            itm.border = borderObj
        });
    });

    sheet.lastRow.height = 22
    sheet.lastRow._cells.forEach(itm => {
        itm.border = borderObj
    });

    // 헤더 스타일 적용
    let topRow = sheet.getRow(1)
 
    topRow.height = 33
    topRow._cells.forEach(itm => {
        itm.style.fill = fillObj
        itm.border = borderObj
        itm.font = { bold: true }
    });

    // 엑셀 다운로드
    xlsxDwlr(workbook, ttl)

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

/**
 * 
 * @param { Array } header
 * @param { Array } data
 * @param { Array } mrgArr
 * @param { String } shtNm
 * @param { String } ttl
 * @param { String } wrtr
 * @param { String } totYn
 * @returns 2행 헤더(병합) 생성 후 다운로드
 */
function dblHdrXlsxPrdc(header, data, mrgArr, shtNm, ttl, wrtr, totYn){
    console.log('>> excel create Start : ')

    let start  = performance.now()

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = ttl
    workbook.description = ''
    workbook.creator = wrtr
    workbook.lastModifiedBy = wrtr
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성 및 정보 설정
    let sheet = workbook.addWorksheet(shtNm)
    sheet.columns = header
    sheet.columns.forEach(itm => {
        itm.style.alignment = styleObj.alignment
    });
    sheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 2, }
    ]

    // 헤더 커스텀 (병합, 스타일)
    sheet.duplicateRow(1, 1, false)

    let colKnd = []
    mrgArr.forEach(itm => {
        if(typeof itm.colStrt == 'string' && itm.colStrt?.length == 2)
            colKnd.push(itm.colStrt.slice(0, 1).charCodeAt(0))
        if(typeof itm.colEnd == 'string' && itm.colEnd?.length == 2)
            colKnd.push(itm.colEnd.slice(0, 1).charCodeAt(0))

        sheet.mergeCells(itm.colStrt + ':' + itm.colEnd)
        sheet.getCell( itm.colStrt ).value = itm.colNm
    });

    let colMin = Math.min(...colKnd)
    let colMax = Math.max(...colKnd)

    if(colMin > 65){
        for (let index = 65; index < colMin; index++) {
            sheet.mergeCells(numericToStr(index) + '1:' + numericToStr(index) + '2' )
        }
    }
    if((header.length + 64) > colMax){
        for (let index = (colMax + 1); index <= (header.length + 64); index++) {
            sheet.mergeCells(numericToStr(index) + '1:' + numericToStr(index) + '2' )
        }
    }

    for (let index = 1; index < 3; index++) {
        let hdrRow = sheet.getRow(index)
        hdrRow.height = 28

        hdrRow._cells.forEach(itm => {
            itm.style.fill = fillObj
            itm.style.alignment = styleObj.alignment
            itm.border = borderObj
            itm.font = { bold: true }
        });
    }

    // 데이터 적용
    data.forEach((itm, i) => {
        sheet.addRow(itm)
        sheet._rows[i+2].height = 22
    
        for (let index = 1; index <= header.length; index++) {
            let sltCell = sheet._rows[i+2].getCell(index)
            sltCell.border = borderObj
        }
    });

    // 합계 행 추가
    if(totYn == 'Y'){
        sheet.addRow(data[data.length-1])
        let totRow = sheet.getRow(sheet.lastRow._number)
        totRow.height = 24

        totRow._cells.forEach(itm => {
            let sumCol = 0

            for (let idx = 3; idx < sheet.lastRow._number; idx++) {
                sumCol += sheet.getCell(itm._address.slice(0, 1) + idx).value
            }

            if(itm.style.numFmt == '#,##0'){
                itm.value = { formula: 'SUM(' + itm._address.slice(0, 1) + '3:' + itm._address.slice(0, 1)  + '' + (sheet.lastRow._number-1) + ')', result: sumCol}
            }
            else
                itm.value = ''

            totRow.getCell(itm._address.slice(0, 1)).border = borderObj
        });
        sheet.mergeCells('A' + sheet.lastRow._number +':' + numericToStr(colMin-1) + sheet.lastRow._number)
        totRow.getCell(1).value = '합계'
        totRow.getCell(1).style.fill = fillObj
        totRow.getCell(1).border = borderObj
        totRow.getCell(2).font = { bold: true }
    }

    // 엑셀 다운로드
    xlsxDwlr(workbook, ttl)

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

function getXlsxSidoCode(file) {
    return new Promise((resolve, reject) => {
        // excel 파일 생성
        let wb = new excInst.Workbook()

        // 파일 읽기
        let reader = new FileReader()

        // 반환 데이터 생성
        let list = []

        reader.readAsArrayBuffer(file)
        reader.onload = async () => {
            let buffer = reader.result
            await wb.xlsx.load(buffer).then(workbook => {
                // console.log(workbook, 'workbook instance')
                workbook.eachSheet(sheet => {
                    sheet.eachRow(row => {
                        let obj = {}
                        obj.sigunguCd = row.values[1]
                        obj.addr = row.values[2]
                        obj.sidoCd = row.values[4].result
                        obj.gunguCd = row.values[5].result
                        list.push(obj)
                    })
                })
            })
            resolve(list)
        }
        reader.onerror = reject
    })
}

export{
    snglHdrXlsxPrdc, dblHdrXlsxPrdc, getXlsxSidoCode
}