<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">가정통신문</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYYMM :aplyMinDt="'2020-01'" :aplyMaxDt="$moment().format('YYYY-MM')" @sltHypDt="getHypDt"></AppYYMM>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 pb-3 mt-15" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">가정통신문</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="10">
                        <div
                            class="d-inline-block pa-2 rounded"
                            style="width: 100%; margin: 0 2px; background-color: #F5F5F5; border: 2px solid #1B81DF; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);">
                            <div
                                v-for="(arr, i) in fmlyLeterList" :key="i">
                                <span class="tiptapEditor" v-html="arr.cntnt"></span>
                            </div>
                            <span v-if="fmlyLeterList.length == 0" class="tiptapEditor">작성된 내용이 없습니다.</span>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-divider class="my-5 black001" style="padding-bottom: 1px;"></v-divider>
                <v-row class="pb-3" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="ic_paper" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">생활상황 및 건강상태</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="10">
                        <div
                            class="d-inline-block pa-2 rounded"
                            style="width: 100%; margin: 0 2px; background-color: #F5F5F5; border: 2px solid #1B81DF; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);">
                            <div
                                v-if="Object.keys(lvngCndtn).length > 0">
                                <span class="tiptapEditor">{{ lvngCndtn.cntnt }}</span>
                            </div>
                            <span v-else class="tiptapEditor">작성된 내용이 없습니다.</span>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYYMM from '../../cmm/AppYYMM.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'FmltLtrApp',

    components: {
        CmmDrawIcon,
        AppYYMM,
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getFamlLtrInfo(this.$moment().format('YYYYMM'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 가정통신문 조회 (월간)
        getFamlLtrInfo: function (yymm){
            this.fmlyLeterList.splice(0)
            this.lvngCndtn = Object.assign({})

            http.post('fmlyApp/authFamlLtrInfo', { inqYYMM: yymm })
                .then((response) => ( this.famlLtrRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authFamlLtrInfo : ' + error))
        },
        famlLtrRst: function (res){
            if(res.statusCode == '200'){
                if(res.resList.length > 0)
                    this.fmlyLeterList = res.resList
                if(Object.keys(res.resObj).length > 0)
                    this.lvngCndtn = res.resObj
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != '')
                this.getFamlLtrInfo(this.$moment(v, 'YYYY-MM').format('YYYYMM'))
        },
    },

    data: () => ({
        viewHeight: 0,
        fmlyLeterList: [],
        lvngCndtn: {},
    }),
};
</script>