<template>
    <v-sheet width="100%" :height="$vuetify.breakpoint.height" class="d-flex justify-center align-center">
        <div class="">
            <v-row class="pa-2" style="position: absolute; top: 12px; right: 12px;" no-gutters>
                <v-col class="d-flex" cols="12">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="deferredPrompt"
                        ref="addBtn"
                        class="add-button" style="margin-top: 2px;"
                        @click="clickCallback" icon>
                        <v-icon class="grey006--text" size="30">mdi-home-import-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-5" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <span>
                        <v-img
                            :width="($vuetify.breakpoint.width * 0.6)" max-width="620"
                            src="../../assets/loginLogo.png">
                        </v-img>
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0" style="margin-top: 280px;" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="ma-2 py-5 px-12"
                        @click="$store.commit('setCmmBtmMdl', true), $store.commit('setUserSpr', 'gndr')"
                        outlined rounded>
                        <v-icon>
                            mdi-location-enter
                        </v-icon>
                        <span class="font-weight-medium fontOneToTwo">보호자로 시작</span>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-center mt-3" cols="12">
                    <v-btn
                        class="ma-2 py-5 px-12" color="blue101"
                        @click="$store.commit('setCmmBtmMdl', true), $store.commit('setUserSpr', 'emp')"
                        rounded>
                        <v-icon class="white--text">
                            mdi-location-enter
                        </v-icon>
                        <span class="font-weight-medium fontOneToTwo white--text">직원으로 시작</span>
                    </v-btn>

                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>

export default {
    name: 'SprtdUser',

    components: {
       
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.captureEvent()
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        captureEvent: function (){
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault()
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e
            })
        },
        clickCallback: function (){
            // Show the prompt
            this.deferredPrompt.prompt()
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                // Add analyticcs event
                this.$gtag.event('add_to_home_screen')
                }
                this.deferredPrompt = null
            })
        },
    },

    data: () => ({
        deferredPrompt: null,
    }),
};
</script>