<template>
    <v-row class="pa-0" no-gutters>
        <v-col cols="12">
            <EmpWage
                :aplyYyMm="$parent.$parent.sltDt"
                :fcltyNum="$parent.$parent.sltFcltyNum"
                :empPk="$parent.$parent.sltEmpPk">
            </EmpWage>
        </v-col>
    </v-row>
</template>

<script>
import EmpWage from '../emp/EmpWage.vue';

export default {
    name: 'OutEmpWage',

    components: {
        EmpWage,
    },

    props: {
        aplyYyMm: { type: String, default: '' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '급여 명세서 조회'
            
            // let obj = {}
            // obj.empPk = 2
            // obj.fcltyNum = 14111000790
            // obj.inqYYMM = 202307

            // let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            // console.log(encrypted.toString())
            // let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            // console.log(bs64)

            this.rflQuery()
        })
    },

    computed: {
        
    },

    watch:{

    },
        
    methods: {
        rflQuery: function () {
            if(typeof this.$route.query.dstnt != 'undefined'){
                if(this.$route.query.dstnt != ''){
                    let bs64 = this.$crypto.enc.Utf8.stringify(this.$crypto.enc.Base64.parse(this.$route.query.dstnt))
                    let bytes = this.$crypto.AES.decrypt(bs64, this.$store.getters.getCrypKey)

                    this.onPayExpStt(JSON.parse(bytes.toString(this.$crypto.enc.Utf8)))
                }
            }
        },
        onPayExpStt: function (obj) {
            let tmp = Object.assign({}, obj)
            this.$parent.$parent.sltDt = tmp.inqYYMM.toString()
            this.$parent.$parent.sltFcltyNum = tmp.fcltyNum.toString()
            this.$parent.$parent.sltEmpPk = tmp.empPk
        },
    },

    data: () => ({

    }),
};
</script>