<template>
    <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-bold fontOneToTwo">계약서&nbsp;확인사항</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mr-2"></v-divider>
        <v-row class="pa-0 mr-2 greyE01" no-gutters>
            <v-col class="py-1 txtAlgnMid" cols="12">
                <span class="fontDotNine font-weight-medium">확인내용</span>
            </v-col>
        </v-row>
        <v-divider class="mr-2"></v-divider>
        <div class="d-inline-block overflow-y-auto pr-2" style="width: 100%; height: 210px;">
            <v-row
                class="pa-0 tableBB"
                v-for="(list, i) in stndCndtList" :key="i" no-gutters>
                <v-col class="txtAlgnMid px-1 pb-1" style="line-height: 16px;" cols="12">
                    <span class="fontDotSeven">{{ list.cnfmCntnt }}</span>
                </v-col>
            </v-row>
            <v-row class="pa-0" v-if="stndCndtList.length == 0" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="12">
                    <span class="fontDotEight">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <v-row class="pa-0 mr-2 align-center" no-gutters>
            <v-col class="d-flex justify-center" style="padding-bottom: 2px;" cols="2">
                <span class="d-inline-block" style="width: 18px;">
                    <v-checkbox
                        v-model="cfmYn" :ripple="false"
                        class="cmmSqrBox mt-0" style=""
                        true-value="1" false-value="2"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="py-1" cols="10">
                <span class="fontDotEight">위 항목에 모두 동의합니다.</span>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-6" no-gutters>
            <span>
                <v-btn
                    :class="['pl-6 pr-7', saveBtn || cfmYn == '2' ? '' : 'blue001 white--text']"
                    :disabled="saveBtn || cfmYn == '2'" @click="preCntrcCnfm()"
                    outlined rounded>
                    <v-icon class="" size="18">mdi-content-save-outline</v-icon>
                    <span class="fontDotNine ml-2" style="padding-bottom: 2px;">저장</span>
                </v-btn>
            </span>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'OutStndCndtMdl',

    components: {

    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.stndCndtList = this.$store.getters.getPyrlSrvcCtgObj.list
            this.stndCndtPk = this.$store.getters.getPyrlSrvcCtgObj.bzClcdPk
            this.stndCndtNm = this.$store.getters.getPyrlSrvcCtgObj.name

            this.saveBtn = false
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        // 계약서 확인사항 저장
        setCntrcCnfmList: function (obj){
            http.post('fmly/fmlyInsDirtCnFm', obj)
                .then((response) => ( this.cntrcCnfmRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyInsDirtCnFm : ' + error))
        },
        cntrcCnfmRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
                this.onClose()
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        preCntrcCnfm: function () {
            let obj = {}
            obj.fcltyNum = this.$parent.$parent.$parent.$parent.sltFcltyNum
            obj.name = this.stndCndtNm
            obj.bzClcdPk = this.stndCndtPk

            this.stndCndtList.forEach(el => {
                el.cnfmYn = '1'
            });

            obj.cnfmCntnt = this.stndCndtList

            if(this.cfmYn == '1'){
                this.saveBtn = true
                this.setCntrcCnfmList(obj)
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'info', cntn: '내용을 확인하시고 동의해주세요.' })
            }
        },
        onClose: function () {
            this.$store.commit('setPyrlSrvcMdl', false)
            this.$store.commit('setPyrlSrvcCtgMdl', '')
            this.$store.commit('setPyrlSrvcCtgObj', {})
        },
    },

    data: () => ({
        saveBtn: true,
        cfmYn: '2',
        stndCndtPk: 0,
        stndCndtNm: '',
        stndCndtList: [],
    }),
};
</script>