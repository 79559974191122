<template>
    <v-card
        class="py-4 px-5"
        width="100%" rounded="xl" elevation="3">
        <v-row class="pb-3 align-center" no-gutters>
            <CmmDrawIcon type="ic_time" size="20"></CmmDrawIcon>
            <span class="fontDotEight font-weight-medium blue105--text ml-1" style="line-height: 0.1">
                Today
            </span>
            <span class="fontDotEight font-weight-medium ml-2" style="line-height: 0.1">프로그램&nbsp;일정</span>
            <v-spacer></v-spacer>
            <v-btn @click="rtrAct('PrgmSchdlApp')" x-small text>
                <span>더보기</span>
            </v-btn>
        </v-row>
        <div v-if="prgArr.length > 0">
            <v-row class="pa-0" no-gutters>
                <v-col class="" style="padding: 0 1px;" cols="7">
                    <div
                        class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                        style="line-height: 1.6; width: 100%; heigth: 100%">
                        <span class="fontDotEight font-weight-medium">프로그램</span>
                    </div>
                </v-col>
                <v-col class="" style="padding: 0 1px;" cols="5">
                    <div
                        class="d-inline-block nmlBfClrImp-greyE02 txtAlgnMid rounded-lg rounded-b-0 brdr-greyE05"
                        style="line-height: 1.6; width: 100%; heigth: 100%">
                        <span class="fontDotEight font-weight-medium">운영시간</span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pa-0" no-gutters>
                <v-col class="" style="padding: 0 1px;" cols="7">
                    <div
                        class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 pt-1 pb-2 px-2 tblInitBT"
                        style="line-height: 1.2; width: 100%; heigth: 100%;">
                        <div
                            class="overflow-text-hidden"
                            v-for="(itm, i) in prgNm" :key="i">
                            <span class="fontDotSeven font-weight-medium">{{ itm }}</span>
                            <v-divider
                                v-if="(prgNm.length-1) != i"
                                class="" style="border: 1px dashed #ccc; margin-top: 6px;">
                            </v-divider>
                        </div>
                    </div>
                </v-col>
                <v-col class="" style="padding: 0 1px;" cols="5">
                    <div
                        class="d-inline-block txtAlgnMid rounded-lg rounded-t-0 brdr-greyE05 pt-1 pb-2 px-2 tblInitBT"
                        style="line-height: 1.2; width: 100%; heigth: 100%;">
                        <div class="overflow-text-hidden" v-for="(itm, i) in prgTm" :key="i">
                            <span class="fontDotSeven font-weight-medium">{{ itm }}</span>
                            <v-divider
                                v-if="(prgTm.length-1) != i"
                                class="" style="border: 1px dashed #ccc; margin-top: 6px;">
                            </v-divider>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0" no-gutters>
                <v-col class="txtAlgnMid" cols="12">
                    <span class="greyE06--text fontDotEight">조회된 일정이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';

export default {
    name: 'PrgSrvCard',

    components: {
        CmmDrawIcon,
    },

    props: {
        prgArr: { type: Array, default: () => [] },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.aplctPrgArr()
        })
    },

    computed: {
       
    },

    watch:{
        'prgArr': function () {
            this.aplctPrgArr()
        },
    },
        
    methods: {
        aplctPrgArr: function () {
            this.prgArr.forEach(itm => {
                this.prgTm.push(itm.opTm)
                this.prgNm.push(itm.prgNm)
            });
        },
        rtrAct: function (nm){
            if(this.$route.name != nm)
                this.$router.push({ name: nm })
        },
    },

    data: () => ({
        prgTm: [],
        prgNm: [],
    }),
};
</script>