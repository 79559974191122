<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="11">
            <v-row class="pa-0" no-gutters>
                <v-col v-if="rngCldTyp == 'mnt'" class="" cols="12">
                    <CmmYMD
                        class="my-4"
                        :aplyYMD="$moment(aplyYyMm+'01', 'YYYYMMDD', true).format('YYYYMMDD') "
                        :aplyMinDt="$moment(aplyYyMm+'01', 'YYYYMMDD', true).format('YYYY-MM-DD')"
                        :aplyMaxDt="$moment(aplyYyMm, 'YYYYMM', true).endOf('month').format('YYYY-MM-DD')"
                        @sltHypDt="getCompDt">
                    </CmmYMD>
                </v-col>
                <v-col v-else-if="rngCldTyp == 'none'" class="" cols="12">
                    <CmmYMD
                        class="my-4"
                        :aplyYMD="$moment().format('YYYYMMDD')"
                        @sltHypDt="getCompDt">
                    </CmmYMD>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        {{$moment(sltDt, 'YYYYMMDD', true).format('YYYY년 MM월 DD일')}}&nbsp;식단표
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center nmlBfClr-grey001 py-2 tableBR" cols="2">
                    <span class="fontDotEight font-weight-medium">조식</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">일반식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.gnrlBrkfs }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">죽식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.papBrkfs }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center nmlBfClr-grey001 py-2 tableBR" cols="2">
                    <span class="fontDotEight font-weight-medium">오전</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-2" cols="2">
                            <span class="fontDotEight font-weight-medium">간식</span>
                        </v-col>
                        <v-col class="txtSpcWrp pa-2" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.snckAm }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center nmlBfClr-grey001 py-2 tableBR" cols="2">
                    <span class="fontDotEight font-weight-medium">중식</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">일반식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.gnrlLunch }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">죽식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.papLunch }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center nmlBfClr-grey001 py-2 tableBR" cols="2">
                    <span class="fontDotEight font-weight-medium">오후</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-2" cols="2">
                            <span class="fontDotEight font-weight-medium">간식</span>
                        </v-col>
                        <v-col class="txtSpcWrp pa-2" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.snckPm }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center nmlBfClr-grey001 py-2 tableBR" cols="2">
                    <span class="fontDotEight font-weight-medium">석식</span>
                </v-col>
                <v-col class="" cols="10">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">일반식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.gnrlDiner }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center nmlBfClr-grey001 tableBR py-1" cols="2">
                            <span class="fontDotEight font-weight-medium">죽식</span>
                        </v-col>
                        <v-col class="txtSpcWrp px-2 pb-1" cols="10">
                            <span class="fontDotEight">{{ dietMenuInf.papDiner }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>

            <v-row class="pa-0 mt-8" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        원산지
                    </span>
                </v-col>
            </v-row>

            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center px-1 py-2" cols="12">
                    <span class="fontDotEight">{{ dietMenuInf.orgn }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import CmmYMD from '../../cmm/CmmYMD_kr.vue';

export default {
    name: 'DietTbl',

    components: {
        CmmYMD,
    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
        rngCldTyp: { type: String, default: 'mnt' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.rngCldTyp == 'mnt')
                this.sltDt = this.$moment().format('YYYYMM') + '01'
            else if(this.rngCldTyp == 'none')
                this.sltDt = this.$moment().format('YYYYMMYY')
        
            // if(this.aplyYyMm != '')
            //     this.getMenuList(this.sltDt)
        })
    },

    computed: {
       
    },

    watch:{
        // 'aplyYyMm': function () {
        //     if(this.aplyYyMm != '')
        //         this.getMenuList(this.sltDt)
        // },
        // 'sltDt': function () {
        //     this.getMenuList(this.sltDt)
        // },
    },
        
    methods: {
        //일별 식단표 조회 TOKEN
        getMenuList: function (ymd){
            http.post('fmly/selMenuList', { inqYmdStart: ymd, inqYmdEnd: ymd })
                .then((response) => ( this.menuListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selMenuList : ' + error))
        },
        //일별 식단표 조회 Params
        getOutMenuList: function (code, pk, ymd){
            http.post('fmly/selMenuList', { fcltyNum: code, bnMmbrPk: pk, inqYmdStart: ymd, inqYmdEnd: ymd })
                .then((response) => ( this.menuListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selMenuList : ' + error))
        },
        menuListRst: function (res){
            if(res.statusCode == '200'){
                if(res.result[0] != null){
                    this.dietMenuInf = res.result[0]
                }
                else
                    this.dietMenuInf = {}
            }
        },
        getCompDt: function (dt) {
            this.sltDt = this.$moment(dt, 'YYYY-MM-DD', true).format('YYYYMMDD')

            if(this.bnMmbrPk == 0)
                this.getMenuList(this.sltDt)
            else
                this.getOutMenuList(this.fcltyNum, this.bnMmbrPk, this.sltDt)
        },
    },

    data: () => ({
        sltDt: '',
        dietMenuInf: {},
    }),
};
</script>