<template>
    <v-sheet class="">
        <v-row class="pa-0 align-center" no-gutters>
            <v-spacer></v-spacer>
            <v-btn class="arwBtn mr-2" color="btnBrdrColor" height="26" @click="prevAct" small outlined>
                <span class="black--text">
                    <v-icon>mdi-chevron-left</v-icon>
                </span>
            </v-btn>
            <v-btn class="arwBtn" color="btnBrdrColor" height="26" @click="nxtAct" small outlined>
                <span class="black--text">
                    <v-icon>mdi-chevron-right</v-icon>
                </span>
            </v-btn>
            <v-col cols="1"></v-col>
            <span class="blue102--text text-h5 font-weight-bold" v-text="dotDt"></span>
            <v-col cols="1"></v-col>
            <v-btn class="arwBtn pa-0"
                color="btnBrdrColor" height="26" @click="$store.commit('setPickDtMdl', true)" small outlined>
                <v-icon class="black--text" style="margin-top: 2px;" small>mdi-calendar-month</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-spacer></v-spacer>
            <v-col class="d-flex justify-center" cols="3">
                <v-btn class="arwBtn mr-2" color="btnBrdrColor" height="26" @click="prevAct" small outlined>
                    <span class="black--text">
                        <v-icon>mdi-chevron-left</v-icon>
                    </span>
                </v-btn>
                <v-btn class="arwBtn" color="btnBrdrColor" height="26" @click="nxtAct" small outlined>
                    <span class="black--text">
                        <v-icon>mdi-chevron-right</v-icon>
                    </span>
                </v-btn>
            </v-col>
            <v-col class="d-flex justify-center" cols="5">
                <span class="blue102--text text-h5 font-weight-bold pb-1" v-text="dotDt"></span>
            </v-col>
            <v-col class="d-flex justify-center" cols="1">
                <v-btn class="arwBtn pa-0"
                    color="btnBrdrColor" height="26" @click="$store.commit('setPickDtMdl', true)" small outlined>
                    <v-icon class="black--text" style="margin-top: 2px;" small>mdi-calendar-month</v-icon>
                </v-btn>
            </v-col>
            <v-spacer></v-spacer> -->
        </v-row>
    </v-sheet>
</template>

<script>


export default {
    name: 'CmmYMD_kr',

    components: {
       
    },

    props: {
        aplyYMD: { type: String, default: '' },
        aplyMinDt: { type: String, default: '' },
        aplyMaxDt: { type: String, default: '' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$store.commit('setPickDtMdlTyp', 'YMD')

            if(this.aplyYMD != ''){
                this.dotDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY-MM-DD')
                this.$store.commit('setPickDt', this.hypDt)
            }
            else{
                this.dotDt = this.$store.getters.getPrsntDt('yyyy.mm.dd')
                this.hypDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')
            }

            if(this.aplyMinDt != '')
                this.$store.commit('setPickDtMdlMin', this.aplyMinDt)
            if(this.aplyMaxDt != '')
                this.$store.commit('setPickDtMdlMax', this.aplyMaxDt)
        })
    },

    destroyed: function(){
        this.$store.commit('setPickDtMdlTyp', 'YMD')
        this.$store.commit('setPickDt', '')
        this.$store.commit('setPickDtMdlMin', '')
        this.$store.commit('setPickDtMdlMax', '')
    },

    computed: {
        getPickDt: function () {
            return this.$store.state.pickDt
        },
    },

    watch:{
        getPickDt: function (v) {
            this.dotDt = this.$moment(v, 'YYYY-MM-DD', true).format('YYYY.MM.DD')
            this.hypDt = v
            this.transDt()
        },
        'aplyYMD': function () {
            if(this.aplyYMD != '' && this.aplyYMD != this.hypDt){
                this.dotDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY-MM-DD')
                this.$store.commit('setPickDt', this.hypDt)
            }
        },
        'aplyMinDt': function () {
            if(this.aplyMinDt != '')
                this.$store.commit('setPickDtMdlMin', this.aplyMinDt)
            else
                this.$store.commit('setPickDtMdlMin', '')
        },
        'aplyMaxDt': function () {
            if(this.aplyMaxDt != '')
                this.$store.commit('setPickDtMdlMax', this.aplyMaxDt)
            else
                this.$store.commit('setPickDtMdlMax', '')
        },
    },
        
    methods: {
        prevAct: function () {
            if(this.aplyMinDt != ''){
                if(this.$moment(this.aplyMinDt).diff(this.hypDt, 'days') < 0){
                    this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).subtract(1, 'days').format('YYYY.MM.DD')
                    this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).subtract(1, 'days').format('YYYY-MM-DD')
                }
            }
            else{
                this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).subtract(1, 'days').format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).subtract(1, 'days').format('YYYY-MM-DD')
            }
            this.transDt()
        },
        nxtAct: function () {
            if(this.aplyMaxDt != ''){
                if(this.$moment(this.aplyMaxDt).diff(this.hypDt, 'days') > 0){
                    this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).add(1, 'days').format('YYYY.MM.DD')
                    this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).add(1, 'days').format('YYYY-MM-DD')
                }
            }
            else{
                this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).add(1, 'days').format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).add(1, 'days').format('YYYY-MM-DD')
            }
            this.transDt()
        },
        transDt: function () {
            this.$emit('sltDotDt', this.dotDt)
            this.$emit('sltHypDt', this.hypDt)
        },
    },

    data: () => ({
        dotDt: '',
        hypDt: '',
    }),
};
</script>