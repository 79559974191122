<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">더보기</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block px-5"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '70px' }">
            <div
                class="d-inline-block"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <v-divider class="blue104 pt-0_5 mt-5 mb-3"></v-divider>
                <v-row class="pa-0" style="margin-top: ;" no-gutters>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('HealthVtlApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_health" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">건강정보</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('FmltLtrApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_famlLtr" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">가정통신문</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('MealMenuApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_diet" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">식단표</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('BnftSrvRcrdApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_rcrdPpr" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">급여제공</span>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">기록지</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('BnftSrvSttmApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_stmt" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">급여비용</span>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">명세서</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('PhyThrRecApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_trtHst" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">물리&#40;작업&#41;</span>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">치료내역</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('DpstHstryApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_crdt" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">입금내역</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }"
                            @click="rtrAct('PrgmSchdlApp')">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_calendar" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">프로그램</span>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">일정</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" v-if="false" no-gutters>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_album" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">앨범</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="py-3 px-2" cols="4">
                        <span
                            class="d-inline-block d-flex justify-center align-center rounded ic_encs py-2 white"
                            style="width: 100%; height: 100%;"
                            v-ripple="{ center: true, class: 'grey--text' }">
                            <span>
                                <div class="d-flex justify-center">
                                    <CmmDrawIcon class="pt-0_5" type="menu_vstRsrv" size="40"></CmmDrawIcon>
                                </div>
                                <div class="txtAlgnMid pb-2" style="line-height: 0.9;">
                                    <span class="fontDotEight font-weight-medium">면회예약</span>
                                </div>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="blue104 pt-0_5 mt-3 mb-5"></v-divider>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';

export default {
    name: 'SiteMapApp',

    components: {
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 138
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 138
        },
    },
        
    methods: {
        prvRtr: function (){
            this.$router.go(-1)
        },
        rtrAct: function (nm){
            if(this.$route.name != nm)
                this.$router.push({ name: nm })
        },
    },

    data: () => ({
        viewHeight: 0,
    }),
};
</script>