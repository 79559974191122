<template>
    <v-sheet class="" width="100%">
        <v-row class="py-3 greyE02" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="py-1 pr-2" cols="4">
                <v-btn
                    class="px-0" width="100%" height="40" color="grey008"
                    @click="$store.commit('setPickLvnRmMdl', true)"
                    dense outlined>
                    <div class="d-flex align-center">
                        <span class="mr-3" style="font-size: 16px;">{{ $store.getters.getPickLvnRmNm }}</span>
                        <v-spacer></v-spacer>
                        <v-icon class="pt-1">mdi-menu-down</v-icon>
                    </div>
                </v-btn>
            </v-col>
            <v-col class="py-1 pl-2" cols="4">
                <v-text-field
                    v-model="nmSrch"
                    label="이름검색" @input="lstMltplSrch"
                    dense outlined hide-details>
                </v-text-field>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="align-center my-5" no-gutters>
            <v-icon class="font-weight-medium mr-1" size="26">mdi-chevron-right</v-icon>
            <span class="grey006--text font-weight-medium">수급자 목록</span>
            <v-spacer></v-spacer>
            <span class="grey006--text fontDotNine mr-3">전체: {{ bnfcrList.length }}명</span>
        </v-row>
        <v-divider class="mt-4 mx-2"></v-divider>
        <v-row class="greyE01 mx-2" no-gutters>
            <v-col class="txtAlgnMid py-1 tableBR " cols="5">
                <span class="font-weight-medium">수급자명</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="4">
                <span class="font-weight-medium">식사여부</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="3">
                <span class="font-weight-medium">식사도움</span>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <div
            v-if="bnfcrList.length > 0"
            class="d-inline-block overflow-y-auto"
            :style="{ 'width': '100%', 'height': ($vuetify.breakpoint.height - 390) + 'px' }">
            <v-row
                class="pa-0 mx-2 tableBB"
                v-for="(lst, i) in bnfcrList" :key="i"
                no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="5">
                    <span class="fontDotEight">{{ lst.name }}</span>
                    <span class="fontDotSeven">({{ lst.age }}세)</span>
                    <span class="fontDotSeven">&nbsp;-&nbsp;{{ lst.lvnRmNm }}</span>
                </v-col>
                <v-col v-if="lst.outYn == '2'" class="txtAlgnMid py-1 tableBR" cols="4">
                    <div>
                        <span
                            :class="['d-inline-block rounded onOffSpan', lst.mornMl ? 'blue001 white--text' : 'black--text']"
                            style="width: 33px; margin-right: 2px; padding-right: 2px;">
                            <span class="fontDotSeven">아침</span>
                        </span>
                        <span
                            :class="['d-inline-block rounded onOffSpan', lst.lnchMl ? 'blue001 white--text' : 'black--text']"
                            style="width: 33px; margin-right: 2px; padding-right: 2px;">
                            <span class="fontDotSeven">점심</span>
                        </span>
                        <span
                            :class="['d-inline-block rounded onOffSpan', lst.dinrMl ? 'blue001 white--text' : 'black--text']"
                            style="width: 33px; padding-right: 2px;">
                            <span class="fontDotSeven">저녁</span>
                        </span>
                    </div>
                    <div>
                        <span
                            :class="['d-inline-block rounded onOffSpan', lst.amSnk ? 'blue001 white--text' : 'black--text']"
                            style="width: 52px; margin-right: 2px; padding-right: 2px;">
                            <span class="fontDotSeven">오전간식</span>
                        </span>
                        <span
                            :class="['d-inline-block rounded onOffSpan', lst.amSnk ? 'blue001 white--text' : 'black--text']"
                            style="width: 52px; padding-right: 2px;">
                            <span class="fontDotSeven">오후간식</span>
                        </span>
                    </div>
                </v-col>
                <v-col
                    v-if="lst.outYn == '2'"
                    :class="['d-flex justify-center align-center', lst.itmMeal == null ? 'nmlBfClr-yellow001' : 'nmlBfClr-green001' ]"
                    @click="onCbSrMdl(lst.bnMmbrPk, lst.name, lst.bnftOfrRecHisPk, lst.intbNutrYn, '1')"
                    cols="3">
                    <span v-if="lst.itmMeal == null" class="fontDotEight mx-1">미작성</span>
                    <span v-else class="fontDotEight mx-1">작성</span>
                </v-col>
                <v-col v-else-if="lst.outYn == '1'" class="txtAlgnMid py-4" cols="7">
                    <span class="fontDotEight">외박중</span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0 mx-2 tableBB" no-gutters>
                <v-col class="txtAlgnMid px-2 py-2" cols="12">
                    <span class="fontDotEight">조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="$store.state.bnftcrdTotMdl" persistand>
            <MealSrvRcrdMdl v-if="$store.state.bnftcrdCtgMdl.yNo == '1'"></MealSrvRcrdMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import MealSrvRcrdMdl from './MealSrvRcrdMdl.vue';

export default {
    name: 'MealSrvRcrd',

    components: {
        MealSrvRcrdMdl,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.sltHypDt == '')
                this.getMobAllBnftMealSttList(this.$store.getters.getPrsntDt('yyyymmdd'))
            else
                this.getMobAllBnftMealSttList(
                    this.$moment(this.$parent.$parent.$parent.$parent.sltHypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )
        })
    },

    computed: {
        getSltHypDt: function(){
            return this.$parent.$parent.$parent.$parent.sltHypDt
        },
        getSltLvnRmNm: function(){
            return this.$store.getters.getPickLvnRmNm 
        },
    },

    watch:{
        getSltHypDt: function(dt){
            let hypDt = this.$moment(dt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            this.getMobAllBnftMealSttList(hypDt)
        },
        getSltLvnRmNm: function(){
            this.lstMltplSrch()
        },
    },
        
    methods: {
        //수급자 정보 조회
        getMobAllBnftMealSttList: function (ymd){
            http.post('empApp/authSelAllMealSttList', { inqYMD: ymd })
                .then((response) => ( this.allBnftMealSttListRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelAllMealSttList : ' + error))
        },
        allBnftMealSttListRst: function (res){
            
            this.orgBnfcrList.splice(0)
            this.bnfcrList.splice(0)

            if(res.result != null){
                this.orgBnfcrList = res.result
                this.orgBnfcrList.forEach(obl => {
                    //mornMl: false, lnchMl: false, dinrMl: false, amSnk: false, pmSnk: false
                    if(obl.itmMeal != null){
                        if( obl.itmMeal.findIndex( v => v == '아침' ) > -1 )
                            obl.mornMl = true
                        else
                            obl.mornMl = false

                        if( obl.itmMeal.findIndex( v => v == '점심' ) > -1 )
                            obl.lnchMl = true
                        else
                            obl.lnchMl = false

                        if( obl.itmMeal.findIndex( v => v == '저녁' ) > -1 )
                            obl.dinrMl = true
                        else
                            obl.dinrMl = false

                        if( obl.itmMeal.findIndex( v => v == '오전' ) > -1 )
                            obl.amSnk = true
                        else
                            obl.amSnk = false

                        if( obl.itmMeal.findIndex( v => v == '오후' ) > -1 )
                            obl.pmSnk = true
                        else
                            obl.pmSnk = false
                    }
                });

                this.lstMltplSrch()
            }
        },
        //리스트 검색
        lstMltplSrch: function (){
            this.bnfcrList.splice(0)
            
            if(this.$store.getters.getPickLvnRmNm == '전체'){
                if(this.nmSrch == '')
                    this.bnfcrList = Object.assign([], this.orgBnfcrList)
                else
                    this.bnfcrList = Object.assign([], this.orgBnfcrList.filter( v => v.name.includes(this.nmSrch) ))
            }
            else{
                if(this.nmSrch == '')
                    this.bnfcrList = Object.assign(
                        [], this.orgBnfcrList.filter( v => v.lvnRmNm == this.$store.getters.getPickLvnRmNm )
                    )
                else
                    this.bnfcrList = Object.assign(
                        [], this.orgBnfcrList.filter(
                            v => v.lvnRmNm == this.$store.getters.getPickLvnRmNm && v.name.includes(this.nmSrch)
                        )
                    )
            }
        },
        onCbSrMdl: function (bmPk, nm, brhPk, nutrYn, cnd){
            let obj = {}
            obj.bnMmbrPk = bmPk
            obj.name = nm
            obj.bnftOfrRecHisPk = brhPk
            obj.intbNutrYn = nutrYn
            obj.inqYMD = this.$moment(this.$parent.$parent.$parent.$parent.sltHypDt, 'YYYY-MM-DD', true).format('YYYY.MM.DD')

            this.$store.commit('setBnftcrdTotMdl', true)
            this.$store.commit('setBnftcrdCtgMdl', cnd)
            this.$store.commit('setBnftcrdCtgObj', obj)
        },
    },

    data: () => ({
        orgBnfcrList: [],
        bnfcrList: [],
        nmSrch: '',
    }),
};
</script>