<template>
    <v-sheet width="100%" class="pa-1">
        <v-row class="txtAlgnMid pa-0" no-gutters>
            <span>잠시만 기다려 주세요...</span>
        </v-row>
    </v-sheet>
</template>

<script>

export default {
    name: 'NtvLgn',

    components: {
       
    },

    props: {

    },

    created: function(){
        window.app = this
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })  
    },

    beforeDestroy() {
       
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        getToken: function (tkn, key) {
            this.$store.commit('setUserSpr', 'gndr')
            this.$store.commit('setToken', tkn)
            this.$store.commit('setSctKey', key)
            this.$router.replace({ name: 'BnfrcMgmt' }).catch(()=>{})
        },
        empLgn: function (tkn, key, nm, mmbrPk, jobNm, fcltyClnm) {
            this.$store.commit('setToken', tkn)
            this.$store.commit('setSctKey', key)
            this.$store.commit('setUserNm', nm)
            this.$store.commit('setUserPk', mmbrPk)
            this.$store.commit('setJobNm', jobNm)
            this.$store.commit('setFltyClnm', fcltyClnm)

            this.$router.replace({ name: 'EmpMgmt' }).catch(()=>{})
        },
        callNtv: function () {
            window.Android.showToast('Native Interface Line test')
        }
    },

    data: () => ({

    }),
};
</script>