<template>
    <v-sheet class="">
        <v-row class="pa-0 align-center" no-gutters>
            <span class="fontOneToOne font-weight-bold" style="padding-bottom: 1px;">
                {{ sltYear }}년
            </span>
            <v-spacer></v-spacer>
            <v-btn class="" @click="prevBtn == false ? prevAct() : ''" icon>
                <CmmDrawIcon type="ic_prev" size="20"></CmmDrawIcon>
            </v-btn>
            <v-btn class="mr-1" @click="nxtBtn == false ? nxtAct() : ''" icon>
                <CmmDrawIcon type="ic_nxt" size="20"></CmmDrawIcon>
            </v-btn>
            <v-btn class="" @click="yySltr = true" icon>
                <CmmDrawIcon type="ic_calendar" size="20"></CmmDrawIcon>
            </v-btn>
        </v-row>
        <v-dialog v-model="yySltr" max-width="180">
            <v-sheet rounded="lg">
                <div class="rounded" style="position: relative;">
                    <div
                        class="d-inline-block white"
                        style="width: 100%; height: 60px; position: sticky; top: 0; left: 0; z-index: 6; border-bottom: 1px solid #dbdbdb !important;">
                        <div class="pt-4 px-5 d-flex align-center">
                            <span class="fontOneToOne font-weight-bold">연도 선택</span>
                            <v-spacer></v-spacer>

                        </div>
                    </div>
                    <div
                        class="d-inline-block"
                        style="width: 100%; z-index: 2;">
                        <v-row
                            :class="[
                                'pa-0 justify-center align-center',
                                getArrFrRng(aplyMinYear, aplyMaxYear).length - 1 == i ? '' : 'tableBB',
                                sltYear == y ? 'nmlBfClr-greyE03' : ''
                            ]"
                            v-for="(y, i) in getArrFrRng(aplyMinYear, aplyMaxYear).slice().reverse()" :key="i"
                            @click="setYYYY(y)"
                            no-gutters>
                            <span class="fontOne font-weight-medium mt-4 mb-5">{{ y }}</span>
                        </v-row>
                    </div>
                    <div
                        class="d-inline-block white"
                        style="width: 100%; height: 25px; position: sticky; bottom: 0; left: 0; z-index: 6; border-top: 1px solid #dbdbdb !important;">
                        <div class="d-flex align-center">&nbsp;</div>
                    </div>
                </div>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from './CmmDrawIcon.vue';

export default {
    name: 'AppYYYY',

    components: {
        CmmDrawIcon,
    },

    props: {
        aplyYear: { type: String, default: '' },
        aplyMinYear: { type: String, default: '1900' },
        aplyMaxYear: { type: String, default: '2999' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.aplyYear != '')
                this.sltYear = this.aplyYear
            else
                this.sltYear = this.$moment().format('YYYY')

            this.transDt()
        })
    },

    computed: {
 
    },

    watch:{
        'sltYear': function () {
            let min = Number(this.aplyMinYear)
            let max = Number(this.aplyMaxYear)
            let now = Number(this.sltYear)

            if(min < now)
                this.prevBtn = false
            else
                this.prevBtn = true

            if(now < max)
                this.nxtBtn = false
            else
                this.nxtBtn = true
        },
    },
        
    methods: {
        prevAct: function () {
            let min = Number(this.aplyMinYear)
            let now = Number(this.sltYear)

            if(min < now){
                this.sltYear = String(now - 1)
                this.transDt()
            }
        },
        nxtAct: function () {
            let max = Number(this.aplyMaxYear)
            let now = Number(this.sltYear)

            if(now < max){
                this.sltYear = String(now + 1)
                this.transDt()
            }
        },
        transDt: function () {
            this.$emit('sltYear', this.sltYear)
        },
        setYYYY: function (yyyy) {
            this.sltYear = yyyy
            this.transDt()
            this.onClose()
        },
        onClose: function () {
            this.yySltr = false
        },
        getArrFrRng: function (n, x) {
            return [...Array(Number(x) - Number(n) + 1).keys()].map((i) => i + Number(n))
        },
    },

    data: () => ({
        sltYear: '',
        yySltr: false,
        prevBtn: false,
        nxtBtn: false,
    }),
};
</script>