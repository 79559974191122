<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">급여제공기록지</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYMD :aplyMinDt="'2020-01-01'" :aplyMaxDt="$moment().format('YYYY-MM-DD')" @sltHypDt="getHypDt"></AppYMD>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 mt-15" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="pt-1" cols="10">
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                <span class="blue104--text fontDotEight font-weight-medium">신체활동 지원</span>
                            </span>
                            <v-spacer></v-spacer>
                            <span
                                class="d-inline-block d-flex align-start pt-1"
                                style="height: 30px; border-bottom: 1px solid black;">
                                <CmmDrawIcon type="ic_info_user" size="20"></CmmDrawIcon>
                                <span class="fontDotEight font-weight-medium">작성자</span>
                                <span class="fontDotEight ml-2">
                                    {{ hisWrtrs[0] == '' ? '없음' : hisWrtrs[0] }}
                                </span>
                            </span>
                        </v-row>
                        <v-divider class="black002 mt-3"></v-divider>
                        <v-row class="pa-0" v-for="(list, i) in recList1" :key="i" no-gutters>
                            <v-col
                                :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-start align-center' : '' ]"
                                style="line-height: 1.3;" cols="5">
                                <div class="font-weight-medium fontDotEight">{{ list.clsfc3Cdnm }}</div>
                                <div
                                    :class="['txtAlgnFst', list.clsfc3Cd == '10' ? 'fontDotSeven greyE06--text' : 'font-weight-medium fontDotEight']">
                                    {{ list.txtSub }}
                                </div>
                            </v-col>
                            <v-col class="py-1 txtAlgnMid tableBB tableBR" style="line-height: 1.3;" cols="3">
                                <span class="fontDotEight" v-html="list.plnNm"></span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                                <div v-if="list.clsfc3Cd == '11'" style="padding: 0 1px;">
                                    <span
                                        :class="['d-inline-block rounded onOffSpan', list.itmAr1 ? 'grey006 white--text' : 'black--text']"
                                        style="width: 40px; margin-right: 1px;">
                                        <v-icon :class="[list.itmAr1 ? 'white--text' : 'black--text']" size="12">mdi-walk</v-icon>
                                        <span style="font-size: 0.69rem;">산책</span>
                                    </span>

                                    <span
                                        :class="['d-inline-block rounded onOffSpan', list.itmAr2 ? 'grey006 white--text' : 'black--text']"
                                        style="width: 40px;">
                                        <v-icon :class="[list.itmAr2 ? 'white--text' : 'black--text']" size="12">mdi-exit-run</v-icon>
                                        <span style="font-size: 0.69rem;">외출</span>
                                    </span>
                                </div>
                                <div
                                    class="fontDotEight px-2" style="line-height: 1.3;"
                                    v-else-if="list.clsfc3Cd == '98'">
                                    {{ list.itmVal }}
                                </div>
                                <span
                                    v-else-if="list.clsfc3Cd == '4'"
                                    :class="['fontDotEight', list.clr == 'red' ? 'grey006--text' : 'greyE08--text']">
                                    {{ list.itmVal }}
                                </span>
                                <span style="padding: 0 1px;" v-else-if="list.clsfc3Cd == '96'">
                                    <div class="d-flex justify-center">
                                        <span
                                            :class="['d-inline-block rounded onOffSpan', list.morn ? 'grey006 white--text' : 'black--text']"
                                            style="width: 30px; margin-right: 1px;">
                                            <span class="fontDotSeven">아침</span>
                                        </span>
                                        <span
                                            :class="['d-inline-block rounded onOffSpan', list.lnch ? 'grey006 white--text' : 'black--text']"
                                            style="width: 30px; margin-right: 1px;">
                                            <span class="fontDotSeven">점심</span>
                                        </span>
                                        <span
                                            :class="['d-inline-block rounded onOffSpan', list.dinr ? 'grey006 white--text' : 'black--text']"
                                            style="width: 30px;">
                                            <span class="fontDotSeven">저녁</span>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center mt-1">
                                        <span
                                            :class="['d-inline-block rounded onOffSpan', list.am ? 'grey006 white--text' : 'black--text']"
                                            style="width: 30px; padding-left: 2px; margin-right: 1px; border: 1px solid black;">
                                            <span class="fontDotSeven">오전</span>
                                        </span>
                                        <span
                                            :class="['d-inline-block rounded onOffSpan', list.pm ? 'grey006 white--text' : 'black--text']"
                                            style="width: 30px; padding-left: 2px; border: 1px solid black;">
                                            <span class="fontDotSeven">오후</span>
                                        </span>
                                    </div>
                                </span>
                                <span v-else class="fontDotEight">{{ list.itmVal }} 회</span>
                            </v-col>
                        </v-row>
                        <div class="pt-3 pb-2">
                            <span class="font-weight-medium fontDotEight">신체활동 특이사항</span>
                        </div>
                        <div
                            class="d-inline-block overflow-y-auto rounded greyE07 px-2 py-1"
                            style="width: 100%; height: 50px; word-break: break-all; line-height: 1.2;">
                            <span class="grey006--text fontDotEight font-weight-medium">{{ recList1Spc }}</span>
                        </div>
                        <v-divider class="my-5"></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                <span class="blue104--text fontDotEight font-weight-medium">인지·정서 지원</span>
                            </span>
                            <v-spacer></v-spacer>
                            <span
                                class="d-inline-block d-flex align-start pt-1"
                                style="height: 30px; border-bottom: 1px solid black;">
                                <CmmDrawIcon type="ic_info_user" size="20"></CmmDrawIcon>
                                <span class="fontDotEight font-weight-medium">작성자</span>
                                <span class="fontDotEight ml-2">
                                    {{ hisWrtrs[1] == '' ? '없음' : hisWrtrs[1] }}
                                </span>
                            </span>
                        </v-row>
                        <v-divider class="black002 mt-3"></v-divider>
                        <v-row class="pa-0" v-for="(list, j) in recList2" :key="recList1.length + j" no-gutters>
                            <v-col
                                :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-start align-center' : '' ]"
                                cols="5">
                                <div class="d-flex justify-start align-center">
                                    <span class="font-weight-medium fontDotEight">{{ list.clsfc3Cdnm }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1 txtAlgnMid tableBB tableBR" style="line-height: 1.3;" cols="3">
                                <span class="fontDotEight" v-html="list.plnNm"></span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                                <div
                                    class="overflow-x-hidden overflow-y-auto d-inline-block px-1"
                                    style="width: 100%; height: 42px; word-break: break-all;"
                                    v-if="list.clsfc3Cd == '99'">
                                    <span class="fontDotEight">{{ list.itmVal }}</span>
                                </div>
                                <span v-else class="fontDotEight">{{ list.itmVal }} 회</span>
                            </v-col>
                        </v-row>
                        <div class="pt-3 pb-2">
                            <span class="font-weight-medium fontDotEight">인지정서 특이사항</span>
                        </div>
                        <div
                            class="d-inline-block overflow-y-auto rounded greyE07 px-2 py-1"
                            style="width: 100%; height: 50px; word-break: break-all; line-height: 1.2;">
                            <span class="grey006--text fontDotEight font-weight-medium">{{ recList2Spc }}</span>
                        </div>
                        <v-divider class="my-5"></v-divider>
                        <v-row class="pa-0 align-center" no-gutters>
                            <span class="rounded-xl px-3 pb-1" style="border: 1px solid #1B81DF;">
                                <span class="blue104--text fontDotEight font-weight-medium">기능회복</span>
                            </span>
                            <v-spacer></v-spacer>
                            <span
                                class="d-inline-block d-flex align-start pt-1"
                                style="height: 30px; border-bottom: 1px solid black;">
                                <CmmDrawIcon type="ic_info_user" size="20"></CmmDrawIcon>
                                <span class="fontDotEight font-weight-medium">작성자</span>
                                <span class="fontDotEight ml-2">
                                    {{ hisWrtrs[2] == '' ? '없음' : hisWrtrs[2] }}
                                </span>
                            </span>
                        </v-row>
                        <v-divider class="black002 mt-3"></v-divider>
                        <v-row
                            class="pa-0"
                            v-for="(list, k) in recList3" :key="recList1.length + recList2.length + k" no-gutters>
                            <v-col
                                :class="['py-1 tableBB tableBR', list.txtSub == '' ? 'd-flex justify-start align-center' : '' ]"
                                cols="5">
                                <div class="d-flex justify-start align-center">
                                    <span class="font-weight-medium fontDotEight">{{ list.clsfc3Cdnm }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1 txtAlgnMid tableBB tableBR" style="line-height: 1.3;" cols="3">
                                <span class="fontDotEight" v-html="list.plnNm"></span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBB" cols="4">
                                <span class="fontDotEight">{{ list.itmVal }} 회</span>
                            </v-col>
                        </v-row>
                        <v-divider class="white mt-5"></v-divider>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYMD from '../../cmm/AppYMD.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'BnftSrvRcrdApp',

    components: {
        CmmDrawIcon,
        AppYMD,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getBnftOfrRecDay(this.$moment().format('YYYYMMDD'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 본인부담금 청구내역 조회 (월간)
        getBnftOfrRecDay: function (ymd){
            this.clrFrm()
            this.dmyRecList1.forEach(rec1 => {
                this.recList1.push(Object.assign({}, rec1))
            });
            this.dmyRecList2.forEach(rec2 => {
                this.recList2.push(Object.assign({}, rec2))
            });
            this.dmyRecList3.forEach(rec3 => {
                this.recList3.push(Object.assign({}, rec3))
            });
            this.hlthNrs = Object.assign({}, this.dmyHlthNrs)

            http.post('fmlyApp/authBnftOfrRecDay', { inqYMD: ymd })
                .then((response) => ( this.bnftOfrRecDayRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authBnftOfrRecDay : ' + error))
        },
        bnftOfrRecDayRst: function (res){
            if(res.statusCode == '200'){
                //좌측 메뉴 텍스트 작성
                if(res.result.plnList.length > 0){
                    let trgArr = res.result.plnList.filter( v => v.clsfc2Cd == '1' )
                    
                    trgArr.forEach(list => {
                        let chc = this.recList1.findIndex( v => v.clsfc2Cd == list.clsfc2Cd && v.clsfc3Cd == list.clsfc3Cd )

                        if(chc > -1){
                            this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                                if(list.itmVal1 == cd.valcd && list.itmVal2 != null)
                                    this.recList1[chc].plnNm = '<div>' + cd.valcdnm + list.itmVal2 + '회</div><div>/ ' + list.itmVal3 + '분</div>'
                                else if(list.itmVal1 == cd.valcd && list.itmVal2 == null)
                                    this.recList1[chc].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + list.itmVal3 + '분</div>'
                            });
                        }
                    });

                    let trgArr2 = res.result.plnList.filter( v => v.clsfc2Cd == '7' )
                    
                    trgArr2.forEach(list => {
                        let chc = this.recList3.findIndex( v => v.clsfc2Cd == list.clsfc2Cd && v.clsfc3Cd == list.clsfc3Cd )

                        if(chc > -1){
                            this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                                if(list.itmVal1 == cd.valcd && list.itmVal2 != null)
                                    this.recList3[chc].plnNm = '<div>' + cd.valcdnm + list.itmVal2 + '회</div><div>/ ' + list.itmVal3 + '분</div>'
                                else if(list.itmVal1 == cd.valcd && list.itmVal2 == null)
                                    this.recList3[chc].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + list.itmVal3 + '분</div>'
                            });
                        }
                    });

                    let chc1 = res.result.plnList.findIndex( v => v.clsfc2Cd == '3' )
                    if(chc1 > -1){
                        this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                            if(res.result.plnList[chc1].itmVal1 == cd.valcd && res.result.plnList[chc1].itmVal2 != null)
                                this.recList2[0].plnNm = '<div>' + cd.valcdnm + res.result.plnList[chc1].itmVal2 + '회</div><div>/ ' + res.result.plnList[chc1].itmVal3 + '분</div>'
                            else if(res.result.plnList[chc1].itmVal1 == cd.valcd && res.result.plnList[chc1].itmVal2 == null)
                                this.recList2[0].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + res.result.plnList[chc1].itmVal3 + '분</div>'
                        });
                    }
                    let chc2 = res.result.plnList.findIndex( v => v.clsfc2Cd == '4' )
                    if(chc2 > -1){
                        this.$store.getters.getCmmCode('vCd45').forEach(cd => {
                            if(res.result.plnList[chc2].itmVal1 == cd.valcd && res.result.plnList[chc2].itmVal2 != null)
                                this.recList2[1].plnNm = '<div>' + cd.valcdnm + res.result.plnList[chc2].itmVal2 + '회</div><div>/ ' + res.result.plnList[chc2].itmVal3 + '분</div>'
                            else if(res.result.plnList[chc2].itmVal1 == cd.valcd && res.result.plnList[chc2].itmVal2 == null)
                                this.recList2[1].plnNm = '<div>' + cd.valcdnm + '</div><div>/ ' + res.result.plnList[chc2].itmVal3 + '분</div>'
                        });
                    }

                    //신체활동지원
                    if(Object.keys(res.result.recInfo1).length > 0){
                        this.hisWrtrs.splice(0, 1, res.result.recInfo1.hisWrtr)
                        this.recList1Spc = res.result.recInfo1.spclNote
                        this.recList1.forEach(rec1 => {
                            switch (rec1.clsfc3Cdnm) {
                                case '옷갈아입기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm1Val)
                                    break;
                                case '세면하기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm2Val)
                                    break;
                                case '양치하기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm3Val)
                                    break;
                                case '머리감기':
                                    rec1.itmVal = Number(res.result.recInfo1.itm5Val)
                                    break;
                                case '몸단장':
                                    rec1.itmVal = Number(res.result.recInfo1.itm6Val)
                                    break;
                                case '이동도움':
                                    rec1.itmVal = Number(res.result.recInfo1.itm10Val)
                                    break;
                                case '산책(외출) 동행':
                                    if(res.result.recInfo1.itm11ValAr?.findIndex( v => v == '1' ) > -1)
                                        rec1.itmAr1 = true
                                    if(res.result.recInfo1.itm11ValAr?.findIndex( v => v == '2' ) > -1)
                                        rec1.itmAr2 = true
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //인지정서지원
                    if(Object.keys(res.result.recInfo2).length > 0){
                        this.hisWrtrs.splice(1, 1, res.result.recInfo2.hisWrtr)
                        this.recList2Spc = res.result.recInfo2.spclNote
                        this.recList2.forEach(rec2 => {
                            switch (rec2.clsfc3Cdnm) {
                                case '인지관리 지원':
                                    rec2.itmVal = Number(res.result.recInfo2.itm1Val)
                                    break;
                                case '의사소통, 정서':
                                    rec2.itmVal = Number(res.result.recInfo2.itm2Val)
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //기능회복
                    if(Object.keys(res.result.recInfo3).length > 0){
                        this.hisWrtrs.splice(2, 1, res.result.recInfo3.hisWrtr)
                        this.recList3.forEach(rec3 => {
                            switch (rec3.clsfc3Cdnm) {
                                case '신체기능의 훈련':
                                    rec3.itmVal = Number(res.result.recInfo3.itm1Val)
                                    break;
                                case '기본동작 훈련':
                                    rec3.itmVal = Number(res.result.recInfo3.itm2Val)
                                    break;
                                case '일상생활동작 훈련':
                                    rec3.itmVal = Number(res.result.recInfo3.itm2Val)
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    //건강및간호관리
                    if(res.result.hlthNrs == null)
                        res.result.hlthNrs = Object.assign({}, this.dmyHlthNrs)

                    if(Object.keys(res.result.hlthNrs).length > 0){
                        this.hlthNrs = res.result.hlthNrs

                        if(res.result.hlthNrs.wrtr != null)
                            this.hisWrtrs.splice(3, 1, res.result.hlthNrs.wrtr)
                    }

                    res.result.rec301List.forEach(list => {
                        //몸씻기
                        if(list.bathSchdlPk > 0 && list.bathSchdlPk != null) { 
                            if(list.actBth != '' && list.actBth != null){
                                this.recList1[5].clr = 'red'
                                this.recList1[5].itmVal = list.actBth
                            }
                            else{
                                this.recList1[5].clr = 'blue'
                                this.recList1[5].itmVal = list.bthTm
                            }
                        }
                        //식사하기
                        let mealArr = list.itmMeal
                        if(mealArr != null){
                            mealArr.forEach(itm => {
                                if(itm == '아침') this.recList1[6].morn = true
                                else if(itm == '점심') this.recList1[6].lnch = true
                                else if(itm == '저녁') this.recList1[6].dinr = true
                                else if(itm == '오전') this.recList1[6].am = true
                                else if(itm == '오후') this.recList1[6].pm = true
                            });
                        }
                        //체위변경
                        if(list.itmPos != null)
                            this.recList1[7].itmVal = list.itmPos
                        //화장실 이용하기
                        if(list.itmToil != null)
                            this.recList1[8].itmVal = list.itmToil
                    });
                }
            }
        },
        clrFrm: function () {
            for (let index = 0; index < this.hisWrtrs.length; index++) {
                this.hisWrtrs.splice(index, 1, '')
            }

            this.recList1.splice(0)
            this.recList2.splice(0)
            this.recList3.splice(0)
            this.hlthNrs = null
            this.recList1Spc = ''
            this.recList2Spc = ''
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != '')
                this.getBnftOfrRecDay(this.$moment(v, 'YYYY-MM-DD').format('YYYYMMDD'))
        },
    },

    data: () => ({
        viewHeight: 0,
        recList1Spc: '',
        recList2Spc: '',
        hisWrtrs: [ '', '', '', '', ],
        recList1: [],
        recList2: [],
        recList3: [],
        hlthNrs: null,
        dmyRecList1: [
            //신체활동지원 ( 301, 1, 1 ) ( val1, val2, val3, val4, val5, val10, val11 )
            { clsfc2Cd: '1', clsfc3Cd: '1', clsfc3Cdnm: '옷갈아입기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '2', clsfc3Cdnm: '세면하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '3', clsfc3Cdnm: '양치하기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '5', clsfc3Cdnm: '머리감기', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '6', clsfc3Cdnm: '몸단장', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '4', clsfc3Cdnm: '몸씻기', txtSub:'', plnNm: '', clr: '', itmVal: '', },
            { clsfc2Cd: '1', clsfc3Cd: '96', clsfc3Cdnm: '식사하기', txtSub:'', plnNm: '', morn: false, lnch: false, dinr: false, am: false, pm: false },
            { clsfc2Cd: '1', clsfc3Cd: '97', clsfc3Cdnm: '체위변경', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '98', clsfc3Cdnm: '화장실 이용하기', txtSub:'', plnNm: '', itmVal: '', },
            { clsfc2Cd: '1', clsfc3Cd: '10', clsfc3Cdnm: '이동도움', txtSub:'신체 기능유지, 증진', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '1', clsfc3Cd: '11', clsfc3Cdnm: '산책(외출) 동행', txtSub:'', plnNm: '', itmAr1: false, itmAr2: false, },
            // { clsfc2Cd: '1', clsfc3Cd: '99', clsfc3Cdnm: '신체활동', txtSub:'특이사항', plnNm: '', itmVal: '' },
        ],
        dmyRecList2: [
            //인지정서지원 ( 301, 1, 2 ) ( val1, val2 )
            { clsfc2Cd: '3', clsfc3Cd: '1', clsfc3Cdnm: '인지관리 지원', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '4', clsfc3Cd: '1', clsfc3Cdnm: '의사소통, 정서', txtSub:'', plnNm: '', itmVal: 0, },
            // { clsfc2Cd: '5', clsfc3Cd: '99', clsfc3Cdnm: '인지 정서', txtSub:'특이사항', plnNm: '', itmVal: '' },
        ],
        dmyRecList3: [
            //기능회복훈련 ( 301, 1, 3 ) ( val1, val2, val3 )
            { clsfc2Cd: '7', clsfc3Cd: '2', clsfc3Cdnm: '신체기능의 훈련', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '7', clsfc3Cd: '3', clsfc3Cdnm: '기본동작 훈련', txtSub:'', plnNm: '', itmVal: 0, },
            { clsfc2Cd: '7', clsfc3Cd: '4', clsfc3Cdnm: '일상생활동작 훈련', txtSub:'', plnNm: '', itmVal: 0, },
        ],
        dmyHlthNrs: {
            bldPrsH: '', bldPrsL: '', bldSgr: '', cntnt: '', hltTmSum: 0, nlstmsum: 0, pulse: '', recDt: '',
            rn: 0, rnTot: 0, rsprt: '', tmprt: '', wght: '', wrtr: ''
        },
    }),
};
</script>