import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import vueEsign from 'vue-esign'
import vueCookies from "vue-cookies"
import { router } from './router/index.js'
import { store } from './store.js'

import moment from 'moment'
import 'moment/locale/ko'
import './registerServiceWorker'

import crypto from 'crypto-js'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
Vue.prototype.$crypto = crypto

Vue.use(vueEsign)
Vue.use(vueCookies)

Vue.$cookies.config("30d")

new Vue({
    vuetify,
    router,
    store: store,
    render: h => h(App)
}).$mount('#app')
