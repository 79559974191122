<template>
    <v-sheet class="" width="100%">
        <v-row class="py-3 greyE02" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="py-1 pr-2" cols="4">
                <v-btn
                    class="px-0" width="100%" height="40" color="grey008"
                    @click="$store.commit('setPickLvnRmMdl', true)"
                    dense outlined>
                    <div class="d-flex align-center">
                        <span class="mr-3" style="font-size: 16px;">{{ $store.getters.getPickLvnRmNm }}</span>
                        <v-spacer></v-spacer>
                        <v-icon class="pt-1">mdi-menu-down</v-icon>
                    </div>
                </v-btn>
            </v-col>
            <v-col class="py-1 pl-2" cols="4">
                <v-text-field
                    v-model="nmSrch"
                    label="이름검색" @input="lstMltplSrch"
                    dense outlined hide-details>
                </v-text-field>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="align-center my-5" no-gutters>
            <v-icon class="font-weight-medium mr-1" size="26">mdi-chevron-right</v-icon>
            <span class="grey006--text font-weight-medium">수급자 목록</span>
            <v-spacer></v-spacer>
            <span class="grey006--text fontDotNine mr-3">전체: {{ bnfcrList.length }}명</span>
        </v-row>
        <v-divider class="mt-4 mx-2"></v-divider>
        <v-row class="greyE01 mx-2" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR " cols="5">
                <span class="font-weight-medium">수급자명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <div style="line-height: 1.2;">
                    <div class="txtAlgnMid">
                        <span class="font-weight-medium">목욕</span>
                    </div>
                    <div class="txtAlgnMid">
                        <span class="font-weight-medium">일정시간</span>
                    </div>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <div style="line-height: 1.2;">
                    <div class="txtAlgnMid">
                        <span class="font-weight-medium">목욕도움</span>
                    </div>
                    <div class="txtAlgnMid">
                        <span class="font-weight-medium">&#40;목욕시간&#41;</span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <div
            v-if="bnfcrList.length > 0"
            class="d-inline-block overflow-y-auto"
            :style="{ 'width': '100%', 'height': ($vuetify.breakpoint.height - 414) + 'px' }">
            <v-row
                class="pa-0 mx-2 tableBB"
                v-for="(lst, i) in bnfcrList" :key="i"
                no-gutters>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="5">
                    <span class="fontDotEight">{{ lst.name }}</span>
                    <span class="fontDotSeven">({{ lst.age }}세)</span>
                    <span class="fontDotSeven">&nbsp;-&nbsp;{{ lst.lvnRmNm }}</span>
                </v-col>
                <v-col v-if="lst.outYn == '2'" class="txtAlgnMid py-2 tableBR" cols="3">
                    <span class="fontDotEight" v-if="lst.bthTm != null">{{ lst.bthTm }}</span>
                    <span class="fontDotEight" v-else>&nbsp;</span>
                </v-col>
                <v-col
                    v-if="lst.outYn == '2'"
                    :class="['d-flex justify-center align-center', lst.bathSchdlPk == null ? 'nmlBfClr-yellow001' : 'nmlBfClr-green001' ]"
                    @click="onCbSrMdl(lst.bnMmbrPk, lst.name, lst.bthTm, lst.actTm)"
                    cols="4">
                    <span v-if="lst.bthTm != null && lst.actTm == null" class="fontDotEight mx-1">미작성</span>
                    <span v-else-if="lst.bthTm == null && lst.actTm == null" class="fontDotEight mx-1">미계획</span>
                    <span v-else class="fontDotEight mx-1">작성</span>
                </v-col>
                <v-col v-else-if="lst.outYn == '1'" class="txtAlgnMid py-4" cols="7">
                    <span class="fontDotEight">외박중</span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0 mx-2 tableBB" no-gutters>
                <v-col class="txtAlgnMid px-2 py-2" cols="12">
                    <span class="fontDotEight">조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="$store.state.bnftcrdTotMdl" persistand>
            <BathSrvRcrdMdl v-if="$store.state.bnftcrdCtgMdl.yNo == '2'"></BathSrvRcrdMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

import BathSrvRcrdMdl from './BathSrvRcrdMdl';

export default {
    name: 'BathBlnftSrvRcrd',

    components: {
        BathSrvRcrdMdl,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.sltHypDt == '')
                this.getMobAllBnftBthSttList(this.$store.getters.getPrsntDt('yyyymmdd'))
            else
                this.getMobAllBnftBthSttList(
                    this.$moment(this.$parent.$parent.$parent.$parent.sltHypDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                )
        })
    },

    computed: {
        getSltHypDt: function(){
            return this.$parent.$parent.$parent.$parent.sltHypDt
        },
        getSltLvnRmNm: function(){
            return this.$store.getters.getPickLvnRmNm 
        },
    },

    watch:{
        getSltHypDt: function(dt){
            let hypDt = this.$moment(dt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            this.getMobAllBnftBthSttList(hypDt)
        },
        getSltLvnRmNm: function(){
            this.lstMltplSrch()
        },
    },
        
    methods: {
        //수급자 정보 조회
        getMobAllBnftBthSttList: function (ymd){
            http.post('empApp/authSelAllBthSttList', { inqYMD: ymd })
                .then((response) => ( this.allMobAllBnftBthSttRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelAllBthSttList : ' + error))
        },
        allMobAllBnftBthSttRst: function (res){
            this.orgBnfcrList.splice(0)
            this.bnfcrList.splice(0)
        
            if(res.result != null){
                this.orgBnfcrList = res.result
         
                this.lstMltplSrch()
            }
        },
        //리스트 검색
        lstMltplSrch: function (){
            this.bnfcrList.splice(0)
            
            if(this.$store.getters.getPickLvnRmNm == '전체'){
                if(this.nmSrch == '')
                    this.bnfcrList = Object.assign([], this.orgBnfcrList)
                else
                    this.bnfcrList = Object.assign([], this.orgBnfcrList.filter( v => v.name.includes(this.nmSrch) ))
            }
            else{
                if(this.nmSrch == '')
                    this.bnfcrList = Object.assign(
                        [], this.orgBnfcrList.filter( v => v.lvnRmNm == this.$store.getters.getPickLvnRmNm )
                    )
                else
                    this.bnfcrList = Object.assign(
                        [], this.orgBnfcrList.filter(
                            v => v.lvnRmNm == this.$store.getters.getPickLvnRmNm && v.name.includes(this.nmSrch)
                        )
                    )
            }
        },
        onCbSrMdl: function (bmPk, nm, bTm){
            let obj = {}
            obj.bnMmbrPk = bmPk
            obj.name = nm
            obj.inqYMD = this.$moment(this.$parent.$parent.$parent.$parent.sltHypDt, 'YYYY-MM-DD', true).format('YYYY.MM.DD')

            if(bTm != null){
                this.$store.commit('setBnftcrdTotMdl', true)
                this.$store.commit('setBnftcrdCtgMdl', '2')
                this.$store.commit('setBnftcrdCtgObj', obj)

            }
        },
    },

    data: () => ({
        orgBnfcrList: [],
        bnfcrList: [],
        nmSrch: '',
    }),
};
</script>