<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <router-view></router-view>
        <v-footer
            class="pa-0" color="white"
            app>
            <v-row class="py-2" no-gutters>
                <v-col class="d-flex justify-center" cols="3">
                    <span
                        class="px-3 py-1 rounded"
                        v-ripple="{ center: true, class: 'grey--text' }"
                        @click="rtrAct('BnfrcMgmt')">
                        <div class="d-flex justify-center">
                            <CmmDrawIcon
                                :type="rtNum == 0 ? 'gnb_home_blue' : 'gnb_home_black'"
                                size="26">
                            </CmmDrawIcon>
                        </div>
                        <div class="txtAlgnMid" style="padding-right: 1px; line-height: 0.8;">
                            <span
                                :class="['fontDotSeven font-weight-medium', rtNum == 0 ? 'blue104--text' : 'black--text' ]">
                                홈
                            </span>
                        </div>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center" cols="3">
                    <span
                        class="px-3 py-1 rounded"
                        v-ripple="{ center: true, class: 'grey--text' }"
                        @click="rtrAct('NewNewsApp')">
                        <div class="d-flex justify-center">
                            <CmmDrawIcon
                                :type="rtNum == 1 ? 'gnb_alarm_blue' : 'gnb_alarm_black'"
                                size="26">
                            </CmmDrawIcon>
                        </div>
                        <div class="txtAlgnMid" style="padding-right: 0px; line-height: 0.8;">
                            <span
                                :class="['fontDotSeven font-weight-medium', rtNum == 1 ? 'blue104--text' : 'black--text' ]">
                                새소식
                            </span>
                        </div>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center" cols="3">
                    <span
                        class="px-3 py-1 rounded"
                        v-ripple="{ center: true, class: 'grey--text' }"
                        @click="rtrAct('BnfcrInfoApp')">
                        <div class="d-flex justify-center">
                            <CmmDrawIcon
                                :type="rtNum == 2 ? 'gnb_user_blue' : 'gnb_user_black'"
                                size="26">
                            </CmmDrawIcon>
                        </div>
                        <div class="txtAlgnMid" style="padding-right: 1px; line-height: 0.8;">
                            <span
                                :class="['fontDotSeven font-weight-medium', rtNum == 2 ? 'blue104--text' : 'black--text' ]">
                                수급자
                            </span>
                        </div>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center" cols="3">
                    <span
                        class="px-3 py-1 rounded"
                        v-ripple="{ center: true, class: 'grey--text' }"
                        @click="rtrAct('SiteMapApp')">
                        <div class="d-flex justify-center">
                            <CmmDrawIcon
                                :type="rtNum == 3 ? 'gnb_more_blue' : 'gnb_more_black'"
                                size="26">
                            </CmmDrawIcon>
                        </div>
                        <div class="txtAlgnMid" style="padding-right: 0px; line-height: 0.8;">
                            <span
                                :class="['fontDotSeven font-weight-medium', rtNum == 3 ? 'blue104--text' : 'black--text' ]">
                                더보기
                            </span>
                        </div>
                    </span>
                </v-col>
            </v-row>
        </v-footer>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../cmm/CmmDrawIcon.vue';
import { shutDownApp } from '@/assets/js/ntvBridge';

export default {
    name: 'BnfcrTpl',

    components: {
        CmmDrawIcon,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.rflPath(this.$route.name)
        })
    },

    computed: {
        getRouterPath: function () {
            return this.$route.name
        },
    },

    watch:{
        getRouterPath: function (v) {
            this.rflPath(v)
        },
    },

    methods: {
        cnfLogout: function (){
            this.$store.commit('setToken', '')
            this.$store.commit('setSctKey', '')
            this.$router.push({ name: 'SprtdUser' })

            shutDownApp()
        },
        rtrAct: function (nm){
            if(this.$route.name != nm)
                this.$router.push({ name: nm })

            this.rflPath(nm)
        },
        drwAct: function (nm){
            if(nm != '' && nm != this.$route.name)
                this.$router.push({ name: nm })
        },
        rflPath: function (path){
            switch (path) {
                case 'BnfrcMgmt':
                    this.rtNum = 0
                    break;
                case 'NewNewsApp':
                    this.rtNum = 1
                    break;
                case 'BnfcrInfoApp':
                    this.rtNum = 2
                    break;
                case 'SiteMapApp':
                    this.rtNum = 3
                    break;
                default:
                    this.rtNum = -1
                    break;
            }
        },
    },

    data: () => ({
        rtNum: 0,
        appBarTtl: '',
        drw: false,
        vLst: 0,
        drwList: [
            {
                icon: 'mdi-account-outline',
                title: '내 정보',
                action: 'BnfrcMgmt',
            },
            // {
            //     icon: 'mdi-clipboard-text-outline',
            //     title: '노인장기요양급여',
            //     action: 'PyrlSrvcMgmt',
            // },
        ],
    }),
};
</script>