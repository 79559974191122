<template>
    <v-sheet class="">
        <v-row class="pa-0 align-center" no-gutters>
            <span class="fontOneToOne font-weight-bold" style="padding-bottom: 1px;">
                {{ $moment(dotDt, 'YYYY.MM.DD').format('YYYY년 MM월 DD일') }}
            </span>
            <v-spacer></v-spacer>
            <v-btn class="" @click="prevAct" icon>
                <CmmDrawIcon type="ic_prev" size="20"></CmmDrawIcon>
            </v-btn>
            <v-btn class="mr-1" @click="nxtAct" icon>
                <CmmDrawIcon type="ic_nxt" size="20"></CmmDrawIcon>
            </v-btn>
            <v-btn class="" @click="$store.commit('setPickDtMdl', true)" icon>
                <CmmDrawIcon type="ic_calendar" size="20"></CmmDrawIcon>
            </v-btn>
        </v-row>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from './CmmDrawIcon.vue';

export default {
    name: 'AppYMD',

    components: {
        CmmDrawIcon,
    },

    props: {
        aplyYMD: { type: String, default: '' },
        aplyMinDt: { type: String, default: '' },
        aplyMaxDt: { type: String, default: '' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$store.commit('setPickDtMdlTyp', 'YMD')

            if(this.aplyYMD != ''){
                this.dotDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY-MM-DD')
                this.$store.commit('setPickDt', this.hypDt)
            }
            else{
                this.dotDt = this.$store.getters.getPrsntDt('yyyy.mm.dd')
                this.hypDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')
            }

            if(this.aplyMinDt != '')
                this.$store.commit('setPickDtMdlMin', this.aplyMinDt)
            if(this.aplyMaxDt != '')
                this.$store.commit('setPickDtMdlMax', this.aplyMaxDt)
        })
    },

    destroyed: function(){
        this.$store.commit('setPickDtMdlTyp', 'YMD')
        this.$store.commit('setPickDt', '')
        this.$store.commit('setPickDtMdlMin', '')
        this.$store.commit('setPickDtMdlMax', '')
    },

    computed: {
        getPickDt: function () {
            return this.$store.state.pickDt
        },
    },

    watch:{
        getPickDt: function (v) {
            this.dotDt = this.$moment(v, 'YYYY-MM-DD', true).format('YYYY.MM.DD')
            this.hypDt = v
            this.transDt()
        },
        'aplyYMD': function () {
            if(this.aplyYMD != '' && this.aplyYMD != this.hypDt){
                this.dotDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.aplyYMD, 'YYYYMMDD', true).format('YYYY-MM-DD')
                this.$store.commit('setPickDt', this.hypDt)
            }
        },
        'aplyMinDt': function () {
            if(this.aplyMinDt != '')
                this.$store.commit('setPickDtMdlMin', this.aplyMinDt)
            else
                this.$store.commit('setPickDtMdlMin', '')
        },
        'aplyMaxDt': function () {
            if(this.aplyMaxDt != '')
                this.$store.commit('setPickDtMdlMax', this.aplyMaxDt)
            else
                this.$store.commit('setPickDtMdlMax', '')
        },
    },
        
    methods: {
        prevAct: function () {
            if(this.aplyMinDt != ''){
                if(this.$moment(this.aplyMinDt).diff(this.hypDt, 'days') < 0){
                    this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).subtract(1, 'days').format('YYYY.MM.DD')
                    this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).subtract(1, 'days').format('YYYY-MM-DD')
                }
            }
            else{
                this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).subtract(1, 'days').format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).subtract(1, 'days').format('YYYY-MM-DD')
            }
            this.transDt()
        },
        nxtAct: function () {
            if(this.aplyMaxDt != ''){
                if(this.$moment(this.aplyMaxDt).diff(this.hypDt, 'days') > 0){
                    this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).add(1, 'days').format('YYYY.MM.DD')
                    this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).add(1, 'days').format('YYYY-MM-DD')
                }
            }
            else{
                this.dotDt = this.$moment(this.dotDt, 'YYYY.MM.DD', true).add(1, 'days').format('YYYY.MM.DD')
                this.hypDt = this.$moment(this.hypDt, 'YYYY-MM-DD', true).add(1, 'days').format('YYYY-MM-DD')
            }
            this.transDt()
        },
        transDt: function () {
            this.$emit('sltDotDt', this.dotDt)
            this.$emit('sltHypDt', this.hypDt)
        },
    },

    data: () => ({
        dotDt: '',
        hypDt: '',
    }),
};
</script>