<template>
    <v-sheet class="py-3" width="100%">
        <CmmYMD
            class="my-3"
            :aplyMaxDt="$moment().format('YYYY-MM-DD')"
            @sltHypDt="getCompDt">
        </CmmYMD>
        <v-divider class="mx-4 mt-5 mb-6"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <span class="overflow-x-auto">
                    <v-tabs
                        class="cstTabs" slider-size="1" height="40"
                        v-model="tab" show-arrows>
                        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                        <v-tab
                            active-class="greyE02 blue102--text"
                            v-for="(tb, i) in tabMenu" :key="i">
                            <span class="fontOne">{{ tb.tbNm }}</span>
                        </v-tab>
                    </v-tabs>
                </span>
            </v-col>
            <v-col cols="12">
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item transition="false">
                        <CareBlnftSrvRcrd v-if="tab == 0"></CareBlnftSrvRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <MealSrvRcrd v-if="tab == 1"></MealSrvRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <BathBlnftSrvRcrd v-if="tab == 2"></BathBlnftSrvRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <ChgPosObsrvRcrd v-if="tab == 3"></ChgPosObsrvRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <TltUseRcrd v-if="tab == 4"></TltUseRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <CncntExcrtObsrvRcrd v-if="tab == 5"></CncntExcrtObsrvRcrd>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <PhyscSnctnRcrd v-if="tab == 6"></PhyscSnctnRcrd>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import CmmYMD from '../cmm/CmmYMD_kr.vue';

import CareBlnftSrvRcrd from './bnftcrd/CareBlnftSrvRcrd.vue';          // 요양급여제공기록
import MealSrvRcrd from './bnftcrd/MealSrvRcrd.vue';                    // 식사하기
import BathBlnftSrvRcrd from './bnftcrd/BathBlnftSrvRcrd.vue';          // 몸씻기
import ChgPosObsrvRcrd from './bnftcrd/ChgPosObsrvRcrd.vue';            // 체위변경
import TltUseRcrd from './bnftcrd/TltUseRcrd.vue';                      // 화장실이용
import CncntExcrtObsrvRcrd from './bnftcrd/CncntExcrtObsrvRcrd.vue';    // 집중배설
import PhyscSnctnRcrd from './bnftcrd/PhyscSnctnRcrd.vue';              // 신체제재

export default {
    name: 'BnftrcrdMgmt',

    components: {
        CmmYMD,
        CareBlnftSrvRcrd,
        MealSrvRcrd,
        BathBlnftSrvRcrd,
        ChgPosObsrvRcrd,
        TltUseRcrd,
        CncntExcrtObsrvRcrd,
        PhyscSnctnRcrd,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$parent.$parent.appBarTtl = '요양급여제공'
            this.sltHypDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        getCompDt: function (dt) {
            this.sltHypDt = dt
        },
    },

    data: () => ({
        tab: 0,
        sltHypDt: '',
        tabMenu: [
            { tbNm: '급여' }, { tbNm: '식사' }, { tbNm: '목욕' }, { tbNm: '체위' },
            { tbNm: '화장실' }, { tbNm: '집중배설' }, { tbNm: '신체제재' }, 
        ],
    }),
};
</script>