<template>
    <v-dialog v-model="$store.state.pickLvnRmMdl">
        <v-sheet class="ma-0 py-4 pl-4 pr-2" rounded="lg">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="10">
                    <span class="font-weight-bold fontOneToTwo">생활실 선택</span>
                </v-col>
                <v-col class="d-flex" cols="2">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="onClose"
                        style="margin-top: 2px;" icon>
                        <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            
            <v-row class="pr-2 mt-4" no-gutters>
                <v-col cols="12">
                    <v-btn width="100%" color="greyE00" @click="aplyLvnRm('전체')" outlined>
                        <span class="grey006--text fontOne">전체</span>
                    </v-btn>
                </v-col>
            </v-row>
            <div
                class="d-inline-block overflow-y-auto mt-1"
                :style="{ 'width': '100%', 'height': ($vuetify.breakpoint.height * 0.67)+'px',}">
                <v-divider class="mr-2"></v-divider>
                <v-row class="pr-2" v-for="(bNm, i) in bldNmList" :key="i" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBB" cols="4">
                        {{ bNm }}
                    </v-col>
                    <v-col cols="8">
                        <v-row class="" v-for="(bFlr, j) in bldFrlList[i]" :key="j" no-gutters>
                            <v-col class="d-flex justify-center align-center tableBL tableBB" cols="5">
                                {{ bFlr }}층
                            </v-col>
                            <v-col cols="7">
                                <v-row
                                    class=""
                                    v-for="(bLvn, k) in orgLvnRmList.filter( v => v.bldNm == bNm && v.bldFlr == bFlr )" :key="k"
                                    no-gutters>
                                    <v-col class="sltFld tableBL tableBB py-1" @click="aplyLvnRm(bLvn.lvnRmNm)" cols="12">
                                        <div class="txtAlgnMid">{{ bLvn.lvnRmNm }}</div>
                                        <div class="txtAlgnMid">({{ Number(bLvn.fxNum) - Number(bLvn.rmnCnt) }}명)</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <v-row class="pr-2" no-gutters>
                <v-col cols="12">
                    <v-btn width="100%" color="greyE00" @click="aplyLvnRm('전체')" outlined>
                        <span class="grey006--text fontOne">전체</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'CmmLvnRmPickMdl',

    components: {
      
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$store.getters.getToken != '')
                this.getLvnRmList()
        })
    },

    computed: {
        getToken: function () {
            return this.$store.getters.getToken
        }
    },

    watch:{
        getToken: function () {
            if(this.$store.getters.getToken != '')
                this.getLvnRmList()
        },
    },
        
    methods: {
        //직원 정보 조회
        getLvnRmList: function (){
            http.post('empApp/authSelLvnRmList', {})
                .then((response) => ( this.lvnRmListRst(response.data) ))
                .catch((error) => console.log('axios error /empApp/authSelLvnRmList : ' + error))
        },
        lvnRmListRst: function (res){
            this.bldNmList.splice(0)
            this.bldFrlList.splice(0)
            this.orgLvnRmList.splice(0)

            //fxNum 정원, rmnCnt 잔여
             if(res.result != null){
                this.orgLvnRmList = Object.assign([], res.result)
                this.lvnRmGrping(res.result)
             }
             else{
                let obj = {}
                obj.clr = 'info'
                obj.cntn = res.message

                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', obj)
             }
        },
        lvnRmGrping: function (arr){
            let bldnm = []

            arr.forEach(lll => {
                bldnm.push(lll.bldNm)
            });

            let bldnmSet = new Set(bldnm)
            this.bldNmList = [...bldnmSet]

            for (let index = 0; index < this.bldNmList.length; index++) {
                this.bldFrlList.push([])
            }

            this.bldNmList.forEach((nm, i) => {
                let bldFlr = []
                let flrList = arr.filter( v => v.bldNm == nm )

                if(flrList.length > 0){
                    flrList.forEach(ll => {
                        bldFlr.push(ll.bldFlr)
                    });
                }

                let bldFrlSet = new Set(bldFlr)
 
                this.bldFrlList.splice(i, 1, [...bldFrlSet])
            });

        },
        onClose: function () {
            this.$store.commit('setPickLvnRmMdl', false)
        },
        aplyLvnRm: function (txt) {
            this.$store.commit('setPickLvnRmNm', txt)
            this.onClose()
        },
    },

    data: () => ({
        bldNmList: [],
        bldFrlList: [],
        orgLvnRmList: [],
    }),
};
</script>