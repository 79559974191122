<template>
    <v-sheet class="">
        <v-row class="pa-0 align-center" no-gutters>
            <v-spacer></v-spacer>
            <v-btn class="arwBtn mr-2" color="btnBrdrColor" height="26" @click="prevAct" small outlined>
                <span class="black--text">
                    <v-icon>mdi-chevron-left</v-icon>
                </span>
            </v-btn>
            <v-btn class="arwBtn" color="btnBrdrColor" height="26" @click="nxtAct" small outlined>
                <span class="black--text">
                    <v-icon>mdi-chevron-right</v-icon>
                </span>
            </v-btn>
            <v-col cols="1"></v-col>
            <span class="blue102--text text-h5 font-weight-bold" v-text="dotMthDt"></span>
            <v-col cols="1"></v-col>
            <v-btn class="arwBtn pa-0"
                color="btnBrdrColor" height="26" @click="$store.commit('setPickDtMdl', true)" small outlined>
                <v-icon class="black--text" style="margin-top: 2px;" small>mdi-calendar-month</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </v-row>
    </v-sheet>
</template>

<script>


export default {
    name: 'CmmYYMM_kr',

    components: {
       
    },

    props: {
        aplyYyMm: { type: String, default: '' },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.$store.commit('setPickDtMdlTyp', 'YYMM')
            
            if(this.aplyYyMm != ''){
                this.dotMthDt = this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYY.MM')
                this.hypMthDt = this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYY-MM')
                this.$store.commit('setPickDt', this.hypMthDt)
            }
            else{
                this.dotMthDt = this.$store.getters.getPrsntDt('yyyy.mm')
                this.hypMthDt = this.$store.getters.getPrsntDt('yyyy-mm')
            }
        })
    },

    computed: {
        getPickDt: function () {
            return this.$store.state.pickDt
        },
    },

    watch:{
        getPickDt: function (v) {
            if(!(this.$moment(v, 'YYYY-MM', true).format('YYYY.MM') == 'Invalid date')){
                this.dotMthDt = this.$moment(v, 'YYYY-MM', true).format('YYYY.MM')
                this.hypMthDt = v
                this.transDt()
            }
        },
        'aplyYyMm': function () {
            if(this.aplyYyMm != '' && this.aplyYyMm != this.hypMthDt){
                this.dotMthDt = this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYY.MM')
                this.hypMthDt = this.$moment(this.aplyYyMm, 'YYYYMM', true).format('YYYY-MM')
                this.$store.commit('setPickDt', this.hypMthDt)
            }
        },
    },
        
    methods: {
        prevAct: function () {
            this.dotMthDt = this.$moment(this.dotMthDt, 'YYYY.MM', true).subtract(1, 'months').format('YYYY.MM')
            this.hypMthDt = this.$moment(this.hypMthDt, 'YYYY-MM', true).subtract(1, 'months').format('YYYY-MM')
            this.transDt()
        },
        nxtAct: function () {
            this.dotMthDt = this.$moment(this.dotMthDt, 'YYYY.MM', true).add(1, 'months').format('YYYY.MM')
            this.hypMthDt = this.$moment(this.hypMthDt, 'YYYY-MM', true).add(1, 'months').format('YYYY-MM')
            this.transDt()
        },
        transDt: function () {
            this.$emit('sltDotDt', this.dotMthDt)
            this.$emit('sltHypDt', this.hypMthDt)
        },
    },

    data: () => ({
        dotMthDt: '',
        hypMthDt: '',
    }),
};
</script>