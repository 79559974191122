<template>
    <v-sheet class="nmlBfClrImp-greyE04" width="100%" height="100%">
        <div
            class="d-inline-block d-flex align-center blue104"
            style="width: 100%; height: 70px; position: fixed; top: 0; left: 0; z-index: 6;">
            <v-row class="pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="d-flex" cols="11">
                    <span
                        class="d-flex align-center rounded"
                        style="padding: 0 6px 0 0px;"
                        @click="prvRtr()"
                        v-ripple="{ center: true, class: 'grey--text' }">
                        <span style="padding-top: 5px;">
                            <CmmDrawIcon type="ic_slt_arrow_white" size="22"></CmmDrawIcon>
                        </span>
                        <span class="fontOneToOne white--text font-weigth-bold ml-3">건강정보</span>
                    </span>
                    <v-spacer></v-spacer>
                    <CmmDrawIcon class="mr-2" mode="logo" type="md_logo_white" size="26"></CmmDrawIcon>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
        <div
            class="d-inline-block"
            :style="{ 'width': '100%', 'height': viewHeight + 'px', 'marginTop': '86px' }">
            <div
                class="d-inline-block rounded-xl rounded-b-0 white"
                style="width: 100%; height: 99%; overflow-y: auto; z-index: 2;">
                <div
                    class="d-inline-block rounded-xl rounded-b-0 white"
                    style="width: 100%; position: fixed; z-index: 2;">
                    <div class="px-6 py-3">
                        <AppYMD :aplyMinDt="'2020-01-01'" :aplyMaxDt="$moment().format('YYYY-MM-DD')" @sltHypDt="getHypDt"></AppYMD>
                    </div>
                    <v-divider></v-divider>
                    <v-divider class="greyE04" style="padding: 1px 0;"></v-divider>
                </div>
                <v-row class="pt-5 pb-4 mt-15" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex align-center" cols="10">
                        <CmmDrawIcon type="gnb_health" size="20"></CmmDrawIcon>
                        <span class="fontDotNine font-weight-medium ml-2" style="padding-bottom: 1px;">건강정보</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="pb-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="10">
                        <div v-for="(list, i) in healthInfList" :key="i">
                            <v-divider class="black002"></v-divider>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">관찰시간</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.obsrvTmHh }}</span>
                                    <span class="fontDotEight" style="padding: 0 2px;">:</span>
                                    <span class="fontDotEight">{{ list.obsrvTmMm }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">혈압&nbsp;&#40;고&#47;저&#41;</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.bldPrsH }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">mm/Hg</span>
                                    <span class="fontDotEight" style="padding: 0 2px;">&nbsp;&#47;&nbsp;</span>
                                    <span class="fontDotEight">{{ list.bldPrsL }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">mm/Hg</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">맥박&nbsp;&#47;&nbsp;체온</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.pulse }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">회/분</span>
                                    <span class="fontDotEight" style="padding: 0 2px;">&nbsp;&#47;&nbsp;</span>
                                    <span class="fontDotEight">{{ list.tmprt }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">℃</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">호흡&nbsp;&#47;&nbsp;혈당</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.rsprt }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">회/분</span>
                                    <span class="fontDotEight" style="padding: 0 2px;">&nbsp;&#47;&nbsp;</span>
                                    <span class="fontDotEight">{{ list.bldSgr }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">mg/dL</span>
                                </v-col>
                            </v-row>
                            <v-row class="tableBB" no-gutters>
                                <v-col class="tableBR pa-1" cols="6">
                                    <span class="fontDotEight font-weight-medium">체중</span>
                                </v-col>
                                <v-col class="txtAlgnEnd pa-1" cols="6">
                                    <span class="fontDotEight">{{ list.wght }}</span>
                                    <span style="margin-left: 1px; font-size: 0.6rem;">kg</span>
                                </v-col>
                            </v-row>
                            <v-divider class="black002 mb-5"></v-divider>
                        </div>
                        <div v-if="healthInfList.length == 0">
                            <v-divider class="black002"></v-divider>
                            <div class="txtAlgnMid my-1">
                                <span class="grey006--text fontDotSeven">작성된 건강정보가 없습니다.</span>
                            </div>
                            <v-divider class="mt-2"></v-divider>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import CmmDrawIcon from '../../cmm/CmmDrawIcon.vue';
import AppYMD from '../../cmm/AppYMD.vue';

import { http } from '@/assets/js/baseAxios';

export default {
    name: 'HealthVtlApp',

    components: {
        CmmDrawIcon,
        AppYMD,
    },

    props: {

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.viewHeight = this.$vuetify.breakpoint.height - 154
            this.getHlthVtlList(this.$moment().format('YYYYMMDD'))
        })
    },

    computed: {
        getFrameHeight: function () {
            return this.$vuetify.breakpoint.height
        },
    },

    watch:{
        getFrameHeight: function (v) {
            this.viewHeight = v - 154
        },
    },
        
    methods: {
        // 건강 제공기록 (일간)
        getHlthVtlList: function (ymd){
            this.healthInfList.splice(0)

            http.post('fmlyApp/authHlthVtlList', { inqYMD: ymd })
                .then((response) => ( this.hlthVtlRst(response.data) ))
                .catch((error) => console.log('axios error /fmlyApp/authHlthVtlList : ' + error))
        },
        hlthVtlRst: function (res){
            if(res.statusCode == '200'){
                if(res.hlthVtlList.length > 0)
                    this.healthInfList = res.hlthVtlList
            }
            else{
                this.$store.commit('setCmmAlertMdl', true)
                this.$store.commit('setCmmAlertCntn', { clr: 'error', cntn: res.message })
            }
        },
        prvRtr: function (){
            this.$router.go(-1)
        },
        getHypDt: function (v){
            if(v != '')
                this.getHlthVtlList(this.$moment(v, 'YYYY-MM-DD').format('YYYYMMDD'))
        },
    },

    data: () => ({
        viewHeight: 0,
        healthInfList: [],
    }),
};
</script>