<template>
    <v-sheet
        class="ma-0 pa-4" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-medium fontOneToTwo">{{ $store.state.signAgrObj.msgTtl }}</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <vue-esign
                    class="rounded" style="border: 3px solid #4bc5e8;"
                    ref="esign" format="image/png" :width="signWidth" :height="signHeight"
                    :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                </vue-esign>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3 mb-1" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    style="font-size: 16px;" height="26" color="grey006" @click="handleReset" outlined rounded>
                    <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                    지우기
                </v-btn>
                <v-btn
                    class="ml-3 white--text" height="26" color="blue001" rounded @click="handleGenerate"
                    style="font-size: 16px;">
                    <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                    저장
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'BnftSrvPln',

    props : {

    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {
        getSignAgrObj: function () {
            return  this.$store.getters.getSignAgrObj
        }
    },

    watch:{

    },

    beforeDestroy: function () {

    },
        
    methods: { 
        //급여제공계획 저장
        setFmlyInsSign: function (obj){
            http.post('fmly/fmlyInsSign', obj)
                .then((response) => ( this.fmlyInsSignRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyInsSign : ' + error))
        },
        fmlyInsSignRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.onClose()
            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        handleReset: function () {
            this.$refs.esign.reset()
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res

                let obj = {}
                obj.bzPk = this.$store.getters.getSignAgrObj.bzPk
                obj.eDocClcd = this.$store.state.signAgrObj.eDocClcd
                obj.bzClcd = this.$store.state.signAgrObj.bzClcd
                obj.sign = res

                this.setFmlyInsSign(obj)
            }).catch(err => {
                console.log(err)
            })
        },
        onClose: function () {
            this.$store.commit('setSignAgrObj', {})
            this.$store.commit('setSignAgrMdl', false)
        },
    },
    
    data: () => ({
        agrCnfm: 2,
        sign: '',
        signWidth: 300,
        signHeight: 225,
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
    }),
};
</script>