<template>
    <v-app>
        <!-- body -->
        <v-main>
            <router-view></router-view>
        </v-main>

        <!-- common Modal -->
        <div class="" style="position: fixed; z-index: 999; top: 0px; left: 50%; transform: translateX(-50%);">
            <v-snackbar
                class="cmmNtyBar"
                v-model="$store.state.cmmAlertMdl" timeout="2500" :value="true" color="white"
                transition="slide-y-transition" absolute centered top>
                <v-alert
                    class="cmmAlt" border="top" :type="$store.state.cmmAlertCntn.clr"
                    colored-border dense>
                    <span class="fontDotNine font-weight-medium black--text mb-1">{{ $store.state.cmmAlertCntn.cntn }}</span>
                </v-alert>
            </v-snackbar>
        </div>
        <!-- <div class="mdlPosnTop">
            <v-dialog v-model="$store.state.cmmAlertMdl" content-class="elevation-8" hide-overlay attach>
                <v-snackbar
                    :timeout="3000">
                    <v-alert
                        class="pt-3" border="top" :type="$store.state.cmmAlertCntn.clr"
                        colored-border dense>
                        <span class="fontDotNine font-weight-medium">{{ $store.state.cmmAlertCntn.cntn }}</span>
                    </v-alert>
                </v-snackbar>
            </v-dialog>
        </div> -->
        <v-dialog v-model="$store.state.cmmPrtMdl" eager>
            <PdfCore ref="pdfCore"></PdfCore>
        </v-dialog>
        <CmmDtPickMdl></CmmDtPickMdl>
        <v-dialog v-model="$store.state.signAgrMdl" eager>
            <StndCndt v-if="$store.state.signAgrObj.eDocClcd == '1'"></StndCndt>
            <PrvcyAgr v-else-if="$store.state.signAgrObj.eDocClcd == '2'"></PrvcyAgr>
            <BnftSrvPln v-else-if="$store.state.signAgrObj.eDocClcd == '4'"></BnftSrvPln>
            <EmpSignMdl v-else-if="$store.state.signAgrObj.eDocClcd == '8'"></EmpSignMdl>
            <EmpNtcMdl v-else-if="$store.state.signAgrObj.eDocClcd == '201'"></EmpNtcMdl>
        </v-dialog>
        <v-bottom-sheet
            v-model="$store.state.cmmBtmMdl" inset>
            <v-card class="rounded-t-xl">
                <GrdnLgn v-if="$store.getters.getUserSpr == 'gndr'"></GrdnLgn>
                <EmpLgn v-else-if="$store.getters.getUserSpr == 'emp'"></EmpLgn>
            </v-card>
        </v-bottom-sheet>
    </v-app>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'

import { http } from '@/assets/js/baseAxios';

import GrdnLgn from './components/lgn/GrdnLgn.vue'
import EmpLgn from './components/lgn/EmpLgn.vue'

import PdfCore from './components/cmm/PdfCore.vue'
import CmmDtPickMdl from './components/cmm/CmmDtPickMdl';

import StndCndt from './components/bnfcr/StndCndt.vue'
import PrvcyAgr from './components/bnfcr/PrvcyAgr.vue'
import BnftSrvPln from './components/bnfcr/BnftSrvPln.vue'
import EmpNtcMdl from './components/emp/EmpNtcMdl.vue'
import EmpSignMdl from './components/emp/EmpSignMdl.vue'

require('./assets/css/font.css')
require('./assets/css/commons.css')
require('./assets/js/ntvBridge.js')

export default {
    name: 'App',

    components: {
        GrdnLgn,
        EmpLgn,
        PdfCore,
        CmmDtPickMdl,
        StndCndt,
        PrvcyAgr,
        BnftSrvPln,
        EmpNtcMdl,
        EmpSignMdl
    },

    created: function(){
        //현재 날짜를 전역변수로 이용하기 위해 스토어에 저장
        let dt = [
            { typ: 'yyyymmdd', dt: this.$moment().format('YYYYMMDD')},
            { typ: 'yyyy-mm-dd', dt: this.$moment().format('YYYY-MM-DD') },
            { typ: 'yyyy.mm.dd', dt: this.$moment().format('YYYY.MM.DD') },
            { typ: 'yyyymm', dt: this.$moment().format('YYYYMM') },
            { typ: 'yyyy.mm', dt: this.$moment().format('YYYY.MM') },
            { typ: 'yyyy-mm', dt: this.$moment().format('YYYY-MM') },
            { typ: 'yyyy', dt: this.$moment().format('YYYY') }, { typ: 'mm', dt: this.$moment().format('MM') },
            { typ: 'dd', dt: this.$moment().format('DD') }
        ]
        this.$store.commit('setPrsntDt', dt)

        //console.log(this.$store.getters.getPrsntDt('yyyy-mm-dd'))
    },

    mounted: function(){
        document.querySelector('head').insertAdjacentHTML("beforeend", `<link rel="manifest" href="test.webmanifest">`)

        this.$nextTick(function () {
            this.getCmnItmValCdJsn(['42', '45', '65', '73', '107'])
        })
    },

    methods: {
        //공통코드 조회
        getCmnItmValCdJsn: function (arr){
            http.post('cmm/selCmnItmValCdJsn', { valClcdS: arr })
                .then((response) => ( this.cmnItmValCdJsnRst(response.data) ))
                .catch((error) => console.log('axios error /cmm/selCmnItmValCdJsn : ' + error))
        },
        cmnItmValCdJsnRst: function (res){
            let code = [
                { typ: 'vCd42', list: res.vCd42 },
                { typ: 'vCd45', list: res.vCd45 },
                { typ: 'vCd65', list: res.vCd65 },
                { typ: 'vCd73', list: res.vCd73 },
                { typ: 'vCd107', list: res.vCd107 }
            ]

            this.$store.commit('setCmmCode', code)
        },
        addToHomeScreen: function (){
            console.log('navigator' in window && 'getInstalledRelatedApps' in window.navigator)
            if ('navigator' in window && 'standalone' in window.navigator && window.navigator.standalone) {
                this.tts = '홈 화면에 추가되어 있습니다.'
                console.log('홈 화면에 추가되어 있습니다.')
            }
            else if ('navigator' in window && 'getInstalledRelatedApps' in window.navigator){
                window.navigator.getInstalledRelatedApps().then((relatedApps) => {
                    if (relatedApps.length > 0) {
                        this.tts = '홈 화면에 추가되어 있습니다.'
                        console.log('홈 화면에 추가되어 있습니다.')
                    } else {
                        this.tts = '홈 화면에 추가되어 않습니다.'
                        console.log('홈 화면에 추가되어 있지 않습니다.')
                    }
                });
            }
        },
    },

    data: () => ({
        cmmBtmMdl: false,
        tts: ''
    }),
};
</script>