<template>
    <v-sheet
        class="ma-0 pa-4" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="10">
                <span class="font-weight-medium fontOneToTwo">{{ $store.state.signAgrObj.msgTtl }}</span>
            </v-col>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    @click="onClose"
                    style="margin-top: 2px;" icon>
                    <v-icon class="grey006--text" size="30">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="py-1 greyE02" style="text-align: center;" cols="12">
                <span class="fontOne font-weight-medium">계약서 확인사항</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="12">
                <div
                    class="tableBB py-1" 
                    v-for="(cfm, i) in cntrcCnfm1" :key="i"
                    >
                    <span class="fontDotNine">{{ cfm.cnfmCntnt }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-checkbox
                    v-model="agrCnfm" label="위 항목에 모두 동의 합니다."
                    class="mt-3" style="" true-value="1" false-value="2"
                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                    hide-details>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-divider v-if="cntrcCnfm2.length > 0" class="mt-5"></v-divider>
        <v-row v-if="cntrcCnfm2.length > 0" class="pa-0" no-gutters>
            <v-col class="py-1 greyE02" style="text-align: center;" cols="12">
                <span class="fontOne font-weight-medium">낙상위험 방지 관련 확인사항</span>
            </v-col>
        </v-row>
        <v-divider v-if="cntrcCnfm2.length > 0" class=""></v-divider>
        <v-row class="pa-0" v-for="(cfm, i) in cntrcCnfm2" :key="i" no-gutters>
            <v-col class="tableBB py-1" cols="4">
                <span class="fontDotNine">{{ cfm.cnfmCntnt }}</span>
            </v-col>
            <v-col class="tableBB py-1" cols="8">
                <span class="fontDotNine">{{ cfm.cnfmCntntDtl }}</span>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" ref="divEsign" cols="12">
                <vue-esign
                    class="rounded" style="border: 3px solid #4bc5e8;"
                    ref="esign" format="image/png" :width="signWidth" :height="signHeight"
                    :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor">
                </vue-esign>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3 mb-1" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    style="font-size: 16px;" height="26" color="grey006" @click="handleReset" outlined rounded>
                    <v-icon class="mr-1" size="18">mdi-eraser</v-icon>
                    지우기
                </v-btn>
                <v-btn
                    class="ml-3 white--text" height="26" color="blue001" rounded :disabled="agrCnfm == 2" @click="handleGenerate"
                    style="font-size: 16px;">
                    <v-icon class="mr-1" size="18">mdi-content-save-outline</v-icon>
                    저장
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'StndCndt',

    props : {
     
    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCntrcCnfmList(this.$store.getters.getSignAgrObj.bzPk)

            this.signWidth = this.$refs.divEsign.clientWidth
            this.signHeight = (this.$refs.divEsign.clientWidth/4) * 3
        })
    },

    computed: {
        getSignAgrObj: function () {
            return  this.$store.getters.getSignAgrObj
        }
    },

    watch:{

    },

    beforeDestroy: function () {

    },
        
    methods: { 
        //계약서 확인사항 조회
        getCntrcCnfmList: function (pk){
            http.post('fmly/selCntrcCnfmList', { bzPk: pk })
                .then((response) => ( this.cntrcCnfmListRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selCntrcCnfmList : ' + error))
        },
        //계약서 확인사항 저장
        setCntrcCnfmList: function (obj){
            http.post('fmly/fmlyInsBnftCnFm', obj)
                .then((response) => ( this.cntrcCnfmRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/fmlyInsBnftCnFm : ' + error))
        },
        cntrcCnfmListRst: function (res){
            this.cntrcCnfm1.splice(0)
            this.cntrcCnfm2.splice(0)

            this.cntrcCnfm1 = res.result.filter( v => v.cnfmClcd == '1' )
            this.cntrcCnfm2 = res.result.filter( v => v.cnfmClcd == '2' &&  v.cnfmYn == '1' )
        },
        cntrcCnfmRst: function (res){
            let obj = {}
            if(res.statusCode == '200'){
                obj.clr = 'info'
            }
            else
                obj.clr = 'error'

            obj.cntn = res.message

            this.onClose()
            this.$store.commit('setCmmAlertMdl', true)
            this.$store.commit('setCmmAlertCntn', obj)
        },
        handleReset: function () {
            this.$refs.esign.reset()
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res

                let obj = {}
                obj.bzPk = this.$store.getters.getSignAgrObj.bzPk
                obj.eDocClcd = this.$store.state.signAgrObj.eDocClcd 
                obj.bzClcd = this.$store.state.signAgrObj.bzClcd 
                obj.sign = res

                this.cntrcCnfm1.forEach(el => {
                    el.cnfmYn = '1'
                });

                obj.cnfmCntnt = this.cntrcCnfm1
                this.setCntrcCnfmList(obj)
            }).catch(err => {
                console.log(err)
            })
        },
        onClose: function () {
            this.$store.commit('setSignAgrObj', {})
            this.$store.commit('setSignAgrMdl', false)
        },
    },
    
    data: () => ({
        agrCnfm: 2,
        signWidth: 300,
        signHeight: 225,
        cntrcCnfm1: [],             //계약서 확인사항
        cntrcCnfm2: [],             //낙상위험 방지 관련 확인사항
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
    }),
};
</script>